import * as React from 'react';

export type sizeType = {
  size?: string;
};

type BaseIconProps = {
  children: React.ReactNode;
  background?: boolean;
  backgroundClassName?: string;
  backgroundSize?: string;
  size?: string;
  viewBox?: string;
};

const BaseIcon = ({
  children,
  background = false,
  backgroundClassName = 'bg-white bg-opacity-10 rounded-lg flex items-center justify-center',
  backgroundSize = 'w-8 h-8',
  size = 'w-6 h-6',
  viewBox,
}: BaseIconProps) => {
  const backgroundClasses = `${
    background ? backgroundSize + ' ' + backgroundClassName : ''
  }`;

  return (
    <div className={backgroundClasses}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox ? viewBox : '0 0 20 20'}
        className={size}
        stroke='inherit'
        strokeWidth='2'>
        {children}
      </svg>
    </div>
  );
};

export {BaseIcon};
