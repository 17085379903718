import { Tabs } from 'antd';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Intervention from 'Routes/OrganisationInsights/Children/ViewDetails/components/Sprints/components/Menu/Intervention';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getMissionDetail } from 'Services/Mission.service';
import { getSprint } from 'Services/Sprints.service';
import { cleanSprint } from '../../../../../../ReduxV2/state';
import StatusAndTrend from './components/StatusAndTrend/StatusAndTrend';

const { TabPane } = Tabs;

const MissionLayout: React.FC = () => {
  // ``localhost:3000/1441/organisation-insights/intel/intervention/?mission=1097&mission_sprint=686``
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory(); // Initialize useHistory

    // Function to parse query parameters
  const getQueryParams = (query: string) => {
    return new URLSearchParams(query);
  };

  const {id}:any = useParams();
  const queryParams = getQueryParams(location.search);
  const missionIdParam:any = queryParams.get('mission');
  const missionSprintIdParam:any = queryParams.get('mission_sprint');

  const [sprint, setSprint] = useState<any>({});

  const [t] = useTranslation()

  const sprint_id = missionSprintIdParam

  const loadSprint = async () => {
    if (sprint_id === 0) {
      setSprint({});
      return;
    }
    try {
      const res = await getSprint(sprint_id).call;
      setSprint(res.data);
    } catch (error) {}
  };

  const { 
    data: missionData,
    callFunction:getMissionById  
  } = useManualApiWithLoading<any>({
    apiFunction: getMissionDetail
  });

  useEffect(() => {
  if(missionIdParam) getMissionById(missionIdParam)
  }, [missionIdParam])

  useEffect(() => {
  }, [missionIdParam]);

  useEffect(() => {
    if(sprint_id) {
      loadSprint();
    }
  }, [sprint_id]);

  const mission = missionData?.data

  return (
    <div>
      <div className='flex flex-row justify-between'>
        <h2>Project: {mission?.name}</h2>
      </div>
      {sprint.name && (
        <h4>
          {t('commons.intervention')} {sprint.uid}: {sprint.name}
        </h4>
      )}
      <StatusAndTrend mission={mission}/>
      <div className='my-15 pointer' onClick={() => 
          window.location.href = `/${id}/organisation-insights/`
        }>
        <p><b>Return to Projects</b></p>
      </div>
      <Tabs
        type='card'
        activeKey='5'
        onTabClick={(e, event) => {
          event.preventDefault();
          if (e !== '5') {
            history.push(`/${id}/organisation-insights/?mission=${missionIdParam}&tab=9&subtab=${e}`);
          } 
        }}>
        <TabPane tab='Overview' key='1'>
        </TabPane>
        <TabPane tab='Key Drivers' key='3'>
        </TabPane>
        <TabPane tab='Smart Solutions Search' key='4'>
        </TabPane>
        <TabPane tab={t('commons.interventions')} key='5'>
        <div>
          <div
            className='my-15 pointer'
            onClick={() => dispatch(cleanSprint())}>
            <p>
              <b>Return to {t('commons.interventions')}</b>
            </p>
          </div>
          <Intervention sprintId={missionSprintIdParam}/>
        </div>
 
        </TabPane>
        {/* Additional tabs can be included here as needed */}
      </Tabs>
    </div>
  );
};

export default MissionLayout;
