import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Button } from 'antd';
import { Tag } from 'types/Tags';
import moment from 'moment';

type TagListColumnsType = {
  setSelectedTag: React.Dispatch<React.SetStateAction<Tag| undefined>>,
  setIsOpenEditTag: React.Dispatch<React.SetStateAction<boolean>>,
  setIsOpenDeleteTag: React.Dispatch<React.SetStateAction<boolean>>,
}

const tagListColumns = ({setSelectedTag, setIsOpenDeleteTag, setIsOpenEditTag}:TagListColumnsType)  => [
  {
    title: 'Tags',
    dataIndex: 'name',
    key: 'name',
    // render: text => <a>{text}</a>,
  },
  {
    title: 'Created by',
    dataIndex: 'created_by',
    key: 'created_by',
  },
  {
    title: 'Created on',
    dataIndex: 'created',
    key: 'created',
    render: (text:string) => moment.utc(text).format('MM/DD/YY'),
  },
  {
    title: '# of elements tagged',
    key: 'count',
    dataIndex: 'count',
  },
  {
    title: 'Options',
    key: 'options',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_: any, record: Tag) => (
      <Space size="middle">
        <Button
          type="text" // Use "text" type to remove background and border
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTag(record)
            setIsOpenDeleteTag(true);
          }} />
        <Button
          type="text" // Use "text" type to remove background and border
          icon={<EditOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTag(record)
            setIsOpenEditTag(true);
          }} />
      </Space>
    ),
  },
];

export default tagListColumns;