import React, { useCallback, useEffect, useState } from "react"
import { Input, Table, Button, Modal } from "antd"
import { DeleteOutlined, EditOutlined, PlusSquareFilled, SearchOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import debounce from "lodash.debounce"

import { deleteWorkflow, getWorkflow } from "../../../../Services/Workflow"
import { AUTOSAVING_DELAY } from "../../../../../config/constants"
import FormWorkflow from "./components/FormWorkflow/FormWorkflow"

import "./Workflow.scss"

const { Column } = Table
const { confirm } = Modal

/**
 * Renders a Workflow component.
 *
 * @return {React.Element} The rendered Workflow component.
 */
const Workflow = () => {
  const [queryString, setQueryString] = useState("")
  const [createWF, setCreateWF] = useState(false)
  const [allWF, setAllWF] = useState([])
  const [editId, setEditId] = useState(0)
  const [editName, setEditName] = useState("")
  const [editSteps, setEditSteps] = useState([])
  const [page] = useState(1)
  const [pageSize] = useState(5)
  const [countItems, setCountItems] = useState(0)
  const [loading, setLoading] = useState(false)

  const { id } = useParams()

  /**
   * Load a workflow.
   *
   * @param {_page} _page - The page number.
   * @param {_pageSize} _pageSize - The page size.
   * @return {Promise<void>} - A promise that resolves when the workflow is loaded.
   */
  const loadWorkflow = async (_page, _pageSize) => {
    try {
      setLoading(true)
      const res = await getWorkflow(id, _page || page, _pageSize || pageSize, queryString.toLowerCase()).call
      setCountItems(res.data?.count || 0)
      setAllWF(res.data?.results?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          steps: item.steps?.length || 0,
          roles: item.steps?.filter((step) => step?.role_responsible?.id).length || 0,
          data: {
            id: item.id,
            name: item.name,
            steps: item.steps.map((step) => {
              return {
                id: step.id,
                description: step.description,
                role_responsible: step.role_responsible?.id,
                order: step.order,
              }
            }) || [],
          }
        }
      }) || [])
    } catch (error) { }
    setLoading(false)
  }

  /**
   * Edit the workflow button.
   *
   * @param {type} id - The ID of the workflow button.
   * @return {type} undefined
   */
  const editWorkflowButton = (id) => {
    setEditId(id)
    setEditName(allWF?.find((item) => item.id === id)?.data?.name || "")
    setEditSteps(allWF?.find((item) => item.id === id)?.data?.steps || [])
    setCreateWF(true)
  }

  /**
   * Deletes a workflow button.
   *
   * @param {type} id - the ID of the workflow button to delete
   * @return {type} undefined
   */
  const deleteWorkflowButton = (id) => {
    confirm({
      icon: <></>,
      content: <div className="text-center">
        <h3>Are you sure you want to delete this workflow?</h3>
        <div>You won’t be able to recover this once it is deleted.</div>
      </div>,
      onOk: async () => {
        try {
          await deleteWorkflow(id).call
          loadWorkflow()
        } catch (error) { }
      },
      okText: "Delete",
      cancelText: "Cancel",
    })
  }

  const debounceSearch = useCallback(debounce(setQueryString, AUTOSAVING_DELAY), [])

  useEffect(() => {
    loadWorkflow()
  }, [queryString])

  useEffect(() => {
    if (!createWF) {
      setEditId(0)
      setEditName("")
      setEditSteps([])
      loadWorkflow()
    }
  }, [createWF])

  return (
    <div className="workflow form-table-container">
      {createWF ? (
        <FormWorkflow
          back={() => setCreateWF(false)}
          itemId={editId}
          items={editSteps}
          name={editName}
        />
      ) : (
        <>
          <div className="flex w-50 ml-auto mb-15">
            <Input
              prefix={<SearchOutlined />}
              className="border-r-50"
              onChange={(e) => {
                debounceSearch(e.target.value)
              }}
            />
            <div className="flex-vertical" onClick={() => setCreateWF(true)}>
              <PlusSquareFilled
                style={{
                  fontSize: "35px",
                  color: "#3C5DD6",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              />
            </div>
          </div>
          <Table
            rowClassName="pointer"
            dataSource={allWF}
            pagination={{
              defaultPageSize: 5,
              total: countItems,
              showSizeChanger: true,
              responsive: true,
              onChange: (page, pageSize) => loadWorkflow(page, pageSize),
              pageSizeOptions: [5, 10, 20, 50, 100],
              onShowSizeChanged: (page, pageSize) => loadWorkflow(page, pageSize),
            }}
            loading={loading}
          >
            <Column
              className="pointer"
              title="Title"
              dataIndex="name"
              key="name"
              render={(text) =>
                <Button
                  type="link"
                >{text}</Button>
              }
              onCell={(record, rowIndex) => {
                return {
                  onClick: () => editWorkflowButton(record?.id),
                };
              }}
              width={"50%"}
            />
            <Column
              className="pointer"
              title="Steps"
              dataIndex="steps"
              key="steps"
              onCell={(record, rowIndex) => {
                return {
                  onClick: () => editWorkflowButton(record?.id),
                };
              }}
            />
            <Column
              className="pointer"
              title="# of Roles Involved"
              dataIndex="roles"
              key="roles"
              onCell={(record, rowIndex) => {
                return {
                  onClick: () => editWorkflowButton(record?.id),
                };
              }}
            />
            <Column
              className="pointer"
              title="Inspect"
              dataIndex="action"
              key="action"
              render={(_, record) => (
                <div>
                  <EditOutlined
                    onClick={() => editWorkflowButton(record?.id)}
                  />
                  <DeleteOutlined
                    onClick={() => deleteWorkflowButton(record?.id)}
                  />
                </div>
              )}
            />
          </Table>
        </>
      )}

    </div>
  )
}

export default Workflow