import React, {useEffect, useState} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {
  deleteSpecialistDocuments,
  getSpecialistDocuments,
} from 'Services/Specialist.service';
import './styles/SpecialistDocuments.scss';
import {SpecialistDocument} from 'types/Specialist';

/**
 * Renders a list of specialist documents.
 *
 * @param {string} specialistId - The ID of the specialist.
 * @param {boolean} uploadLoading - Indicates if the upload is in progress.
 * @return {JSX.Element} The rendered specialist documents.
 */
const SpecialistDocuments: React.FC<{
  specialistId: number | string;
  uploadLoading: boolean;
}> = ({specialistId, uploadLoading}) => {
  const [allDocuments, setAllDocuments] = useState<SpecialistDocument[]>([]);
  const [show, setShow] = useState(false);

  const loadDocuentBySpecialist = async () => {
    try {
      const res = await getSpecialistDocuments(specialistId).call;
      setAllDocuments(res?.data?.results);
    } catch (error) {}
  };

  /**
   * Deletes a document.
   *
   * @param {type} id - The ID of the document to delete.
   * @return {type} undefined.
   */
  const deleteDocument = async (id: number) => {
    try {
      await deleteSpecialistDocuments(id).call;
      loadDocuentBySpecialist();
    } catch (error) {}
  };

  useEffect(() => {
    if (specialistId) {
      loadDocuentBySpecialist();
    }
  }, [specialistId, uploadLoading]);

  return (
    <div className='specialistdocuments'>
      <div
        className={`${allDocuments.length && 'documents-container'} ${
          show ? 'h-auto' : ''
        } `}>
        {allDocuments.map((item, index) => (
          <div key={index} className='flex justify-beetween'>
            <a href={item?.file} target='_blank' rel='noopener noreferrer'>
              {item.filename}
            </a>
            <a className='mr-10' onClick={() => deleteDocument(item?.id)}>
              <DeleteOutlined style={{color: '#969696'}} />
            </a>
          </div>
        ))}
      </div>
      <div className='text-center'>
        {allDocuments.length > 3 && (
          <p className='pointer' onClick={() => setShow((prev) => !prev)}>
            {show ? 'Show less' : 'Show More'}
          </p>
        )}
      </div>
    </div>
  );
};

export default SpecialistDocuments;
