import React from 'react';
import {Input} from 'antd';
import {LoadingOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';

interface SearchAddProps {
  isSearching: boolean;
  onAdd: () => void;
  onSearchChange: (value: string) => void;
  searchPlaceholder?: string;
}

export const SearchAdd: React.FC<SearchAddProps> = ({
  isSearching,
  onAdd,
  onSearchChange,
  searchPlaceholder,
}) => {
  return (
    <div className='grid grid-flow-col items-center gap-1.5'>
      <Input
        data-testid='searchInput'
        suffix={isSearching ? <LoadingOutlined /> : <SearchOutlined />}
        className='w-[300px]'
        size='small'
        placeholder={searchPlaceholder}
        onChange={(e) => {
          onSearchChange(e.target.value);
        }}
      />
      <button
        data-testid='searchAddButton'
        type='button'
        className={`w-10 h-10 rounded bg-primary flex items-center justify-center shrink-0 
          transition-colors duration-200 hover:bg-primary-hover`}
        onClick={onAdd}>
        <PlusOutlined
          style={{
            fontSize: '20px',
            color: 'white',
            cursor: 'pointer',
          }}
        />
      </button>
    </div>
  );
};
