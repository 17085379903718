import { notification } from 'antd';

export const getNotification = (type, errorMessage) => {
  notification[type]({
    message: errorMessage.header,
    description: errorMessage.body,
    style: {
      borderRadius: '5px',
    },
  });
};
