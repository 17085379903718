/* eslint-disable @typescript-eslint/no-explicit-any */
export type ApiResponse<T> = {
  call: Promise<T>;
  controller: AbortController;
};

function fetchWithLoading<T>(apiFunction: (...params: any[]) => ApiResponse<T>, startLoading: () => void, stopLoading: () => void, setData: (data: T) => void, setError: (error: Error) => void, ...params: any[]) {
  startLoading();

  const { call, controller } = apiFunction(...params);

  call.then(response => {
    setData(response);
  }).catch(err => {
    console.log(err); // TODO: implement logger when UI error occurs
    setError(err);
  }).finally(() => {
    stopLoading();
  });

  return controller;
}

export default fetchWithLoading