import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import './styles/TextareaDebounce.scss';

/**
 * Renders a debounced textarea component.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.name - The name of the textarea.
 * @param {Object} props.control - The control object for the form.
 * @param {Function} [props.debounceAction=()=>{}] - The debounced action to be executed.
 * @param {string} [props.className=''] - The additional class names for the textarea.
 * @param {boolean} [props.autoheight=false] - Determines whether the textarea should have autoheight.
 * @param {number} [props.maxlength=50000] - The maximum length of the textarea.
 * @return {JSX.Element} - The debounced textarea component.
 */
const TextareaDebounce = ({
  name,
  control,
  debounceAction = () => { },
  className = '',
  autoheight = false,
  maxlength = 50000,
}) => {
  const [id] = useState(`textarea-${Math.random().toString(36).substr(2, 9)}`); // Generate random id

  useEffect(() => {
    if (!autoheight) return;
    const textarea = document.getElementById(id);

    textarea.addEventListener('input', () => {
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [autoheight]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <textarea
          id={id}
          className={`textarea textareadebounce ${className} ${autoheight ? 'autoheight' : ''
            }`}
          {...field}
          rows={autoheight ? 1 : 5}
          maxLength={maxlength}
          onChange={(value) => {
            field.onChange(value);
            debounceAction();
          }}
        />
      )}
    />
  );
};

export default TextareaDebounce;
