import React from 'react';
import './style.scss';
import { useTranslation } from "react-i18next";

export function Breadcrumbs({ data }) {
  return <ul className='breadcrumb'>{createBreadCrumbs(data)}</ul>;
}
// Changed the last elemment to render as a span
const createBreadCrumbs = (data) => {
  /*   let result = data.map((item, index) => {
      return index !== data.length - 1 ? (
        <li className='breadcrumb__item'>
          <a href={item.path} title={item.name}>
            {item?.name?.substring(0, 12)}
            {item?.name?.length > 12 ? '...' : ''}
          </a>
        </li>
      ) : (
        <li className='breadcrumb__item'>
          <span>{item.name}</span>
        </li>
      );
    }); */

  const { t } = useTranslation();

  return (
    <p className='breadcrumb__item'>
      <a href="/dashboard">{t('Network')} {'>'} </a>
      <span className="className='breadcrumb__item'">{data[1]?.name}</span>
    </p>
  );
};
