import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Row,
  Select,
  Tag,
  TimePicker,
  Upload,
} from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment';
import Chart from 'react-apexcharts';
import {Controller, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {getNotification} from 'Components/GetNotification';
import {
  deleteReportDemographic,
  getIntelDemographic,
  getReportDemographic,
  patchIntelDemographic,
  postIntelDemographic,
  postReportDemographic,
} from 'Services/demographic.service';
import {
  deletePatientLanguages,
  getAllLenguageList,
  getPatientLanguages,
  patchPatientLanguages,
  postPatientLanguages,
} from 'Services/PatientLenguage.service';
import {Specialist} from '../Specialist';
import {Ethnicities, InsuranceTypes} from './Components';
import './styles/Demographics.scss';
import InputDebounce from 'Components/InputDebounce/InputDebounce';
import {CheckboxDebounce} from 'Components/ChecboxDebounce';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from 'Components/DebounceLoading/DebounceLoading';
import {AUTOSAVING_DELAY} from '../../../../../config/constants';
import {
  DemographicDetail,
  DemographicFile,
  DemographicLog,
} from 'types/Demographic';
import {Language, LanguageDetail, PostLanguage} from 'types/Language';
import {UpdateLogCard} from 'Components/molecules/card';

const {Panel} = Collapse;
const {Option} = Select;

// @todo rename this to something more descriptive
type ValidDemoGraphicKey =
  | 'clinicians'
  | 'english_percentage'
  | 'total_patients'
  | 'pc_practice_type';

/**
 * Renders a grid with demographic data.
 *
 * @return {JSX.Element} The rendered ShareModal component.
 */
const Demographics = () => {
  const [disable] = useState(false);
  const [dataDemographic, setDataDemographic] = useState<DemographicDetail>();
  const [allReports, setAllReports] = useState<DemographicFile[]>([]);
  const [lenguages, setLenguages] = useState<Language[]>([]);
  const [allPatientLenguage, setAllPatientLenguage] = useState<
    LanguageDetail[]
  >([]);

  const [selectLenguage, setSelectLenguage] = useState<number | null>(null);

  const [practice_option] = useState([
    {
      label: 'Internal',
      value: 'INTERNAL',
      initial: true,
    },
    {
      label: 'Family',
      value: 'FAMILY',
      initial: true,
    },
    {
      label: 'Pediatrics',
      value: 'PEDIATRICS',
      initial: true,
    },
    {
      label: 'Geriatrics',
      value: 'GERIATRICS',
      initial: true,
    },
    {
      label: 'Other',
      value: 'OTHER',
      initial: true,
    },
  ]);
  const dataDemographicLogs = dataDemographic?.logs ?? ([] as DemographicLog[]);

  const practice_setting = [
    {
      label: 'Urban',
      value: 'URBAN',
    },
    {
      label: 'Rural',
      value: 'RURAL',
    },
    {
      label: 'Suburban',
      value: 'SUBURBAN',
    },
    {
      label: 'Mix',
      value: 'MIX',
    },
  ];

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: {},
    watch,
  } = useForm({
    defaultValues: {
      clinicians: 0,
      english_percentage: 0,
      total_patients: 0,
      pc_practice_type: '',
      hours_of_operation: '',
      qi_program: '',
      practice_setting: '',
      specialist_referrals: '',
    },
  });

  const refForm = useRef<HTMLButtonElement | null>(null);

  const watchEnglish = watch('english_percentage');
  const watchMeetType = watch('pc_practice_type');

  const network = useSelector((state) => {
    //@ts-ignore
    return state.network.value;
  });

  const saveDemographic = async (params: DemographicDetail) => {
    dialogStartSubject$.setSubject = true;
    const data = {
      organization: network,
      ...params,
      hours_of_operation: params.hours_of_operation
        ? moment(params.hours_of_operation, 'HH:mm:ss').format('HH:mm:ss')
        : '',
      other_pc_practice_type:
        params.pc_practice_type == 'OTHER' ? params.other_pc_practice_type : '',
    };

    try {
      if (data?.id) {
        await patchIntelDemographic(data).call;
      } else {
        await postIntelDemographic(data).call;
      }
      getNotification('success', {
        header: 'Success!',
        body: 'Demographic updated successfully',
      });
      loadDemographic();
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const loadReports = async (id: number) => {
    try {
      const res = await getReportDemographic(id).call;
      setAllReports(res?.data?.results);
    } catch (error) {}
  };

  const loadDemographic = async () => {
    try {
      if (network) {
        const res = await getIntelDemographic(network).call;
        setDataDemographic(res?.data?.results[0]);
        Object.keys(res?.data?.results[0]).map((key) => {
          const validKey = key as ValidDemoGraphicKey;
          setValue(validKey, res?.data?.results[0][key]);
        });

        loadReports(res?.data?.results[0]?.id);
        loadPatientLenguage(res?.data?.results[0]?.id);
      }
    } catch (error) {}
  };

  const uploadFile = async (e: any) => {
    try {
      let formData = new FormData();

      if (dataDemographic?.id) {
        formData.append('demographics', dataDemographic.id.toString());
      }
      formData.append('file', e.file?.originFileObj);

      await postReportDemographic(formData).call;
      if (dataDemographic?.id) {
        loadReports(dataDemographic.id);
      }
    } catch (error) {}
  };

  const deleteReport = async (id: number) => {
    try {
      await deleteReportDemographic(id).call;
      if (dataDemographic?.id) {
        loadReports(dataDemographic.id);
      }
    } catch (error) {}
  };

  const loadLenguage = async () => {
    try {
      const res = await getAllLenguageList().call;
      setLenguages(res?.data?.results);
    } catch (error) {}
  };

  const loadPatientLenguage = async (id: number) => {
    try {
      const res = await getPatientLanguages(id).call;
      setAllPatientLenguage(res?.data?.results);
    } catch (error) {}
  };

  const deleteLenguage = async (id: number) => {
    try {
      await deletePatientLanguages(id).call;
      if (dataDemographic?.id) {
        loadPatientLenguage(dataDemographic.id);
      }
    } catch (error) {}
  };

  const addLenguage = async () => {
    try {
      if (dataDemographic?.id && selectLenguage) {
        const data: PostLanguage = {
          demographics: dataDemographic.id,
          language: selectLenguage,
        };
        await postPatientLanguages(data).call;
        setSelectLenguage(null);
        loadPatientLenguage(dataDemographic.id);
      }
    } catch (error) {}
  };

  const updatePatientLenguage = async (data: any) => {
    dialogStartSubject$.setSubject = true;
    try {
      await patchPatientLanguages(data).call;
      if (dataDemographic?.id) {
        loadPatientLenguage(dataDemographic.id);
      }
    } catch (error) {}
    dialogStopSubject$.setSubject = false;
  };

  const debouncePatient = useCallback(debounce(updatePatientLenguage, 650), []);

  const activeSubmitForm = () => {
    if (refForm.current) {
      refForm?.current.click();
    }
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  useEffect(() => {
    reset();
    loadDemographic();
    loadLenguage();
  }, [network]);

  return (
    <div className='demographics'>
      <div className='w-80'>
        {/* @ts-ignore */}
        <form onSubmit={handleSubmit(saveDemographic)} className='mt-15'>
          <div className='title p-10'>
            <div className='flex justify-beetween items-center font-18'>
              <p className='text-white'>Demographics</p>
              <div>
                <button
                  type='submit'
                  className='btn-edit hidden'
                  ref={(e) => (refForm.current = e)}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className={`${disable ? 'pointer-events-none' : ''}`}>
            <Collapse
              expandIconPosition='end'
              defaultActiveKey={['1', '2', '3']}>
              <Panel header='General Information' key='1'>
                <Row>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>Tax Identification Number</p>
                    <InputDebounce
                      name='tin'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-90'
                    />
                  </Col>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>National Provider Identifier</p>
                    <InputDebounce
                      name='npis'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-90'
                    />
                  </Col>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>Primary Care Practice Type</p>
                    <Controller
                      name='pc_practice_type'
                      control={control}
                      render={({field}) => (
                        <Select
                          {...field}
                          className='select-class w-90'
                          placeholder='Choose an option'
                          optionFilterProp='children'
                          bordered={false}
                          onChange={(net) => {
                            field.onChange(net);
                            debounceActive();
                          }}>
                          {practice_option.map((item) => (
                            <Option key={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      )}
                    />
                    <div
                      className={watchMeetType == 'OTHER' ? 'block' : 'hidden'}>
                      <div>
                        <label>Practice Type</label>
                        <InputDebounce
                          name='other_pc_practice_type'
                          control={control}
                          debounceAction={debounceActive}
                          className='ml-5'
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>Number of Clinicians within Practice</p>
                    <InputDebounce
                      name='clinicians'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-90'
                    />
                  </Col>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>EHR Used </p>
                    <InputDebounce
                      name='ehr_used'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-90'
                    />
                  </Col>
                  <Col className='mt-5 flex' xs={24} sm={24} md={12} lg={12}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='ehr_access'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>EHR Access</span>
                    </div>
                  </Col>
                  <Col className='mt-5' xs={24} sm={24} md={12} lg={12}>
                    <p>Practice Setting</p>
                    <Controller
                      name='practice_setting'
                      control={control}
                      render={({field}) => (
                        <Select
                          {...field}
                          className='select-class w-90'
                          placeholder='Choose an option'
                          optionFilterProp='children'
                          bordered={false}
                          onChange={(net) => {
                            field.onChange(net);
                            debounceActive();
                          }}
                          options={practice_setting}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Panel>
              <Panel header='Practice Operations' key='2'>
                <Row>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='answering_service'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        {' '}
                        Does the practice utilize an answering service?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5' span={24}>
                    <p>Hours of operation</p>
                    <Controller
                      name='hours_of_operation'
                      control={control}
                      render={({field}) => (
                        <TimePicker
                          className='w-50'
                          onChange={(net) => {
                            //@ts-ignore
                            field.onChange(net);
                            debounceActive();
                          }}
                          value={
                            field?.value
                              ? moment(field?.value, 'HH:mm:ss')
                              : null
                          }
                          placeholder='HH:MM:SS'
                        />
                      )}
                    />
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='walk_in_app'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        {' '}
                        Does the practice allow walk-in appointments?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='in_house_coord_man'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does this practice offer in-house care coordination or
                        case management?
                      </span>
                    </div>
                    <InputDebounce
                      name='in_house_coord_man_specify'
                      control={control}
                      debounceAction={debounceActive}
                      className='ml-5'
                    />
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='pat_fam_ad_council'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does this practice have a patient family advisory
                        council?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='tcm_management'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Is there an HIE or an acute notification system to
                        manage TCM?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='weekly_huddles'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        {' '}
                        Does the office conduct weekly huddles?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='specialist_referrals'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does the practice refer to specialists?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    {getValues('specialist_referrals') &&
                      dataDemographic?.id && (
                        <Specialist demographic={dataDemographic.id} />
                      )}
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='workflows_job_roles'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does the practice have designated workflows and job
                        roles?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='standing_ords_prot'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does your office have standing orders and/or protocols
                        for clinicians and non-clinicians?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='qa_perf_analysis'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does your practice monitor and/or analyze own clinical
                        quality/performance?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='qi_program'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>QI Program</span>
                    </div>
                  </Col>
                  <Col
                    className={`mt-5 ${
                      getValues('qi_program') ? 'block' : 'hidden'
                    }`}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}>
                    <div className='h-72 flex'>
                      <Upload
                        className='m-auto'
                        beforeUpload={() => {}}
                        showUploadList={false}
                        customRequest={() => {}}
                        onChange={uploadFile}>
                        <Button>Upload documents (Optionally)</Button>
                      </Upload>
                    </div>
                    {!!allReports.length && (
                      <div className={`documents-container`}>
                        {allReports?.map((report, index) => {
                          return (
                            <div key={index} className='flex justify-beetween'>
                              <a
                                className='word-break'
                                href={report?.file}
                                target='_blank'
                                rel='noopener noreferrer'>
                                {report.filename}
                              </a>
                              <a
                                className='mr-10'
                                onClick={() => deleteReport(report?.id)}>
                                <DeleteOutlined style={{color: '#969696'}} />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Col>
                  <Col
                    className={`mt-5 ${
                      getValues('qi_program') ? 'block' : 'hidden'
                    }`}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}>
                    <p>What kinds of data do you collect and track?</p>
                    <InputDebounce
                      name='data_collect_track'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-90'
                    />
                  </Col>
                  <Col className='mt-5 flex' span={24}>
                    <div className='flex items-center'>
                      <CheckboxDebounce
                        name='staffed_coder_biller'
                        control={control}
                        debounceAction={debounceActive}
                      />
                      <span className='ml-5'>
                        Does the office have a designated coder/biller on staff?
                      </span>
                    </div>
                  </Col>
                  <Col className='mt-5' span={24}>
                    <p>
                      Does the practice utilize any patient engagement
                      platform(s)?
                    </p>
                    <div className='flex justify-beetween'>
                      <div className='flex items-center'>
                        <CheckboxDebounce
                          name='pat_eng_sms_text'
                          control={control}
                          debounceAction={debounceActive}
                        />
                        <span className='ml-5'>SMS/Texting</span>
                      </div>
                      <div className='flex items-center'>
                        <CheckboxDebounce
                          name='pat_eng_portal'
                          control={control}
                          debounceAction={debounceActive}
                        />
                        <span className='ml-5'>Patient Portal</span>
                      </div>
                      <div className='flex items-center'>
                        <CheckboxDebounce
                          name='pat_eng_info'
                          control={control}
                          debounceAction={debounceActive}
                        />
                        <span className='ml-5'>Other</span>
                        <InputDebounce
                          name='other_pat_eng'
                          control={control}
                          debounceAction={debounceActive}
                          className='ml-5'
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Panel>
              <Panel header='Patient Demographics' key='3'>
                <Row>
                  <Col span={24}>
                    <p>Total Patients</p>
                    <InputDebounce
                      name='total_patients'
                      type='number'
                      control={control}
                      debounceAction={debounceActive}
                      className='w-100'
                    />
                  </Col>
                  <Col className='mt-15' span={12}>
                    <span>Primary Language:</span>
                    <hr />
                    <div className='flex items-center'>
                      <p>English:</p>
                      <InputDebounce
                        name='english_percentage'
                        type='number'
                        control={control}
                        debounceAction={debounceActive}
                        className='ml-15'
                      />
                    </div>
                    <div className='flex mt-15'>
                      <p className='w-100px'>Other:</p>
                      <Select
                        className='select-class'
                        placeholder='Choose a language'
                        optionFilterProp='children'
                        bordered={false}
                        value={selectLenguage}
                        onChange={(net) => {
                          setSelectLenguage(net);
                        }}>
                        {lenguages.map((item) => (
                          <Option key={item.id}>{item.name}</Option>
                        ))}
                      </Select>
                      <Button type='primary' onClick={addLenguage}>
                        Add
                      </Button>
                    </div>
                    <div className='mt-5'>
                      {allPatientLenguage?.map((item, index) => (
                        <div key={index} className={`mt-5 'block`}>
                          <Tag
                            closable
                            onClose={() => deleteLenguage(item?.id)}
                            color='#102431'>
                            {item?.language?.name}
                          </Tag>
                          <input
                            className='input-class'
                            defaultValue={item?.percentage}
                            type='text'
                            placeholder='Percentage'
                            onChange={(e) =>
                              debouncePatient({
                                id: item?.id,
                                percentage: e.target.value,
                              })
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col className='mt-15' span={12}>
                    <Chart
                      type='pie'
                      width={350}
                      series={[
                        Number(watchEnglish) ? Number(watchEnglish) : 0,
                        ...allPatientLenguage?.map((item) =>
                          item?.percentage ? Number(item?.percentage) : 0
                        ),
                      ]}
                      options={{
                        labels: [
                          'English',
                          ...allPatientLenguage?.map(
                            (item) => item?.language?.name
                          ),
                        ],
                      }}
                    />
                  </Col>
                  <Col className='mt-15' span={24}>
                    {dataDemographic?.id && (
                      <InsuranceTypes demographicId={dataDemographic.id} />
                    )}
                  </Col>
                  <Col className='mt-15' span={24}>
                    {dataDemographic?.id && (
                      <Ethnicities
                        demographicId={dataDemographic?.id.toString()}
                      />
                    )}
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </form>
      </div>
      <div className='w-20 mt-15 px-10'>
        <UpdateLogCard logs={dataDemographicLogs} />
      </div>
      <DebounceLoading />
    </div>
  );
};

export default Demographics;
