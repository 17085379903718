import React, {useEffect, useRef, useState} from 'react';
import {Collapse, notification, Popover, Carousel} from 'antd';
import {
  deleteCollaborator,
  getPatCategories,
  getUsersPatDetail,
  getUsersPatInspect,
  patchUsersPat,
} from 'Services/UserPat.service';
import {getPftJourneys} from 'Services/PftJourney.service';
import {getUserPatPhaseGoals, mergeAllCompetencies} from 'Services/PftJourney.service';
import {
  AboutCompetencyModal,
  CollaboratorsModal,
  Milestones,
  PatPDF,
  TcpModal,
} from './components';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {ClearLocalStorage} from '../../utils/localStorageOperations';
import './styles/PatData.scss';
import {getMilestones} from 'Services/MIlestones.service';
import {useDispatch, useSelector} from 'react-redux';
import {cleanPat, setPftCategoryScores} from '../../ReduxV2/state';
import {selectPftJourney, setPftJourneyPhases} from 'ReduxV2/state';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {MilestoneCard, PercentageCard} from 'Components/molecules/card';
import {TagSteps} from 'Components/molecules/tag';
import PercentageCardDetail from './components/PercentageCardDetail/PercentageCardDetail'
const {Panel} = Collapse;

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-');
}

const GoalsToReachNext = ({categoriesOptions, nextPhase, milestones}) => {
  const carouselRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [title, setTitle] = useState(milestones[0]?.competency?.title ?? '');
  const [category, setCategory] = useState('');
  const [reference, setReference] = useState(
    milestones[0]?.competency?.ref_id ?? ''
  );

  useEffect(() => {
    setCategory(categoriesOptions?.find(category => category?.id === milestones[currentSlide]?.competency?.category)?.name ?? '');
  }, [currentSlide, categoriesOptions, milestones])

  return (
    <div className='w-full overflow-hidden'>
      <h2 className='flex gap-2 items-center mt-6 mb-3'>
        <span>Goals to Reach</span>
        <span
          className='text-sm border border-solid px-2 py-px rounded bg-white'
          style={{color: nextPhase.color, borderColor: nextPhase.color}}>
          {nextPhase.name}
        </span>
      </h2>

      <div className='w-full bg-blue-200 text-center py-1 px-6 rounded-lg'>
        <h3>{`Category: ${category}`}</h3>
        {title?.includes('Milestone #') ? (
            <h3>{title}</h3>
          )
          : (
            <h3>{`Milestone #${reference} - ${title}`}</h3>
          )
        }
      </div>
      <div className='w-full block'>
        <Carousel
          arrows={false}
          dots={false}
          beforeChange={(current, next) => {
            setCurrentSlide(next);
            const nextMilestone = milestones[next];
            setTitle(nextMilestone?.competency?.title);
            setReference(nextMilestone?.competency?.ref_id);
            setCategory(categoriesOptions?.find(category => category?.id === nextMilestone?.competency?.category)?.name ?? '');
          }}
          swipeToSlide={true}
          draggable={true}
          ref={carouselRef}
          className='block w-full max-w-[100vw] overflow-hidden'>
          {milestones.map((milestone, index) => {
            const current = milestone.current_option,
              target = milestone.target_option;
            return (
              <div
                key={index}
                className='flex flex-1 gap-6 justify-center items-center'>
                <div className='w-75 flex pt-3 pb-5 -mb-2'>
                  <MilestoneCard
                    description={current.description}
                    isPositive={false}
                  />
                </div>
                <div className='flex w-10 h-10 place-content-center'>
                  <span className='text-3xl'>›</span>
                </div>
                <div className='w-75 flex'>
                  <MilestoneCard
                    description={target.description}
                    isPositive={true}
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
      <div className='flex justify-end'>
        <ul className='grid gap-2.5'>
          <li className='flex items-center gap-2 justify-end'>
            <span className='font-semibold'>Current State</span>
            <div className='rounded-full h-2 w-27 bg-negative' />
          </li>
          <li className='flex items-center gap-2 justify-end'>
            <span className='font-semibold'>Target State</span>
            <div className='rounded-full h-2 w-27 bg-positive' />
          </li>
        </ul>
      </div>

      <div className='flex justify-center mb-5'>
        <ul className='flex items-center justify-center gap-2 text-sm font-medium'>
          <li>
            <button
              className='py-1 px-2 flex items-center gap-2'
              onClick={() => {
                if (carouselRef?.current) {
                  carouselRef.current.prev();
                }
              }}>
              <span className='text-2xl'>‹</span>
              <span>Back</span>
            </button>
          </li>
          <li>
            {currentSlide + 1} / {milestones.length}
          </li>
          <li>
            <button
              className='py-1 px-2 flex items-center gap-2'
              onClick={() => {
                if (carouselRef?.current) {
                  carouselRef.current.next();
                }
              }}>
              <span>Next</span>
              <span className='text-2xl'>›</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PhaseList = ({phases}) => (
  <div className='py-4 flex items-center gap-4 flex-wrap'>
    <h2 className='text-3xl font-bold'>Summary:</h2>
    <TagSteps tags={phases} />
  </div>
);

const PhaseDescription = ({description}) => (
  <div className='bg-blue-200 mt-1.5'>
    <h3 className='px-4 py-1 font-extralight italic text-sm'>
      About this phase
    </h3>
    <div className='px-4 py-3 border-solid border-t border-teal-800 text-sm'>
      {description}
    </div>
  </div>
);

const ScreenHeader = ({type, title, creator, inspectData, allMilestones}) => {
  return (
    <>
      {/* <p
        className='text-black pointer mb-10'
        onClick={() => dispatch(cleanPat())}>
        <img className='arrow-back' src='/img/arrowBack.png' alt='back' />{' '}
        Return to PAT
      </p> */}
      <div className='flex justify-beetween'>
        <div className='grid gap-2'>
          <h2 className='text-4xl leading-tight'>
            Assessment Details: {title}
          </h2>
          {type && (<h3 className='text-xl leading-tight'>Type: {type}</h3>)}
          <h3 className='text-xl leading-tight'>Created by: {creator}</h3>
        </div>
        <PDFDownloadLink
          document={
            <PatPDF inspectData={inspectData} allMilestones={allMilestones} />
          }
          fileName={`PAT-${slugify(title)}.pdf`}>
          <button
            type='button'
            className='py-3 px-6 rounded bg-primary text-white transition-colors hover:bg-primary-hover'>
            Share Results
          </button>
        </PDFDownloadLink>
      </div>
    </>
  );
};

/**
 * Renders the PatData component.
 *
 * @return {JSX.Element} The rendered component.
 */
const PatData = ({onClickNext}) => {
  const [step, setStep] = useState(0);
  const [patCompetencies, setPatCompetencies] = useState([]);
  const [notePats, setNotePats] = useState({});
  const [patCategories, setPatCategories] = useState([]);
  const [selectPatOptions, setSelectPatOptions] = useState({});
  const [selectedTcpOptions, setSelectedTcpOptions] = useState([]);
  const [inspectData, setInspectData] = useState();
  const [visibleTcp, setVisibleTcp] = useState(false);
  const [visibleCompetency, setVisibleCompetency] = useState(false);
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [visibleCollaboratorModal, setVisibleCollaboratorModal] =
    useState(false);
  const [showCompetenciesDetail, setShowCompetenciesDetail] = useState('none')
  const [hideSummary, setHideSummary] = useState('block')
  const [allMilestones, setAllMilestones] = useState({
    target_phase: {},
    filtered_competencies: [],
  });
  const [competencyFormated, setCompetencyFormated] = useState({});
  const [categoryDetail, setCategoryDetail] = useState({
    title: '',
    competencies: [{
      id: '',
      title: '',
      description: '',
      current_option: '',
      target_option: '',
      user_pat: ''
    }]
  });
  const [selectCompetencyAbout, setSelectCompetencyAbout] = useState({});
  const [collaboratorId, setCollaboratorId] = useState(0);
  const [patType, setPatType] = useState(undefined);

  const dispatch = useDispatch();
  const phases = useSelector((state) => state.pftJourney.phases);
  const id = useSelector((state) => {
    return state.pat.value;
  });

  const getAllPats = async (type) => {
    try {
      const patCategories = await getPatCategories(type).call;
      setPatCategories(patCategories?.data);
      const catego = [];
      patCategories?.data?.map((categorie) =>
        catego.push(...categorie?.pat_competencies)
      );
      setPatCompetencies(catego);
    } catch (error) {}
  };

  const loadData = async () => {
    try {
      if (id) {
        const res = await getUsersPatDetail(id).call;
        const options = {};
        const notesObj = {};
        res.data?.pat_options?.forEach(({pat_option, notes}, index) => {
          options[pat_option.competency] = pat_option;
        });
        res.data?.competency_notes?.forEach(
          ({notes, pat_competency}, index) => {
            notesObj[pat_competency] = {value: notes};
          }
        );
        setNotePats(() => notesObj);
        if (res.data?.pat_options.length) {
          setStep(
            res.data?.pat_options[res.data?.pat_options.length - 1].pat_option
              .competency - 1
          );
        }
        setTitle(res?.data?.title);
        setType(res?.data?.type.name);
        setSelectPatOptions(options);
      }
    } catch (error) {
      ClearLocalStorage();
    }
  };

  const loadInspect = async () => {
    try {
      if (id) {
        const res = await getUsersPatInspect(id).call;
        setInspectData(res?.data);
        setPatType(res?.data?.type)
        dispatch(setPftJourneyPhases(res?.data?.phases));
        dispatch(setPftCategoryScores(res?.data?.category_scores));
      }
    } catch (error) {}
  };

  const loadPftJourney = async () => {
    try {
      if (id) {
        const res = await getPftJourneys(id).call;
        if (res?.data?.results.length) {
          dispatch(selectPftJourney(res.data.results[0].id));
        }
      }
    } catch (error) {}
  };

  const saveComments = async () => {
    try {
      const competency_notes = [];
      Object.keys(notePats).map((key) => {
        competency_notes.push({
          pat_competency: key,
          notes: notePats[key]?.value,
        });
      });

      const data = {
        id: id,
        competency_notes,
      };
      await patchUsersPat(data).call;
      notification.success({
        message: 'Success!',
        description: 'This comment has been successfully saved!',
      });
    } catch (error) {}
  };

  /**
   * Open the TCT modal.
   *
   * @param {Object} improvement_tactics - The improvement tactics.
   */
  const openTctModal = ({improvement_tactics}) => {
    setSelectedTcpOptions(improvement_tactics);
    setVisibleTcp(true);
  };

  /**
   * Finds and opens a TCP connection based on the given ID.
   *
   * @param {type} id - The ID of the TCP connection to search for.
   * @return {type} The result of opening the TCP connection.
   */
  const findAndOpenTcp = (id) => {
    const competency = patCompetencies.find((item) => item.id == id);
    openTctModal(competency);
  };

  const formatCompetency = (competencies)=>{
    let formatedCompetencies = {}
    competencies.forEach(element => {
      formatedCompetencies[element.competency.id] = {
        current_option:element.current_option.description,
        target_option:element.target_option.description,
        notes: element.competency.notes?.notes
      }
    });
    return formatedCompetencies
  }

  const loadMilestone = async () => {
    if (id) {
      const options = {
        pat_id: id,
        competency_full_info: true,
      };
      const res = await getUserPatPhaseGoals(options).call;
      const formattedRes = mergeAllCompetencies(res.data);
      setAllMilestones(formattedRes);
      setCompetencyFormated(formatCompetency(formattedRes.filtered_competencies))
    }
  };

  /**
   * Opens the about modal for a specific competency.
   *
   * @param {type} competencyId - the ID of the competency to open the modal for
   * @return {type} undefined
   */
  const openAboutModal = (competencyId) => {
    const competency = patCompetencies.find((item) => item.id === competencyId);
    setSelectCompetencyAbout(competency);
    setVisibleCompetency(true);
  };

  const deleteCollaboratorAction = async (id) => {
    try {
      const res = await deleteCollaborator(id).call;
      loadInspect();
    } catch (error) {}
  };

  const editCollaborator = (collaborator_Id) => {
    setCollaboratorId(collaborator_Id);
    setVisibleCollaboratorModal(true);
  };

  useEffect(() => {
    loadInspect();
    loadData();
    loadMilestone();
    loadPftJourney();
  }, []);

  useEffect(() => {
    if(patType) getAllPats(patType);
  }, [patType])

  useEffect(() => {
    setStep(0);
    setSelectPatOptions({});
    setNotePats({});
  }, []);

  useEffect(() => {
    loadInspect();
  }, [visibleCollaboratorModal]);

  const hideDetail = ()=>{
    setShowCompetenciesDetail('none')
    setHideSummary('block')
  }

  const filterCategoryCompetencies = (category)=>{
    let filterCategory = patCategories.filter((cat)=> cat.name == category)
    let competencies = filterCategory[0].pat_competencies.map((el)=>(
      {
        id: el.ref_id,
        title: el.title,
        description: el.description,
        notes: notePats[el.id] ? notePats[el.id].value : '',
        current_option: selectPatOptions[el.id] ? selectPatOptions[el.id].description : 'N/A',
        target_option: competencyFormated[el.id] ? competencyFormated[el.id].target_option : 'N/A'
      }
    ))
    let response = {
      title: filterCategory[0].name,
      competencies,
      user_pat: id,
      handleClose: hideDetail
    }
    return response
  }

  const handleClickCard = (e)=>{
    let category = e.currentTarget.getAttribute('data-value')
    const detail = filterCategoryCompetencies(category)
    setCategoryDetail(detail)
    setHideSummary('none')
    setShowCompetenciesDetail('block')
  }

  const nameCategorieText = patCategories?.find(
    (cat) => cat.id == patCompetencies[step]?.category
  );

  const currentPhaseDescription =
    phases?.find((phase) => phase.current === true)?.description ?? '';

  return (
    <>
          <section className='h-100' style={{display:showCompetenciesDetail}}>
            <PercentageCardDetail data={categoryDetail} handleClose={hideDetail}/>
          </section>
      <div className='patdata' style={{display:hideSummary}}>
        <section className='flex flex-col gap-4' style={{display:'none'}}>
          <ScreenHeader
            title={title}
            type={type}
            creator={inspectData?.created_by?.name}
            inspectData={inspectData}
            allMilestones={allMilestones}
          />
          <PhaseList phases={phases} />
          <section className='flex flex-col gap-4'>
            {!!currentPhaseDescription && (
              <PhaseDescription description={currentPhaseDescription} />
            )}
            <div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pointer-events-auto'>
              {inspectData?.category_scores?.map((category, index) => (
                <PercentageCard
                  key={index}
                  title={category?.category}
                  data-value={category?.category}
                  percentage={category?.percentage}
                  className="cursor-pointer"
                  onClick={handleClickCard}
                />
              ))}
            </div>
            {!!allMilestones?.filtered_competencies?.length && (
              <GoalsToReachNext
                categoriesOptions={patCategories}
                nextPhase={allMilestones.target_phase}
                milestones={allMilestones?.filtered_competencies}
              />
            )}
            <div className='flex justify-center'>
              <button
                onClick={onClickNext}
                className='py-3 px-6 rounded bg-primary text-white transition-colors hover:bg-primary-hover'>
                Plan For Transformation
              </button>
            </div>
          <div className='flex justify-beetween mt-15'>
            <div className='mr-15 flex-1 w-50'>
              <div className='bg-title-inspect mt-15 mb-5 '>
                <div className='p-10 flex justify-beetween'>
                  <p className='text-20'>
                    <b>Collaborators</b>
                  </p>
                  <button
                    type='button'
                    className='modal-delete-btn bg-white'
                    onClick={() => setVisibleCollaboratorModal(true)}>
                    + Add Collaborator
                  </button>
                </div>
              </div>
              <table
                className={`w-100 ${
                  inspectData?.collaborators?.length ? 'd-block' : 'd-none'
                }`}>
                <thead>
                  <tr className='text-left'>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {inspectData?.collaborators?.map((collaborator, index) => (
                    <tr
                      key={index}
                      className={`text-20 p-5 ${
                        index % 2 != 0 ? 'bg-white' : 'inspect-odd'
                      }`}>
                      <td className='p-5 text-sm'>{collaborator?.persona?.name}</td>
                      <td className='p-5 text-sm'>
                        {collaborator?.persona?.email}
                      </td>
                      <td className='p-5 text-sm flex'>
                        <a
                          onClick={() => {
                            editCollaborator(collaborator?.id);
                          }}>
                          <EditOutlined />
                        </a>
                        <a
                          className='ml-10'
                          onClick={() => {
                            deleteCollaboratorAction(collaborator?.id);
                          }}>
                          <DeleteOutlined style={{color: '#F46767'}} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          </section>
        </section>

        {/* <div className='mt-10 collapse'>
          <Milestones
            openTctModal={findAndOpenTcp}
            allMilestones={allMilestones}
            openAboutModal={openAboutModal}
          />
          <Collapse expandIconPosition='end'>
            <Panel header='Milestones'>
              <Collapse expandIconPosition='end'>
                {patCategories?.map((category) => (
                  <Panel
                    header={
                      <div>
                        <p className='text-white'>
                          <b>Category:</b> {category?.name}
                          <Popover
                            content={category?.information}
                            title={
                              <p className='text-orange'>What is this about?</p>
                            }
                            style={{ width: '150px' }}>
                            <span className='hoverIcon-white ml-10' />
                          </Popover>
                        </p>
                      </div>
                    }
                    key={category?.id}>
                    <div className='collapse-child'>
                      <Collapse expandIconPosition='end'>
                        {category?.pat_competencies.map((competency, index) => (
                          <Panel
                            header={
                              <div>
                                <span>{competency?.description}</span>
                                <span
                                  className='hoverIcon ml-10'
                                  onClick={() => openAboutModal(competency?.id)}
                                />
                              </div>
                            }
                            key={competency?.id}>
                            <p className='my-10'>
                              <b>Answer</b>
                            </p>
                            {selectPatOptions[competency?.id]?.description}
                            {!!competency?.improvement_tactics.length && (
                              <div className='mt-15'>
                                <button
                                  type='button'
                                  className='modal-dont-delete b-rounded-10'
                                  onClick={() => openTctModal(competency)}>
                                  Improvement Tactics
                                </button>
                              </div>
                            )}
                            <div className='mt-15'>
                              <p>Notes</p>
                              <textarea
                                className='textarea'
                                value={notePats[competency?.id]?.value || ''}
                                onChange={(e) =>
                                  setNotePats((prev) => ({
                                    ...prev,
                                    [competency?.id]: { value: e?.target?.value },
                                  }))
                                }
                              />
                            </div>
                            <div className='text-end mt-15'>
                              <button
                                type='button'
                                className='modal-dont-delete b-rounded-10'
                                onClick={() => saveComments()}>
                                Save
                              </button>
                            </div>
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          </Collapse>
        </div> */}
        <TcpModal
          visible={visibleTcp}
          cancel={() => setVisibleTcp(false)}
          tcp={selectedTcpOptions}
        />
        <AboutCompetencyModal
          visible={visibleCompetency}
          selectCategoryAbout={selectCompetencyAbout}
          onCancel={() => setVisibleCompetency(false)}
        />
        <CollaboratorsModal
          visibleModal={visibleCollaboratorModal}
          closeVisibleModal={() => {
            setVisibleCollaboratorModal(false);
            setCollaboratorId(0);
          }}
          patId={id}
          collaboratorId={collaboratorId}
        />
      </div>
    </>
  );
};

export default PatData;
