import { createSlice } from "@reduxjs/toolkit";

type NetworkMeasuresState = {
  measures: any[],
};

const initialState: NetworkMeasuresState = {
  measures: [],
};

export const networkMeasuresSlice = createSlice({
  name: "network_measures",
  initialState,
  reducers: {
    selectNetworkMeasures: (state, { payload }) => {
      state.measures = payload;
    },
  },
});

export const { selectNetworkMeasures } = networkMeasuresSlice.actions;