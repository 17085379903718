import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ScoreCardFileType } from 'types/ScoreCard';
import { Button } from 'antd';

import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { createScoreCardLink } from 'Services/Sprints.service'

import { Col } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import moment from 'moment';

import { getNotification } from 'Components/GetNotification';
import { getScorecardTemplate, getSprintTracking, patchSprints, postSprintMeasures, getSprintMeasures, getSprintMeasuresHistorical, postSprintQuantitativeTracking } from '../../../../../../../../../../Services/Sprints.service';
import { dialogStartSubject$, dialogStopSubject$ } from 'Components/DebounceLoading/DebounceLoading';
import { AUTOSAVING_DELAY } from 'config/constants';
import { getMissionDetail } from 'Services/Mission.service';
import FormTable from 'Components/FormTable/FormTable';
import TextareaDebounce from 'Components/TextareaDebounce/TextareaDebounce';
import ScoreCardUpload from './Components/ScoreCardUpload';
import ScoreCardSelect from './Components/ScoreCardSelect';
import ScoreCardHistoryTable from './Components/ScoreCardHistoryTable';
import { SCORECARD_GOOGLE_TEMPLATE_LINK } from 'config/constants';

import {
  getFiles,
} from 'Services/Meetings.service';

import './styles/Historical.scss';
import ExcelScoreCardUpload from './Components/ExcelScoreCardUpload';
import GoogleSheetLinkUpload from './Components/GoogleSheetLinkUpload/GoogleSheetLinkUpload';

/**
 * Renders the Huddle Scorecard component.
 *
 * @return {JSX.Element} The rendered Huddle Scorecard component.
 */
const HuddleScorecard = () => {
  const [template, setTemplate] = useState("");
  const [missionData, setMissionData] = useState({});
  const [historical, setHistorical] = useState([]);
  const [historicalSelect, setHistoricalSelect] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [triggerUpload, setTriggerUpload] = useState(false)
  const [link, setLink] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [refreshScoreCards, setRefreshScoreCards] = useState(true);
  const [fileType, setFileType] = useState(ScoreCardFileType.GOOGLE);

  const refForm = useRef();

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  const sprintId = useSelector(state => {
    return state.sprint.value
  });

  const { handleSubmit, control, setValue, reset, formState: { errors }} = useForm();

  const { fields: fields_measures, append: append_measures, remove: remove_measures } = useFieldArray({
    control,
    name: "measures"
  });


  const handleTemplateChange = (selection) => {
    setFileType(selection)
  }

  const activeSubmitForm = () => {
    refForm?.current.click()
  }

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY)
    , []);

  const loadMission = async () => {
    try {
      const res = await getMissionDetail(mission).call;
      setMissionData(res.data);
    } catch (error) { }
  }

  /**
   * Loads huddles based on the given index.
   *
   * @param {number} index - The index of the huddle to load.
   */
  const loadHuddles = async (index) => {
    if (sprintId) {
      if (missionData?.category === "QUALITATIVE") {
        try {
          const res = await getSprintTracking(sprintId).call;
          setValue("qualitative_tracking", res.data?.results[index || 0]?.description || "")
          setHistorical(res.data?.results || []);
        } catch (error) { }
      }
      if (missionData?.category === "QUANTITATIVE") {
        try {
          reset();
          const res = await getSprintMeasuresHistorical(sprintId, 'HUDDLE_SCORECARD').call;
          setHistorical(res.data?.results || []);
          const measuresVersion = res.data?.results[index || 0]?.version;
          if (measuresVersion) {
            const res = await getSprintMeasures(sprintId, 'HUDDLE_SCORECARD', measuresVersion).call;
            const measures = res.data || [];
            setValue("measures", measures)
            if (measures?.length === 0)
              append_measures({ created: true });
          }
        } catch (error) { }
      }
    }
  }

  const loadTemplate = async () => {
    try {
      const res = await getScorecardTemplate().call;
      setTemplate(res.data?.file || "");
    } catch (error) {
      console.error("template", error)
    }
  }

  /**
   * Saves historical data based on the given parameters.
   *
   * @param {object} params - The parameters for saving historical data.
   * @return {Promise} A promise that resolves when the data is saved.
   */
  const saveHistorical = async (params) => {
    dialogStartSubject$.setSubject = true;
    try {
      if (missionData?.category === "QUALITATIVE") {
        const data = {
          mission_sprint: sprintId,
          description: params.qualitative_tracking,
        };
        delete params.qualitative_tracking;
        await postSprintQuantitativeTracking(data).call;
      }
    } catch (error) {
      return;
    }
    try {
      if (missionData?.category === "QUANTITATIVE") {
        const data = [];
        const measures_init = {
          mission_sprint: sprintId,
          measure_category: "HUDDLE_SCORECARD",
        };
        if (params.measures?.length == 0) {
          params.measures.push({ ...measures_init });
          append_measures({ ...measures_init });
        }
        params.measures.map((item) => {
          if (item?.created_by) delete item.created_by;
          (item?.id || item?.created) && data.push({
            ...item,
            ...measures_init,
          });
        });
        delete params.measures;
        await postSprintMeasures(data).call;
      }
    } catch (error) {
      return;
    }

    try {
      const data = {
        ...params,
        status: "IN_ACTION",
        id: sprintId,
      };

      await patchSprints(data).call

      getNotification('success', {
        header: '',
        body: 'The information has been saved successfully',
      });

    } catch (error) { }
    dialogStopSubject$.setSubject = false;
  }

  const [formTableData] = useState({
    control: control,
    debounceActive: debounceActive,
    errors: errors,
  })

  useEffect(() => {
    loadMission();
    loadHuddles();
    loadTemplate();
  }, []);

  useEffect(() => {
    loadHuddles();
  }, [missionData?.category]);

  const { data:scorecardsTemplates, callFunction:fetchScoreCards } = useManualApiWithLoading({
    apiFunction: getFiles
  });
  const { data:createdScorecardData, setData:setCreatedScorecardData, error:createScoreCardError, setError:setCreateScoreCardError, callFunction:createScoreCard } = useManualApiWithLoading({
    apiFunction: createScoreCardLink
  });

  const afterExcelUpload = () => {
    fetchScoreCards(sprintId, 'huddle-scorecards/?mission_sprint=')
  }

  useEffect(() => {
    fetchScoreCards(sprintId, 'huddle-scorecards/?mission_sprint=')
  }, [refreshScoreCards, sprintId])

  const hasScorecards = scorecardsTemplates?.data?.results?.length > 0

  useEffect(() => {
    if(triggerUpload && fileType == ScoreCardFileType.GOOGLE) {
      setTriggerUpload(false);
      setIsModalVisible(false);
      setLink(undefined);
      createScoreCard(sprintId, link)
    }
  }, [triggerUpload])

  useEffect(() => {
    if(createdScorecardData?.data?.created_at) {
      fetchScoreCards(sprintId, 'huddle-scorecards/?mission_sprint=')
      getNotification('success', {
        header: 'Sucess',
        body: 'Link uploaded',
      });
      setCreatedScorecardData(undefined)
    }

    if(createScoreCardError) {
      setCreateScoreCardError(undefined)
      getNotification('error', {
        header: 'Error uploading link',
        body: 'Verify is a valid google url link',
      });
    }

  }, [createdScorecardData, createScoreCardError])

  return <div className='historical'>
    <p>What metric(s) are you tracking?</p>
    {historical.length > 0 &&
      <div className="historical">
        <div className="historical-label" onClick={() => setHistoricalSelect(prev => !prev)}>
          <div className="flex justify-beetween">
            <p>Historical</p>
            <p>v</p>
          </div>
        </div>
        <div className={`${historicalSelect ? 'historical-container-open' : 'historical-container-close'}`}>
          {
            historical?.map((item, index) => (
              <div key={index} className="pointer" onClick={() => loadHuddles(index)}>
                <p>{moment(item?.created_at).format('lll')}</p>
                <p><b>By:</b></p>
                <p>{item?.created_by?.name}</p>
                <hr />
              </div>
            ))
          }
        </div>
      </div>
    }
    <form onSubmit={handleSubmit(saveHistorical)}>
      <div>
        <div className="flex justify-end font-18">
          <div>
            <div>
              <button type="submit" className="btn-edit d-none" ref={e => refForm.current = e}>Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        missionData?.category === "QUALITATIVE" && (
          <>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="my-15">
                <p>What will be captured to track progress during each huddle?</p>
                <TextareaDebounce name="qualitative_tracking" control={control} debounceAction={debounceActive} />
              </div>
            </Col>
          </>
        )
      }
      {
        missionData?.category === "QUANTITATIVE" &&
        <div className="p-10 w-100">
          <FormTable
            {...formTableData}
            design="list"
            name="measures"
            fields={fields_measures}
            addLabel="Add Measure"
            headers={[
              {
                type: 'inputMulti',
                title: "Measure",
                name: "name",
                width: 6,
              },
              {
                type: "select",
                title: "% or #",
                name: "measure_type",
                options:
                  [
                    {
                      value: "PERCENTAGE",
                      label: "%",
                    },
                    {
                      value: "NUMBER",
                      label: "#",
                    }
                  ],
                width: 6,
              },
              {
                type: "text",
                title: "Current State",
                name: "current_state",
              },
              {
                type: "text",
                title: "Target",
                name: "target",
              }
            ]}
            onAppend={append_measures}
            onRemove={remove_measures}
          />
        </div>
      }
      <Col xs={24} sm={24} md={24} lg={24}>
        <div>
          <b className="text-lg">Huddle Scorecard</b>
          {template !== "" &&
            <div className='mb-3'>
              <div className='text-base mt-2 flex items-center gap-2'>
                <p>
                  To update your scorecard, first download the Template file:
                </p>
                <Button 
                  data-testid='downloadTemplate'
                  className='extra-small'
                  type="primary"
                  size='small'
                  onClick={() => {
                    if(fileType == ScoreCardFileType.GOOGLE) {
                      window.open(SCORECARD_GOOGLE_TEMPLATE_LINK, '_blank');
                    } else {
                      window.location.href = template
                    }
                  }}
                >
                  Download Template
                  <DownloadOutlined />
                </Button>
                <ScoreCardSelect defaultValue={fileType} onChange={handleTemplateChange}/>
              </div>
            </div>
          }
          <div className='text-base mt-2 flex items-center gap-2'>
              <p>Upload your scorecard to track progress:</p>
              <ScoreCardUpload 
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setTriggerUpload={setTriggerUpload}
                fileType={fileType}
                setFileType={setFileType}
                link={link}
                file={file}
                googleUploadComponent={
                  <GoogleSheetLinkUpload 
                    link={link}
                    setLink={setLink}
                  />
                }
                excelUploadComponent={
                  <ExcelScoreCardUpload 
                    file={file}
                    setFile={setFile}
                    fileType={fileType}
                    id={sprintId}
                    field="mission_sprint"
                    urlFiles="huddle-scorecards/"
                    postUploadFileCallback={afterExcelUpload}
                    postUrlFile="huddle-scorecards/"
                    triggerUpload={triggerUpload}
                    setTriggerUpload={setTriggerUpload}
                    setIsModalVisible={setIsModalVisible}
                  />
                }
               />
          </div>
          {hasScorecards && (<ScoreCardHistoryTable setRefreshScoreCards={setRefreshScoreCards} records={scorecardsTemplates?.data?.results ?? []}/>)}
        </div>
      </Col>
    </form>
  </div>;
};

export default HuddleScorecard;
