import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';

/**
 * Renders an input component with debounce functionality.
 *
 * @param {Object} props - The props object.
 *   @param {string} props.name - The name of the input.
 *   @param {Object} props.control - The control object for the input.
 *   @param {function} [props.debounceAction=()=>{}] - The debounce action function.
 *   @param {string} [props.className=''] - The additional class name for the input.
 *   @param {Object} [props.rules={}] - The rules object for the input.
 *   @param {string} [props.placeholder=''] - The placeholder text for the input.
 *   @param {number} [props.maxlength=10] - The maximum length of the input.
 * @return {JSX.Element} - The rendered input component.
 */
const InputNumberDebounce = ({
  name,
  control,
  debounceAction = () => { },
  className = '',
  rules = {},
  placeholder = '',
  maxlength = 10,
  suffix = null,
  disabled = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          className={`input-class ${className}`}
          placeholder={placeholder}
          pattern="^\d*(\.\d{0,4})?$"
          {...field}
          maxLength={maxlength}
          autoComplete='off'
          disabled={disabled}
          onChange={(value) => {
            field.onChange(value);
            debounceAction();
          }}
          suffix={suffix}
        />
      )}
    />
  );
};

export default InputNumberDebounce;
