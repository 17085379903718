import React, {useEffect, useState} from 'react';
import {Button, Col, List, Row} from 'antd';
import {
  RightCircleOutlined,
  UpCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {getMissionPerformance} from 'Services/Performance.service';
import ProjectSprints from './ProjectSprints';
import flat from 'Assets/flat.svg';
import up from 'Assets/up.png';
import down from 'Assets/down.png';
import {
  PerformanceCategory,
  PerformanceMainMeasure,
  PerformanceMission,
} from 'types/Performance';

/**
 * Render a project item with toggling functionality.
 *
 * @param {Object} project - The project data.
 * @param {number} index - The index of the project item.
 * @return {JSX.Element} The rendered project item.
 */
const ProjectItem: React.FC<{
  project: PerformanceCategory;
  index: number;
}> = ({project, index}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectData, setProjectData] = useState({} as PerformanceMission);

  const tremdImg = {
    FLAT: flat,
    UP: up,
    DOWN: down,
  };

  /**
   * Selects the appropriate image for the current state based on the target value.
   *
   * @param {number} target - The target value.
   * @param {number} current_state - The current state value.
   * @return {string} The name of the image to display.
   */
  function selectCurrentStateImg(
    target: number,
    current_state: number
  ): string {
    const redValue = target - target * 0.2;
    const yellowValue = target - target * 0.1;
    if (current_state <= redValue) {
      return 'ProjectsLIst-circle-red';
    }
    if (current_state > redValue && current_state < yellowValue) {
      return 'ProjectsLIst-circle-yellow';
    } else {
      return 'ProjectsLIst-circle-green';
    }
  }

  const toggleOpen = () => setIsOpen(!isOpen);

  const loadProjectData = async () => {
    try {
      const res = await getMissionPerformance(project?.id).call;
      setProjectData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!isOpen) return;
    loadProjectData();
  }, [isOpen]);

  const main_measure: PerformanceMainMeasure =
    project?.main_measure ??
    ({
      target: 0,
      current_state: 0,
    } as PerformanceMainMeasure);

  return (
    <>
      <Row gutter={12} key={`b-${index}`} className={isOpen ? 'row-black' : ''}>
        {!isOpen && (
          <>
            <Col span={7}>
              <div className='ProjectsLIst-container-data flex-left'>
                {project?.name}
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center'>
                {main_measure.current_state || 0}
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center'>
                {main_measure.target || 0}
              </div>
            </Col>
            <Col span={3}>
              <div className='ProjectsLIst-container-data text-center'>
                <span
                  className={selectCurrentStateImg(
                    main_measure?.target,
                    main_measure?.current_state
                  )}
                />
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center'>
                <img
                  className='ProjectsLIst-img'
                  src={tremdImg[project?.trend]}
                  alt={project?.trend}
                />
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center'>
                {project?.sprints}
              </div>
            </Col>
            <Col span={2}>
              <div className='ProjectsLIst-container-data text-center'>
                {project?.barriers}
              </div>
            </Col>
            <Col span={3}>
              <div className='ProjectsLIst-container-data text-center'>
                {project?.lessons_learned}
              </div>
            </Col>
          </>
        )}
        {isOpen && (
          <>
            <Col span={23}>
              <b>{project?.name}</b>{' '}
              <span className='subtitle'>Project Details</span>
            </Col>
          </>
        )}
        <Col span={1}>
          <div className=''>
            <Button type='primary' onClick={toggleOpen}>
              {isOpen ? <UpCircleOutlined /> : <RightCircleOutlined />}
            </Button>
          </div>
        </Col>
      </Row>
      {isOpen && (
        <Row gutter={12} key={`d-${index}`} className='row-detailed'>
          <Col span={24}>
            <b className={`badge-${project?.status} ml-5`}>
              {project?.status?.replace('_', ' ').toLowerCase()}
            </b>
            <Row gutter={12} className='mt-10 mb-15 mr-5 ml-5'>
              <Col span={12}>
                {projectData?.evaluated_success?.length > 0 && (
                  <>
                    <b>How will success be measured?</b>
                    <List size='small'>
                      {projectData?.evaluated_success?.map((item, index) => (
                        <List.Item>
                          <CheckOutlined />
                          {item?.name}
                        </List.Item>
                      ))}
                    </List>
                  </>
                )}
                {projectData?.lessons_learned?.length > 0 && (
                  <>
                    <b className='mt-10'>Lessons Learned</b>
                    <List size='small'>
                      {projectData?.lessons_learned?.map((item, index) => (
                        <List.Item>
                          <CheckOutlined />
                          {item?.description}
                        </List.Item>
                      ))}
                    </List>
                  </>
                )}
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  <Col span={6} className='text-center'>
                    Trend
                  </Col>
                  <Col span={6} className='text-center'>
                    Sprints
                  </Col>
                  <Col span={6} className='text-center'>
                    Barriers
                  </Col>
                  <Col span={6} className='text-center'>
                    Lessons Learned
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={6}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      <img
                        className='ProjectsLIst-img'
                        src={tremdImg[project?.trend]}
                        alt={project?.trend}
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {project?.sprints}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {project?.barriers}
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='ProjectsLIst-container-data text-center bordered'>
                      {project?.lessons_learned}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ProjectSprints project={projectData} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProjectItem;
