import React from 'react';
import { ButtonElement } from '../../Components/ButtonElement';
import style from './style.module.scss';
export default class ConfirmationMessage extends React.Component {
  render() {
    return (
      <div className={style.confirmationMessage}>
        <h3>{this.props.heading}</h3>
        <p>{this.props.message}</p>
        <ul className={style.confirmationMessage__list}>
          <li>
            <ButtonElement
              onClick={this.props.onCancel}
              children={this.props.cancelButtonLabel || 'Cancel'}
              type='primary'
            />
          </li>
          <li>
            <ButtonElement
              onClick={this.props.onOk}
              children={this.props.confirmButtonLabel || 'Confirm'}
            />
          </li>
        </ul>
      </div>
    );
  }
}