import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getSprint} from 'Services/Sprints.service';
import {Planning} from '../Planning';
import {Action} from '../Action';
import {Closed} from '../Closed';
import {TagStatus} from '../TagStatus';
import {Steps} from 'Components/molecules/steps/Steps';
import {ContinueWithoutSavingModal} from 'Components/molecules/modal';
import {SprintDetail} from 'types/Sprint';
import {Button, Icon} from 'Components/atoms';
import {useHistory} from 'react-router-dom';
import { useTranslation } from "react-i18next"
import { matchPath } from 'react-router-dom';

type HeaderProps = {
  name: string;
  uid: string | number;
  why_sprinting: string;
  status: string;
};

type FooterProps = {
  onClickNext: () => void;
  title: string;
  isLastStep: boolean;
};

const Header: React.FC<HeaderProps> = ({name, uid, why_sprinting, status}) => {
  const {t} = useTranslation();

  return (
    <header>
      <hr className='hr-color' />
      <div className='flex justify-around'>
        <div>
          <p>
            <b>Project Assigned</b>
          </p>
          <p>{name}</p>
        </div>
        <div>
          <p>
            <b># of {t('commons.intervention')}</b>
          </p>
          <p>{uid}</p>
        </div>
        <div>
          <p>
            <b>Reason of {t('commons.intervention')}</b>
          </p>
          <p>
            {why_sprinting === 'TO_CREATE_DEMAND'
              ? 'To create demand'
              : 'To implement an intervention'}
          </p>
        </div>
        <div>
          <p>
            <b>Status</b>
          </p>
          <p>
            <TagStatus tag={status} />
          </p>
        </div>
      </div>
      <hr className='hr-color' />
    </header>
  );
};

const Footer: React.FC<FooterProps> = ({onClickNext, title, isLastStep}) => (
  <footer className='flex justify-end'>
    {!isLastStep && (
      <Button onClick={onClickNext}>
        <span>{title}</span>
        <Icon name='arrow-right' />
      </Button>
    )}
  </footer>
);

type InterventionProps = {
  sprintId: number;
};

const Intervention: React.FC<InterventionProps>= ({sprintId}) => {
  const menuItems = [
    {
      title: 'Planning',
      description: 'Planning',
    },
    {
      title: 'In Action',
      description: 'In Action',
    },
    {
      title: 'Reflection',
      description: 'Reflection',
    },
  ];

  const [sprintData, setSprintData] = useState<SprintDetail>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingLocation, setPendingLocation] = useState<any>(null);
  const history = useHistory();  // Get the history object

  const currentStepTitle = menuItems[currentStep]?.title ?? '';
  const isLastStep = currentStep === menuItems.length - 1;

  const isWorkflowEdited: boolean = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.isEdited ?? false
  );

  const changeStep = (current: number) => {
    if (isWorkflowEdited && currentStep === 0) {
      setIsModalVisible(true);
    } else {
      setCurrentStep(current);
    }
  };

  const goToNextStep = () => {
    if (isWorkflowEdited && currentStep === 0) {
      setIsModalVisible(true);
    } else {
      setCurrentStep(currentStep + 1);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  };
  const loadSprint = async () => {
    if (sprintId) {
      try {
        const res = await getSprint(sprintId).call;
        setSprintData(res.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    loadSprint();
  }, [sprintId]);

  // Set up history.block to prevent navigation away
  useEffect(() => {
    if (isWorkflowEdited) {
      const unblock = (history as any).block((location:any) => {
        const match = matchPath(location.pathname, {
          path: '/:id/organisation-insights/intel/intervention/',
          exact: true,
          strict: false,
        });

        if (!match) {
          setPendingLocation(location);  // Store the location where the user intends to go
          setIsModalVisible(true);  // Show the modal
          return false;  // Block the navigation
        }
        return true;  // Allow navigation
      });

      return () => {
        unblock();  // Clean up the block when component is unmounted
      };
    } 
  }, [isWorkflowEdited, history]);

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    if (pendingLocation) {
      window.location.href = pendingLocation.pathname + pendingLocation.search;  // Force navigation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);  // Close the modal and cancel navigation
  };

  return (
    <div className='menu' data-testid='menu'>
      {!!sprintData && (
        <Header
          name={sprintData?.mission?.name}
          uid={sprintData?.uid}
          why_sprinting={sprintData?.why_sprinting ?? ''}
          status={sprintData?.status}
        />
      )}
      <Steps current={currentStep} onChange={changeStep} items={menuItems} />
      <div className='grid gap-4 mb-4'>
        {currentStep == 0 && <Planning />}
        {currentStep == 1 && <Action />}
        {currentStep == 2 && <Closed />}
        <Footer
          onClickNext={goToNextStep}
          title={`Finish ${currentStepTitle}`}
          isLastStep={isLastStep}
        />
      </div>
      <ContinueWithoutSavingModal
        isOpen={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalConfirm}
      />
    </div>
  );
};

export default Intervention;
