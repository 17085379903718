import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { InsuranceTypeDetail, PaginatedInsuranceType, PostInsuranceType, PatchInsuranceType } from "types/Demographic";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getInsuranceTypes(id: number): {
  call: Promise<AxiosResponse<PaginatedInsuranceType>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}patient-insurance-types/?demographics=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postInsuranceTypes(data: PostInsuranceType): {
  call: Promise<AxiosResponse<InsuranceTypeDetail>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}patient-insurance-types/`, data, headers())
  }
}

export function patchInsuranceTypes(data: PatchInsuranceType) {
  return {
    call: axios.patch(`${API_BASE_URL}patient-insurance-types/${data.id}/`, data, headers())
  }
}

export function deleteInsuranceTypes(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}patient-insurance-types/${id}/`, {
      ...headers()
    }),
  }
}