export function updateApiStarted(state) {
  return {
    ...state,
    updateDesignation: {
      isProcessing: true,
      error: false,
      success: false,
    },
  };
}
export function updateApiSuccess(state, payload) {
  return {
    ...state,
    updateDesignation: {
      isProcessing: false,
      error: false,
      success: true,
    },
  };
}
export function updateApiFailed(state) {
  return {
    ...state,
    updateDesignation: {
      isProcessing: false,
      error: true,
      success: false,
    },
  };
}
