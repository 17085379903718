import axios from 'axios';
import { API_BASE_URL } from 'config/constants';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getUsers() {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}users/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getUserInfo(userId) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}users/${userId}/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getUsersSearch(email) {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}users/?q=${email}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getClientRoles() {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}client-roles/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postClientRoles(data) {
  return {
    call: axios.post(`${API_BASE_URL}client-roles/`, data, headers()),
  };
}

export const loadRolesCascader = async (id) => {
  try {
    const res = await getClientRoles(id).call
    const entries = []
    const resData = Object.entries(res.data);
    for (const [index, [name, items]] of resData.entries()) {
      entries.push({
        value: name.toLocaleLowerCase(),
        label: name,
        disabled: items.length === 0,
        children: items.map((item) => ({
          value: item.role?.id,
          label: item.role?.name,
        }))
      })
    }
    return entries
  } catch (error) {
    console.log(error)
  }
  return []
}