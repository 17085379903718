export const INITIAL_STATE = {
  loginResponse: {
    data: [],
  },
  designation: {
    name: 'designation',
    value: '',
    errorMessage: '',
  },
  email: {
    name: 'email',
    value: '',
    errorMessage: '',
  },
  password: {
    name: 'password',
    value: '',
    errorMessage: '',
  },
  firstName: {
    name: 'firstName',
    value: '',
    errorMessage: '',
  },
  lastName: {
    name: 'lastName',
    value: '',
    errorMessage: '',
  },
  confirmPassword: {
    name: 'password',
    value: '',
    errorMessage: '',
  },
  loginEmail: {
    name: 'loginEmail',
    value: '',
    errorMessage: '',
  },
  loginPassword: {
    name: 'loginPassword',
    value: '',
    errorMessage: '',
  },
  loading: false,
  loginApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  signUpResponse: [],
  isSignUpSuccess: null,
  loginResponse: [],
  forgotPassword: {
    isProcessing: null,
    success: null,
    error: null,
  },
  resetPassword: {
    isProcessing: null,
    success: null,
    error: null,
  },
  signupApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  confirm_password: {
    name: 'confirm_password',
    value: '',
    errorMessage: '',
  },
  userDetails: {
    isProcessing: null,
    success: null,
    error: null,
  },
};
