import * as React from 'react';

export const PercentageCircle: React.FC<{
  percentage: number;
}> = ({percentage}) => {
  return (
    <div className='flex items-center justify-center bg-white rounded-full w-12.5 h-12.5 shrink-0'>
      <p className='text-black'>{percentage}%</p>
    </div>
  );
};
