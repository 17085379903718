import React, { useState } from 'react';
import ArchetypeModal from '../../../../../../../../Components/ArchetypeModal/ArchetypeModal';
import iconAvatar from '../../../../../../../../Assets/avatar.png';
import checkMark from '../../../../../../../../Assets/green-checkmark.png';
import mail from '../../../../../../../../Assets/send-mail.png';
import './styles/TeamCard.scss';

import { useTranslation } from "react-i18next"

/**
 * Renders a team member card.
 *
 * @param {object} item - The team member object.
 * @param {function} editMember - A function to edit the team member.
 * @param {function} deleteMember - A function to delete the team member.
 * @param {boolean} isMission - Indicates if the team member is on a mission.
 * @return {JSX.Element} The rendered team member card.
 */
const TeamCard = ({ item, editMember, deleteMember, isMission = false }) => {
  const [archetypeModal, setArchetypeModal] = useState(false);
  const [selectPersonaItem, setSelectPersonaItem] = useState({});

  const [t] = useTranslation()

  const modalArchetype = ({ persona }) => {
    setSelectPersonaItem(persona);
    setArchetypeModal(true);
  };

  const iconType = {
    SUCCESS: checkMark,
    NOTUSER: iconAvatar,
    PENDING: mail,
  };

  return (
    <div className='teamcard'>
      <div className='teamcard-card'>
        <div className='icon-container'>
          <img
            src={
              item?.persona?.user?.is_verified
                ? iconType.SUCCESS
                : iconType.NOTUSER
            }
            alt=''
            className='border-rounder icon'
          />
        </div>
        <div className='flex pt-15 text-left'>
          <img
            className='staff-card-avatar'
            src={item?.persona?.image || '/img/avatar.png'}
            alt=''
          />
          <div>
            <p className='staff-card-name'>{item?.persona?.name}</p>
            <p className='text-black'>
              <b>{item?.persona?.role?.name}</b>{' '}
            </p>
          </div>
        </div>
        <div
          className={`${item?.persona?.archetype == '' ? 'hidden' : 'block'}`}
          onClick={() => modalArchetype(item)}>
          <p>
            <span className='staff-card-archetype'>
              {item?.persona?.archetype_info?.name}
            </span>
          </p>
        </div>
        <hr className='hr-color w-90 m-auto my-10' />
        <p className='mb-5 block h-25px'>
          <span
            className={`influencer-tag ${item?.right_influencer ? '' : 'd-none'
              }`}>
            {' '}
            Influencer
          </span>
        </p>
        <p className='text-black'>Is a problem for them?</p>
        <p className='text-black'>
          <b>{item?.is_problem ? 'Yes' : 'No'}</b>
        </p>
        <p className='text-black my-10'>{item?.job_and_resp}</p>
        <div className='teamcard-card-option justify-center'>
          <div className='flex justify-beetween w-90 m-auto mb-10'>
            <div>
              <p>Demand</p>
              <p>{item?.demand_prob_score}</p>
            </div>
            <div>
              <p>Trust</p>
              <p>{item?.trust_other_score}</p>
            </div>
          </div>
          <hr className='hr-color w-90 m-auto my-10' />
          <div className='grid'>
            <button
              className='staff-card-btn'
              type='button'
              onClick={() => editMember(item.id)}>
              Edit Engagement in {isMission ? 'Project' : t('commons.intervention')}
            </button>
            <button
              className='staff-card-btn-r mt-10'
              onClick={() => deleteMember(item.id)}>
              Remove
            </button>
          </div>
        </div>
      </div>
      <ArchetypeModal
        closeModal={() => setArchetypeModal(false)}
        visibleModal={archetypeModal}
        selectPersona={selectPersonaItem}
      />
    </div>
  );
};

export default TeamCard;
