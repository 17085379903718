/* eslint-disable no-console */
export function fetchTeamBuildDetailsStarted(state) {
  return {
    ...state,
    getTeamBuildDetails: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function fetchTeamBuildDetailsSuccess(state, payload) {
  return {
    ...state,
    getTeamBuildDetails: {
      isProcessing: false,
      success: true,
      error: null,
    },
    teamBuildData: payload,
  };
}
export function fetchTeamBuildDetailsFailed(state, payload) {
  return {
    ...state,
    getTeamBuildDetails: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching details',
    },
  };
}


export function fetchOtherPersonDetailsStarted(state) {
  return {
    ...state,
    getOtherPersonDetails: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function fetchOtherPersonDetailsSuccess(state, payload) {
  return {
    ...state,
    getOtherPersonDetails: {
      isProcessing: false,
      success: true,
      error: null,
    },
    otherPersonData: payload,
  };
}
export function fetchOtherPersonDetailsFailed(state, payload) {
  return {
    ...state,
    getOtherPersonDetails: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching details',
    },
  };
}

export function addTeamMembersStarted(state) {
  return {
    ...state,
    addTeamMembersDetails: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addTeamMembersSuccess(state, payload) {
  return {
    ...state,
    addTeamMembersDetails: {
      isProcessing: false,
      success: true,
      error: null,
    },
    teamMembersData: payload,
  };
}
export function addTeamMembersFailed(state, payload) {
  return {
    ...state,
    addTeamMembersDetails: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching details',
    },
  };
}

export function getTeamBuildStarted(state) {
  return {
    ...state,
    getTeamBuildData: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getTeamBuildSuccess(state, payload) {
  return {
    ...state,
    getTeamBuildData: {
      isProcessing: false,
      success: true,
      error: null,
    },
    getTeamBuild: payload,
  };
}
export function getTeamBuildFailed(state, payload) {
  return {
    ...state,
    getTeamBuildData: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching details',
    },
  };
}
