import axios, { AxiosResponse } from 'axios';
import {API_BASE_URL, headers} from 'config/constants';
import { Organisation } from 'types/Organisation';
import { AbortableRequestConfig } from 'types/Request';

export function getOrganisationById(id: number): {
  call: Promise<AxiosResponse<Organisation>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}organization/${id}/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

