import {Icon} from 'Components/atoms';
import React from 'react';

type StepProps = {
  title: string;
  description?: string;
};

type StepsProps = {
  current: number;
  items: StepProps[];
  onChange: (current: number) => void;
};

export const Steps: React.FC<StepsProps> = ({current, items, onChange}) => {
  const styles = {
    prev: 'border-primary bg-primary text-white',
    current: 'border-primary bg-primary text-white',
    next: 'border-gray-500 bg-white',
  };

  const isLast = (index: number): boolean => index === items.length - 1;

  const getStyle = (index: number): string => {
    if (index < current) {
      return styles.prev;
    }

    if (index === current) {
      return styles.current;
    }

    return styles.next;
  };

  return (
    <div className='flex gap-2 justify-between relative'>
      <div className='absolute top-[18px] left-0 w-full h-0.5 bg-gray-500'></div>
      {items.map((item, index) => (
        <div
          key={item?.title}
          className={`relative grid gap-3${
            isLast(index) ? ' bg-[#ecf7ff]' : ''
          }`}>
          <div
            className={`absolute -top-1.5 -left-1.5 w-12 h-12 bg-white rounded-full border-2 border-solid border-primary transition-opacity ${
              index === current ? 'opacity-100' : 'opacity-0'
            }`}></div>
          <button
            data-testid='stepButton' 
            className={`relative flex w-9 h-9 border-solid border-2 rounded-full transition-colors items-center justify-center ${getStyle(
              index
            )}`}
            onClick={() => {
              onChange(index);
            }}>
            {index < current ? (
              <Icon name='check-white' />
            ) : (
              <span>{index + 1}</span>
            )}
          </button>
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};
