/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLoading } from "Components/FullScreenLoader/LoadingContext";
import { useState, useEffect } from "react";
import fetchWithLoading  from "utils/fetchWithLoading";
import { FetchWithLoadingArgs } from "./hooks";

function useFetchWithLoading<T>({ apiFunction, params, triggers }: FetchWithLoadingArgs<T>) {
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    let isMounted = true;
    const controller = fetchWithLoading(apiFunction, startLoading, stopLoading, setData, setError, ...params);

    return () => {
      if (isMounted) {
        controller.abort();
      }
      isMounted = false;
    };
  }, [...triggers]);

  return { data, error };
}

export default useFetchWithLoading;