import {ApiBase} from '../../../utils/apiBase';
import {getNotification} from '../../../Components/GetNotification';
import {
  GET_DESIGNATIONS_FAILED,
  GET_DESIGNATIONS_SUCCESS,
} from '../../Redux.constants';
import {FETCH_DESIGNATIONS} from '../../Api.constants';

export function getDesignationsAction(params) {
  let {input, limit, offset, orgId} = params;
  return async (dispatch) => {
    dispatch({
      type: GET_DESIGNATIONS_FAILED,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        FETCH_DESIGNATIONS +
          `?organization=${orgId}&q=${input}&limit=${limit}&offset=${offset}`
      );
      dispatch({
        type: GET_DESIGNATIONS_SUCCESS,
        payload: {
          designationList: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DESIGNATIONS_FAILED,
        payload: {error},
      });
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to fetch designation list',
      });
    }
  };
}
