/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLoading } from "Components/FullScreenLoader/LoadingContext";
import { useState } from "react";
import fetchWithLoading from "utils/fetchWithLoading";
import { FetchWithLoadingArgs } from "./hooks";

function useManualApiWithLoading<T>({ apiFunction }: FetchWithLoadingArgs<T>) {
  const { startLoading, stopLoading } = useLoading();
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  const callFunction = (...params: any[]) => {
    const controller = fetchWithLoading(apiFunction, startLoading, stopLoading, setData, setError, ...params);

    return () => {
      controller.abort();
    };
  };

  return { data, setData, error, setError, callFunction };
}

export default useManualApiWithLoading;