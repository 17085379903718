export function signUpFormChange(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      value: payload.value,
      errorMessage: '',
    },
  };
}

export function signUpSuccess(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      value: payload.value,
      errorMessage: '',
      loading: false,
    },
    signupApi: {isProcessing: false},
    signUpResponse: payload.signUpResponse,
    isSignUpSuccess: true,
  };
}
export function signUpValidationErrors(state, payload) {
  const newState = {...state};
  newState.loading = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
  };
}
export function signUpStarted(state, payload) {
  return {
    ...state,
    signupApi: {isProcessing: true},
  };
}
export function signUpError(state, payload) {
  return {
    ...state,
    ...payload,
    signupApi: {isProcessing: false},
  };
}
