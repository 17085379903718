export function setMetadataSuccess(state, payload) {
    if(!sessionStorage.getItem("metadataBA")){
      sessionStorage.setItem("metadataBA",JSON.stringify(payload));
    }
    return {
      ...state,
      metadata: payload
    };
  }

export function setMetadataFailed(state,payload) {
    return {
      ...state,
      metadata: payload
    };
  }
  