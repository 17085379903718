import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Collapse, Col, Row, Radio } from 'antd';
import moment from 'moment';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { getNotification } from 'Components/GetNotification';
import {
  getSprintHuddlesDetail,
  patchSprintHuddle,
  postSprintHuddleBarriers,
  postSprintHuddleItems,
  patchSprints,
  patchSprintHuddleBarriers,
  deleteSprintHuddleBarriers,
  deleteSprintHuddleItems,
  patchSprintHuddleItems,
} from 'Services/Sprints.service';
import TextareaDebounce from 'Components/TextareaDebounce/TextareaDebounce';
import DateDebounce from 'Components/DateDebounce/DateDebounce';
import DebounceLoading, {
  dialogStartSubject$,
  dialogStopSubject$,
} from 'Components/DebounceLoading/DebounceLoading';
import { AUTOSAVING_DELAY } from '../../../../../../../../../../../config/constants';
import './styles/Huddle.scss';
import FormTable from 'Components/FormTable/FormTable';
import InputMultilineDebounce from 'Components/InputMultilineDebounce/InputMultipleDebounce'
import { Icon } from 'Components/atoms';

const { Panel } = Collapse;

/**
 * Renders the Huddle component.
 *
 * @param {number} id - The id of the Huddle.
 * @returns {JSX.Element} The rendered Huddle component.
 */
const Huddle = ({ id, onDelete }) => {
  const [disable, setDisable] = useState(false);
  const [barriers, setBarriers] = useState([]);
  const [goingWell, setGoingWell] = useState([]);
  const [notGoingWell, setNotGoingWell] = useState([]);
  const [doDifferently, setDoDifferently] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const refForm = useRef();

  const sprintId = useSelector((state) => {
    return state.sprint.value;
  });

  const {
    fields: fields_barriers,
    append: append_barriers,
    remove: remove_barriers,
  } = useFieldArray({
    control,
    name: 'barriers',
  });

  const {
    fields: fields_going_well,
    append: append_going_well,
    remove: remove_going_well,
  } = useFieldArray({
    control,
    name: 'going_well',
  });

  const {
    fields: fields_not_going_well,
    append: append_not_going_well,
    remove: remove_not_going_well,
  } = useFieldArray({
    control,
    name: 'not_going_well',
  });

  const {
    fields: fields_do_differently,
    append: append_do_differently,
    remove: remove_do_differently,
  } = useFieldArray({
    control,
    name: 'do_differently',
  });

  /**
   * Saves a huddle.
   *
   * @param {Object} params - The parameters for the huddle.
   * @return {undefined}
   */
  const saveHuddle = async (params) => {
    dialogStartSubject$.setSubject = true;
    try {
      const barrierAuxData = params.barriers?.map((barrier, index) => {
        if (barriers[index]?.hasOwnProperty('id')) {
          return { id: barriers[index].id, ...barrier };
        }

        return barrier;
      });

      const barrierReq = await barrierAuxData?.map(async (item) => {
        if (item?.id) {
          await patchSprintHuddleBarriers(item).call;
        } else {
          await postSprintHuddleBarriers([item]).call;
        }
      });

      await Promise.all(barrierReq);

      delete params.barriers;
    } catch (error) {
      return;
    }

    try {
      const goingWellAuxData = params.going_well?.map((barrier, index) => {
        if (goingWell[index]?.hasOwnProperty('id')) {
          return { id: goingWell[index].id, ...barrier, item_type: 'GOING_WELL' };
        }

        return { ...barrier, item_type: 'GOING_WELL' };
      });

      const notGoingWellAuxData = params.not_going_well?.map(
        (barrier, index) => {
          if (notGoingWell[index]?.hasOwnProperty('id')) {
            return {
              id: notGoingWell[index].id,
              ...barrier,
              item_type: 'NOT_GOING_WELL',
            };
          }

          return { ...barrier, item_type: 'NOT_GOING_WELL' };
        }
      );

      const doDifferentlyAuxData = params.do_differently?.map(
        (barrier, index) => {
          if (doDifferently[index]?.hasOwnProperty('id')) {
            return {
              id: doDifferently[index].id,
              ...barrier,
              item_type: 'DO_DIFFERENTLY',
            };
          }

          return { ...barrier, item_type: 'DO_DIFFERENTLY' };
        }
      );
      const huddleItemsReq = await [
        ...goingWellAuxData,
        ...notGoingWellAuxData,
        ...doDifferentlyAuxData,
      ].map(async (item) => {
        if (item?.id) {
          await patchSprintHuddleItems(item).call;
        } else {
          await postSprintHuddleItems([item]).call;
        }
      });
      await Promise.all(huddleItemsReq);
      delete params.going_well;
      delete params.not_going_well;
      delete params.do_differently;
    } catch (error) { }

    try {
      if (params?.date === null || params?.date === '') delete params.date;
      const data = {
        id,
        ...params,
        meeting_our_goal: params?.meeting_our_goal == 'yes' ? true : false,
        need_more: params?.need_more == 'yes' ? true : false,
      };
      if (data?.date)
        data.date = moment(params?.date).format('YYYY-MM-DDTHH:mm:ss');

      await patchSprintHuddle(data).call;

      getNotification('success', {
        header: '',
        body: 'The information has been saved successfully',
      });
    } catch (error) {
      console.log(error, '13');
    }
    try {
      const data = {
        status: 'IN_ACTION',
        id: sprintId,
      };

      await patchSprints(data).call;
    } catch (error) {
      console.log(error, '14');
    }
    loadArraysData();
    dialogStopSubject$.setSubject = false;
  };

  const loadArraysData = async () => {
    try {
      const res = await getSprintHuddlesDetail(id).call;
      setBarriers(res?.data?.barriers);
      setGoingWell(res?.data?.going_well_items);
      setNotGoingWell(res?.data?.not_going_well_items);
      setDoDifferently(res?.data?.do_differently);
    } catch (error) { }
  };

  const loadHuddleDetails = async () => {
    if (id) {
      reset();
      try {
        const res = await getSprintHuddlesDetail(id).call;
        setBarriers(res?.data?.barriers);
        setGoingWell(res?.data?.going_well_items);
        setNotGoingWell(res?.data?.not_going_well_items);
        setDoDifferently(res?.data?.do_differently);
        setValue('date', res.data?.date);
        setValue('meeting_our_goal', res.data?.meeting_our_goal ? 'yes' : 'no');
        setValue('need_more', res.data?.need_more ? 'yes' : 'no');
        setValue('why_or_why_not', res.data?.why_or_why_not);
        setValue('providers_info', res.data?.providers_info);
        setValue('staff_info', res.data?.staff_info);
        setValue('leadership_info', res.data?.leadership_info);
        setValue('wins_celebration', res.data?.wins_celebration);
        if (res.data?.barriers?.length > 0) {
          res.data.barriers.map((item) => {
            append_barriers(item);
            return item;
          });
        } else {
          append_barriers({ created: true, huddle: id });
        }
        if (res.data?.going_well_items?.length > 0) {
          res.data.going_well_items.map((item) => {
            append_going_well(item);
            return item;
          });
        } else {
          append_going_well({ created: true, huddle: id });
        }
        if (res.data?.not_going_well_items?.length > 0) {
          res.data.not_going_well_items.map((item) => {
            append_not_going_well(item);
            return item;
          });
        } else {
          append_not_going_well({ created: true, huddle: id });
        }
        if (res.data?.do_differently?.length > 0) {
          res.data.do_differently.map((item) => {
            append_do_differently(item);
            return item;
          });
        } else {
          append_do_differently({ created: true, huddle: id });
        }
      } catch (error) { }
    }
  };

  const activeSubmitForm = () => {
    refForm?.current.click();
  };

  const debounceActive = useCallback(
    debounce(activeSubmitForm, AUTOSAVING_DELAY),
    []
  );

  const [formTableData] = useState({
    control: control,
    debounceActive: debounceActive,
    errors: errors,
  });

  /**
   * Adds barriers to the given value.
   *
   * @param {type} value - The value to add barriers to.
   * @return {type} The updated value with barriers added.
   */
  const addBarriers = (value) => {
    const barrierObj = {
      description: '',
      how_to_resolve: '',
      huddle: id,
    };
    append_barriers(barrierObj);
    setBarriers([...barriers, barrierObj]);
  };

  /**
   * Adds a huddle item of the given type.
   *
   * @param {string} type - The type of the huddle item.
   * @return {undefined} -
   */
  const addHuddleItem = (type) => {
    const huddleItemObj = {
      huddle: id,
      item_type: type,
      description: '',
    };
    if (type == 'GOING_WELL') {
      append_going_well(huddleItemObj);
      setGoingWell([...goingWell, huddleItemObj]);
    }
    if (type == 'NOT_GOING_WELL') {
      append_not_going_well(huddleItemObj);
      setNotGoingWell([...notGoingWell, huddleItemObj]);
    }
    if (type == 'DO_DIFFERENTLY') {
      append_do_differently(huddleItemObj);
      setDoDifferently([...doDifferently, huddleItemObj]);
    }
  };

  /**
   * Deletes an item based on the type and index provided.
   *
   * @param {string} type - The type of item to delete.
   * @param {number} index - The index of the item to delete.
   * @return {Promise<void>} A promise that resolves when the item is successfully deleted.
   */
  const deleteItem = async (type, index) => {
    try {
      if (type == 'barrier') {
        const id = barriers[index]?.id;
        await deleteSprintHuddleBarriers(id).call;
        setBarriers(() => barriers.filter((item) => item.id != id));
        remove_barriers(index);
      }
      if (type == 'goingWell') {
        const id = goingWell[index]?.id;
        await deleteSprintHuddleItems(id).call;
        setGoingWell(() => goingWell.filter((item) => item.id != id));
        remove_going_well(index);
      }
      if (type == 'notGoingWell') {
        const id = notGoingWell[index]?.id;
        await deleteSprintHuddleItems(id).call;
        setNotGoingWell(() => notGoingWell.filter((item) => item.id != id));
        remove_not_going_well(index);
      }
      if (type == 'doDifferently') {
        const id = doDifferently[index]?.id;
        await deleteSprintHuddleItems(id).call;
        setDoDifferently(() =>
          doDifferently.filter((item) => item.id != id)
        );
        remove_do_differently(index);
      }
    } catch (error) { }
  };

  useEffect(() => {
    loadHuddleDetails();
  }, [id]);

  return (
    <div className='huddle overflow-hidden'>
      <form onSubmit={handleSubmit(saveHuddle)}>

        <button
          type='submit'
          className='btn-edit d-none'
          ref={(e) => (refForm.current = e)}>
          Save
        </button>

        <div className={`grid gap-4 font-medium${disable ? ' pointer-events-none' : ''}`}>
          <div className="flex justify-between items-center">
            <div className="flex-1 grid gap-2.5 max-w-[350px]">
              <p className="text-gray-900">Huddle Date</p>
              <DateDebounce
                name='date'
                control={control}
                debounceAction={debounceActive}
                className='!rounded'
              />
            </div>
            <div>
              <button 
                onClick={onDelete} 
                type='button' 
                className='px-4 py-2 rounded border border-solid border-gray-400 flex gap-2 items-center'>
                  <span className="text-sm">Delete</span>
                  <Icon name='trashcan' />
                </button>
            </div>
          </div>
          <div className="grid gap-2.5">
            <p className='text-gray-900'>Are we meeting our goal?</p>
            <Controller
              name={`meeting_our_goal`}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  onChange={(e) => {
                    field.onChange(e);
                    debounceActive();
                  }}
                  value={field.value}>
                  <Radio.Button value='yes' className='!rounded-l'>Yes</Radio.Button>
                  <Radio.Button value='no' className='!rounded-r'>No</Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className="grid gap-2.5">
            <p className='text-gray-900 relative'>Why or why not?</p>
            <div className="-mt-2.5">
              <InputMultilineDebounce
                name='why_or_why_not'
                control={control}
                debounceAction={debounceActive}
                className='flex-1 w-full font-normal !rounded'
                autoheight={true}
              />
            </div>
          </div>
          <div className="grid gap-2.5">
            <p className='text-gray-900 relative'>What barriers are there?</p>
            <div className='-m-[15px]'>
              <FormTable
                {...formTableData}
                name='barriers'
                fields={fields_barriers}
                isDebounActive={false}
                headers={[
                  {
                    type: 'inputMulti',
                    title: 'Barrier',
                    name: 'description',
                  },
                  {
                    type: 'inputMulti',
                    title: 'How can we resolve',
                    name: 'how_to_resolve',
                  },
                ]}
                onAppend={addBarriers}
                onRemove={(index) => deleteItem('barrier', index)}
              />
            </div>
          </div>
          <div className="grid gap-2.5">
            <p className="text-gray-900 relative">What is going well?</p>
            <div className='-m-[15px]'>
              <FormTable
                {...formTableData}
                name='going_well'
                fields={fields_going_well}
                isDebounActive={false}
                headers={[
                  {
                    type: 'inputMulti',
                    name: 'description',
                  },
                ]}
                onAppend={(value) => addHuddleItem('GOING_WELL')}
                onRemove={(index) => deleteItem('goingWell', index)}
              />
            </div>
          </div>
          <div className="grid gap-2.5">
            <p className='text-gray-900'>Do we need to do more of it?</p>
            <Controller
              name={`need_more`}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  onChange={(e) => {
                    field.onChange(e);
                    debounceActive();
                  }}
                  value={field.value}>
                  <Radio.Button value='yes' className='!rounded-l'>Yes</Radio.Button>
                  <Radio.Button value='no' className='!rounded-r'>No</Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className="grid gap-2.5">
            <p className="text-gray-900 relative">What is not going well?</p>
            <div className='-m-[15px]'>
              <FormTable
                {...formTableData}
                name='not_going_well'
                fields={fields_not_going_well}
                isDebounActive={false}
                headers={[
                  {
                    type: 'inputMulti',
                    name: 'description',
                  },
                ]}
                onAppend={(value) => addHuddleItem('NOT_GOING_WELL')}
                onRemove={(index) => deleteItem('notGoingWell', index)}
              />
            </div>
          </div>
          <div className="grid gap-2.5">
            <p className="text-gray-900 relative">What do we need to do differently?</p>
            <div className='-m-[15px]'>
              <FormTable
                {...formTableData}
                name='do_differently'
                fields={fields_do_differently}
                isDebounActive={false}
                headers={[
                  {
                    type: 'inputMulti',
                    name: 'description',
                  },
                ]}
                onAppend={(value) => addHuddleItem('DO_DIFFERENTLY')}
                onRemove={(index) => deleteItem('doDifferently', index)}
              />
            </div>
          </div>
          <Collapse
            expandIconPosition='end'
            className='mt-15'
            defaultActiveKey={['1']}>
            <Panel header='Information Sharing' key='1'>
              <p className='font-medium text-gray-900'>How will information be shared with:</p>
              <Row gutter={10}>
                <Col xs={24} sm={24} md={8}>
                  <div className='mb-15'>
                    <p className='font-medium text-gray-900'>Providers?</p>
                    <TextareaDebounce
                      name='providers_info'
                      control={control}
                      debounceAction={debounceActive}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <div className='mb-15'>
                    <p className='font-medium text-gray-900'>Staff?</p>
                    <TextareaDebounce
                      name='staff_info'
                      control={control}
                      debounceAction={debounceActive}
                    />
                  </div>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <div className='mb-15'>
                    <p className='font-medium text-gray-900'>Leadership?</p>
                    <TextareaDebounce
                      name='leadership_info'
                      control={control}
                      debounceAction={debounceActive}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col xs={24}>
                  <div className='mb-15'>
                    <p>
                      How will the user celebrate successes or early wins with
                      providers and practice?
                    </p>
                    <TextareaDebounce
                      name='wins_celebration'
                      control={control}
                      debounceAction={debounceActive}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      </form>
      <DebounceLoading />
    </div>
  );
};

export default Huddle;
