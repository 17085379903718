import React, { useEffect, useState } from 'react';
import { Result } from 'antd';
import { useForm } from 'react-hook-form';
import { getSmartSeachSprint, postContactUs } from '../../../../Services/SmartSolution.service';
import './styles/SolutionSidebar.scss';
import { getNotification } from '../../../../Components/GetNotification';

/**
 * Renders the SolutionSidebar component.
 *
 * @param {Object} newPromnt - The newPromnt object.
 * @param {boolean} visible - Indicates if the SolutionSidebar is visible.
 * @param {function} closeModal - The function to close the modal.
 * @return {JSX.Element} The rendered SolutionSidebar component.
 */
const SolutionSidebar = ({ newPromnt, visible, closeModal }) => {

  const [loading, setLoading] = useState(false);
  const [activeForm, setActiveForm] = useState(true);
  const [searchId, setSearchId] = useState(0);
  const [summary, setSummary] = useState("first");
  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm();

  const closeMember = () => {
    closeModal()
  }

  /**
   * Saves the contact information and sends an email notification.
   *
   * @param {Object} params - The contact information.
   * @return {Promise<void>} - A promise that resolves when the email is sent successfully.
   */
  const saveContact = async (params) => {
    try {
      reset();
      await postContactUs({ ...params, solution: searchId, smart_sprints_json: summary }).call
      getNotification('success', {
        header: 'Success',
        body: 'We will be sending the required information to your email shortly.'
      })
      setActiveForm(false)
    } catch (error) {
      getNotification('error', {
        header: 'Apologies',
        body: "An error occurred when trying to send the email, please try again later.",
      });
    }
  }

  let modalClickPath = document.getElementsByClassName("bg-modal-click");
  let modalMember = document.getElementsByClassName("membermodal");

  window.onclick = function (event) {
    let path = event.path || (event.composedPath && event.composedPath());
    const condition = path.includes(modalClickPath[0]) && visible && !path.includes(modalMember[0]);
    if (condition) {
      closeMember()
    }
  }

  const search = async () => {
    try {
      setLoading(true)
      setSummary(null)
      const data = { "solution": newPromnt.id }
      const res = await getSmartSeachSprint(data).call;
      setSearchId(newPromnt.id)
      setSummary(res.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (newPromnt) {
      setActiveForm(true)
      search()
    }
  }, [newPromnt])

  return <div className={`solutionsidebar ${activeForm ? 'overflow-h h-100' : ''}`}>
    <div className=" p-15">
      <div>
        {
          loading ?
            <div className="text-center smartsolutions-container-loading">
              <img src="/img/loading-search.gif" alt="" className="w-25" />
            </div>
            :
            <div>
              {
                summary !== null ?
                  summary !== "first" ?
                    <div>

                      <div className=" mt-15">
                        <p className="text-white"><b>Sprint: {summary.sprint_name}</b></p>
                        <p className="text-white mt-15"><b>Sprint length:</b> </p>
                        <p className='text-white'>{summary.sprint_length}</p>
                        <p className="text-white mt-15"><b>Engaged list:</b></p>
                        {
                          summary.engaged_list?.map((key, index) =>
                            <p className="text-white ml-15" key={index}>• {key?.role}</p>
                          )
                        }
                        <p className="text-white mt-15"><b>Lead measures:</b></p>
                        {
                          summary.lead_measures?.split(",").map((key, index) =>
                            <p className="text-white ml-15" key={index}>• {key}</p>
                          )
                        }
                        <p className="text-white mt-15"><b>Success criteria:</b></p>
                        {
                          summary.success_criteria?.split(",").map((key, index) =>
                            <p className="text-white ml-15" key={index}>• {key}</p>
                          )
                        }
                        <p className="text-white mt-15"><b>Failure criteria:</b></p>
                        {
                          summary.failure_criteria?.split(",").map((key, index) =>
                            <p className="text-white ml-15" key={index}>• {key}</p>
                          )
                        }
                        <p className="text-white mt-15"><b>Sprint breakdown:</b></p>
                        {
                          summary.sprint_breakdown?.map((item, index) =>
                            <div key={index}>
                              <p className="text-white ml-15">•Week {item.week}</p>
                              <p className="text-white ml-25">• {item.description}</p>
                            </div>
                          )
                        }
                      </div>

                    </div>
                    : ""
                  :
                  <div className="">
                    <Result
                      status="warning"
                      title={
                        <div>
                          <p className="text-white">Sorry, our AI is saturated right now, please try again later.</p>
                          <div className="text-center mt-5">
                            <button type="submit" className="talk-btn text-base" onClick={search}>Refresh</button>
                          </div>
                        </div>
                      }
                    />
                  </div>
              }
            </div>
        }
      </div>
    </div>
    {
      summary !== null ?
        summary !== "first" ?
          <div className={`${activeForm ? 'block' : 'd-none'}`}>
            <div className={`contact-us ${loading ? 'd-none' : ''}`}>
              <div>
                <div>
                  <h3 className="mainsearch-container-title text-base">We see you’re hard at work finding answers.</h3>
                  <p className="text-white text-xs">To see your search results, please fill out the quick form below. We will even send
                    you a copy of your search results for your reference!</p>
                </div>
                <form onSubmit={handleSubmit(saveContact)} className={`mt-15`}>
                  <div className="mt-10">
                    <input placeholder="Name" className={`input-class w-100 ${errors.name ? "border-error" : ""}`} type="text" {...register('name', { required: true })} />
                  </div>
                  <div className="mt-10">
                    <input placeholder="Email" className={`input-class w-100 ${errors.email ? "border-error" : ""}`} type="text" {...register('email', { required: true })} />
                  </div>
                  <div className="mt-10">
                    <input placeholder="Organization" className={`input-class w-100 ${errors.organization ? "border-error" : ""}`} type="text" {...register('organization', { required: true })} />
                  </div>
                  <div className="text-center mt-5">
                    <button type="submit" className="talk-btn">Send</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          : ''
        : ''
    }
  </div>;
};

export default SolutionSidebar;
