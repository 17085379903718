import {createSelector} from 'reselect';

function getLoginPageReducer(state) {
  return state.LoginPageReducer;
}
function getUserReducer(state) {
  return state.UserReducer;
}

function mergeReducer(LoginPageReducer, UserReducer) {
  return {
    loading: LoginPageReducer.loading,
    ...UserReducer,
    firstName: LoginPageReducer.firstName,
    lastName: LoginPageReducer.lastName,
    designation: LoginPageReducer.designation,
    email: LoginPageReducer.email,
    password: LoginPageReducer.password,
    confirmPassword: LoginPageReducer.confirmPassword,
    loginEmail: LoginPageReducer.loginEmail,
    loginPassword: LoginPageReducer.loginPassword,
    loading: LoginPageReducer.loading,
    signUpResponse: LoginPageReducer.signUpResponse,
    isSignUpSuccess: LoginPageReducer.isSignUpSuccess,
    loginApi: LoginPageReducer.loginApi,
    forgotPassword: LoginPageReducer.forgotPassword,
    signupApi: LoginPageReducer.signupApi,
    confirm_password: LoginPageReducer.confirm_password,
    resetPassword: LoginPageReducer.resetPassword,
    password: LoginPageReducer.password,
  };
}

export const SelectState = createSelector(
  [getLoginPageReducer, getUserReducer],
  mergeReducer
);
