import axios from 'axios'
import { API_BASE_URL } from '../../config/constants'

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  }
}

export const getMeasureMetrics = (id, type) => {
  const controller = new AbortController()
  return {
    call: axios.get(`${API_BASE_URL}measure-metrics/?measure=${id}&metric_type=${type}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  }
}

export const postMeasureMetrics = (data) => {
  return axios.post(`${API_BASE_URL}measure-metrics/`, data, headers())
}

export const patchMeasureMetrics = (id, data) => {
  return axios.patch(`${API_BASE_URL}measure-metrics/${id}/`, data, headers())
}