/* eslint-disable */
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {GetLocalStorage} from '../../utils/localStorageOperations';
import Container from '../Container';
import {SwitchContext} from '../new/Context/ComponentSwitchContext';
import style from './style.module.scss';
class HeaderNav extends Component {
  static contextType = SwitchContext;
  constructor(props) {
    super(props);
    this.state = {
      addOnClass: false,
      cancelLogout: false,
    };
  }
  render() {
    return (
      <header className={style.mainHeader}>
          <div className={style.headerInner}>
            <div className={`${style.headerInner__brand}`}>
              <span>
                <img
                  className='h-3rem'
                  src={
                    GetLocalStorage('clientData')?.logo ||
                    '/img/blueagilis-whitelogo-1.png'
                  }
                  alt='logo'
                />
              </span>
            </div>
          </div>
      </header>
    );
  }
}
export default withRouter(HeaderNav);
