import {BaseIcon, sizeType} from './BaseIcon';
import React from 'react';

const path = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 12V17H17V12H14.8125V13.4286H12V12H8V13.4286H5.1875V12H3Z'
      strokeWidth='1.5'
    />
    <path
      d='M3 11.5V8L4 7.33333H6M17 11.5V8L16 7.33333H14.5M6 7.33333V6.5C6 5.39543 6.89543 4.5 8 4.5H12.5C13.6046 4.5 14.5 5.39543 14.5 6.5V7.33333M6 7.33333H14.5'
      strokeWidth='1.5'
    />
  </svg>
);
const ToolboxIcon: React.FC<sizeType> = ({size}) => (
  <BaseIcon size={size}>{path}</BaseIcon>
);

const ToolboxIconWithBackground = () => <BaseIcon background>{path}</BaseIcon>;

export {ToolboxIcon, ToolboxIconWithBackground};
