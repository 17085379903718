const allpopulatetoolboxdata = {data: [], loading: true};

export const GetAllPopulateToolboxDataReducer = (
  state = allpopulatetoolboxdata,
  action
) => {
  switch (action.type) {
    case 'getallpopulateToolboxdata':
      return {
        data: action.payload,
        loading: action.loader,
      };
    default:
      return state;
  }
};
