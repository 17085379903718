import {connect} from 'react-redux';
import UserProfile from './UserProfile';
import {SelectState} from './Selector';
import {
  changeProfileData,
  getPresignedUrl,
  uploadImage,
  uploadProfileImage
} from '../../Redux/Actions';
const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.UploadImageReducer,
    ...state.UserProfileReducer,
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPresignedUrl: (params, callback) => {
      dispatch(uploadProfileImage(params, callback));
    },
    changeProfileData: (payload, next) => {
      dispatch(changeProfileData(payload, next));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
