import { ApiBase } from '../../../utils/apiBase';
import { LIST_ORGANIZATION_DEPARTMENT } from '../../Api.constants';
import {
    GET_NETWORKING_TOOL_START,
    GET_NETWORKING_TOOL_SUCCESS,
    GET_NETWORKING_TOOL_FAILED,
} from '../../Redux.constants';

export const getNewWorkingToolAction = (id, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NETWORKING_TOOL_START,
        });
        try {
            let url = `/net-map/?mission=${id}`
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(url);
            dispatch({
                type: GET_NETWORKING_TOOL_SUCCESS,
                payload: response.data.results,
            });

            return response.data.results;
            // callBack && callBack(true);
        } catch (error) {
            dispatch({
                type: GET_NETWORKING_TOOL_FAILED,
                payload: { apiError: error },
            });
            // callBack && callBack(false);
        }
    };
};

export const getPersonalizelAction = (id, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NETWORKING_TOOL_START,
        });
        try {
            let url = `/personalized-action-plan/?mission=${id}`
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(url);
            dispatch({
                type: GET_NETWORKING_TOOL_SUCCESS,
                payload: response.data.results,
            });

            return response.data.results;
            // callBack && callBack(true);
        } catch (error) {
            dispatch({
                type: GET_NETWORKING_TOOL_FAILED,
                payload: { apiError: error },
            });
            // callBack && callBack(false);
        }
    };
};

export const getMissionAction = (id, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NETWORKING_TOOL_START,
        });
        try {
            let url = `/meeting-agendas/?mission=${id}`
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(url);
            dispatch({
                type: GET_NETWORKING_TOOL_SUCCESS,
                payload: response.data.results,
            });

            return response.data.results;
            // callBack && callBack(true);
        } catch (error) {
            dispatch({
                type: GET_NETWORKING_TOOL_FAILED,
                payload: { apiError: error },
            });
            // callBack && callBack(false);
        }
    };
};


export const getBehaviourAction = (id, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NETWORKING_TOOL_START,
        });
        try {
            let url = `/behavioral-economics/?mission=${id}`
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(url);
            dispatch({
                type: GET_NETWORKING_TOOL_SUCCESS,
                payload: response.data.results,
            });

            return response.data.results;
            // callBack && callBack(true);
        } catch (error) {
            dispatch({
                type: GET_NETWORKING_TOOL_FAILED,
                payload: { apiError: error },
            });
            // callBack && callBack(false);
        }
    };
};


export const postNewWorkingToolAction = (postRequest, id, callBack) => {
    let formData = new FormData();
    formData.append('filepath', postRequest.filepath);
    formData.append('mission', postRequest.mission);
    formData.append('filename', postRequest.filename);
    formData.append('date', postRequest.date);
    if (id) {
        formData.append("id", id);
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/net-map/${id}/?mission=${postRequest.mission}`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.patch(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/net-map/`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.post(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    }
}

export const getNodeProfilingToolAction = (id, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_NETWORKING_TOOL_START,
        });
        try {
            let url = `/node-profile/?mission=${id}`
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(url);
            dispatch({
                type: GET_NETWORKING_TOOL_SUCCESS,
                payload: response.data.results,
            });

            return response.data.results;
            // callBack && callBack(true);
        } catch (error) {
            dispatch({
                type: GET_NETWORKING_TOOL_FAILED,
                payload: { apiError: error },
            });
            // callBack && callBack(false);
        }
    };
};


export const postNodeProfilingToolAction = (postRequest, id, callBack) => {
    let formData = new FormData();
    formData.append('filepath', postRequest.filepath);
    formData.append('mission', postRequest.mission);
    formData.append('filename', postRequest.name);
    formData.append('date', postRequest.date);
    if (id) {
        formData.append("id", id);
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/node-profile/${id}/?mission=${postRequest.mission}`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.patch(url, formData);

                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
                return response
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/node-profile/`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.post(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    }
}

export const postPersonlizedPlanAction = (postRequest, id, callBack) => {
    let formData = new FormData();
    formData.append('filepath', postRequest.filepath);
    formData.append('mission', postRequest.mission);
    formData.append('filename', postRequest.filename);
    formData.append('date', postRequest.date);
    if (id) {
        formData.append("id", id);
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/personalized-action-plan/${id}/?mission=${postRequest.mission}`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.patch(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/personalized-action-plan/`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.post(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    }
}

export const postMissionPlanAction = (postRequest, id, callBack) => {
    let formData = new FormData();
    formData.append('filepath', postRequest.filepath);
    formData.append('mission', postRequest.mission);
    formData.append('filename', postRequest.filename);
    formData.append('date', postRequest.date);
    if (id) {
        formData.append("id", id);
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/meeting-agendas/${id}/?mission=${postRequest.mission}`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.patch(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/meeting-agendas/`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.post(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    }
}


export const postBehaviourAction = (postRequest, id, callBack) => {
    let formData = new FormData();
    formData.append('filepath', postRequest.filepath);
    formData.append('mission', postRequest.mission);
    formData.append('filename', postRequest.filename);
    formData.append('date', postRequest.date);
    if (id) {
        formData.append("id", id);
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/behavioral-economics/${id}/?mission=${postRequest.mission}`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.patch(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    } else {
        return async (dispatch) => {
            dispatch({
                type: GET_NETWORKING_TOOL_START,
            });
            let url = `/behavioral-economics/`
            try {
                const apiBaseInstance = new ApiBase();
                let response = await apiBaseInstance.instance.post(url, formData);
                dispatch({
                    type: GET_NETWORKING_TOOL_SUCCESS,
                });
                callBack && callBack(true);
            } catch (error) {
                dispatch({
                    type: GET_NETWORKING_TOOL_FAILED,
                    payload: { apiError: error },
                });
                callBack && callBack(false);
            }
        };
    }
}
