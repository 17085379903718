import {
    GET_NETWORKING_TOOL_START,
    GET_NETWORKING_TOOL_SUCCESS,
    GET_NETWORKING_TOOL_FAILED,
} from '../../Redux.constants';
import { getToolBoxStarted, getToolBoxFailed, getToolBoxSuccess } from './Helper';

const initialState = {
    getToolBoxApi: {
        isProcessing: false,
        error: false,
        success: false,
    },
    getToolBoxData: null
}

export default function ToolBoxReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NETWORKING_TOOL_START:
            return getToolBoxStarted(state, action);
        case GET_NETWORKING_TOOL_SUCCESS:
            return getToolBoxSuccess(state, action.payload);
        case GET_NETWORKING_TOOL_FAILED:
            return getToolBoxFailed(state, action);
        default:
            return {...state};
    }
};

