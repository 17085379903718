export const TEAM_BUILDER_STATE = {
  getMissionTeam: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addSprintTeamApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  getSprintTeam: {
    isProcessing: null,
    success: null,
    error: null,
  },
  missionTeamList: [],
  sprintTeamList: [],

  hypothesisTitle: '',
  hypothesisHeadingApi: {
    loading: null,
    error: null,
    message: null,
  },
};
