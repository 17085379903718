export const initialiseAddInsightsForm = () => {
  let form = {
    firstName: {
      name: 'First Name',
      value: '',
      errorMessage: '',
    },
    lastName: {
      name: 'Last Name',
      value: '',
      errorMessage: '',
    },
    email: {
      name: 'Email',
      value: '',
      errorMessage: '',
    },
    designation: {
      name: 'Designation',
      value: null,
      errorMessage: '',
    },
    department: {
      name: 'Department',
      value: null,
      errorMessage: '',
    },
  };
  return JSON.parse(JSON.stringify(form));
};
export const INITIAL_STATE = {
  designationList: {
    isLoading: false,
    success: null,
    error: null,
    totalDesignation: 0,
    data: [],
  },
  departmentList: {
    isLoading: false,
    success: null,
    error: null,
    data: [],
  },
  addEmployee: {
    isLoading: false,
    success: null,
    error: null,
    data: [],
  },
  employeesList: {
    isLoading: false,
    success: null,
    error: null,
    data: [],
    totalEmployees: 0,
  },
  employeesListForSeletBox: {
    isLoading: false,
    success: null,
    error: null,
    data: [],
    totalEmployees: 0,
  },
  addInsightsForm: initialiseAddInsightsForm(),
};
