import React, { useMemo } from 'react';
import { LeftOutlined, RightOutlined} from "@ant-design/icons"
import { Table} from 'antd';
import tagDetailListColumns  from './TagDetailListColumns';
import { TagDetailType } from 'types/Tags';

type TagListType = {
  data: TagDetailType[],
  setSelectedTag: React.Dispatch<React.SetStateAction<TagDetailType | undefined>>,
  setIsOpenDeleteTag: React.Dispatch<React.SetStateAction<boolean>>,
};
/**
 * Renders the Tag Page.
 *
 * @return {JSX.Element} The rendered Tag Page.
 */
const TagDetailList: React.FC<TagListType>  = ({data, setSelectedTag, setIsOpenDeleteTag}) => {

  const columns = useMemo(() => tagDetailListColumns({setSelectedTag, setIsOpenDeleteTag}), [])
  const itemRender = (current:any, type:string, originalElement:any) => {
    if (type === 'prev') {
      return <div className='flex flex-row gap-2'>
          <LeftOutlined/>
          <span>Back</span>
        </div>
    }
    if (type === 'next') {
      return <div className='flex flex-row gap-2'>
          <span>Next</span>
          <RightOutlined/> 
        </div>
    }
    return originalElement;
  };


  return (
    <div id='tagList'>
      <Table
        columns={columns as any}
        dataSource={data}
        pagination={{
          pageSize: 20, // Set the number of records per page
          showSizeChanger: false, // Hide the page size changer
          showQuickJumper: true, // Show quick jumper for navigation
          simple: true, // Use simple pagination, similar to the image
          itemRender: itemRender, // Custom item render function
        }}
      />
    </div>
  );
};

export default TagDetailList;
