import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Row, Select } from 'antd';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getMissions } from '../../../../../Services/Mission.service';
import { getSprints } from '../../../../../Services/Sprints.service';
import { meetingTypes } from './models';
import './styles/MeetingModal.scss';

import { useTranslation } from "react-i18next"

/**
 * Renders a meeting modal component.
 *
 * @param {Object} meetData - The meet data.
 * @param {Object} allFormData - The all form data object.
 * @return {JSX.Element} The rendered meeting modal component.
 */
const MeetingModal = ({ meetData, allFormData }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = allFormData;

  const [t] = useTranslation()

  const watchMeetType = watch('type');

  const [missions, setMissions] = useState([]);
  const [sprints, setSprints] = useState([]);

  const { id } = useParams();

  const getSprintsApi = async (id) => {
    const allSprints = await getSprints(id).call;
    setSprints(
      allSprints.data.results.map((item) => {
        return { value: item.id, label: item.name };
      })
    );
  };

  const getMissionsApi = async (id) => {
    const allMissions = await getMissions(id).call;
    setMissions(
      allMissions.data?.map((item) => {
        return { value: item.id, label: item.name };
      })
    );
  };

  const loadMeetData = async () => {
    try {
      if (meetData?.organizations[0]?.id) {
        getMissionsApi(meetData?.organizations[0]?.id);
      }
      if (meetData?.missions[0]?.id) {
        getSprintsApi(meetData?.missions[0]?.id);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getMissionsApi(id);
    reset();
    if (meetData) {
      loadMeetData();
    }
  }, [meetData]);

  return (
    <div className='meetingmodal'>
      <div>
        <Row gutter={20}>
          <Col span={24} md={8} className='mt-20'>
            <label>
              Meeting Name
              <span className='pl-1 text-red'>*</span>
            </label>
            <input
              data-testid="meetingNameInput"
              className={`input-class ${errors.title ? 'border-error' : ''}`}
              aria-invalid={errors.title ? 'true' : 'false'}
              {...register('title', { required: true })}
              placeholder='Meeting Name'
            />
          </Col>
          <Col span={24} md={8} className='mt-20 '>
            <label>
              Date <span className='text-red'>*</span>
            </label>
            <br />
            <Controller
              name='date'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DatePicker
                  data-testid="dateInput"
                  {...field}
                  className={`datePicker ${errors.date ? 'border-error' : ''
                    } w-100`}
                  placeholderText='Select date'
                  onChange={(date) => field.onChange(date)}
                  allowClear={false}
                  format='MM-DD-YYYY'
                  value={field.value ? moment(field.value) : ''}
                />
              )}
            />
          </Col>
          <Col span={24} md={8} className='mt-20'>
            <label>Meeting Type</label>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={`select-class ${errors.type ? 'border-error' : ''
                    }`}
                  placeholder='Select a type'
                  optionFilterProp='children'
                  bordered={false}
                  onChange={(net) => {
                    field.onChange(net);
                  }}
                  options={meetingTypes}
                />
              )}
            />
            <div className={watchMeetType == 'OTHER' ? 'block' : 'hidden'}>
              <label>Reason</label>
              <input
                className={`input-class ${errors.other_meeting_type ? 'border-error' : ''
                  }`}
                aria-invalid={errors.other_meeting_type ? 'true' : 'false'}
                {...register('other_meeting_type')}
              />
            </div>
          </Col>
          <Col span={24} md={8} className='mt-20'>
            <label>Project</label>
            <Controller
              name='mission'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={`select-class ${errors.mission ? 'border-error' : ''
                    }`}
                  showSearch
                  placeholder='Select a project'
                  optionFilterProp='children'
                  bordered={false}
                  onChange={(net) => {
                    field.onChange(net);
                    getSprintsApi(net);
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={missions}
                />
              )}
            />
          </Col>
          <Col span={24} md={8} className='mt-20'>
            <label>{t('commons.interventions')}</label>
            <Controller
              name='sprint'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className={`select-class ${errors.sprint ? 'border-error' : ''
                    }`}
                  showSearch
                  placeholder={`Select a ${t('commons.intervention')}`}
                  optionFilterProp='children'
                  bordered={false}
                  onChange={(net) => {
                    field.onChange(net);
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={sprints}
                />
              )}
            />
          </Col>
       </Row>
      </div>
    </div>
  );
};

export default MeetingModal;
