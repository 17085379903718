import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Form, Input, Divider, Cascader, Space, notification } from "antd"
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"

import { postWorkflow, patchWorkflowSteps, deleteWorkflowSteps, patchWorkflow, postWorkflowSteps } from "../../../../../../Services/Workflow"
import { loadRolesCascader, postClientRoles } from "../../../../../../Services/Users.service"

/**
 * Renders a form for managing a workflow.
 *
 * @param {object} props - The props object containing the following properties:
 *   - itemId: The ID of the item
 *   - back: A function to navigate back
 *   - items: An array of items
 *   - name: The name of the workflow
 * @return {JSX.Element} The rendered form
 */
const FormWorkflow = ({
  itemId,
  back,
  items,
  name,
}) => {
  const [form] = Form.useForm()
  const [roles, setRoles] = useState([]);
  const [removeStepsIds, setRemoveStepsIds] = useState([])
  const [updateSteps, setUpdateSteps] = useState([])
  const [newRole, setNewRole] = useState("")
  const [steps] = useState(items?.length > 0 ? items : [
    {
      description: "",
      role_responsible: "",
    }
  ])

  const { id } = useParams()

  /**
   * Check if any option in the given path contains the inputValue.
   *
   * @param {any} inputValue - The value to search for.
   * @param {Array} path - The path to search in.
   * @return {boolean} True if any option contains the inputValue, otherwise false.
   */
  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)

  /**
   * Renders a dropdown menu with additional functionality.
   *
   * @param {object} menus - The menus to be rendered.
   * @return {JSX.Element} The rendered dropdown menu.
   */
  const dropdownRender = (menus) => (
    <div>
      {menus}
      <Divider
        style={{
          margin: 0,
        }}
      />
      <div
        style={{
          padding: 8,
        }}
      >
        <Form
          layout="inline"
          className="add-role-form"
        >
          <Form.Item name="new-role">
            <Input placeholder="New Additional Role" value={newRole} onChange={(e) => setNewRole(e.target.value)} autoComplete="off" />
          </Form.Item>
          <Form.Item name="new-role-button">
            <Button type="primary" size="small" onClick={async () => {
              if (await onAddRole(newRole) === true) setNewRole("")
            }}>Add</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )

  /**
   * A function that takes an array of labels and returns a JSX element or an array of JSX elements.
   *
   * @param {Array} labels - An array of labels.
   * @return {JSX.Element|Array} - A JSX element or an array of JSX elements.
   */
  const displayRender = (labels) => {
    if (typeof labels[1] === "string") return <span>{labels[1]}</span>
    return labels.map((label, i) => {
      if (typeof label === "number") {
        return roles.map((roleCategory) => {
          return roleCategory.children.map((role) => {
            if (role.value === label)
              return `${role.label}`
          })
        })
      } else if (typeof label === "string") label = `${labels[1]}`
      else return <span>{label}</span>
    })
  }

  /**
   * Handles adding or removing rows from the items array at the specified index.
   *
   * @param {number} index - The index where the row will be added or removed.
   * @param {string} task - The task to perform: "add" or "remove".
   * @param {Array} items - The array of items to modify.
   * @return {Promise} A promise that resolves when the operation is complete.
   */
  const onAddRemoveRows = async (index, task, items) => {
    if (task === "add")
      items.splice(index, 0, {
        description: "",
        role_responsible: "",
      })
    else if (task === "remove")
      items.splice(index, 1)

    let orderedSteps = {}
    items.map((item, index) => {
      if (!item?.id) return
      orderedSteps = {
        ...orderedSteps,
        [index]: {
          ...item,
          order: index + 1,
        }
      }
    })
    setUpdateSteps(orderedSteps)
  }

  /**
   * Adds a role to the client.
   *
   * @param {string} role - The name of the role to be added.
   * @return {boolean} Returns true if the role was added successfully, otherwise false.
   */
  const onAddRole = async (role) => {
    if (role === "") return false
    try {
      await postClientRoles({
        role: {
          name: role,
        },
      })
      fillRoles()
      notification.success({
        message: "Success",
        description: "The role has been added successfully",
      })
      return true
    } catch (error) { }
    notification.error({
      message: "Attention",
      description:
        "An error occurred while adding the role, please try again later",
    })
    return false
  }

  const fillRoles = async () => setRoles(await loadRolesCascader(id))

  /**
   * Handles the submission of the form.
   * 
   * @async
   * @param {Object} params - The parameters for the submission.
   * @returns {void}
   */
  const onSubmited = async (params) => {
    let data = {
      ...params,
      organization: id,
      id: itemId,
      steps: itemId === 0 ? params.steps.map((step, index) => {
        if (step?.id) return null//add only new items
        return ({
          ...step,
          order: index + 1,
          role_responsible: typeof step.role_responsible === "object" ? step.role_responsible[1] : (step.role_responsible || null),
        })
      }) : [],
    }
    //remove steps null and keep order
    data.steps = data.steps.filter((step) => step)
    if (itemId > 0) data.id = itemId
    try {
      if ((itemId || 0) === 0)
        await postWorkflow(data).call
      else
        patchWorkflow(data).call
      // steps need to be updated separately
      await removeStepsIds.forEach(async (stepId) => await deleteWorkflowSteps(stepId).call)
      await Object.entries(updateSteps).map(async ([index, step]) => await patchWorkflowSteps(step).call)
      itemId > 0 && params.steps.map(async (step, index) => {
        if (!step?.id) await postWorkflowSteps({
          ...step,
          order: index + 1,
          role_responsible: typeof step.role_responsible === "object" ? step.role_responsible[1] : (step.role_responsible || null),
          workflow: itemId,
        }).call
      })
    } catch (error) {
      notification.error({
        message: "Attention",
        description:
          "An error occurred while saving the workflow, please try again later",
      })
      console.error(error)
      return
    }
    notification.success({
      message: "Success",
      description: itemId > 0 ? "The workflow has been updated successfully" : "The workflow has been created successfully",
    })
    back && back()
  }

  useEffect(() => {
    fillRoles()
  }, [])

  return (
    <div className="workflow-form form-table-container-editor">
      <div className="header">
        <Button type="text" onClick={back}>
          <ArrowLeftOutlined />
        </Button>
        {itemId > 0 ? "Edit" : "New"} Practice Workflow
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        onFinish={onSubmited}
        requiredMark={true}
        autoComplete="off"
        validateMessages={{
          required: "This field is required",
        }}
        onFieldsChange={(updatedFields) => {
          const updatedName = updatedFields.map((step) => step.name[0] || "")[0] || ""
          if (updatedName !== "steps") return
          const updatedIndex = updatedFields.map((step) => step.name[1] || 0)[0]
          if (updatedIndex < 0) return
          const updatedField = updatedFields.map((step) => step.name[2] || "")[0] || ""
          if (typeof updatedField !== "string") return
          const updatedValue = updatedFields.map((step) => step.value || "")[0] || ""
          const updatedId = parseInt(document.querySelector(`[name="${updatedIndex},id"]`)?.value) || 0
          if (updatedId === 0) return
          const editedStep = steps.find((step) => step.id === updatedId)
          let step = updateSteps[updatedIndex] || (editedStep ? editedStep[0] : {}) || {}
          step[updatedField] = typeof updatedValue === "object" ? updatedValue[1] : updatedValue || ""
          setUpdateSteps({
            ...updateSteps,
            [updatedIndex]: {
              ...step,
              id: updatedId,
            },
          })
        }}
      >
        <Form.Item name="name" label="Workflow Name" initialValue={name || ""} rules={[{ required: true }]}>
          <Input allowClear size="small" placeholder="Input Name" />
        </Form.Item>
        <Form.List
          name="steps"
          initialValue={steps}
        >
          {(fields, { add, remove }) => {
            let step = 0
            return (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  step++
                  return (
                    <Space key={key} align="baseline">
                      <div>
                        {
                          fields.length > 1 && (
                            <Button
                              className="dynamic-delete-button"
                              onClick={() => {
                                const stepId = document.querySelector(`[name="${name},id"]`)?.value || 0
                                if (stepId > 0) setRemoveStepsIds([...removeStepsIds, stepId])
                                remove(name)
                                onAddRemoveRows(name, "remove", steps)
                              }}
                            ><MinusCircleOutlined /></Button>

                          )
                        }
                        <Input type="hidden" name={[name, "id"]} value={steps[step - 1]?.id} />
                      </div>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        label={`Step ${step}`}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="Enter Step" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "role_responsible"]}
                        label="Role Responsible"
                      >
                        <Cascader placeholder="Select Role Responsible"
                          options={roles}
                          expandTrigger="hover"
                          showSearch={{
                            filter,
                          }}
                          placement="bottomLeft"
                          dropdownRender={dropdownRender}
                          displayRender={displayRender}
                        />
                      </Form.Item>
                      <Button
                        className="primary"
                        onClick={() => {
                          // get data-step
                          if (name === fields.length - 1) add()
                          else add("", name + 1)
                          onAddRemoveRows(name + 1, "add", steps)
                        }}
                      ><PlusOutlined /></Button>
                    </Space>
                  )
                })}
              </>
            )
          }
          }
        </Form.List>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Space>
            <Button type="button" onClick={back}>Cancel</Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div >
  )
}

export default FormWorkflow
