import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, Select } from 'antd';
import React, { useState } from 'react';
import { getNotification } from '../../../../Components/GetNotification';
import ModalWindow from '../../../../Components/ModalWindow';
import { deleteMemberToEntity, updatedMemberToEntity } from '../../../../Services/Members.service';

const UserList = ({ data, id, visible, typeEntity = null, onCancel }) => {
    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)
    const [userId, setUserId] = useState('')

    const columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text) => <div style={{ position: "absolute" }}><img style={{ borderRadius: "50%", width: "30px", height: "30px" }} src={text || '/img/avatar.png'} /></div>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <div style={{ width: "50px" }}> {text}
            </div>,
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Permission',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                const changePermission = async (e) => {
                    record.action = e;
                    record.entity_id = id;
                    record.entity_name = typeEntity;
                    const res = await updatedMemberToEntity(record).call;
                    onCancel()
                    getNotification('success', {
                        header: 'success',
                        body: 'User updated successfully',
                    });
                }
                return (
                    <div style={{ width: "50px" }}>
                        <select value={_} onChange={e => changePermission(e.target.value)} className="pointer bg-transparent border-0">
                            <option value="view">view</option>
                            <option value="edit">edit</option>
                        </select>
                    </div>
                )
            }
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <div style={{ cursor: "pointer" }}>
                    <Space size="middle" onClick={() => handleDeleteUser(record.member_id)}>
                        <DeleteOutlined />
                    </Space>
                </div>
            ),
        },
    ];

    const handleDeleteUser = (id) => {
        setUserId(id)
        setShowUserDeleteModal(true)
    }

    const handleHideDeleteModal = () => {
        setShowUserDeleteModal(false)
    }

    const handleConfirmDeleteUser = async () => {
        let res;
        if (typeEntity) {
            try {
                res = await deleteMemberToEntity(typeEntity, userId).call;
                if (res) {
                    getNotification('success', {
                        header: 'success',
                        body: 'User successfully delete',
                    });
                }
            } catch (err) {
                getNotification('error', {
                    header: 'error',
                    body: 'Error to delete user',
                });
            };
        }
        if (res) {
            setShowUserDeleteModal(false)
            onCancel()
        }
    }

    return (<div>
        <Table columns={columns} dataSource={data} pagination={false} />
        <ModalWindow
            title="Remove User"
            visible={showUserDeleteModal}
            onCancel={handleHideDeleteModal}
            onOk={handleConfirmDeleteUser}
            children={`Are you sure you want to remove this user from this network?`}
        />
    </div>)
};

export default (UserList);