import React from 'react';
import { Modal } from 'antd';
import './AboutCompetencyModal.scss';

/**
 * Render the About Competency Modal component.
 *
 * @param {Object} visible - Indicates if the modal is visible or not.
 * @param {Function} onCancel - Function to call when the modal is canceled.
 * @param {Function} selectCategoryAbout - Function to call when selecting a category about.
 * @return {JSX.Element} The rendered About Competency Modal component.
 */
const AboutCompetencyModal = ({ visible, onCancel, selectCategoryAbout }) => {
  return (
    <div className='AboutCompetencyModal'>
      <Modal
        title={<p className='text-orange'>What is this about?</p>}
        open={visible}
        onCancel={onCancel}
        footer={false}>
        <p className='text-black'>
          <b>What is this Milestone about?</b>
        </p>
        <p>{selectCategoryAbout?.about}</p>
        <p className='text-black my-10'>
          <b>Why is this important?</b>
        </p>
        <p>Why this Milestone is important:</p>
        {selectCategoryAbout?.important?.split(`\n`).map((important) => (
          <p>{important}</p>
        ))}
        <p className='text-black my-10'>
          <b>What lag measures will improve?</b>
        </p>
        {selectCategoryAbout?.lag_measures?.split(`\n`).map((lag_measure) => (
          <p>- {lag_measure}</p>
        ))}
      </Modal>
    </div>
  );
};

export default AboutCompetencyModal;
