import {
  INTEGRATE_IMAGE_ERROR,
  INTEGRATE_IMAGE_STARTED,
  INTEGRATE_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_STARTED,
  UPLOAD_IMAGE_SUCCESS,
} from '../../Redux.constants';
import {ApiBase} from '../../../utils/apiBase';
import {UPLOAD_IMAGE} from '../../Api.constants';
import axios from 'axios';
import {getNotification} from '../../../Components/GetNotification';

const failureMessageOfGetPresignedURl = () => {
  return getNotification('error', {
    header: 'Failed!',
    body: 'Failed to fetch image url.',
  });
};
export function getPresignedUrl(params, callback) {
  const apiBaseInstance = new ApiBase();
  let imageUrl = '';
  return (dispatch) => {
    dispatch({
      type: UPLOAD_IMAGE_STARTED,
      payload: {},
    });
    apiBaseInstance.instance
      .get(UPLOAD_IMAGE + `?key=${params}`)
      .then((response) => {
        imageUrl = response.data.presigned_url;
        dispatch({
          type: UPLOAD_IMAGE_SUCCESS,
          payload: {imageUrl},
        });
      })
      .then((res) => {
        callback(imageUrl);
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_IMAGE_ERROR,
          payload: error.message,
        });
        failureMessageOfGetPresignedURl();
      });
  };
}
export function uploadProfileImage(params, callback) {
  let formData = new FormData()
  const apiBaseInstance = new ApiBase();
  formData.append("image", params.file);
  let imageUrl = '';
  return (dispatch) => {
    dispatch({
      type: UPLOAD_IMAGE_STARTED,
      payload: {},
    });
    apiBaseInstance.instance
      .patch(`/users/${localStorage.getItem('userId')}/`,formData)
      .then((response) => {
        imageUrl = response.data.image;
        dispatch({
          type: UPLOAD_IMAGE_SUCCESS,
          payload: {imageUrl},
        });
      })
      .then((res) => {
        successMessageOfUploadImage();
        callback(imageUrl);
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_IMAGE_ERROR,
          payload: error.message,
        });
        failureMessageOfGetPresignedURl();
      });
  };
}
const successMessageOfUploadImage = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'File uploaded successfully',
  });
};
const failureMessageOfUploadImage = () => {
  return getNotification('error', {
    header: 'Failed!',
    body: 'Failed to upload file.',
  });
};
const integarteInageStarted = (dispatch) =>
  dispatch({
    type: INTEGRATE_IMAGE_STARTED,
    payload: {},
  });

export function uploadImage(params, callback) {
  var config = {
    responseType: 'stream',
    headers: {
      'x-amz-acl': 'public-read-write',
      'Content-Type': params.file.type,
    },
  };
  return (dispatch) => {
    integarteInageStarted(dispatch);
    axios
      .put(params.imageUrl.imageUrl, params.file, config)
      .then((response) => {
        callback && callback(response.config.url.split('?')[0]);
        dispatch({
          type: INTEGRATE_IMAGE_SUCCESS,
          payload: {integratedUrl: response.config.url.split('?')[0]},
        });
        successMessageOfUploadImage();
      })
      .catch((error) => {
        dispatch({
          type: INTEGRATE_IMAGE_ERROR,
          payload: error.message,
        });
        failureMessageOfUploadImage();
      });
  };
}
