import {
  DELETE_SUMMARY_START,
  DELETE_SUMMARY_FAILED,
  DELETE_SUMMARY_SUCCESS,
} from '../../Redux.constants';
import {deleteApiStarted, deleteApiFailed, deleteApiSuccess} from './Helper';

const Initia_State = {
  deleteSummarystate: {
    isProcessing: null,
    error: null,
    success: null,
  },
};

const deleteSummaryReducer = (state = Initia_State, action) => {
  switch (action.type) {
    case DELETE_SUMMARY_START:
      return deleteApiStarted(state, action);
    case DELETE_SUMMARY_FAILED:
      return deleteApiFailed(state, action);
    case DELETE_SUMMARY_SUCCESS:
      return deleteApiSuccess(state, action);
    default:
      return state;
  }
};
export default deleteSummaryReducer;
