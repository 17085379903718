export const INITIAL_STATE = {
  updateMissionDetailsApi: {
    isProcessing: false,
    success: false,
    error: false,
    data: null,
  },
  getMissionDetailsApi: {
    isProcessing: false,
    success: false,
    error: false,
  },
  missionHypothesisApi: {
    isProcessing: false,
    success: false,
    error: false,
  },
  getMissionHypothesisApi: {
    isProcessing: false,
    success: false,
    error: false,
  },
  getMostValualeApi: {
    isProcessing: false,
    success: false,
    error: false,
  },
  evidenceSearchApi: {
    isProcessing: false,
    error: false,
  },
  evidenceSearchResult: [],
  viewMissionDetails: {
    missionOverview: {},
    teamInfo: {},
    emersion: {},
    driveDiagram: {},
    eventBasedSoltution: {},
    minimumViableSolution: {},
    missionTerminationPlan: {},
    missionSprints: {},
    sprintRetrospectives: {},
  },
  teamInfo: {
    departmentList: [],
    designationList: [],
    employeeList: [],
    isDepartmentLoading: false,
    isDesignationLoading: false,
    isEmployeeLoading: false,
    error: false,
  },
  missionTeamDetails: [],
  objectiveList: [],
  objectiveListPatchId: null,
  missionOverviewData: {},
  newTeamMembers: [],
};
