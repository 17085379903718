export function getNotesStart(state) {
  return {
    ...state,
    getNotesApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getNotesSuccess(state, payload) {
  return {
    ...state,
    getNotesApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    notesList: payload.data ? payload.data.results : [],
    totalNotesCount: payload?.data?.count,
  };
}
export function getNotesFailed(state, payload) {
  return {
    ...state,
    getNotesApi: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
    notesList: [],
  };
}

export function addNotesStart(state) {
  return {
    ...state,
    addNotesApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addNotesSuccess(state, payload) {
  return {
    ...state,
    addNotesApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function addNotesFailed(state, payload) {
  return {
    ...state,
    addNotesApi: {
      isProcessing: false,
      success: false,
      error: 'Error in adding notes',
    },
  };
}
