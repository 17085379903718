import {
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_VALIDATION_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_SUCCESS,
  LOGIN_VERIFICATION_SUCCESS,
  UPDATE_ERROR_MESSAGE,
  GET_USER_LIST_START,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILED
} from '../../Redux.constants';
import {
  createOrganisationData,
  createOrganisationDataFailed,
  createOrganisationDataSuccess,
  createOrganisationValidationErrors,
  departmentData,
  departmentDataFailed,
  departmentDataSuccess,
  updateErrorMessage,
  userListStart,
  userListSuccess,
  userListFailed
} from './helper';

const INITIAL_STATE = {
  isRegisteredUser: false,
  departmentdata: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  userList: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  organisationdata: {
    ispending: false,
    completed: false,
    failed: false,
    data: [],
  },
  name: {
    name: 'name',
    errorMessage: '',
  },
  point_of_contact: {
    name: 'point_of_contact',
    errorMessage: '',
  },
  country: {
    name: 'country',
    errorMessage: '',
  },
  contact_email: {
    name: 'contact_email',
    errorMessage: '',
  },
  phone: {
    name: 'phone',
    errorMessage: '',
  },
  website: {
    name: 'website',
    errorMessage: '',
  },
  isProcessing: null,
};
export default function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEPARTMENT:
      return departmentData(state, action.payload);
    case GET_DEPARTMENT_SUCCESS:
      return departmentDataSuccess(state, action.payload);
    case GET_DEPARTMENT_FAILED:
      return departmentDataFailed(state, action.payload);
    case CREATE_ORGANISATION:
      return createOrganisationData(state, action.payload);
    case CREATE_ORGANISATION_SUCCESS:
      return createOrganisationDataSuccess(state, action.payload);
    case CREATE_ORGANISATION_FAILED:
      return createOrganisationDataFailed(state, action.payload);
    case CREATE_ORGANISATION_VALIDATION_ERROR:
      return createOrganisationValidationErrors(state, action.payload);
    case UPDATE_ERROR_MESSAGE:
      return updateErrorMessage(state, action.payload);
    case GET_USER_LIST_START:
      return userListStart(state, action.payload)
    case GET_USER_LIST_SUCCESS:
      return userListSuccess(state, action.payload)
    case GET_USER_LIST_FAILED:
      return userListFailed(state, action.payload)
    default:
      return { ...state };
  }
}
