import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUsers, getUserInfo} from "../../Services/Users.service";

const initialState = {
    loading: false,
    value: [],
    user: {}
}

export const getUsersRedux = createAsyncThunk(
    'user/getUsersRedux',
    async (thunkAPI) => {
        const res = await getUsers().call;
        return res.data.results
    }
)

export const fetchUserInfo = createAsyncThunk(
    'user/fetchUserInfo',
    async (userId, thunkAPI) => {
        const res = await getUserInfo(userId).call;
        return res.data
    }
)

export const usersSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loadUsers: async (state, action) => {
            state.value = action.payload;

        },
        getUsersRedux
    },
    extraReducers: {
        [getUsersRedux.pending]: (state) => {
            state.loading = true
        },
        [getUsersRedux.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.value = payload
        },
        [getUsersRedux.rejected]: (state) => {
            state.loading = false
        },
        [fetchUserInfo.pending]: (state) => {
            state.loading = true
        },
        [fetchUserInfo.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.user = payload
        },
        [fetchUserInfo.rejected]: (state) => {
            state.loading = false
        },
    },
});


export const { loadUsers } = usersSlice.actions;