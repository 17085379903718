import React, { useState } from 'react';
import ProfileBg from '../../../../../public/img/profile-bg.jpg';
import style from '../../style.module.scss';
import { Upload } from 'antd';
import Loader from '../../../../Components/Loader';

const uploadImageActions = ({ props, file, setimageList }) => {
  props.getPresignedUrl(file, (imageUrl) => {
    localStorage.setItem('profileImage', imageUrl);
    window.location.reload(true);
  });
};
const onProfileImageSection = ({ props, imageList, onCustomRequest }) => (
  <Upload
    fileList={imageList}
    customRequest={onCustomRequest}
    showUploadList={false}
    accept={'.png,.jpg,.jpeg'}
    onRemove={true}>
    <ProfileImageSection
      name={props.userDetails.fullName}
      props={props}
      imageSrc={
        localStorage.getItem('profileImage') !== 'null' ?
          localStorage.getItem('profileImage')
          : '/img/avatar.png'
      }
    />
  </Upload>
);
export function LeftBar(props) {
  const [imageList, setimageList] = useState([]);
  const onCustomRequest = (file) => {
    uploadImageActions({ props, file, setimageList });
  };
  return (
    <div className={style.settingsLeft}>
      <div className={style.settingsLeft__top}>
        <img className={style.settingsLeft__picture} src={ProfileBg} />
      </div>
      <div className={style.profilePicBlock}>
        <div className={style.userImage}>
          {!props.integrateApi.loading &&
            !props.imageApi.loading &&
            !props.updateProfileApi.loading ? (
            onProfileImageSection({ props, imageList, onCustomRequest })
          ) : (
            <Loader hasWrapper={true} classList={['xs blue']} />
          )}
        </div>
      </div>
    </div>
  );
}

function ProfileImageSection({ imageSrc }) {
  return (
    <>
      <span>
        <img src={imageSrc} alt='logo' />
      </span>
      <span title='change profile' className={style.editOverlay}>
        Edit
      </span>
    </>
  );
}
