import {Row, Col} from 'antd';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ReactGA from 'react-ga';
import {ROUTES} from '../../Routes.constants';
import './styles/DashboardLayout.scss';
import Header from '../Header';
import SmartSolutionChatbot from '../SmartSolutionChatbot';

/**
 * Renders the Dashboard layout.
 *
 * @param {Object} props - The properties for the DashboardLayout component.
 * @param {ReactNode} props.children - The children elements to be rendered within the layout.
 * @return {ReactElement} The rendered Dashboard layout.
 */
const DashboardLayout = ({children}) => {
  const navigate = useHistory();

  useEffect(() => {
    navigate.listen((location) => ReactGA.pageview(location.pathname));
  }, []);

  return (
    <Header isStandAlone={false}>
      <div className='dashboardlayout'>
        <Row>
          {/* <Col sm={24} md={4}>
            <div className='sidebar'>
              <p>
                <a
                  className='item'
                  onClick={() => navigate.push(ROUTES.GROUPS)}>
                  Groups
                </a>
              </p>
              <p>
                <a
                  className='item'
                  onClick={() => navigate.push(ROUTES.NETWORKS)}>
                  Networks
                </a>
              </p>
              {/* 	<p><a className="item" onClick={() => navigate.push(ROUTES.TEAMS)}>Teams</a></p> 
              {/* <p><a className="item" onClick={() => navigate.push(ROUTES.INTEL)}>Intel</a></p>
            </div>
          </Col> */}
          <Col sm={24} md={24} className='px-25 pt-15 overflow-x-hidden'>
            {children}
          </Col>
        </Row>
        <SmartSolutionChatbot /> 
      </div>
    </Header>
  );
};

export default DashboardLayout;
