import {connect} from 'react-redux';
import {changeProfileData} from '../../../Redux/Actions/UserProfileActions';
import UserDetails from './userdetails';
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserProfile: (payload, next) =>
      dispatch(changeProfileData(payload, next)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
