import React, {useEffect} from 'react';
//
import {getAllAssessmentDraftData} from '../Actions/AllTest';
import {useSelector, useDispatch} from 'react-redux';

import {SwitchContext} from '../../../Components/new/Context/ComponentSwitchContext';
import {AnswerStorageContext} from '../../../Components/new/Context/AnswerStorageContext';

const Draftassessment = () => {
  const {changecomponent} = React.useContext(SwitchContext);
  const {add_quiz_id} = React.useContext(AnswerStorageContext);

  const [alltestdata, setalltestdata] = React.useState([]);

  let dispatch = useDispatch();
  let data = useSelector((state) => state.GetDraftPopulateAllTestDataReducer);

  useEffect(() => {
    dispatch(getAllAssessmentDraftData());
  }, [dispatch]);

  useEffect(() => {
    if (
      data !== undefined &&
      data.data !== undefined &&
      data.data.data !== undefined
    ) {
      setalltestdata(data.data.data.results);
    }
  }, [data, dispatch]);

  return (
    <div className='AssessmentHome'>
      <div className='row m-0 p-0'>
        <div className='col-12'>
          <span className='GreyForeground Bold FontSize18'>
          Practice Assessment Tool - Drafts
          </span>
          <span className='FloatRight'>
            <button
              className='NewButton'
              style={{marginRight: '10px', cursor: 'pointer'}}
              onClick={() => changecomponent('dashboard')}>
              Back
            </button>
            <button
              className='NewButton'
              style={{marginRight: '10px', cursor: 'pointer'}}
              onClick={() => changecomponent('new assessment')}>
              Completed
            </button>
            <button
              className='NewButton'
              style={{cursor: 'pointer'}}
              onClick={() => changecomponent('take assessment')}>
              + Take New
            </button>
          </span>
        </div>

        {alltestdata.length === 0 ? (
          <></>
        ) : (
          <>
            <div className='col-6 FontSize12 LightBlueForeground'>
              Here is the place where you can describe your project and jot down
              the questions you have in mind.
            </div>
            <div className='col-6'></div>
          </>
        )}

        {alltestdata.length === 0 ? (
          <div className='col-12 mt-4'>
            <div className='NewAssessmentDiv GreyForeground'>
              <div className='EmptystateImg'></div>
              <br />
              <span className=' Bold FontSize18'>Nothing to show</span>
              <br />
              <span className=' FontSize14'>
                Its empty here you do not have any files
              </span>
            </div>
          </div>
        ) : alltestdata !== undefined ? (
          alltestdata.map((x) => (
            <div className='col-12 mt-2' key={x.id}>
              <div
                className='AllTestDiv WhiteForeground Pointer'
                onClick={() => {
                  add_quiz_id(x.id);
                  changecomponent('draft assessment')
                }}>
                <span className='Bold'>{`Test ${x.id}`}</span>
                <span className='FloatRight FontSize14'>
                  <span className='ViewImg'></span>
                </span>
                {/* <span className="FloatRight FontSize14 MarginRight10">07/09/21</span> */}
              </div>
            </div>
          ))
        ) : null}
      </div>
    </div>
  );
};

export default Draftassessment;
