import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dashboardhomediv, { Dashboardhomedivgrey } from './Dashboardhomediv';
import { DashboardHomeVideos } from './DashboardHomeVideos'
import {
  getNewWorkingToolAction,
  getNodeProfilingToolAction,
  postNewWorkingToolAction,
  postNodeProfilingToolAction,
  getPersonalizelAction,
  getMissionAction,
  postPersonlizedPlanAction,
  postMissionPlanAction,
  getBehaviourAction,
  postBehaviourAction,
} from '../../../Redux/Actions';
import { dispatch } from 'rxjs/internal/observable/pairs';

const Dashboardhome = (props) => {
  const [file, setFile] = useState([]);
  const params = useParams();
  const [nodefiles, setnodefiles] = useState([]);
  const [personalizeFile, setPersonalizeFile] = useState([]);
  const [missionFiles, setMissionFiles] = useState([]);
  const [behaviorFiles, setBehaviorFiles] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getData();
    getNodeData();
    getPersonalizeData();
    getmissioneData();
    getBehaviorData();
  }, []);

  const getData = async () => {
    const res = await dispatch(getNewWorkingToolAction(params.id));
    if (res?.length) {
      setFile([...res]);
    }
  };

  const getNodeData = async () => {
    const res = await dispatch(getNodeProfilingToolAction(params.id));
    if (res?.length) {
      setnodefiles([...res]);
    }
  };

  const getPersonalizeData = async () => {
    const res = await dispatch(getPersonalizelAction(params.id));
    if (res?.length) {
      setPersonalizeFile([...res]);
    }
  };

  const getmissioneData = async () => {
    const res = await dispatch(getMissionAction(params.id));
    if (res?.length) {
      setMissionFiles([...res]);
    }
  };

  const getBehaviorData = async () => {
    const res = await dispatch(getBehaviourAction(params.id));
    if (res?.length) {
      setBehaviorFiles([...res]);
    }
  };

  const handleSubmitNet = (file, id, type) => {
    switch (type) {
      case 'network_mapping_tools':
        return dispatch(
          postNewWorkingToolAction(file, id, (res) => {
            if (res) {
              getData();
            }
          })
        );
      case 'personalize_action_plan':
        return dispatch(
          postPersonlizedPlanAction(file, id, (res) => {
            if (res) {
              getPersonalizeData();
            }
          })
        );
      case 'meeting_agendas':
        return dispatch(
          postMissionPlanAction(file, id, (res) => {
            if (res) {
              getmissioneData();
            }
          })
        );
      case 'node_profiling_tools':
        return dispatch(
          postNodeProfilingToolAction(file, id, (res) => {
            if (res) {
              getNodeData();
            }
          })
        );
      case 'behavioral_economics':
        return dispatch(
          postBehaviourAction(file, id, (res) => {
            if (res) {
              getBehaviorData();
            }
          })
        );
    }
  };

  const handleSubmitNode = (file, id, type) => {
    dispatch(
      postNodeProfilingToolAction(file, id, (res) => {
        if (res) {
          getNodeData();
        }
      })
    );
  };

  return (
    <div className='DashboardHome'>
      {/*  <Dashboardhomediv title={'BlueAgilis Transformation Tool (BATT)'} /> */}
      <DashboardHomeVideos
        title={'Training Video Guides'}
        videos={[
          {
            url: 'https://www.youtube.com/watch?v=aec-L9pY-hw',
            title: 'Minimum Viable Story: Jose Azar, MD',
          },
          {
            url: 'https://youtu.be/DNHqaimOjIU',
            title: 'Evaluation & Termination Plan: Malaz Boustani, MD',
          },
        ]}
      />
      <Dashboardhomedivgrey
        title={'Platform Training Material'}
        isFileBtn={true}
        type='personalize_action_plan'
        handleSubmit={handleSubmitNet}
        file={personalizeFile}
        file2={[
          'https://static.blueagilis.com/toolbox-training/platform-training-1.pdf',
          'https://static.blueagilis.com/toolbox-training/platform-training-2.pdf',
          'https://static.blueagilis.com/toolbox-training/StakeholderAnalysis2024_RD2.pdf'
        ]}
        hiddenUpload
      />
      <Dashboardhomedivgrey
        title={'Agile Science Glossary'}
        isFileBtn={true}
        type='personalize_action_plan'
        handleSubmit={handleSubmitNet}
        file={personalizeFile}
        file2={['https://static.blueagilis.com/agile_science_glossary.pdf']}
        hiddenUpload
      />
      <Dashboardhomedivgrey
        title={'Practice Assessment Tool Resources'}
        isFileBtn={true}
        type='personalize_action_plan'
        handleSubmit={handleSubmitNet}
        file={personalizeFile}
        file2={[
          'https://static.blueagilis.com/PAT_Playbook.pdf',
        ]}
        hiddenUpload
      />
      {/*       <Dashboardhomedivgrey
        title={'Personalized Action Plan'}
        isFileBtn={true}
        type='personalize_action_plan'
        handleSubmit={handleSubmitNet}
        file={personalizeFile}
        file2={[
          'https://blueagilisbucket.s3.ap-south-1.amazonaws.com/BlueAgilis+Meeting+Agendas.xlsx',
        ]}
      /> */}
      <Dashboardhomedivgrey
        title={'Meeting Agendas'}
        isFileBtn={true}
        handleSubmit={handleSubmitNet}
        type={'meeting_agendas'}
        file={missionFiles}
        file2={[
          'https://blueagilisbucket.s3.ap-south-1.amazonaws.com/BlueAgilis+Meeting+Agendas.xlsx',
        ]}
      />
      {/*       <Dashboardhomedivgrey
        title={'Network Mapping Tools'}
        file={file}
        type='network_mapping_tools'
        isFileBtn={true}
        handleSubmit={handleSubmitNet}
        file2={['https://aidus.s3.amazonaws.com/Node+Profiling+Table.xlsx']}
      />
      <Dashboardhomedivgrey
        title={'Node Profiling Tools'}
        file={nodefiles}
        isFileBtn={true}
        type={`node_profiling_tools`}
        handleSubmit={handleSubmitNet}
        file2={['https://aidus.s3.amazonaws.com/Node+Mapping+.xlsx']}
      /> */}
      <Dashboardhomedivgrey
        title={'Behavioral Economics'}
        type='behavioral_economics'
        file={behaviorFiles}
        isFileBtn={true}
        handleSubmit={handleSubmitNet}
        file2={['https://aidus.s3.amazonaws.com/Cognitive+Bias.xlsx']}
      />
    </div>
  );
};

export default Dashboardhome;
