export function getMissionTeamStart(state) {
  return {
    ...state,
    getMissionTeam: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getMissionTeamSuccess(state, payload) {
  return {
    ...state,
    getMissionTeam: {
      isProcessing: false,
      success: true,
      error: null,
    },
    missionTeamList: payload.data ? payload.data : [],
  };
}
export function getMissionTeamFailed(state, payload) {
  return {
    ...state,
    getMissionTeam: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addSprintTeamStart(state) {
  return {
    ...state,
    addSprintTeamApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function addSprintTeamSuccess(state, payload) {
  return {
    ...state,
    addSprintTeamApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function addSprintTeamFailed(state, payload) {
  return {
    ...state,
    addSprintTeamApi: {
      isProcessing: false,
      success: null,
      error: 'Error in adding sprint team',
    },
  };
}

export function getSprintTeamStart(state) {
  return {
    ...state,
    getSprintTeam: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getSprintTeamSuccess(state, payload) {
  return {
    ...state,
    getSprintTeam: {
      isProcessing: false,
      success: true,
      error: null,
    },
    sprintTeamList: payload.data ? payload.data.employees : [],
    sprintTeamListId: payload.data ? payload.data.id : null,
  };
}
export function getSprintTeamFailed(state, payload) {
  return {
    ...state,
    getSprintTeam: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}
export function hypothesisHeadingStarted(state) {
  return {
    ...state,
    hypothesisHeadingApi: {
      loading: true,
      error: false,
      message: '',
    },
  };
}
export function hypothesisHeadingSuccess(state, payload) {
  return {
    ...state,
    hypothesisTitle: payload,
    hypothesisHeadingApi: {
      loading: false,
      error: false,
      message: '',
    },
  };
}
export function hypothesisHeadingError(state, payload) {
  return {
    ...state,
    hypothesisHeadingApi: {
      loading: false,
      error: true,
      message: payload,
    },
  };
}

export function getSprintLearningSuccess(state, payload) {
  return {
    ...state,
    getSprintLearningApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    sprintLearingData: { ...payload.data },
  };
}
export function getSprintHuddleSuccess(state, payload) {
  return {
    ...state,
    getSprintLHuddleApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    sprintHuddleData: payload.data ,
    huddle_id:payload.huddle_id.id
  };
}


export function updateSprintHuddleFailed(state) {
  return {
    ...state,
    updateSprintLHuddleApi: {
      isProcessing: false,
      success: null,
      error: 'Error in updating sprint',
    },
  };
}
export function updateSprintHuddleStart(state) {
  return {
    ...state,
    updateSprintLHuddleApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function updateSprintHuddleSuccess(state) {
  return {
    ...state,
    updateSprintLHuddleApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}


export function updateSprintLearningStart(state) {
  return {
    ...state,
    updateSprintLearningApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}


export function updateSprintLearningFailed(state) {
  return {
    ...state,
    updateSprintLearningApi: {
      isProcessing: false,
      success: null,
      error: 'Error in updating sprint',
    },
  };
}
export function updateSprintLearningSuccess(state) {
  return {
    ...state,
    updateSprintLearningApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}


export function updateSprintLearningTableStart(state) {
  return {
    ...state,
    updateSprintLearningTableApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}

export function updateSprintLearningTableFailed(state) {
  return {
    ...state,
    updateSprintLearningTableApi: {
      isProcessing: false,
      success: null,
      error: 'Error in updating sprint',
    },
  };
}
export function updateSprintLearningTableSuccess(state) {
  return {
    ...state,
    updateSprintLearningTableApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function getSprintLearningTableSuccess(state, payload) {
  return {
    ...state,
    getSprintLearningTableApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    [`${payload.key}`]:payload.data ? payload.data :null
  };
}

export function getSprintLearningValueSuccess(state, payload) {
  let arr = []
  if(payload.flag){
    arr = state.table_2.map((item,index)=>({
      ...item,
      score:!payload.data[index]?"":payload.data[index]?.score,
     
    }))
  }
  return {
    ...state,
    getSprintLearningTableApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    [`${payload.key}`]:payload.data ? arr :null
  };
}


export function updateSprintTopicValueSuccess(state,payload) {
  return {
    ...state,
    updateSprintLTopicValuesApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
    [`${payload.field}`]:payload.data ? payload.data :null
  };
}

export function updateSprintTopicValueStart(state) {
  return {
    ...state,
    updateSprintLTopicValuesApi: {
      isProcessing: true,
      success: false,
      error: null,
    },
  };
}

