import {BaseIcon, sizeType} from './BaseIcon';
import React from 'react';

const path = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g id='Icon'>
      <path
        id='Union'
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M9.98289 1.97607C7.85935 1.97607 5.82279 2.81965 4.32122 4.32122C2.81965 5.82279 1.97607 7.85935 1.97607 9.98289C1.97607 12.1064 2.81965 14.143 4.32122 15.6446C5.82279 17.1461 7.85935 17.9897 9.98289 17.9897C12.1064 17.9897 14.143 17.1461 15.6446 15.6446C17.1461 14.143 17.9897 12.1064 17.9897 9.98289C17.9897 7.85935 17.1461 5.82279 15.6446 4.32122C14.143 2.81965 12.1064 1.97607 9.98289 1.97607ZM5.38188 5.38188C6.60214 4.16161 8.25718 3.47607 9.98289 3.47607C11.7086 3.47607 13.3636 4.16161 14.5839 5.38188C15.8042 6.60214 16.4897 8.25718 16.4897 9.98289C16.4897 11.7086 15.8042 13.3636 14.5839 14.5839C13.3636 15.8042 11.7086 16.4897 9.98289 16.4897C8.25718 16.4897 6.60214 15.8042 5.38188 14.5839C4.16161 13.3636 3.47607 11.7086 3.47607 9.98289C3.47607 8.25718 4.16161 6.60214 5.38188 5.38188ZM7.29276 10.7452L8.48662 7.76061L10.3736 13.1056L10.3736 13.1057C10.4523 13.3285 10.5932 13.5239 10.7797 13.6689C10.9662 13.8139 11.1904 13.9023 11.4257 13.9236C11.6609 13.9449 11.8974 13.8983 12.1069 13.7891C12.3164 13.68 12.4902 13.5131 12.6076 13.3081L12.6077 13.3078L13.8044 11.2167H15.3046C15.7188 11.2167 16.0546 10.8809 16.0546 10.4667C16.0546 10.0525 15.7188 9.7167 15.3046 9.7167H13.3694C13.1005 9.7167 12.8521 9.86074 12.7185 10.0942L11.5946 12.0583L9.66452 6.5912L9.66374 6.58903C9.57976 6.35345 9.42613 6.14898 9.22324 6.00274C9.02035 5.8565 8.77779 5.77541 8.52774 5.77023C8.27769 5.76505 8.03198 5.83602 7.82321 5.97374C7.61443 6.11145 7.45247 6.30939 7.3588 6.54128L7.3588 6.54128L7.35786 6.54362L6.08863 9.7167H4.66125C4.24704 9.7167 3.91125 10.0525 3.91125 10.4667C3.91125 10.8809 4.24704 11.2167 4.66125 11.2167H6.59641C6.90309 11.2167 7.17887 11.03 7.29276 10.7452Z'
        fill='#00BDDC'
        strokeWidth={0.5}
      />
    </g>
  </svg>
);
const MeetingsIcon: React.FC<sizeType> = ({size}) => (
  <BaseIcon size={size}>{path}</BaseIcon>
);

const MeetingsIconWithBackground = () => <BaseIcon background>{path}</BaseIcon>;

export {MeetingsIcon, MeetingsIconWithBackground};
