import React from 'react';
import { Row, Col } from 'antd';
import logo from '../../Assets/NotFoundImg.png';
import './style.scss';
import { useHistory } from 'react-router-dom';

/**
 * Renders a component for displaying a "Not Found" page.
 *
 * @return {JSX.Element} The rendered "Not Found" component.
 */
const NotFound = () => {
  const history = useHistory();

  return (
    <div className='NotFound'>
      <Row className='h-100'>
        <Col span={12} className='flex-vertical'>
          <div className='text-center'>
            <img src='/img/blueagilis-whitelogo.png' alt='' className='w-50' />
            <div className='text-left w-50 m-auto'>
              <p className='text-black'>
                <b className='text-xl'>Error 404</b>
              </p>
              <p className='text-lg mb-10'>
                It looks like this page does not exist.
              </p>
              <p className='text-lg'>
                Sorry but the requested page is not found, click the button
                below to go back
              </p>
              <button
                type='button'
                className='modal-dont-delete b-rounded-5 mt-10'
                onClick={() => history.goBack()}>
                Go Back
              </button>
            </div>
          </div>
        </Col>
        <Col span={12} className='flex-vertical'>
          <img src={logo} alt='' className='w-80' />
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
