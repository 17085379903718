import axios from 'apiConfig';
import { AxiosResponse, AxiosError } from "axios";
import { API_BASE_URL } from "config/constants";
import { ClearLocalStorage, GetLocalStorage } from "utils/localStorageOperations";
import { AbortableRequestConfig } from 'types/Request';
import { PaginatedNetwork } from "types/Network";

const headers = () => {
  return {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}
export function getAllNetworks(): {
  call: Promise<AxiosResponse<PaginatedNetwork>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}organization/?is_on_hold=false`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getAllNetworkGroups(): {
  call: Promise<AxiosResponse<PaginatedNetwork>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}organization-group/?is_on_hold=false&limit=9999`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function getNetworksBy(params: any): {
    call: Promise<AxiosResponse<PaginatedNetwork>>;
    controller: AbortController;
} {
    Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);
    const paramsObj = new URLSearchParams(params);

    const controller = new AbortController();
    const call = axios.get(`${API_BASE_URL}organization/?${paramsObj.toString()}`, {
        signal: controller.signal,
        ...headers()
    } as AbortableRequestConfig);
    //@todo this logic should be elsewere, and it is: apiBase.js. should this be used throughout?
    const enhancedCall = call.catch((error: AxiosError) => {
        if (error.response?.status === 401) {
            const client = GetLocalStorage('clientName') || 'blue-agilis';
            ClearLocalStorage();
            window.location.href = `/${client}/login`;
        }
    });

    return {
        call: enhancedCall as Promise<AxiosResponse<PaginatedNetwork>>,
        controller
    }
}

export function deleteNetwork(id: number): {
    call: Promise<AxiosResponse<void>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.delete(`${API_BASE_URL}organization/${id}/`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }

}

export function getNetworksSprints(id: number): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {

    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}organization-sprint/?organization=${id}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function getNetworkMeasures(orgId: number): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.get(`${API_BASE_URL}mission-measures/?organization=${orgId}`, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function updateNetwork(id: number, data: any): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    return {
        call: axios.patch(`${API_BASE_URL}organization/${id}/`, data, {
            signal: controller.signal,
            ...headers()
        } as AbortableRequestConfig),
        controller
    }
}

export function createNetwork(data: any): {
    call: Promise<AxiosResponse<any>>;
    controller: AbortController;
} {
    const controller = new AbortController();
    const call = axios.post(`${API_BASE_URL}organization/`, data, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig);

    return {
        call: call,
        controller
    }
}