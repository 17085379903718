import { getArchetypes } from "../../../../../../../Services/Teams.service";

export const getArchetypesData = async () => {
  try {
    const archetypes = await getArchetypes().call;
    if (archetypes?.data?.results?.length) {
      return archetypes.data.results.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.descriptions?.map((desc) => desc.text),
          approaches: item.approaches?.map((approach) => approach.text),
          typical_influencers: item.typical_influencers?.map((influencer) => influencer.text),
          ways_to_present: item.ways_to_present?.map((way) => way.text),
        };
      })
    }
  } catch (error) { }
}

export const archetype_data = {//deprecated
  SKEPTIC: {
    description: [
      "Someone who is analytical and focused on finding flaws.",
      "They are often seen as overly critical and judgemental, but can also be seen as helpful and providing valuable insight.",
      "They can be seen as difficult to work with and nitpicky, but can also be seen as providing valuable feedback.",
      "They are often viewed as analytical and detail-oriented and are committed to finding solutions."
    ],
    approaches: [
      "Acknowledge tehir insight- Show them that you value their analysis and insight.",
      "Encourage constructive feedback- Provide feedback that is focused on the task, not the person.",
      "Set achievable goals- Set achievable goals that are realistic and manageable.",
      "Offer support- Offer support and listen to their concerns.",
      "Celebrate successes- Allow them to express their opinions and ideas without judgment.",
      "Provide guidance- Offer guidance and direction when needed.",
      "Encourage self-care- Remind them to take care of themselves and practice self-care.",
    ]
  },
  APPREHENSIVE: {
    description: [
      "Someone who is introverted and prefers to work alone.",
      "They are often seen avoiding contact with others and keeping to themselves, but can also be seen as helpful and providing valuable insight.",
      "They can be seen as difficult to work with and reclusive, but can also be seen as providing valuable feedback.",
      "hey are often viewed as analytical and detail-oriented and are committed to findign solutions."
    ],
    approaches: [
      "Acknowledge their insight- Show them that you value their analysis and insight.",
      "Allow room for independence- Give them the freedom to work independently and on their own terms.",
      "Encourage constructive feedback- Provide feedback that is focused on the task, not the person.",
      "Set achievable goals- Set achievable goals that are realistic and manageable.",
      "Offer support- Offer support and listen to their concerns.",
      "Respect their boundaries- Respect their need for privacy and alone time.",
      "Provide guidance- Offer guidance and direction when needed.",
      "Encourage self-care- Remind them to take care of themselves and practice self-care."
    ]
  },
  ENGAGED: {
    description: [
      "Someone who is highly driven and motivated.",
      "They are often seen as intense and emotional, but can also be seen as creative and inspiring.",
      "They can be seen as difficult to work with and demanding, but can also be seen as making valuable contributions.",
      "They are often vewed as risk-takers and are willing to take on challenges in order to achieve success."
    ],
    approaches: [
      "Acknowledge their enthusiasm- Let them know that you value their enthusiasm and hard work.",
      "Encourage collaboration- Suggest working on projects collaboratively instead of individually.",
      "Offer constructive feedback- Provide feedback that is focused on the task, no the person.",
      "Set realistic goals- Set achievable goals that are realistic and manageable.",
      "Celebrate achievements- Celebrate successes and recognize efforts.",
      "Respect their emotions- Allow them space to express their emotions and ideas.",
      "Provide support- Offer support and listen to their concerns.",
      "Encourage self-care- Remind them to take care of themselves and practice self-care."
    ]
  },
  CYNIC: {
    description: [
      "Someone who is overly cynical and negative.",
      "They are often seen as pessimistic and quick to criticize, but can also be seen as helpful and providing valuable insight.",
      "They can be seen as difficult to work with and always questioning, but can also be seen as providing valuable feedback.",
      "They are often viewed as analytical and detail-oriented and are committed to finding solutions."
    ],
    approaches: [
      "Acknowledge their insight- Show them that you value their analysis and insight.",
      "Encourage constructive feedback- Provide feedback that is focused on hte task, not the person.",
      "Set achievable goals- Set achievable goals that are realistic and manageable.",
      "Offer support- Offer support and listen to their concerns.",
      "Celebrate successes- Celebrate successes and recognize efforts.",
      "Respect their opinion- Allow them to express their opinions and ideas without judgement.",
      "Provide evidence- Provide evidence to back up claims and ideas.",
      "Encourage positivity- Remind them to look for the positive and focus on solutions."
    ]
  },
  NON_CONFORMIST: {
    description: [
      "Someone who is independent, rebellious and a non-conformist.",
      "They are often seen as disruptive and challenging the status quo, but can also be seen as creative and innovative.",
      "They can be seen as unpredictable and difficult to work with, but can also be seen as making valuable contributions.",
      "They are often viewed as risk-takers and are willing to challenge the status quo in order to achieve success."
    ],
    approaches: [
      "Acknowledge their ideas- Show them that you value their ideas and input.",
      "Allow room for risk- Encourage them to take risks and be creative.",
      "Encourage collaboratino- Suggest working on projects collaboratively instead of individually.",
      "Offer constructive feedback- Provide feedback that is focused on the task, not the person.",
      "Respect their independece- Allow them space to work independently and express their ideas.",
      "Set achievable goals- Set achievable goals that are realistic and manageable.",
      "Offer support- Offer support and listen to their concerns.",
      "Encourage self-care- Reming them to take care of themselves and practice self-care."
    ]
  },
  PERFECTIONIST: {
    description: [
      "Strives for excellence and is constantly working to improve.",
      "They are often seen as hardworking and reliable, but can also be seen as overly critical and demanding.",
      "They can be demanding of themselves and others. and can be seen as too rigid or inflexible."
    ],
    approaches: [
      "Acknowledge their high standards- Let them know that you understand their standards and appreciate their hard work.",
      "Encourage collaboration- Suggest working on projects collaboratively instead of individually.",
      "Offer constructive feedback- Provide feedback that is focused on the task, not the person.",
      "Set realistic goals- Set achievable goals that are realistic and manageable.",
      "Celebrate achiecements- Celebrate successes and recognize efforts.",
      "Provide support- Offer support and listen to their concerns.",
      "Take breaks- Encourage taing breaks to hekp with stress and exhaustion.",
      "Encourage self-care- Remind them to take care of themselves and practice self-care."
    ]
  },
}