import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import {
  deleteKeyDriverSD,
  getKeyDriversSD,
  patchKeyDriverSD,
  patchKeyDriverSDOrder,
  postKeyDriverSD,
} from '../../../../../../../../Services/KeyDrivers.service';
import './styles/SecondaryDrivers.scss';
import { PercentageCircle } from "@components/atoms";
import { getNotification } from '../../../../../../../../Components/GetNotification';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


/**
 * Renders a component for managing secondary drivers.
 *
 * @param {Object} pdId - The primary driver ID.
 * @return {JSX.Element} The rendered component.
 */
const SecondaryDrivers = ({ pdId }) => {
  const [secondaryDrivers, setSecondaryDrivers] = useState([]);
  const [visibleDriverModal, setVisibleDriverModal] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({ defaultValues: { percentage: 0 } });

  /**
   * Saves the secondary driver information.
   *
   * @param {Object} params - The parameters for saving the secondary driver.
   * @return {Promise<void>} - A promise that resolves when the secondary driver is saved.
   */
  const saveSecondary = async (params) => {
    try {
      const data = {
        ...params,
        primary_driver: pdId,
        order: secondaryDrivers.length
          ? secondaryDrivers[secondaryDrivers.length - 1].order + 1
          : 1,
        percentage: params?.percentage ? params?.percentage : 0,
      };
      if (data?.id) {
        await patchKeyDriverSD({
          id: data?.id,
          description: data?.description,
          percentage: data?.percentage,
        }).call;
      } else {
        await postKeyDriverSD(data).call;
      }
      loadSecondaryDrivers();
      setVisibleDriverModal(false);
    } catch (error) {
      getNotification('error', {
        header: 'Attention',
        body: error.response.data.percentage,
      });
    }
  };

  const loadSecondaryDrivers = async () => {
    if (pdId) {
      try {
        const res = await getKeyDriversSD(pdId).call;
        setSecondaryDrivers(res?.data?.results);
      } catch (error) { }
    }
  };

  /**
   * Opens the driver modal.
   *
   * @param {number} index - The index of the secondary driver.
   * @return {Promise<void>} A promise that resolves when the modal is opened.
   */
  const openDriverModal = async (index = null) => {
    reset();
    if (index !== null) {
      setValue('id', secondaryDrivers[index]?.id);
      setValue('description', secondaryDrivers[index]?.description);
      setValue('percentage', secondaryDrivers[index]?.percentage);
    }
    setVisibleDriverModal(true);
  };

  /**
   * Deletes an SD with the specified ID.
   *
   * @param {type} id - the ID of the SD to delete
   * @return {type} undefined
   */
  const deletSD = async (id) => {
    try {
      setVisibleDriverModal(false);
      await deleteKeyDriverSD(id).call;
      loadSecondaryDrivers();
    } catch (error) { }
  };

  /**
   * Order the second key.
   *
   * @param {Object} source - The source object.
   * @param {Object} destination - The destination object.
   * @param {string} draggableId - The draggable ID.
   * @return {Promise<void>} - Returns a promise that resolves to void.
   */
  const orderSecondKey = async ({ source, destination, draggableId }) => {
    if (!destination) {
      return;
    }
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    }
    try {
      await patchKeyDriverSDOrder(draggableId, {
        order: secondaryDrivers[destination.index].order,
      }).call;
      loadSecondaryDrivers();
    } catch (error) { }
  };

  useEffect(() => {
    loadSecondaryDrivers();
  }, [pdId]);

  return (
    <div className='secondarydrivers grid grid-vertical'>
      <DragDropContext onDragEnd={(result) => orderSecondKey(result)}>
        <Droppable droppableId='second'>
          {(dropProvSecond) => (
            <div
              {...dropProvSecond.droppableProps}
              ref={dropProvSecond.innerRef}
              className='w-100'>
              {secondaryDrivers?.map((driver, index) => (
                <Draggable
                  draggableId={`${driver.id}`}
                  index={index}
                  key={driver?.id}>
                  {(draggableProvSecond) => (
                    <div className='w-100 grid grid-vertical'>
                      <div
                        className='secondarydrivers-card'
                        onClick={() => openDriverModal(index)}
                        {...draggableProvSecond.draggableProps}
                        ref={draggableProvSecond.innerRef}
                        {...draggableProvSecond.dragHandleProps}>
                        <p className='text-white'>
                          {' '}
                          <b> {driver?.description} </b>
                        </p>
                        {driver && <PercentageCircle percentage={driver.percentage} />}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {dropProvSecond.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        className={`keydrivers-btn-new bg-blue pointer ${secondaryDrivers.length ? 'sticky bottom-5 l-14' : ''
          }`}
        onClick={() => openDriverModal()}>
        <p className='text-white'>
          {' '}
          <b> + Add New Driver </b>
        </p>
      </div>
      <Modal
        title='Secondary Driver'
        visible={visibleDriverModal}
        onCancel={() => setVisibleDriverModal(false)}
        footer={false}
        className='mission-modal'>
        <form onSubmit={handleSubmit(saveSecondary)}>
          <div>
            <p>Secondary Driver</p>
            <input
              type='text'
              className='input-class'
              placeholder='Enter text'
              {...register('description')}
            />
          </div>
          <div className='mt-15'>
            <p>How much is this driver contributing to the problem?</p>
            <input
              type='number'
              className='input-class'
              placeholder='Enter Percentage'
              {...register('percentage')}
            />
          </div>
          <div className='mt-15 text-end'>
            {getValues('id') && (
              <button
                type='button'
                className='modal-dont-delete bg-red mr-10'
                onClick={() => deletSD(getValues('id'))}>
                Delete
              </button>
            )}
            <button
              type='button'
              className='modal-delete-btn'
              onClick={() => setVisibleDriverModal(false)}>
              Cancel
            </button>
            <button type='submit' className='modal-dont-delete ml-10'>
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default SecondaryDrivers;
