export function uploadImageStarted(state, payload) {
  return {
    ...state,
    uploadingImage: true,
    imageApi: {
      loading: true,
      error: false,
      message: '',
    },
  };
}
export function uploadImageSuccess(state, payload) {
  return {
    ...state,
    imageUrl: payload,
    imageApi: {
      loading: false,
      error: false,
      message: '',
    },
  };
}
export function uploadImageError(state, payload) {
  return {
    ...state,
    uploadingImage: false,
    imageApi: {
      loading: false,
      error: true,
      message: payload,
    },
  };
}

export function integrateImageStarted(state, payload) {
  return {
    ...state,
    integrateApi: {
      loading: true,
      error: false,
      message: '',
    },
  };
}
export function integrateImageSuccess(state, payload) {
  return {
    ...state,
    integratedUrl: payload.integratedUrl,
    uploadingImage: false,
    integrateApi: {
      loading: false,
      error: false,
      message: '',
    },
  };
}
export function integrateImageError(state, payload) {
  return {
    ...state,
    uploadingImage: false,
    integrateApi: {
      loading: false,
      error: true,
      message: payload,
    },
  };
}
