import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom/cjs/react-router-dom.min';
import {
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  Select,
  notification,
  Alert,
} from 'antd';
import {
  loadRolesCascader,
  postClientRoles,
} from '../../../../../../../../Services/Users.service';
import {getMemberByPk} from '../../../../../../../../Services/Teams.service';
import {getArchetypesData} from '../../models';
import './styles/PersonaData.scss';

const PersonaData = ({
  personaId,
  updatePersonaData,
  showEmail = false,
  showEditNotification = false,
}) => {
  const [persona, setPersona] = useState({});
  const [archetypeOptions, setArchetypeOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const [newRole, setNewRole] = useState('');

  const {id} = useParams();

  const loadPersona = async () => {
    if (personaId) {
      const res = await getMemberByPk(personaId).call;
      setPersona(res?.data);
      updatePersonaData('role', res?.data?.role?.id);
      setRole([res?.data?.role?.id || 0]);
      updatePersonaData('archetype', res?.data?.archetype);
      updatePersonaData('email', res?.data?.email);
    } else {
      setPersona({});
    }
  };

  const loadArchetypes = async () => {
    try {
      const archetypes = await getArchetypesData();
      setArchetypeOptions(
        archetypes.map((item) => ({value: item.id, label: item.name}))
      );
    } catch (error) {}
  };

  const fillRoles = async () => setRoles(await loadRolesCascader(id));

  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

  const dropdownRender = (menus) => (
    <div>
      {menus}
      <Divider
        style={{
          margin: 0,
        }}
      />
      <div
        style={{
          padding: 8,
        }}>
        <Form layout='inline' className='add-role-form'>
          <Form.Item name='new-role'>
            <Input
              placeholder='New Additional Role'
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
              autoComplete='off'
            />
          </Form.Item>
          <Form.Item name='new-role-button'>
            <Button
              type='primary'
              size='small'
              onClick={async () => {
                if ((await onAddRole(newRole)) === true) setNewRole('');
              }}>
              Add
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );

  const onAddRole = async (role) => {
    if (role === '') return false;
    try {
      await postClientRoles({
        role: {
          name: role,
        },
      });
      fillRoles();
      notification.success({
        message: 'Success',
        description: 'The role has been added successfully',
      });
      return true;
    } catch (error) {}
    notification.error({
      message: 'Attention',
      description:
        'An error occurred while adding the role, please try again later',
    });
    return false;
  };

  const displayRender = (labels) => {
    if (typeof labels[1] === 'string') return <span>{labels[1]}</span>;
    return labels.map((label, i) => {
      if (typeof label === 'number') {
        return roles.map((roleCategory) => {
          return roleCategory.children.map((role) => {
            if (role.value === label) return `${role.label}`;
          });
        });
      } else if (typeof label === 'string') label = `${labels[1]}`;
      else return <span>{label}</span>;
    });
  };

  useEffect(() => {
    loadPersona();
    loadArchetypes();
    fillRoles();
  }, [personaId]);

  return (
    <div className={`personadata ${persona?.id ? 'grid' : 'hidden'}`}>
      <div className='flex'>
        <span className='border-rounder g-avatar'>
          <img
            src={`${persona?.image || '/img/avatar.png'}`}
            alt='logo'
            className='border-rounder g-avatar'
          />
        </span>
        <span className='ml-15 text-blue'>{persona?.name}</span>
      </div>
      <div className='flex-vertical'>
        <span>Role:</span>
        <Cascader
          placeholder='Select Role Responsible'
          options={roles}
          value={role}
          bordered={false}
          expandTrigger='hover'
          showSearch={{
            filter,
          }}
          placement='bottomLeft'
          dropdownRender={dropdownRender}
          displayRender={displayRender}
          className='input-class ml-10'
          onChange={(e) => {
            if (e === undefined) return setRole([]);
            const value = e[1];
            setPersona({...persona, role: value});
            updatePersonaData('role', value);
            setRole([value]);
          }}
        />
      </div>
      <div
        className={`flex-vertical mt-15 ${showEmail ? 'd-none' : 'd-block'}`}>
        <span>Archetype:</span>
        <Select
          className='input-class ml-10'
          placeholder='Select an archetype'
          optionFilterProp='children'
          bordered={false}
          options={archetypeOptions}
          value={persona?.archetype}
          onChange={(e) => {
            setPersona({...persona, archetype: e});
            updatePersonaData('archetype', e);
          }}
        />
      </div>
      <div
        className={`flex-vertical mt-15 ${showEmail ? 'd-block' : 'd-none'}`}>
        <span>Email:</span>
        <input
          type='text'
          className='input-class ml-5'
          value={persona?.email}
          onChange={(e) => {
            setPersona({...persona, email: e.target.value});
            updatePersonaData('email', e.target.value);
          }}
        />
      </div>
      <div className={`mt-15 ${showEditNotification ? 'd-block' : 'd-none'}`}>
        <Alert
          message={
            <p className='text-xs'>
              To edit more information about the Member go to <b>Teams Page.</b>
            </p>
          }
          type='info'
          showIcon
        />
      </div>
    </div>
  );
};

export default PersonaData;
