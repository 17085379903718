import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { 
  deleteNetwork, 
  updateNetwork as updateNetworkService, 
  createNetwork as createNetworkService,
  getNetworksBy, 
  getAllNetworkGroups 
} from "Services/Network.service";

const MESSAGES = {
  ITEM_CREATED: 'Network created.',
  ITEM_DELETED: 'Network deleted.',
  ITEM_ARCHIVED: 'Network archived.',
  ITEM_UPDATED: 'Network updated.',
};

type StateProps = {
  items: any[];
  groups: any[];
  count: number;
  status: 'idle' | 'loading' | 'success' | 'error';
  statusMessage: string;
  page: number;
  perPage: number;
  search: string;
  isOnHold: boolean;
  isSearching: boolean;
  showAddNetworkLink: boolean;
  selectedNetwork: any | null;
  selectedGroup: number | string;
  queryString: string;
}

type ParamsProps = {
  search: string;
  page: number;
  perPage: number;
  isOnHold: boolean;
  groupId: string;
}

const initialState: StateProps = {
  items: [],
  groups: [],
  count: 0,
  status: 'idle',
  statusMessage: '',
  page: 1,
  perPage: 8,
  search: '',
  isOnHold: false,
  isSearching: false,
  showAddNetworkLink: true,
  selectedNetwork: '',
  selectedGroup: '',
  queryString: '',
};

export const fetchNetworks = createAsyncThunk(
  'networkListView/fetchNetworks',
  async(__, {getState}) => {
    const state = getState();
    //@ts-ignore
    const { page, perPage, search, selectedGroup, isOnHold } = state.networkListView;
    let offset = ((page - 1) * perPage) -1;
    if(offset < 0) {
      offset = 0;
    }

    const params = {
      offset,
      is_on_hold: isOnHold,
      limit: page == 1 ? perPage - 1 : perPage,
      q: search,
      group_id: selectedGroup,
    }

    const res = await getNetworksBy(params).call;
    return res.data;
  }
);

export const fetchNetworkGroups = createAsyncThunk(
  'networkListView/fetchNetworkGroups',
  async() => {
    const res = await getAllNetworkGroups().call;
    return res.data;
  }
);

export const deleteNetworkById = createAsyncThunk(
  'networkListView/deleteNetworkById',
  async(id: number, {dispatch}) => {
    await deleteNetwork(id).call;
    dispatch(fetchNetworks());
    return {id: id};
  }
);

export const archiveNetworkById = createAsyncThunk(
  'networkListView/archiveNetworkById',
  async(id: number, {dispatch}) => {
    await updateNetworkService(id, {is_on_hold: true});
    dispatch(fetchNetworks());
    return {id: id};
  }
);

export const createNetwork = createAsyncThunk(
  'networkListView/createNetwork',
  async(data: any, {dispatch}) => {
    const res = await createNetworkService(data).call;
    dispatch(fetchNetworks());
    return res;
  }
);

export const updateNetwork = createAsyncThunk(
  'networkListView/updateNetwork',
  async (data: any, { dispatch }) => {
    if (data?.id) {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key === 'profile_image_url' && data[key]) {
          formData.append(key, data[key].originFileObj);
        } else {
          formData.append(key, data[key]);
        }
      });

      const res = await updateNetworkService(data.id, formData).call;
      dispatch(fetchNetworks());
      return res;
    }
  }
);

export const networkListViewSlice = createSlice({
  name: 'networkListView',
  initialState,
  reducers: {
    cleanNetworkListView: () => {
      return initialState;
    },
    cleanNetworkListViewSelectedNetwork: (state) => {
      state.selectedNetwork = null;
    },
    cleanNetworkListViewStatusMessage: (state) => {
      state.status = 'idle';
      state.statusMessage = '';
    },    
    setNetworkListViewSearch(state, {payload}) {
      state.search = payload;
    },
    setNetworkListViewIsSearching(state, {payload}) {
      state.isSearching = payload;
    },
    setNetworkListViewSelectedNetwork(state, {payload}) {
      const thisNetwork = state.items.find((network) => network.id == payload);
      state.selectedNetwork = thisNetwork;
    },
    selectNetworkListViewGroup(state, {payload}) {
      state.selectedGroup = payload;
    },
    selectNetworkListViewPage(state, {payload}) {
      state.page = payload;
    },
    selectNetworkListViewPerPage(state, {payload}) {
      state.perPage = payload;
    },
    setNetworkListViewQueryString(state) {
      const params: ParamsProps = {
        search: state.search,
        page: state.page,
        perPage: state.perPage,
        isOnHold: state.isOnHold,
        groupId: state.selectedGroup.toString(),
      };

      Object.keys(params).forEach((key) => {
        if (params[key as keyof ParamsProps] === '' || params[key as keyof ParamsProps] === initialState[key as keyof StateProps]) {
          delete params[key as keyof ParamsProps];
        }
      });

      // Convert numeric values to strings
      const paramsString: Record<string, string> = {};
      Object.keys(params).forEach((key) => {
        paramsString[key] = (params as any)[key].toString();
      });

      const paramsObject = new URLSearchParams(paramsString);

      state.queryString = paramsObject.toString() ?? '';
    },
    setNetworkListViewFromParamString(state, {payload}) {
      if(payload) {
        const paramsObj = new URLSearchParams(payload);
        const page = paramsObj.get('page');
        const perPage = paramsObj.get('perPage');
        const isOnHold = paramsObj.get('isOnHold');
        const groupId = paramsObj.get('groupId');

        return {
          ...state,
          search: paramsObj.get('search') ?? '',
          page: page ? parseInt(page) : 1,
          perPage: perPage ? parseInt(perPage) : 8,
          isOnHold: isOnHold ? isOnHold == 'true' : false,
          selectedGroup: groupId ? parseInt(groupId) : '',
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetworks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNetworks.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'idle',
            isSearching: false,
            showAddNetworkLink: state.page == 1,
            items: payload.results,
            count: payload.count,
          }
        }
      })
      .addCase(fetchNetworks.rejected, (state, action) => {
        console.log(action.error);
      });

    builder
      .addCase(deleteNetworkById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteNetworkById.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'success',            
            statusMessage: MESSAGES.ITEM_DELETED,
            selectedNetwork: null,
          }
        }
      });

    builder
      .addCase(fetchNetworkGroups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNetworkGroups.fulfilled, (state, {payload}) => {
        if(payload) {
          const options = [{value: '', label: 'All'}];
          payload.results.forEach((group) => {
            options.push({
              value: group.id,
              label: group.name,
            });
          });
          return {
            ...state,
            status: 'idle',
            groups: options,
          }
        }
      });

    builder
      .addCase(archiveNetworkById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(archiveNetworkById.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'success',            
            statusMessage: MESSAGES.ITEM_ARCHIVED,
            selectedNetwork: null,
          }
        }
      });

    builder
      .addCase(updateNetwork.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNetwork.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'success',            
            statusMessage: MESSAGES.ITEM_UPDATED,
            selectedNetwork: null,
          }
        }
      });

    builder
      .addCase(createNetwork.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createNetwork.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'success',            
            statusMessage: MESSAGES.ITEM_CREATED,
            selectedNetwork: null,
          }
        }
      })
      .addCase(createNetwork.rejected, (state, action) => {
        console.log(action);
        console.log('error', action?.error);
        if(action?.error) {
          return {
            ...state,
            status: 'error',
            statusMessage: 'There was an error creating the network.',
            selectedNetwork: null,
          }
        }
      });
  }
});

export const {
  cleanNetworkListView,
  cleanNetworkListViewSelectedNetwork,
  cleanNetworkListViewStatusMessage,
  setNetworkListViewSearch,
  setNetworkListViewIsSearching, 
  setNetworkListViewSelectedNetwork,
  selectNetworkListViewPage, 
  selectNetworkListViewPerPage,
  setNetworkListViewQueryString,
  setNetworkListViewFromParamString,
  selectNetworkListViewGroup,
} = networkListViewSlice.actions;