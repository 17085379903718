export function listMsopStarted(state) {
  return {
    ...state,
    listMsopReportApi: {
      loading: true,
      data: {tableData: [], totalCount: 0},
      error: false,
    },
  };
}
export function listMsopSuccess(state, payload) {
  return {
    ...state,
    listMsopReportApi: {
      loading: false,
      data: {
        tableData: getTableData(payload.listMsopResponse.results),
        finalStateId: getFinalStateId(payload.listMsopResponse.results),
        totalCount: payload.listMsopCount,
      },
      error: false,
    },
  };
}
export function listMsopFailed(state) {
  return {
    ...state,
    listMsopReportApi: {
      loading: false,
      data: {tableData: [], totalCount: 0},
      error: true,
    },
  };
}

function getTableData(data) {
  let finalData = [];
  if (data) {
    data.map((item, index) => {
      let dataObject = {};
      dataObject.mission = item.mission;
      dataObject.link = item.link;
      dataObject.id = item.id;
      dataObject.datePrepared = item.created_on;
      dataObject.title = item.state.title;
      dataObject.revision = 'Revision ' + (item.state.revision + 1);
      finalData.push(dataObject);
    });
  }
  return finalData;
}

function getFinalStateId(item) {
  let stateId = null;
  if (item.length !== 0) {
    stateId = item[item.length - 1].last_id;
  }
  return stateId;
}
