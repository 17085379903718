import {
  UPDATE_MISSION_START,
  UPDATE_MISSION_FAILED,
  UPDATE_MISSION_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {updateApiStarted, updateApiFailed, updateApiSuccess} from './Helper';

const updateMissionReducer = (state = Default(), action) => {
  switch (action.type) {
    case UPDATE_MISSION_START:
      return updateApiStarted(state, action);
    case UPDATE_MISSION_FAILED:
      return updateApiFailed(state, action);
    case UPDATE_MISSION_SUCCESS:
      return updateApiSuccess(state, action);
    default:
      return state;
  }
};
export default updateMissionReducer;
