import {Input} from 'antd';
import copy from 'Assets/icons/copy.svg';
import {debounce} from 'lodash';
import React, {Dispatch, SetStateAction, useCallback} from 'react';
import {AUTOSAVING_DELAY} from 'config/constants';

type GoogleSheetLinkUploadProps = {
  setLink: Dispatch<SetStateAction<string | undefined>>;
};

const GoogleSheetLinkUpload: React.FC<GoogleSheetLinkUploadProps> = ({
  setLink,
}) => {
  const debounceSetLink = useCallback(
    debounce((nextValue: string) => setLink(nextValue), AUTOSAVING_DELAY),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;
    debounceSetLink(nextValue);
  };

  return (
    <div className='mt-4 flex gap-2'>
      <Input
        data-testid='googleLinkInput'
        onChange={handleChange}
        placeholder='Paste link here'
      />
      <img className='w-10' src={copy} alt='Copy icon' />
    </div>
  );
};

export default GoogleSheetLinkUpload;
