import { SetLocalStorage } from '../../../../../utils/localStorageOperations';
import { FIRST_NAME, LAST_NAME, USER_DESIGNATION, USER_EMAIL, USER_ID, USER_ROLES } from '../../../../Redux.constants';

export function fetchUserDetailsSuccess(state, payload) {
    let { userDetails } = payload;
    SetLocalStorage({ [FIRST_NAME]: userDetails.first_name || '' });
    SetLocalStorage({ [LAST_NAME]: userDetails.last_name || '' });
    SetLocalStorage({ [USER_EMAIL]: userDetails.email });
    SetLocalStorage({ [USER_ROLES]: userDetails.roles });
    SetLocalStorage({ [USER_ID]: userDetails.id });
    SetLocalStorage({
        [USER_DESIGNATION]: userDetails.user_designation || '',
    });
    return {
        ...state,
        userDetails: {
            data: {
                user_data: userDetails
            }
        },
        userDetails: {
            isProcessing: false,
            success: true,
            error: false,
        },
    };
}

export function fetchUserDetailsStarted(state, payload) {
    return {
        ...state,
        buttonLoader: true,
        userDetails: {
            isProcessing: true,
            success: null,
            error: null,
        },
    };
}
export function fetchUserDetailsError(state, payload) {
    return {
        ...state,
        ...payload,
        userDetails: {
            isProcessing: false,
            success: false,
            error: true,
        },
    };
}