import {
  ADD_MSOP_REPORT_FAILED,
  ADD_MSOP_REPORT_START,
  ADD_MSOP_REPORT_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {addMsopStarted, addMsopFailed, addMsopSuccess} from './Helper';

const MsopReportReducer = (state = Default(), action) => {
  switch (action.type) {
    case ADD_MSOP_REPORT_START:
      return addMsopStarted(state, action);
    case ADD_MSOP_REPORT_FAILED:
      return addMsopFailed(state, action);
    case ADD_MSOP_REPORT_SUCCESS:
      return addMsopSuccess(state, action.payload);
    default:
      return state;
  }
};
export default MsopReportReducer;
