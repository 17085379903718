import axios, { AxiosResponse } from 'axios';
import {API_BASE_URL} from '../../config/constants';
import { AbortableRequestConfig } from 'types/Request';
import { Performance, PerformanceMission } from 'types/Performance';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getOrganizationPerformance(id: number | string): {
  call: Promise<AxiosResponse<Performance>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(
      `${API_BASE_URL}organization-performance/?organization=${id}`,
      {
        signal: controller.signal,
        ...headers(),
      } as AbortableRequestConfig),
    controller,
  };
}

export function getMissionPerformance(id: number | string): {
  call: Promise<AxiosResponse<PerformanceMission>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mission-performance/?mission=${id}`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}
