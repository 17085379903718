import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getUsersPat,
} from 'Services/UserPat.service';
import { selectNetworkId } from './networks';

type StateProps = {
  items: any[];
  count: number;
  status: 'idle' | 'loading' | 'success' | 'failed';
  statusMessage: string;
  page: number;
  perPage: number;
  search: string;
  isSearching: boolean;
}

const initialState: StateProps = {
  items: [],
  count: 0,
  status: 'idle',
  statusMessage: '',
  page: 1,
  perPage: 10,
  search: '',
  isSearching: false,
};

export const fetchPats = createAsyncThunk(
  'pats/fetchPats',
  async (__, {getState}) => {
    try {
      const state = getState();
      const networkId = Number(selectNetworkId(state));
      if(networkId > 0){
        //@ts-ignore
        const {page, perPage, search} = state.pats;
        const res = await getUsersPat(networkId, page, perPage, search).call;
        return res.data;
      }
    } catch (error) {
      console.error(error);
      
    }
  }
);


export const patsSlice = createSlice({
  name: 'pats',
  initialState,
  reducers: {
    selectPatsPage: (state, {payload}) => {
      state.page = payload;
    },
    selectPatsSearch: (state, {payload}) => {
      state.search = payload;
    },
    setIsSearching: (state, {payload}) => {
      state.isSearching = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPats.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPats.fulfilled, (state, {payload}) => {
        if(payload) {
          return {
            ...state,
            status: 'idle',
            isSearching: false,
            items: payload.results,
            count: payload.count,

          }
        }
      });
  }
});

export const {selectPatsPage, selectPatsSearch, setIsSearching} = patsSlice.actions;