import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: []
}

export const goalsSplice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    loadGoals: (state, { payload }) => {
      state.value = payload.map(item => ({ ...item, successes: item.successes ? item.successes.split(',').map(item => ({ text: item })) : [] }));
    },
    updatedGoalData: (state, { payload }) => {
      state.value[payload.index] = { ...state.value[payload.index], ...payload.value };
    },
    addSuccessesGoal: (state, { payload }) => {
      state.value[payload.index].successes.push({ text: "" });
    },
    updateSuccessesGoal: (state, { payload }) => {
      state.value[payload.index].successes[payload.successIndex].text = payload.value;
    },
    removeSuccessesGoal: (state, { payload }) => {
      state.value[payload.index].successes.splice(payload.successIndex, 1);
    },
  }
});

export const { loadGoals, updatedGoalData, addSuccessesGoal, updateSuccessesGoal, removeSuccessesGoal } = goalsSplice.actions;