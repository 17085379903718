import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  MISSION_HYPOTHESIS,
  MISSION_HYPOTHESIS_BULK_UPDATE,
} from '../../Api.constants';
import {
  GET_MISSION_HYPOTHESIS_START,
  GET_MISSION_HYPOTHESIS_SUCCESS,
  GET_MISSION_HYPOTHESIS_FAILED,
  MISSION_HYPOTHESIS_FAILED,
  MISSION_HYPOTHESIS_START,
  MISSION_HYPOTHESIS_SUCCESS,
  HYPOTHESIS_HEADING_START,
  HYPOTHESIS_HEADING_SUCCESS,
  HYPOTHESIS_HEADING_ERROR,
} from '../../Redux.constants';

export function updateMissionHypothesisAction(params, callback) {
  return async (dispatch) => {
    dispatch({
      type: MISSION_HYPOTHESIS_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.post(
        MISSION_HYPOTHESIS_BULK_UPDATE,
        params
      );
      dispatch({
        type: MISSION_HYPOTHESIS_SUCCESS,
      });
      callback && callback();
      getNotification('success', {
        header: 'Success!',
        body: response.data,
      });
      callback & callback(true);
    } catch (error) {
      dispatch({type: MISSION_HYPOTHESIS_FAILED});
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to update mission hypothesis.',
      });
      callback && callback(false);
    }
  };
}

export function getMissionHypothesisAction(params) {
  return async (dispatch) => {
    dispatch({
      type: GET_MISSION_HYPOTHESIS_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        `${MISSION_HYPOTHESIS}?mission=${params.id}`
      );
      dispatch({
        type: GET_MISSION_HYPOTHESIS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({type: GET_MISSION_HYPOTHESIS_FAILED});
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to fetch mission hypothesis.',
      });
    }
  };
}

export function updateHypothesisText(params, callback) {
  return async (dispatch) => {
    dispatch({type: HYPOTHESIS_HEADING_START});
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .patch(`/mission-hypothesis/${params.id}/`, params)
      .then((response) => {
        dispatch({
          type: HYPOTHESIS_HEADING_SUCCESS,
          payload: response.data,
        });
        successNotification();
        callback && callback(true);
        window.location.reload();
      })
      .catch((error) => {
        dispatch({
          type: HYPOTHESIS_HEADING_ERROR,
          payload: error.message,
        });
        failedNotification();
        callback && callback(false);
      });
  };
}

const successNotification = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Hypothesis updated successfully.',
  });
};

const failedNotification = () => {
  return getNotification('error', {
    header: 'Failed!',
    body: 'Failed to update mission hypothesis.',
  });
};
