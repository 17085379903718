import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Tag } from 'antd';
import debounce from 'lodash.debounce';
import { getEthnicitiesList } from '../../../../../../Services/demographic.service';
import { deleteEthnicities, getEthnicities, patchEthnicities, postEthnicities } from '../../../../../../Services/Ethnicities.service';
import DebounceLoading, { dialogStartSubject$, dialogStopSubject$ } from '../../../../../../Components/DebounceLoading/DebounceLoading';

const { Option } = Select;

/**
 * Ethnicities component.
 *
 * @param {Object} props - Props object.
 * @param {string} props.demographicId - The demographic ID.
 * @return {JSX.Element} The rendered component.
 */
const Ethnicities = ({ demographicId }) => {
  const [types, setTypes] = useState([]);
  const [selectType, setSelectType] = useState();
  const [allInsurance, setAllInsurance] = useState([]);

  const loadTypes = async () => {
    try {
      const res = await getEthnicitiesList().call;
      setTypes(res?.data?.results);
    } catch (error) {

    }
  }

  const loadInsuranceTypes = async () => {
    try {
      const res = await getEthnicities(demographicId).call;
      setAllInsurance(res?.data?.results);
    } catch (error) {

    }
  }

  const addInsuranceTypes = async () => {
    try {
      await postEthnicities({ demographics: demographicId, ethnicity: selectType }).call;
      setSelectType(null);
      loadInsuranceTypes();
    } catch (error) {

    }
  }

  const removeInsuranceTypes = async (id) => {
    try {
      const res = await deleteEthnicities(id).call;
      loadInsuranceTypes();
    } catch (error) {

    }
  }

  const updatePatientLenguage = async data => {
    dialogStartSubject$.setSubject = true;
    try {
      await patchEthnicities(data).call;
    } catch (error) {

    }
    dialogStopSubject$.setSubject = false;
  }

  const debounceInsurance = useCallback(
    debounce(updatePatientLenguage, 625)
    , []);

  useEffect(() => {
    if (demographicId) {
      loadInsuranceTypes();
      loadTypes();
    }
  }, [demographicId]);

  return (
    <div>
      <div className=" mt-15">
        <span>Ethnicity Breakdown:</span>
        <hr />
        <div className="flex">
          <Select
            className="select-class"
            placeholder="Add Ethnicity"
            optionFilterProp="children"
            bordered={false}
            value={selectType}
            onChange={(net) => {
              setSelectType(net);
            }}
          >
            {types.map(item => (
              <Option key={item.id}>{item.name}</Option>
            ))}
          </Select>
          <Button type="primary" onClick={addInsuranceTypes}>
            Add
          </Button>
        </div>
      </div>
      <div className="mt-5">
        {
          allInsurance?.map((item, index) => (
            <div key={index} className="block mt-5">
              <Tag closable onClose={() => removeInsuranceTypes(item?.id)} color="#102431">
                {item?.ethnicity?.name}
              </Tag>
              <input className="input-class" defaultValue={item?.percentage} type="text" placeholder="Percentage" onChange={(e) => debounceInsurance({ id: item?.id, percentage: e.target.value })} />
            </div>
          ))
        }
      </div>
      <DebounceLoading />
    </div >
  );
};

export default Ethnicities;
