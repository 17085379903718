export function deleteApiStarted(state) {
  return {
    ...state,
    deleteGroupstate: {
      isProcessing: true,
      error: null,
      success: null,
    },
  };
}
export function deleteApiSuccess(state, payload) {
  return {
    ...state,
    deleteGroupstate: {
      isProcessing: null,
      error: null,
      success: true,
    },
  };
}
export function deleteApiFailed(state) {
  return {
    ...state,
    deleteGroupstate: {
      isProcessing: null,
      error: true,
      success: null,
    },
  };
}
