import {connect} from 'react-redux';
import {
  addDepartmentAction,
  addDesignationAction,
  addEmployeeAction,
  resetEmployeeForm,
  removeEmployeeAction,
  addInsightsFormChangeAction,
  fetchEmployeesListAction,
  fetchOrganisationAction,
  getDepartmentsAction,
  getDesignationsAction,
  addNotes,
  getNotes,
  updateNotes,
  getPresignedUrl,
  uploadImage,
  updateDepartment,
  deleteDepartment,
  deleteNotes,
} from '../../Redux/Actions';
import {getSprints} from '../../Redux/Actions/ViewSprintsOrganizationActions';
import {
  getMissionDetails,
  updateMission,
  saveMission,
} from '../../Redux/Actions/ViewOrganizationPersonaActions';
import {deleteSummary} from '../../Redux/Actions/DeleteSummaryAction';
import OrganisationInsights from './OrganisationInsights';
import {updateSprintOrganizationAction} from '../../Redux/Actions/UpdateSprintOrganizationAction';
import {SelectState} from './Selector';

const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.AddNotesReducer,
    ...state.UploadImageReducer,
    ...state.DeleteSummaryReducer,
    ...state.ViewSprintReducer,
    ...state.OrganizationSprintDetailsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteSummary: (params) => {
      dispatch(deleteSummary(params));
    },
    getMissionDetails: (params) => {
      dispatch(getMissionDetails(params));
    },
    saveMission: (params, callback) => {
      dispatch(saveMission(params, callback));
    },
    updateMission: (params) => {
      dispatch(updateMission(params));
    },
    fetchOrganisationAction: (params) => {
      dispatch(fetchOrganisationAction(params));
    },
    getDepartments: (params) => {
      dispatch(getDepartmentsAction(params));
    },
    getDesignations: (params) => {
      dispatch(getDesignationsAction(params));
    },
    addInsightsFormChange: (params) => {
      dispatch(addInsightsFormChangeAction(params));
    },
    addEmployees: (params, callback) => {
      dispatch(addEmployeeAction(params, callback));
    },
    resetEmployeeForm: () => {
      dispatch(resetEmployeeForm());
    },
    removeEmployees: (params, callback) => {
      dispatch(removeEmployeeAction(params, callback));
    },
    fetchEmployeesList: (params) => {
      dispatch(fetchEmployeesListAction(params));
    },
    addDepartmentAction: (params, callback) => {
      dispatch(addDepartmentAction(params, callback));
    },
    addDesignationAction: (params, callback) => {
      dispatch(addDesignationAction(params, callback));
    },
    getSprints: (params, callback) => {
      dispatch(getSprints(params, callback));
    },
    updateSprint: (params, callBack) => {
      dispatch(updateSprintOrganizationAction(params, callBack));
    },
    ...addNotesinInsights(dispatch),
  };
};
function addNotesinInsights(dispatch) {
  return {
    getNotes: (params, callback) => {
      dispatch(getNotes(params, callback));
    },
    addNotes: (params, callback) => {
      dispatch(addNotes(params, callback));
    },
    updateNotes: (params, callback) => {
      dispatch(updateNotes(params, callback));
    },
    getPresignedUrl: (params, callback) => {
      dispatch(getPresignedUrl(params, callback));
    },
    uploadFile: (params, cb) => {
      dispatch(uploadImage(params, cb));
    },
    deleteNotes: (params, callback) => {
      dispatch(deleteNotes(params, callback));
    },
    deleteDepartment: (params, callback) => {
      dispatch(deleteDepartment(params, callback));
    },
    updateDepartment: (params, callback) => {
      dispatch(updateDepartment(params, callback));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationInsights);
