import {DashboardCard} from 'Components/atoms/card/DashboardCard';
import {SearchSelect} from 'Components/atoms/select/SearchSelect';
import {useDashboard} from 'Components/molecules/dashboard/Hooks/useDashboard';
import MilestoneOverviewTable from 'Components/molecules/dashboard/MilestoneOverviewTable';
import {PatOverViewCards} from 'Components/molecules/dashboard/PatOverViewCards';
import {ProgressBar} from 'Components/molecules/dashboard/ProgressBar';
import {ProgressLabel} from 'Components/molecules/dashboard/ProgressLabel';
import CustomDatePicker from 'Components/molecules/datePicker';
import React from 'react';

const PHASE_OVERVIEW_LABELS = [
  'Phase 1',
  'Phase 2',
  'Phase 3',
  'Phase 4',
  'Phase 5',
];

const PATS_PER_PRACTICE_LABELS = ['1', '2', '3', '4', '>5'];

export const PHASE_OVERVIEW_COLORS = [
  'bg-teal-700',
  'bg-cyan-500',
  'bg-green-600',
  'bg-purple-100',
  'bg-pink-100',
];
export const PATS_PER_PRACTICE_COLORS = [
  'bg-teal-700',
  'bg-opaque-500',
  'bg-opaque-400',
  'bg-opaque-300',
  'bg-opaque-200',
];
export const Dashboard: React.FC = (): JSX.Element => {
  const {
    patsData,
    pats,
    phases,
    milestoneOverview,
    TimeFrameOptions,
    selectTimeRange,
    networks,
    selectNetwork,
    totalNetworks,
    totalPats,
    selectOwners,
    owners,
    selectMarkets,
    markets,
    showCustomDate,
    setShowCustomDate,
    setSelectedTimeRange,
    selectedTimeRange,
  } = useDashboard();

  return (
    <div className='flex flex-col gap-5 w-full mb-8'>
      <div className='flex flex-col p-12 mx-12 bg-white'>
        <div className='text-[40px]'>Admin Dashboard</div>
        <div className='grid lg:grid-cols-6 sm:grid-cols-2 md:grid-cols-3 gap-2'>
          <div className='flex flex-row h-10 w-full pr-1'>
            {!showCustomDate && (
            <SearchSelect
              data-testid={'singleSelectByTime'}
              onSelect={selectTimeRange}
              options={TimeFrameOptions}
              placeholder='All time'
              style={{ width: '100%', minWidth: '120px' }}
              placeholderStyle= {{
                lineHeight: '37px'
              }}
            />
            )}
            {showCustomDate && (
              <CustomDatePicker 
                defaultSelectedTimeRange={selectedTimeRange}
                setSelectedTimeRange={setSelectedTimeRange}
                onClose={()=> {
                  setShowCustomDate(false)
                }}
              />
            )}
          </div>
          <div className='col-end-auto w-full col-span-5 sm:col-span-2 md:col-span-5 flex flex-row flex-wrap gap-2'>
            <SearchSelect
              data-testid={'multipleSelectByNetwork'}
              allowClear
              handleChange={selectNetwork}
              options={networks}
              mode="multiple"
              search
              style={{ maxWidth: '200px' }}
              placeholder='All Networks'
              placeholderStyle= {{
                lineHeight: '37px'
              }}
            />
            <SearchSelect
              data-testid={'multipleSelectByOwner'}
              allowClear
              handleChange={selectOwners}
              mode="multiple"
              options={owners}
              search
              style={{ maxWidth: '200px' }}
              placeholder='All Owners'
              placeholderStyle= {{
                lineHeight: '37px'
              }}
            />
            <SearchSelect
              data-testid={'multipleSelectByMarket'}
              allowClear
              handleChange={selectMarkets}
              mode="multiple"
              options={markets}
              search
              style={{ maxWidth: '200px' }}
              placeholder='All Markets'
              placeholderStyle= {{
                lineHeight: '37px'
              }}
            />
          </div>
        </div>
      </div>
      <DashboardCard>
        <div className='' style={{borderBottom: '1px solid lightgray'}}>
          <div className='text-2xl'>PATs per Network</div>
          {/* Subheader, TODO: Get real data */}
          <div className='text-base'>
            {totalPats} Pats across {totalNetworks} Networks
          </div>
        </div>
        <ProgressBar phases={pats} progressType='PATPerPractice' />
        <ProgressLabel
          labels={PATS_PER_PRACTICE_LABELS}
          progressType='PATPerPractice'
          phases={pats}
        />
      </DashboardCard>

      <PatOverViewCards patDummyData={patsData} />

      <DashboardCard>
        <div className='' style={{borderBottom: '1px solid lightgray'}}>
          <div className='text-2xl'>Phase Overview</div>
        </div>
        <div className='w-full flex justify-end mr-[2px]'>
          {totalNetworks} total networks
        </div>
        <ProgressBar phases={phases} progressType='PhaseOverview' />
        <ProgressLabel
          labels={PHASE_OVERVIEW_LABELS}
          progressType='PhaseOverview'
          phases={phases}
        />
      </DashboardCard>

      <DashboardCard textCenter>
        <div className='' style={{borderBottom: '1px solid lightgray'}}>
          <div className='text-2xl'>Milestone Overview</div>
        </div>
        <MilestoneOverviewTable milestoneData={milestoneOverview} />
      </DashboardCard>
    </div>
  );
};
