import React, { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Input, Button, Modal } from 'antd';

type CreateTagModalProps = {
  open: boolean;
  handleCancel: React.MouseEventHandler<HTMLElement>;
  handleSave: (data: TagFormData) => void;
};

type TagFormData = {
  name: string;
};

const CreateTagModal: React.FC<CreateTagModalProps> = ({ open, handleCancel, handleSave }) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<TagFormData>();

  const onSubmit: SubmitHandler<TagFormData> = (data) => {
    handleSave(data);
  };
  
  useEffect(() => {
      reset();
  }, [open]);

  return (
    <Modal
      data-testid="editTagModal"
      aria-modal="true"
      aria-labelledby="modalTitle"
      open={open}
      footer={null}
      onCancel={handleCancel}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div data-testid="overwriteModalContent" className="full-height full-width flex flex-col gap-3 mt-6 pb-0 px-4">
          <h1 data-testid="overwriteModalTitle" className="text-center text-3xl font-bold">Create Tag</h1>
          
          <p>
            <span style={{ color: 'red' }}>*</span> Tag name
          </p>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: 'Tag name is required' }}
            render={({ field }) => <Input {...field} placeholder="Enter Tag Name" />}
          />
          {errors.name && <span role="alert" className="text-red-600">{errors.name.message}</span>}
          <p>
            <span style={{ color: 'red' }}>*</span>These fields are required
          </p>

          <div data-testid="overwriteModalButtons" className="flex flex-row justify-center mt-3">
            <Button data-testid="acceptChangesButton" type="primary" style={{background:'#06A5BB'}} htmlType="submit">Create</Button>
            <Button data-testid="cancelChangesButton" type="text" onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default CreateTagModal;
