import React from 'react';
import { InputNumber as InputNum } from 'antd';
const InputNumber = props => {
    const inputChange = (value) => {
        props.handleChange &&
            props.handleChange({
                value,
                name: props.name,
                index: props.index,
                formObject: props.formObject,
            });
    };

    return (
        <InputNum
        style={{
            width: 200,
            padding:3
          }}
            min={props.min}
            max={props.max}
            disabled={props.disabled}
            type='input'
            name={props.name}
            onChange={(e)=>{props.onChange!==undefined?props.onChange(e):inputChange(e)}}
            value={props.value}>
        </InputNum>
    );
};

export default InputNumber;
