import React from "react"
import { Input } from "antd"

import { useTranslation } from "react-i18next"

/**
 * Render the FeedbackStep6 component.
 *
 * @param {object} props - The component props.
 * @param {string} props.step1Value - The value of step1Value.
 * @param {string} props.step6FeatureValue - The value of step6FeatureValue.
 * @param {function} props.setStep6FeatureValue - The function to set the value of step6FeatureValue.
 * @param {string} props.step6RequestValue - The value of step6RequestValue.
 * @param {function} props.setStep6RequestValue - The function to set the value of step6RequestValue.
 * @return {JSX.Element} - The rendered component.
 */
const FeedbackStep6 = ({
  step1Value,
  step6FeatureValue,
  setStep6FeatureValue,
  step6RequestValue,
  setStep6RequestValue,
}) => {

  // i18n translation hook
  const [t] = useTranslation()
  
  return (
    <>
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step6.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step6.Feature_message')}</p>
          <Input allowClear required size="small" value={step6FeatureValue} onChange={(e) => setStep6FeatureValue(e.target.value)} />
        </>
      )}
      {step1Value === "request" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step6.Request_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step6.Request_message')}</p>
          <Input allowClear required size="small" value={step6RequestValue} onChange={(e) => setStep6RequestValue(e.target.value)} />
        </>
      )}
    </>
  )
}

export default FeedbackStep6