import {ApiBase} from '../../../utils/apiBase';
import {getNotification} from '../../../Components/GetNotification';
import {
  GET_DEPARTMENTS_FAILED,
  GET_DEPARTMENTS_START,
  GET_DEPARTMENTS_SUCCESS,
} from '../../Redux.constants';
import {FETCH_DEPARTMENTS} from '../../Api.constants';

export function getDepartmentsAction(params) {
  let {input, limit, offset, orgId} = params;
  return async (dispatch) => {
    dispatch({type: GET_DEPARTMENTS_START});
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        FETCH_DEPARTMENTS +
          `?organization=${orgId}&q=${input}&limit=${limit}&offset=${offset}`
      );

      dispatch({
        type: GET_DEPARTMENTS_SUCCESS,
        payload: {
          departmentList: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DEPARTMENTS_FAILED,
        payload: {error},
      });
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to fetch department list',
      });
    }
  };
}
