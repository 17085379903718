import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {GET_EMPLOYEE_LIST} from '../../Api.constants';
import {
  GET_EMPLOYEE_LIST_FAILED,
  GET_EMPLOYEE_LIST_START,
  GET_EMPLOYEE_LIST_SUCCESS,
} from '../../Redux.constants';

export const fetchEmployeesListAction = (params) => {
  return async (dispatch) => {
    dispatch({
      type: GET_EMPLOYEE_LIST_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        GET_EMPLOYEE_LIST(params)
      );
      dispatch({
        type: GET_EMPLOYEE_LIST_SUCCESS,
        payload: {
          employeesResponse: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEE_LIST_FAILED,
        payload: {
          error,
        },
      });
      getNotification('error', {
        header: 'Failed!',
        body: 'Failed to fetch employee list',
      });
    }
  };
};
