import {
  UPDATE_PERSONA_START,
  UPDATE_PERSONA_FAILED,
  UPDATE_PERSONA_SUCCESS,
} from '../../Redux.constants';
import {Default} from './InitialState';
import {updateApiStarted, updateApiFailed, updateApiSuccess} from './Helper';

const updatePersonaReducer = (state = Default(), action) => {
  switch (action.type) {
    case UPDATE_PERSONA_START:
      return updateApiStarted(state, action);
    case UPDATE_PERSONA_FAILED:
      return updateApiFailed(state, action);
    case UPDATE_PERSONA_SUCCESS:
      return updateApiSuccess(state, action);
    default:
      return state;
  }
};
export default updatePersonaReducer;
