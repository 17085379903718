import React from 'react';
import {Upload, Modal} from 'antd';
import { withTranslation } from "react-i18next"

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class ImageUpload extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  };

  componentWillMount() {
    this.props.defaultFileList?.length &&
      this.setState({
        fileList: this.props.defaultFileList || [],
      });
  }
  onCustomRequest = ({file, onSuccess}) => {
    // this.props.imageUploadMethod(file.name, () => {
    //   this.props.uploadImage({
    //     imageUrl: this.props.imageUrl,
    //     file: file,
    //   });
    // });
    onSuccess('Ok');
  };
  handleCancel = () => this.setState({previewVisible: false});
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleChange = ({fileList}) => {
    this.setState({fileList});
    this.props.onFileUpload && this.props.onFileUpload(fileList[0]);
  };

  setUploadedImage = (url) => {
    this.setState({
      fileList: url ? [{url}] : [],
    });
  }

  uploadButton = (
    <div>
      <div style={{marginTop: 8}}>{this.props.t('Button.Upload')}</div>
    </div>
  );

  render() {
    const {previewVisible, previewImage, fileList, previewTitle} = this.state;
    return (
      <>
        <Upload
          listType='picture-card'
          fileList={fileList}
          customRequest={this.onCustomRequest}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={this.props.beforeUpload}
          maxCount={1}>
          {fileList.length >= 1 ? null : this.uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}>
          <img alt='example' style={{width: '100%'}} src={previewImage} />
        </Modal>
      </>
    );
  }
}

export default withTranslation()(ImageUpload)
