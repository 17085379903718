import {ApiBase} from '../../../utils/apiBase';
import {
  FETCH_METADATA_SUCCESS,
  FETCH_METADATA_FAILED
} from '../../Redux.constants';

export function FetchMetadataAction() {
  return async (dispatch) => {
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get(
        `pat-phases/`
      );
      let meta = response.data;
      dispatch({
        // type: FETCH_METADATA_SUCCESS,
        type: FETCH_METADATA_SUCCESS,
        payload: {
          phasesColors: meta
        },
      });
    } catch (error) {
      dispatch({
        type: FETCH_METADATA_FAILED,
        payload: {error},
      });
    }
  };
}
