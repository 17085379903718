import React from 'react';

interface DifficultyTagProps {
  difficulty: 'EASY' | 'MEDIUM' | 'HARD';
}

const tagColorsAndText = {
  EASY: {
    bg: 'bg-green-600',
    color: 'text-white',
    text: 'Easy',
  },
  MEDIUM: {
    bg: 'bg-yellow-300',
    color: 'text-black',
    text: 'Medium',
  },
  HARD: {
    bg: 'bg-red-700',
    color: 'text-white',
    text: 'Hard',
  },
};

export const DifficultyTag: React.FC<DifficultyTagProps> = ({difficulty}) => {
  // Default to 'medium' if no difficulty, or the difficulty isn't found in the array
  const props = tagColorsAndText[difficulty] ?? tagColorsAndText.MEDIUM;
  return (
    <span
      className={`rounded py-px px-2 text-sm font-medium ${props.bg} ${props.color}`}>
      {props.text}
    </span>
  );
};
