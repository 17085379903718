import React from 'react';
import { Controller } from 'react-hook-form';
import './styles/ChecboxDebounce.scss';

/**
 * Renders a checkbox component with debounced action.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.name - The name of the checkbox.
 * @param {Object} props.control - The control object for the checkbox.
 * @param {Function} props.debounceAction - The debounce action function.
 * @return {JSX.Element} The rendered checkbox component.
 */
const CheckboxDebounce = ({ name, control, debounceAction }) => {
  return <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <input type="checkbox" checked={field.value} onChange={(value) => {
        field.onChange(value)
        debounceAction()
      }} />
    )}
  />;
};

export default CheckboxDebounce;
