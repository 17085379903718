import axios from 'axios';
import { API_BASE_URL } from '../../../../config/constants';

export const GetAllPopulateToolboxData = (data) => {
  return {
    type: 'getallpopulateToolboxdata',
    payload: data,
    loader: false,
  };
};

export const getDraftAnswerData = (data) => {
  return {
    type: 'getdraftAnswerData',
    payload: data,
    loader: false,
  }
}

export const getallpopulatetoolbox = () => {
  return (dispatch) => {
    axios({
      url: `${API_BASE_URL}toolbox/`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        'content-type': 'application/json',
      },
    }).then(
      (result) => {
        dispatch(GetAllPopulateToolboxData(result));
      },
      (error) => {
        console.log(error);
      }
    );
  };
};


export const getdraftDataById = (id) => {
  return (dispatch) => {
    axios({
      url: `${API_BASE_URL}toolbox/drafts/${id}/`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        'content-type': 'application/json',
      },
    }).then(
      (result) => {
        dispatch(getDraftAnswerData(result))
      },
      (error) => {
        console.log(error);
      }
    );
  }
}