import {ApiBase} from '../../../utils/apiBase';
import {
  ADD_SPRINT_FAILED,
  ADD_SPRINT_START,
  ADD_SPRINT_SUCCESS,
  ADD_SPRINT_VALIDATION_ERRORS,
  GET_SPRINTS_FAILED,
  GET_SPRINTS_START,
  GET_SPRINTS_SUCCESS,
  GET_SPRINT_BY_ID_FAILED,
  GET_SPRINT_BY_ID_START,
  GET_SPRINT_BY_ID_SUCCESS,
  UPDATE_SPRINT_ERROR_MESSAGE,
} from '../../Redux.constants';
import {getNotification} from '../../../Components/GetNotification';
import * as yup from 'yup';
import {string} from 'prop-types';
export * from './Helper/getHypothesisActionHelper';
export * from './Helper/updateSprintActionHelper';
export * from './Helper/getSprintByIdActionHelper';

const ADD_SPRINT_FORM_SCHEMA = yup.object().shape({
  // name: yup.number().required('Hypothesis is required'),
  // hypothesis: yup
  //   .string()
  //   .nullable('Hypothesis cannot be empty')
  //   .required('Hypothesis is required'),
  name: yup
    .string()
    .nullable('name cannot be empty')
    .required('name is required'),
  max_number_of_cadence: yup.number().required('No Of Cadance is required'),
  start_date: yup
    .string('Start Date cannot be empty')
    .nullable('Start Date cannot be empty')
    .required('Start date is required'),
  end_date: yup
    .string('End Date cannot be empty')
    .nullable('End Date cannot be empty')
    .required('EndDate is required'),
  // sprint_frequency: yup.string().required('Cadence Frequency is required'),
});
function fetchValidationErrors(payload) {
  return new Promise((resolve, reject) => {
    ADD_SPRINT_FORM_SCHEMA.validate(payload, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
const SuccessMessage = () => {
  getNotification('success', {
    header: 'Success',
    body: 'Successfully Added the Sprint',
  });
};

export function addSprint(payload, callback) {
  return async (dispatch) => {
    dispatch({
      type: ADD_SPRINT_START,
      payload: {},
    });
    try {
      await ADD_SPRINT_FORM_SCHEMA.validate(payload);
      await addSprintSuccesAndFailure({dispatch, payload, callback});
    } catch (error) {
      let validationErrors = await fetchValidationErrors(payload);
      dispatch({
        type: ADD_SPRINT_VALIDATION_ERRORS,
        payload: validationErrors,
      });
    }
  };
}
async function addSprintSuccesAndFailure({dispatch, payload, callback}) {
  const apiBaseInstance = new ApiBase();
  apiBaseInstance.instance
    .post('/mission-sprint/', payload)
    .then((response) => {
      dispatch({
        type: ADD_SPRINT_SUCCESS,
        payload: {
          data: response.data,
        },
      });
      SuccessMessage();
      callback && callback(response.data?.id);
    })
    .catch((e) => {
      dispatch({
        type: ADD_SPRINT_FAILED,
        payload: {
          error: e,
        },
      });
      showErrorStatus('Failed to add sprint');
    });
}
export function updateSprintErrorMessage(input) {
  if (input.value === null) {
    if (input.name === 'start_date') {
      input.errorMessage = 'Start Date is required';
    } else {
      input.errorMessage = 'End Date is required';
    }
  } else {
    input.errorMessage = '';
  }
  return (dispatch) => {
    dispatch({
      type: UPDATE_SPRINT_ERROR_MESSAGE,
      payload: input,
    });
  };
}
function getSprintStart(dispatch) {
  dispatch({
    type: GET_SPRINTS_START,
    payload: {},
  });
}
function getsprintSuccess(dispatch, response) {
  dispatch({
    type: GET_SPRINTS_SUCCESS,
    payload: {data: response.data},
  });
}
function getSprintFail(dispatch, e) {
  dispatch({
    type: GET_SPRINTS_FAILED,
    payload: {error: e},
  });
}
export function getSprints(params, callback) {
  return async (dispatch) => {
    getSprintStart(dispatch);
    const apiBaseInstance = new ApiBase();
    let holdOn = 'False';
    let queryParams = '';
    if (params.is_on_hold) {
      holdOn = 'True';
    }
    if (params.q) {
      queryParams = params.q;
    }
    apiBaseInstance.instance
      .get(
        `mission-sprint/?mission=${params.id}&is_on_hold=${holdOn}&q=${queryParams}
        &limit=${params.limit}&offset=${params.offset}` //&limit=${limit}&offset=${offset}
      )
      .then((response) => {
        getsprintSuccess(dispatch, response);
        callback && callback(true);
      })
      .catch((e) => {
        getSprintFail(dispatch, e);
        callback && callback(true);
        showErrorStatus('Failed to fetch sprints');
      });
  };
}

const showErrorStatus = (message) => {
  getNotification('error', {
    header: 'Failed!',
    body: message,
  });
};

const showSuccessStatus = (message) => {
  getNotification('success', {
    header: 'Success!',
    body: message,
  });
};
