import {ApiBase} from '../../../utils/apiBase';
import {ADD_EMPLOYEE} from '../../Api.constants';
import {fetchEmployeesListAction} from '../index';
import {
  ADD_EMPLOYEE_FAILED,
  ADD_EMPLOYEE_START,
  ADD_EMPLOYEE_SUCCESS,
  RESET_EMPLOYEE_FORM,
} from '../../Redux.constants';
import {number} from 'yup/lib/locale';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';

const ADD_INSIGHT_FORM_SCHEMA = yup.object().shape({
  first_name: yup
    .string()
    .min(4, 'first name required')
    .required('first name required'),
  last_name: yup.string(),
  email: yup.string().email(),
  department: yup.number(),
  designation: yup.number(),
});

function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    ADD_INSIGHT_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
const successNotification = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Successfuly added insights.',
  });
};

const removeSuccessNotification = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Successfuly removed insights.',
  });
};

const editSuccessNotification = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Successfuly edited insights.',
  });
};
const failureNotification = (value, error) => {
  let message = 'Failed to add Insights';
  error.map((item) => {
    if (item.path === 'first_name' && !value.first_name) {
      message = 'First Name is required';
    } else if (item.path === 'email' && value.email) {
      message = 'Please enter a valid email';
    }
  });
  return getNotification('error', {
    header: 'Failed!',
    body: message,
  });
};

export const resetEmployeeForm = () => {
  return async (dispatch) => {
    dispatch({type: RESET_EMPLOYEE_FORM});
  };
};
export const addEmployeeAction = (params, callback) => {
  return async (dispatch) => {
    dispatch({type: ADD_EMPLOYEE_START});
    try {
      const apiBaseInstance = new ApiBase();

      if (params.id) {
        let response = await apiBaseInstance.instance.patch(
          `${ADD_EMPLOYEE}${params.id}/?id=${params.id}`,
          params
        );

        dispatch({type: ADD_EMPLOYEE_SUCCESS, payload: response.data});
        editSuccessNotification();
      } else {
        let response = await apiBaseInstance.instance.post(
          ADD_EMPLOYEE,
          params
        );
        dispatch({
          type: ADD_EMPLOYEE_SUCCESS,
          payload: {addEmployeeResponse: response.data},
        });
        successNotification();
      }
      dispatch(
        fetchEmployeesListAction({
          organisationId: params.organization,
          input: '',
          limit: 20,
          offset: 0,
        })
      );
      callback();
    } catch (error) {
      let formError = await fetchValidationErrors(params);
      dispatch({
        type: ADD_EMPLOYEE_FAILED,
        payload: {error, formError},
      });
      callback();
      failureNotification(params, formError);
    }
  };
};

export const removeEmployeeAction = (params, callback) => {
  return async (dispatch) => {
    dispatch({type: ADD_EMPLOYEE_START});
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.delete(
        `${ADD_EMPLOYEE}${params.id}/?id=${params.id}`
      );
      dispatch({type: ADD_EMPLOYEE_SUCCESS, payload: response.data});
      removeSuccessNotification();
      dispatch(
        fetchEmployeesListAction({
          organisationId: params.organisationId,
          input: '',
          limit: 20,
          offset: 0,
        })
      );
      callback();
    } catch (error) {
      dispatch({
        type: ADD_EMPLOYEE_FAILED,
        payload: {error},
      });
      callback();
      failureNotification(params, formError);
    }
  };
};
