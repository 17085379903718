import React from 'react';
import './ItemTab.scss';
import {useDispatch} from 'react-redux';
import {cleanPat, resetMeet, resetMission} from '../../../../ReduxV2/state';

/**
 * Renders an item tab component.
 *
 * @param {Object} item - The item object.
 * @param {string} className - The CSS class name.
 * @param {function} onSelectStep - The function to handle step selection.
 * @return {JSX.Element} The rendered item tab component.
 */
const ItemTab = ({item, className, onSelectStep, active}) => {
  const dispatch = useDispatch();

  const resetProjectData = () => {
    dispatch(resetMission());
    dispatch(cleanPat());
    dispatch(resetMeet());
    onSelectStep(item);
  };

  return (
    <a className={className} onClick={resetProjectData}>
      <span>{item.icon}</span>
      <span className={`${active && 'text-white'}`}>{item.label} &nbsp; </span>
    </a>
  );
};

export default ItemTab;
