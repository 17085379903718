import React from "react"
import { Radio } from "antd"
import { useTranslation } from "react-i18next"

/**
 * Renders the FeedbackStep1 component.
 *
 * @param {object} props - The props object containing the step1Value and setStep1Value.
 * @param {string} props.step1Value - The value of the step1 input.
 * @param {function} props.setStep1Value - The function to set the value of the step1 input.
 * @return {JSX.Element} The rendered FeedbackStep1 component.
 */
const FeedbackStep1 = ({
  step1Value,
  setStep1Value,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <>
      <h2 className="mb-10">{t('Feedback_Modal.Step1.header')}</h2>
      <p className="mb-10">{t('Feedback_Modal.Step1.message')}</p>
      <Radio.Group value={step1Value} onChange={(e) => setStep1Value(e.target.value)}>
        <Radio value="bug">{t('Feedback_Modal.Step1.Bug')}</Radio>
        <Radio value="feature">{t('Feedback_Modal.Step1.Relevance')}</Radio>
        <Radio value="request">{t('Feedback_Modal.Step1.Request')}</Radio>
      </Radio.Group>
    </>
  )
}

export default FeedbackStep1