import React, {useEffect, useState} from 'react';
import {Upload, Modal} from 'antd';
import {getNotification} from 'Components/GetNotification';

const customUploadStyle = `
  .custom-upload .ant-upload-list-item,
  .custom-upload .ant-upload-list-picture-card-container,
  .custom-upload .ant-upload-select-picture-card
  .custom-upload .ant-upload-list-picture-card {
    transition: none !important;
  }
`;

interface ImageUploadProps {
  onFileUpload: (file: any) => void;
  defaultFileList: any[];
}

type UploadPreviewProps = {
  blobOrUrl: string;
  title: string;
};

const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const checkUploadSize = (file: any) => {
  const sizeInMB = file.size / 1024 / 1024;
  const isSmallEnough = sizeInMB < 1;
  if (!isSmallEnough) {
    getNotification('error', {
      header: 'Error',
      body: 'Image must smaller than 1MB.',
    });
  }
  return isSmallEnough;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
  onFileUpload,
  defaultFileList,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>(defaultFileList || []);
  const [uploadedImage, setUploadedImage] = useState<UploadPreviewProps | null>(
    null
  );
  const [existingImage, setExistingImage] = useState<UploadPreviewProps | null>(
    null
  );
  const handleCancel = () => setPreviewVisible(false);

  const onCustomRequest = async (options: any) => {
    if (options?.file) {
      const blob = await getBase64(options.file);
      setUploadedImage({
        blobOrUrl: blob as string,
        title: options.file.name,
      });
    }
    if (options?.onSuccess) {
      options.onSuccess('ok');
    }
  };

  const handleChange = ({fileList}: any) => {
    setFileList(fileList);
    if (fileList[0]) {
      setExistingImage({
        blobOrUrl: fileList[0].url,
        title: fileList[0].name,
      });
      onFileUpload(fileList[0]);
    }
  };

  const uploadButton = (
    <div>
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  useEffect(() => {
    setFileList(defaultFileList || []);
    if (defaultFileList?.length > 0) {
      setExistingImage({
        blobOrUrl: defaultFileList[0].url,
        title:
          defaultFileList[0].name ||
          defaultFileList[0].url.substring(
            defaultFileList[0].url.lastIndexOf('/') + 1
          ),
      });
    }
  }, [defaultFileList]);

  const uploadComponentImage = uploadedImage
    ? [{url: uploadedImage.blobOrUrl}]
    : fileList;

  return (
    <>
      <style>{customUploadStyle}</style>
      <Upload
        listType='picture-card'
        accept='image/jpeg, image/png, image/jpg'
        className='!transition-none w-[104px] h-[104px] overflow-hidden custom-upload'
        fileList={uploadComponentImage}
        customRequest={onCustomRequest}
        onPreview={() => {
          setPreviewVisible(true);
        }}
        onRemove={() => {
          if (existingImage) {
            onFileUpload(null);
          }
          setUploadedImage(null);
        }}
        onChange={handleChange}
        beforeUpload={checkUploadSize}
        maxCount={1}>
        {uploadComponentImage.length < 1 && uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={uploadedImage?.title ?? existingImage?.title}
        footer={null}
        onCancel={handleCancel}>
        <img
          alt='example'
          style={{width: '100%'}}
          src={uploadedImage?.blobOrUrl ?? existingImage?.blobOrUrl}
        />
      </Modal>
    </>
  );
};
