/* eslint-disable @typescript-eslint/no-explicit-any */
import './ScoreCardUpload.css';

import {UploadOutlined} from '@ant-design/icons';
import React, {Dispatch, SetStateAction} from 'react';
import {Modal, Button, Radio, RadioChangeEvent} from 'antd';
import {ScoreCardFileType} from 'types/ScoreCard';

type ScoreCardUploadProps = {
  setTriggerUpload: any;
  excelUploadComponent: any;
  googleUploadComponent: any;
  isModalVisible: any;
  setIsModalVisible: any;
  fileType: string | undefined;
  setFileType: Dispatch<SetStateAction<string | undefined>>;
  link: string | undefined;
  file: File | undefined;
};

const ScoreCardUpload: React.FC<ScoreCardUploadProps> = ({
  setTriggerUpload,
  excelUploadComponent,
  googleUploadComponent,
  isModalVisible,
  setIsModalVisible,
  fileType,
  setFileType,
  link,
  file,
}) => {
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setTriggerUpload(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileTypeChange = (e: RadioChangeEvent) => {
    setFileType(e.target.value);
  };

  const isDisabled = () => {
    if(link && fileType === ScoreCardFileType.GOOGLE) return false

    if(file && fileType === ScoreCardFileType.EXCEL) return false

    return true
  }

  return (
    <>
      <Button
        data-testid='uploadFile'
        className='extra-small'
        type='primary'
        size='small'
        onClick={showModal}>
        Upload
        <UploadOutlined />
      </Button>

      <Modal
        data-testid='scoreCardUploadModal'
        aria-modal='true'
        aria-labelledby='modalTitle'
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}>
        <div
          data-testid='scoreCardUploadModalContent'
          className='full-height full-width flex flex-col gap-3 mt-6  pb-0 px-4'>
          <h1
            data-testid='scoreCardUploadModalTitle'
            className='text-3xl font-bold'>
            {fileType === ScoreCardFileType.GOOGLE && 'Upload Link'}
            {fileType === ScoreCardFileType.EXCEL && 'Upload File'}
          </h1>

          <div className='text-sm text-black'>
            <p>Select file type:</p>
            <Radio.Group value={fileType} onChange={handleFileTypeChange}>
              <Radio.Button value={ScoreCardFileType.GOOGLE}>
                {ScoreCardFileType.GOOGLE}
              </Radio.Button>
              <Radio.Button value={ScoreCardFileType.EXCEL}>
                {ScoreCardFileType.EXCEL}
              </Radio.Button>
            </Radio.Group>

            {fileType === ScoreCardFileType.GOOGLE && googleUploadComponent}
            {fileType === ScoreCardFileType.EXCEL && excelUploadComponent}
          </div>

          <div
            data-testid='scoreCardUploadModalButtons'
            className='flex flex-row justify-center mt-3'>
            <Button
              data-testid='acceptChangesButton'
              type='primary'
              disabled={isDisabled()}
              onClick={handleOk}>
              Confirm
            </Button>
            <Button
              data-testid='cancelChangesButton'
              type='text'
              onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScoreCardUpload;
