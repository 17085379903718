import {initialiseAddInsightsForm} from '../initialState';

export function getDepartmentsStarted(state) {
  return {
    ...state,
    departmentList: {
      isProcessing: true,
      success: null,
      error: null,
      data: [],
    },
  };
}
export function getDepartmentsSuccess(state, payload) {
  return {
    ...state,
    departmentList: {
      isProcessing: false,
      success: true,
      error: null,
      totalDepartment: payload.departmentList.count,
      data: payload.departmentList.results.map((item) => ({
        label: item.name,
        value: item.id,
        description: item.description || '-',
      })),
    },
  };
}
export function getDepartmentsFailed(state, payload) {
  return {
    ...state,
    departmentList: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}
export function getDesignationsStarted(state) {
  return {
    ...state,
    designationList: {
      isProcessing: true,
      success: null,
      error: null,
      data: [],
    },
  };
}
export function getDesignationsSuccess(state, payload) {
  return {
    ...state,
    designationList: {
      isProcessing: false,
      success: true,
      error: null,
      totalDesignation: payload.designationList.count,
      data: payload.designationList.results.map((item) => ({
        label: item.name,
        value: item.id,
        description: item.description || '-',
      })),
    },
  };
}
export function getDesignationsFailed(state, payload) {
  return {
    ...state,
    designationList: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}
export function setAddInsightsFormValue(state, action) {
  return {
    ...state,
    addInsightsForm: {
      ...state.addInsightsForm,
      [action.payload.field]: {
        ...state.addInsightsForm[action.payload.field],
        value: action.payload.value,
        error: '',
      },
    },
  };
}

export function getEmployeesStarted(state) {
  return {
    ...state,
    employeesList: {
      isProcessing: true,
      success: null,
      error: null,
      data: [],
    },
  };
}
export function getEmployeesSuccess(state, payload) {
  return {
    ...state,
    employeesListForSeletBox: {
      isProcessing: false,
      success: true,
      error: null,
      totalEmployees: payload?.employeesResponse?.count,
      data: payload.employeesResponse.results.map((item) => ({
        label: item.first_name,
        value: item.id,
      })),
    },
    employeesList: {
      isProcessing: false,
      success: true,
      error: null,
      totalEmployees: payload?.employeesResponse?.count,
      data: payload.employeesResponse.results.map((item) => ({
        id: item.id,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email,
        department: item.department_name,
        designation: item.designation,
      })),
    },
  };
}
export function getEmployeesFailed(state, payload) {
  return {
    ...state,
    employeesList: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addEmployeesStarted(state) {
  return {
    ...state,
    addEmployee: {
      isProcessing: true,
      success: null,
      error: null,
      data: [],
    },
  };
}

export function resetAddEmployeesForm(state) {
  return {
    ...state,
    addEmployee: {
      isProcessing: false,
      success: null,
      error: null,
      data: [],
    },
    addInsightsForm: initialiseAddInsightsForm(),
  };
}
export function addEmployeesSuccess(state, payload) {
  return {
    ...state,
    addEmployee: {
      isProcessing: false,
      success: true,
      error: null,
    },
    addInsightsForm: initialiseAddInsightsForm(),
  };
}

const getaddInsightForm = (addInsightsForm, formError) => {
  for (const key in addInsightsForm) {
    if (Object.hasOwnProperty.call(addInsightsForm, key)) {
      const element = addInsightsForm[key];
      element.errorMessage = formError[key];
    }
  }
  return addInsightsForm;
};
export function addEmployeesFailed(state, payload) {
  let formError = {};
  payload.formError.forEach((element) => {
    formError[element.path] = element.message;
  });
  return {
    ...state,
    addEmployee: {
      isProcessing: false,
      success: null,
      error: 'Error in adding employee',
    },
    // addInsightsForm: getaddInsightForm({...state.addInsightsForm}, formError),
  };
}
