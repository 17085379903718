import {type} from 'os';
import React from 'react';

type Props = {
  label: string;
  required?: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputWithLabel = ({label, required, onChange, value}: Props) => {
  return (
    <div className='mb-4'>
      <label className='block text-gray-700 text-sm font-bold'>
        {required && <span className='text-error-500'>*</span>}
        {label}
      </label>
      <input
        value={value}
        onChange={onChange}
        type='text'
        className='appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none'
      />
    </div>
  );
};
