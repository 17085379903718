export function resetPasswordStarted(state) {
  return {
    ...state,
    resetPassword: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function resetPasswordSuccess(state) {
  return {
    ...state,
    resetPassword: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function resetPasswordError(state) {
  return {
    ...state,
    resetPassword: {
      isProcessing: false,
      success: null,
      error: true,
    },
  };
}
export function resetPasswordValidationErrors(state, payload) {
  const newState = {...state};
  newState.loading = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
    resetPassword: {
      isProcessing: false,
      success: null,
      error: null,
    },
  };
}
