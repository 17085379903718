/*eslint-disable*/
import { createSelector } from 'reselect';

const getOrganisationReducer = (state) => state.OrganisationReducer;
const getUploadImageReducer = (state) => state.UploadImageReducer;
const getUserReducer = (state) => state.UserReducer;

const mergeSelectedReducers = (
  OrganisationReducer,
  UploadImageReducer,
  UserReducer
) => {
  return {
    organisationList: OrganisationReducer.organisationList,
    getOrganisations: OrganisationReducer.getOrganisations,
    organisationCount: OrganisationReducer.organisationCount,
    limit: OrganisationReducer.limit,
    imageUrl: UploadImageReducer.imageUrl,
    imageApi: UploadImageReducer.imageApi,
    integrateApi: UploadImageReducer.integrateApi,
    integratedUrl: UploadImageReducer.integratedUrl,
    uploadingImage: UploadImageReducer.uploadingImage,
    name: UserReducer.name,
    point_of_contact: UserReducer.point_of_contact,
    country: UserReducer.country,
    contact_email: UserReducer.contact_email,
    phone: UserReducer.phone,
    website: UserReducer.website,
    isProcessing: UserReducer.isProcessing,
  };
};

export const SelectState = createSelector(
  [getOrganisationReducer, getUploadImageReducer, getUserReducer],
  mergeSelectedReducers
);
