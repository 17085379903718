import React from "react"
import { Input } from "antd"

import { useTranslation } from "react-i18next"


/**
 * Render the appropriate feedback step based on the value of step1Value.
 *
 * @param {Object} props - The props object.
 * @param {string} props.step1Value - The value of step1Value.
 * @param {string} props.step2BugValue - The value of step2BugValue.
 * @param {function} props.setStep2BugValue - The function to set the value of step2BugValue.
 * @return {JSX.Element} The feedback step component.
 */
const FeedbackStep2 = ({
  step1Value,
  step2BugValue,
  setStep2BugValue,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <>
      {step1Value === "bug" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step2.Bug_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step2.Bug_message')}</p>
          <Input allowClear required size="small" value={step2BugValue} onChange={(e) => setStep2BugValue(e.target.value)} />
        </>
      )}
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step2.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step2.Feature_message')}</p>
        </>
      )}
      {step1Value === "request" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step2.Request_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step2.Request_message')}</p>
        </>
      )}
    </>
  )
}

export default FeedbackStep2