import React from "react"
import { Input } from "antd"

import { useTranslation } from "react-i18next"

/**
 * Renders a feedback step for feature relevance.
 *
 * @param {object} step1Value - The value of step 1.
 * @param {string} step8FeatureValue - The value of step 8 feature.
 * @param {function} setStep8FeatureValue - The function to set the value of step 8 feature.
 * @return {JSX.Element} The rendered feedback step.
 */
const FeedbackStep8 = ({
  step1Value,
  step8FeatureValue,
  setStep8FeatureValue,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <>
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step8.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step8.Feature_message')}</p>
          <Input allowClear required size="small" value={step8FeatureValue} onChange={(e) => setStep8FeatureValue(e.target.value)} />
        </>
      )}
    </>
  )
}

export default FeedbackStep8