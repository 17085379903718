import {useHistory} from 'react-router';
import {ROUTES} from '../../../../../Routes.constants';
import {SetLocalStorage} from '../../../../../utils/localStorageOperations';
import {
  AUTH_TOKEN,
  FIRST_NAME,
  LAST_NAME,
  USER_DESIGNATION,
  USER_ID,
  USER_NAME,
  USER_EMAIL,
  USER_ROLES,
  PROFILE_PICTURE,
  CLIENT,
  REFRESH_TOKEN,
} from '../../../../Redux.constants';

export function inputFormChange(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      value: payload.value,
      errorMessage: '',
    },
  };
}

export function LoginSuccess(state, payload) {
  let {loginResponse} = payload;
  SetLocalStorage({[AUTH_TOKEN]: loginResponse.access});
  SetLocalStorage({[REFRESH_TOKEN]: loginResponse.refresh});
  SetLocalStorage({[FIRST_NAME]: loginResponse.user_data.first_name || ''});
  SetLocalStorage({[LAST_NAME]: loginResponse.user_data.last_name || ''});
  SetLocalStorage({[USER_EMAIL]: loginResponse.user_data.email});
  SetLocalStorage({[USER_ROLES]: loginResponse.user_data.roles});
  SetLocalStorage({[USER_ID]: loginResponse.user_data.id});
  SetLocalStorage({[PROFILE_PICTURE]: loginResponse.user_data.image});
  SetLocalStorage({[CLIENT]: loginResponse.user_data.client});
  SetLocalStorage({
    [USER_DESIGNATION]: loginResponse.user_data.user_designation || '',
  });
  return {
    ...state,
    loginResponse: {data: loginResponse},
    loginApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function validationErrors(state, payload) {
  const newState = {...state};
  newState.loading = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
  };
}
export function loginStarted(state, payload) {
  return {
    ...state,
    buttonLoader: true,
    loginApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function loginError(state, payload) {
  return {
    ...state,
    ...payload,
    loginApi: {
      isProcessing: false,
      success: false,
      error: true,
    },
  };
}
