import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { getNotification } from '../../../../../Components/GetNotification';
import {
  createMeeting,
  getMeetingsDatails,
  patchMeetingGoal,
  postMeetingGoal,
  saveMeetingReflection,
} from '../../../../../Services/Meetings.service';
import MeetingsComments from '../../../../MeetingsComments/MeetingsComments';
import MeetingsPreparing from '../../../../MeetingsPreparing/MeetingsPreparing';
import { MeetingModal } from '../MeetingModal';
import './styles/CreateMeeting.scss';

/**
 * Creates a meeting with the given parameters.
 *
 * @param {Object} meetId - The ID of the meeting.
 * @param {Function} returnHome - A function to navigate back to the home page.
 * @param {Function} changeToReflection - A function to change the view to the reflection page.
 * @return {void}
 */
const CreateMeeting = ({ meetId, returnHome, changeToReflection }) => {
  const allFormData = useForm();

  const { handleSubmit, reset } = allFormData;
  const refForm = useRef();

  const [meetData, setMeetData] = useState(null);

  const { id } = useParams();

  /**
   * Handles the Ok button click event.
   *
   * @param {Object} params - The parameters for the function.
   * @return {Promise<void>} - Returns a Promise that resolves to undefined.
   */
  const handleOk = async (params) => {
    let start;
    let end;
    start = moment(params?.date).format('YYYY-MM-DD') + 'T' + `18:00:00`;
    end = moment(params?.date).format('YYYY-MM-DD') + 'T' + `19:00:00`;
    const payload = {
      start,
      end,
      topic: ' ',
      other_meeting_type:
        params?.type == 'OTHER' ? params?.other_meeting_type : '',
      title: params?.title,
      meeting_type: params?.type,
      organizations: [id],
      missions: params?.mission ? [params?.mission] : [],
      sprints: params?.sprint ? [params?.sprint] : [],
      attendees: params?.array_attendees?.map((member) => ({
        team_persona: member.associated_users,
        attendee_type: member.attendee_type,
      })),
      goals: [],
    };

   try {
      let meet;
      if (meetData) {
        meet = await saveMeetingReflection(meetData.id, payload).call;
      } else {
        meet = await createMeeting(payload).call;
      }

      const goals_personals =
        params.goals?.map(async (item) => {
          if (item?.id) {
            await patchMeetingGoal(item);
          } else {
            await postMeetingGoal({
              meeting: meetData?.id || meet.data.id,
              category: 'PERSONAL_CAT',
              ...item,
            }).call;
          }
        }) || [];

      const goals_meets =
        params.goals_meet?.map(async (item) => {
          if (item?.id) {
            await patchMeetingGoal(item);
          } else {
            await postMeetingGoal({
              meeting: meetData?.id || meet.data.id,
              category: 'MEETING_CAT',
              ...item,
            }).call;
          }
        }) || [];

      await Promise.all([...goals_personals, ...goals_meets]);

      if (meetId) {
        getNotification('success', {
          header: '',
          body: 'The meeting has been updated successfully',
        });
        changeToReflection();
      } else {
        getNotification('success', {
          header: '',
          body: 'The meeting has been created successfully',
        });
        returnHome();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadMeetData = async () => {
    try {
      const meet = await getMeetingsDatails(meetId).call;
      setMeetData(meet.data);
    } catch (error) { }
  };

  const activeSubmitForm = () => {
    refForm?.current.click();
  };

  useEffect(() => {
    reset();
    if (meetId) {
      loadMeetData();
    }
  }, [meetId]);

  return (
    <div className='createmeeting'>
      <form onSubmit={handleSubmit(handleOk)}>
        <MeetingModal meetData={meetData} allFormData={allFormData} />

        <div className='flex justify-end'>
          <div>
            <span className='text-red'>*</span>
            <label className='pl-1 font-medium'>
              These fields are required 
            </label>
          </div>
        </div>
 
        <MeetingsPreparing meetData={meetData} allFormData={allFormData} />
        <div className={`container-button`}>
          <button
            data-testId="createMeetingButton"
            className={`${meetId ? 'd-none' : ''}`}
            type='submit'
            ref={(e) => (refForm.current = e)}>
            Create
          </button>
        </div>
      </form>
      {meetId ? (
        <MeetingsComments
          meetData={meetData}
          allFormData={allFormData}
          activeSubmitForm={activeSubmitForm}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default CreateMeeting;
