import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
import {
  CREATE_ORGANISATION_GROUP,
  CREATE_ORGANISATION_GROUP_FAILED,
  CREATE_ORGANISATION_GROUP_SUCCESS,
  CREATE_ORGANISATION_GROUP_VALIDATION_ERROR,
  GET_DEPARTMENT,
  GET_DEPARTMENT_FAILED,
  GET_DEPARTMENT_SUCCESS,
  UPDATE_ERROR_MESSAGE,
} from '../../Redux.constants';
import * as yup from 'yup';
import {Users as users} from './initialState';
import {LIST_ORGANIZATION_GROUP} from '../../Api.constants';

export function GetDepartments() {
  return async (dispatch) => {
    dispatch({
      type: GET_DEPARTMENT,
      payload: {},
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.get('/department/', {});
      dispatch({
        type: GET_DEPARTMENT_SUCCESS,
        payload: {
          department: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_DEPARTMENT_FAILED,
        payload: {},
      });
    }
  };
}

const ADD_ORGANISATION_FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Organisation title should only have Alphabets.')
    .required('Group Name is required'),
  discription: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Point of contact should only have Alphabets.')
    .required('discription is required'),
});
function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    ADD_ORGANISATION_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}
export function CreateGroup(input, callback) {
  return async (dispatch) => {
    dispatch({
      type: CREATE_ORGANISATION_GROUP,
      payload: {},
    });
    try {
      await addGroup({dispatch, input, callback});
    } catch (error) {
      let createOrganisationValidationErrors = await fetchValidationErrors(
        input
      );
      dispatch({
        type: CREATE_ORGANISATION_GROUP_VALIDATION_ERROR,
        payload: createOrganisationValidationErrors,
      });
      dispatch({
        type: CREATE_ORGANISATION_GROUP_FAILED,
        payload: {
          createOrgnisationError: null,
        },
      });
    }
  };
}
const successMessage = () => {
  return getNotification('success', {
    header: 'Success!',
    body: 'Created new Group',
  });
};

/* eslint-disable   max-lines-per-function */
async function addGroup({dispatch, input, callback}) {
  try {
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.post(
      LIST_ORGANIZATION_GROUP,
      input,
      {headers: {'Content-Type': 'multipart/form-data'}}
    );
    dispatch({
      type: CREATE_ORGANISATION_GROUP_SUCCESS,
      payload: {organisation: response.data},
    });
    callback();
    successMessage();
  } catch (error) {
    dispatch({
      type: CREATE_ORGANISATION_GROUP_FAILED,
      payload: {
        createOrgnisationError:
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors,
      },
    });
    callback();

    getNotification('error', {
      header: error?.response?.data?.non_field_errors
        ? ' Failed - Field Error'
        : 'Failed !',
      body: error?.response?.data?.non_field_errors
        ? error.response.data.non_field_errors
        : 'Failed to create a Group',
    });
  }
}

export function updateErrorMessage(input) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR_MESSAGE,
      payload: input,
    });
  };
}
