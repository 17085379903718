import React from 'react';
import { Modal } from 'antd';
import { PatStatus } from 'types/Pat';
import { useDispatch, useSelector } from 'react-redux';

type CompleteConfirmationModalProps = {
  visibleConfirmation: boolean;
  setVisibleConfirmation: (visible: boolean) => void;
  createOrUpdatePat: (status: PatStatus) => void;
  loadPats: () => void;
  setVisible: (visible: boolean) => void;
};

const CompleteConfirmationModal: React.FC<CompleteConfirmationModalProps> = ({
  visibleConfirmation,
  setVisibleConfirmation,
  createOrUpdatePat,
  loadPats,
  setVisible
}) => {
  const dispatch = useDispatch();
  const isUserAdmin = useSelector((state: any) => state.user.user.client_admin);

  return (
    <Modal
      data-testid='completeConfirmationModal'
      open={visibleConfirmation}
      footer={false}
      bodyStyle={{ overflowY: 'scroll' }}>
      <div className='w-100 h-250px center-flex-align items-center'>
        <div>
          <h3 className='text-center'>
            Are you sure you want to complete this assessment?
          </h3>
          {!isUserAdmin && (
            <p className='text-center'>
              Once completed, you will not be able to make any edits.
            </p>
          )}
          <div className='w-80 flex justify-beetween mt-15 m-auto'>
            <button
              data-testid='completeConfirmationButton'
              type='button'
              className='modal-delete-btn'
              onClick={() => {
                dispatch(createOrUpdatePat(PatStatus.COMPLETED));
                setVisibleConfirmation(false);
                setVisible(false);
              }}>
              Complete
            </button>
            <button
              data-testid='cancelButton'
              type='button'
              className='modal-dont-delete'
              onClick={() => {
                setVisibleConfirmation(false);
                loadPats();
              }}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteConfirmationModal;
