import { createSlice } from "@reduxjs/toolkit";

type StateProps = {
  value: string;
};

const initialState: StateProps = {
  value: ''
};

export const networksSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    selectNetwork: (state, { payload }) => {
      state.value = payload.id;
    },
  }
});

export const selectNetworkId = (state: any): string => state.network.value;
export const { selectNetwork } = networksSlice.actions;