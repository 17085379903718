import { getNotification } from '../../../Components/GetNotification';
import { SetLocalStorage } from '../../../utils/localStorageOperations';
import { ApiBase } from '../../../utils/apiBase';
import {
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_START,
  PROFILE_PICTURE,
} from '../../Redux.constants';

export function changeProfileData(payload, next) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USER_PROFILE_START });

    const apiBaseInstance = new ApiBase().instance;
    apiBaseInstance
      .patch(`/users/${localStorage.getItem('userId')}/`, payload)
      .then((res) => dispatch(updateProfileSuccess(res, next)))
      .catch((error) => dispatch(updateProfileFailed(error)));
  };
}

function updateProfileSuccess(res, next) {
  getNotification('success', {
    header: 'Success!',
    body: 'Profile updated successfully',
  });
  localStorage.setItem('firstName', res.data.first_name);
  localStorage.setItem('lastName', res.data.last_name);
  localStorage.setItem('designation', res.data.user_designation);
  localStorage.setItem('profileImage', res.data.image);
  SetLocalStorage({
    [PROFILE_PICTURE]: res.data.image,
  });
  next && next();
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: res,
  };
}
function updateProfileFailed(err) {
  getNotification('error', {
    header: 'Failed!',
    body: 'Failed to update Profile',
  });
  return {
    type: UPDATE_USER_PROFILE_FAILED,
  };
}
