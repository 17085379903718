import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {}
};

export const personasSlice = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    selectPersona: (state, { payload }) => {
      state.value = payload;
    },
    cleanPersona: (state) => {
      state.value = {};
    },
    updatePersona: (state, { payload }) => {
      state.value = { ...state.value, ...payload };
    },
  }
});

export const { selectPersona, cleanPersona, updatePersona } = personasSlice.actions;