import {ROUTES} from '../../Routes.constants';

export const VIEW_MISSION_DEFAULT_PARAMS = {
  limit: 10,
  offset: 0,
  ordering: '-created_on',
  q: '',
};

export const HEADER_SEARCH_PAGES = [
  {
    path: '',
    defaultParams: VIEW_MISSION_DEFAULT_PARAMS,
    exact: true, // if exact is true ,the search box only show on the exact path page
  },
];
