
import React, { useState, useEffect, FunctionComponent } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Col, Row} from 'antd';
import classNames from 'classnames';
import { BiLock } from 'react-icons/bi';
import { makeWebUrl, parseWebUrl } from '../../utils/queryStringUtils';
import ReactGA from 'react-ga';
import { STEPS } from './constants';
import style from './style.module.scss';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { getOrganisationById } from 'Services/Organisation.service';

interface OrganisationData {
  website?: string;
  phone?: string;
  contact_email?: string;
  profile_image_url?: string;
  name?: string;
}

interface OrganisationInsightsProps {
  children? : any
}

const OrganisationInsights: FunctionComponent<OrganisationInsightsProps> = ({children}) => {
  const location:any = useLocation();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const [state, setState] = useState({
    isViewEmployees: false,
    currentPage: 1,
    showAddMissionModal: false,
    activeStep: parseWebUrl().tab || STEPS[0].id,
    employeePageLimit: 6,
    departmentPageLimit: 6,
    designationPageLimit: 3,
    showForm: false,
  });

  const { data: organisationResponse, callFunction: fetchOrganisationById }:any = useManualApiWithLoading({
    apiFunction: getOrganisationById
  });

  const organisationData = organisationResponse?.data

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    if (params.id) {
      // Assuming fetchOrganisationAction is a prop or a function to fetch data
      fetchOrganisationById(params.id)
      localStorage.setItem('organizationId', params.id);
    }
  }, [params.id]);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (location.data) {
      localStorage.setItem('pageUrlData', JSON.stringify(location.data));
      setState(prev => ({ ...prev, organisationData: location.data as OrganisationData }));
    }
  }, [organisationData, location.data]);

  const onSelectStep = (step: any) => {
    const newParams = parseWebUrl();
    newParams.tab = step.id;
    const URL = (window.location.pathname + makeWebUrl(newParams)).replace('intel/intervention/', '');
    ReactGA.pageview(URL);
    history.push(URL);
    setState(prev => ({ ...prev, activeStep: step.id, showForm: false }));
  };

  const getSidebarSection = (item: any, index: number) => (
    <li
      key={index}
      className={classNames({
        [style.bg__orange]: item.bgColor,
        [style.current]: state.activeStep == item.id,
        'bg-black text-white rounded-lg': state.activeStep == item.id,
      })}
      onClick={() => {
        if(item?.label === 'Projects') return
        onSelectStep(item)
      }}
    >
        <div className='my-2 gap-4 flex flex-row h-12 items-center'>

      <span className='ml-4'>{item.icon}</span>
      <span>{item.label}</span>
        </div>
      {item.locked && <BiLock size="1.3em" />}
    </li>
  );

  return (
    <section className={style.organisationInsights}>
      <Row>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <div className='flex flex-col border-solid border-2 border-gray-400 py-9 px-7 mx-5 my-7 rounded-lg'>
            <div className='flex flex-col lg:flex-row items-center justify-center text-center lg:justify-between lg:text-start gap-8'>
              <img className='w-[76px] rounded-lg' src={organisationData?.profile_image_url ?? ''} alt='Profile' />
              <h2>{organisationData?.name ?? 'Unknown Network'}</h2>
            </div>
            <ul className={style.insightsAside}>
              {STEPS.map(getSidebarSection)}
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <div className='mt-15'>
            {children}
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default OrganisationInsights;
