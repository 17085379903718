import React, { useEffect, useState} from 'react';
import { getNotification } from 'Components/GetNotification';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { deleteTag, getTags, patchTags, postTags } from 'Services/Tag.service';
import { PlusOutlined } from '@ant-design/icons';
import { TagList } from './components/TagList';
import EditTagModal, { TagFormData } from './components/EditTagModal/EditTagModal';
import { Button } from 'antd';
import { Input } from 'antd';
import { CreateTagModal } from './components/CreateTagModal';
import { DeleteTagModal } from './components/DeleteTagModal';
import { Tag as TagType} from 'types/Tags';

const { Search } = Input;
/**
 * Renders the Tag Page.
 *
 * @return {JSX.Element} The rendered Tag Page.
 */
const TagPage: React.FC = () => {
  const [isOpenEditTag, setIsOpenEditTag] = useState<boolean>(false);
  const [isOpenDeleteTag, setIsOpenDeleteTag] = useState<boolean>(false);
  const [isOpenCreateTag, setIsOpenCreateTag] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<TagType | undefined>();
  const [dataSource, setDataSource] = useState<TagType[]>([]);

  const handleSearch = (value:string) => {
    const filteredData = (tagsData?.data?.results ?? []).filter((item:any) =>
      Object.keys(item).some((key:any) =>
        String(item[key]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setDataSource(filteredData);
  };

  const { 
    data:tagsData,
    error:tagError,
    callFunction: fetchTags
  } = useManualApiWithLoading<any>({
    apiFunction: getTags
  });

  const { 
    data: updatedTagData,
    error: tagUpdateError,
    callFunction: updateTag
  } = useManualApiWithLoading<any>({
    apiFunction: patchTags
  });

  const { 
    data: newTagData,
    error: tagCreateError,
    callFunction: createTag
  } = useManualApiWithLoading<any>({
    apiFunction: postTags
  });

  const { 
    data: removedTagData,
    error: tagDeleteError,
    callFunction: removeTag
  } = useManualApiWithLoading<any>({
    apiFunction: deleteTag
  });

  const handleEditTag = (e:TagFormData) => {
    updateTag({id:selectedTag?.id, ...e})
    setIsOpenEditTag(false)
  }

  const handleDeleteTag = () => {
    removeTag(selectedTag?.id)
    setIsOpenDeleteTag(false)
  }
  const handleCreateTag = (e:TagFormData) => {
    createTag(e)
    setIsOpenCreateTag(false)
  }

  useEffect(() => {
    fetchTags()
  }, [newTagData, updatedTagData, removedTagData])

  useEffect(()=> {
    setDataSource(tagsData?.data?.results)
  }, [tagsData])

  useEffect(() => {
    if(tagError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not retreive Tags list',
      });
    }
    if(tagUpdateError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not update Tag',
      });
    }
    if(tagDeleteError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not delete Tag',
      });
    }
    if(tagCreateError){
      const message = tagCreateError?.name ? 'Tag has already been added' : 'Could not create Tag'
      getNotification('error', {
        header: 'Error',
        body: (tagCreateError as any)?.response?.status == 403 ? 'You do not have permission to perform this action.' : message,
      });
    }
  }, [tagError, tagUpdateError, tagDeleteError, tagCreateError])

  return (
    <div className='tagPage'>
      <h1 className='font-mulish font-bold text-6xl'>Tags</h1>
      <div className='flex flex-row justify-end'>
          <Button
            style={{width:'148px', textTransform: 'none', color: 'black', background: '#30DEF7'}}
            onClick={() => {setIsOpenCreateTag(true)}}
            type="primary"
          >
            <span>Create Tag</span>
            <PlusOutlined />
          </Button>
          <Search 
            placeholder='Search Tags' 
            style={{ width: 200 }}
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
          />
 
      </div>
      <br/>
      <TagList
        data={dataSource ?? []}
        setSelectedTag={setSelectedTag}
        setIsOpenEditTag={setIsOpenEditTag}
        setIsOpenDeleteTag={setIsOpenDeleteTag}
      />
      <EditTagModal
        tag={selectedTag}
        open={isOpenEditTag}
        handleCancel={() => {setIsOpenEditTag(false)}}
        handleSave={handleEditTag}
      />
      <CreateTagModal
        open={isOpenCreateTag}
        handleCancel={() => {setIsOpenCreateTag(false)}}
        handleSave={handleCreateTag}
      />
      <DeleteTagModal
        tag={selectedTag}
        open={isOpenDeleteTag}
        handleCancel={() => {setIsOpenDeleteTag(false)}}
        handleSave={handleDeleteTag}
      />
 
    </div>
  );
};

export default TagPage;