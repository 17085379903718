import axios from 'axios';
import PropType from 'prop-types';
import queryString from 'query-string';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {API_BASE_URL} from '../../../config/constants';
import {ROUTES} from '../../Routes.constants';
import {capitalizeFirstLetter} from '../../utils/dummyUtils';
import {SetLocalStorage} from '../../utils/localStorageOperations';
import ForgetPassword from './Children/ForgetPassword/ForgetPassword';
import Form from './Children/Form';
import RegisterationSuccesPage from './Children/RegisterationSuccesPage';
import ResetPassword from './Children/ResetPassword/ResetPassword';
import './style.scss';
import ReactGA from 'react-ga';
import {SVG_PATH} from './Welcomepage.Constants';

export default class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // This is to change Login form and SignUp form
      changeForm: false,
      userRegistered: false,
      showForgotPassword: false,
      uid: '',
      token: '',
      showResetPassword: false,
      clientName: 'Blue Agilis',
      logo: '',
    };
  }

  // Handle the input for Login form
  inputChange = (e, name) => {
    this.props.inputFormChange({value: e.target.value, name: name});
  };
  // Change the state after pressing the signup button
  onSignUpButtonPress = () => {
    this.setState({changeForm: true});
  };
  signUpInputChange = (e, name) => {
    this.props.signUpFormChange({value: e.target.value, name: name});
  };
  signUpHandleSubmit = (e) => {
    e.preventDefault();
    this.props.signUp(
      {
        firstName: this.props.firstName.value,
        lastName: this.props.lastName.value,
        email: this.props.email.value,
        password: this.props.password.value,
        confirmPassword: this.props.confirmPassword.value,
      },
      () => {
        this.setState({changeForm: false, userRegistered: true});
      }
    );
  };
  // Handle login
  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.login(
      {
        loginEmail: this.props.loginEmail.value,
        loginPassword: this.props.loginPassword.value,
      },
      () => {
        this.props.history.push(ROUTES.NETWORKS);
      }
    );
  };

  getClient = async (code) => {
    try {
      const client = await axios.get(`${API_BASE_URL}clients/${code}/`);
      this.setState({logo: client.data.logo});
      SetLocalStorage({logo: client.data.logo});
    } catch (error) {
      window.location.href = `/blue-agilis/login`;
    }
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    localStorage.getItem('idToken') !== null
      ? this.props.history.push(ROUTES.NETWORKS)
      : null;
    let uid = this.props.match.params.uid;
    let token = this.props.match.params.token;
    let clientName = this.props.match.params.client;

    const {SignUp} = queryString.parse(location.search);

    if (SignUp) {
      this.setState({changeForm: true});
    }

    if (uid && token) {
      this.setState({uid, token, showResetPassword: true});
    }

    this.setState({clientName});
    localStorage.setItem('clientName', clientName);
    if (clientName !== 'blue-agilis') {
      this.getClient(clientName);
    } else {
      this.setState({logo: '/img/blueagilis-whitelogo.png'});
      SetLocalStorage({logo: '/img/blueagilis-whitelogo.png'});
    }
  }

  componentDidUpdate() {
    let clientName = this.props.match.params.client;
    localStorage.setItem('clientName', clientName);
  }
  renderLoading = () => {
    return <>Loading</>;
  };
  // Show the logo that is shown in the left side of the page
  renderLogo = () => {
    return (
      <span className='logo'>
        <svg enableBackground='new 0 0 300 302.5' viewBox='0 0 300 302.5'>
          <path d={SVG_PATH} />
        </svg>
      </span>
    );
  };
  resetToLogin = (type) => {
    this.setState({userRegistered: false});
    if (type) {
      this.onSignUpButtonPress();
    }
  };
  // This is to toggle forget password section

  onToggleCancel = (key, value) => {
    this.setState({[key]: !value});
  };
  // Create Forget password modal
  createForgetPassword = () => {
    return (
      <>
        {!this.state.changeForm && (
          <div className='forgot-password'>
            <a onClick={() => this.setState({showForgotPassword: true})}>
              Forgot Password?
            </a>
          </div>
        )}
        {this.state.showForgotPassword && (
          <ForgetPassword
            visible={this.state.showForgotPassword}
            onCancel={() =>
              this.onToggleCancel(
                'showForgotPassword',
                this.state.showForgotPassword
              )
            }
            api={this.props.forgotPassword}
            forgotPasswordAction={this.props.forgotPasswordAction}
          />
        )}
      </>
    );
  };
  // Render the login form
  renderLoginBlock = () => {
    return (
      <div className='login-block__forms'>
        <div className='login-item'>
          <Form
            {...this.props}
            signUpInputChange={this.signUpInputChange}
            inputChange={this.inputChange}
            handleSubmit={this.handleSubmit}
            showSignIn={!this.state.changeForm}
            signUpHandleSubmit={this.signUpHandleSubmit}
          />
          {this.createForgetPassword()}
        </div>
      </div>
    );
  };
  // Toggle to switch back to login item by pressing already have an account section
  createBackToLoginItem = () => {
    return (
      <div className='signup-link'>
        <p>Already Have an Account</p>
        <Link
          onClick={() => {
            this.setState({changeForm: false});
          }}>
          Back to Login
        </Link>
      </div>
    );
  };
  // Render the left side section
  renderForm = () => {
    return (
      <div className='login-block'>
        <div className='login-block__theam'>
          <div className='theam-content'>
            <img src={this.state.logo} className='w-650' />
          </div>
        </div>
        {this.renderLoginBlock()}
      </div>
    );
  };
  renderPage = () => {
    if (this.props.loading && this.props.isRegisteredUser)
      return (
        <section className='login-wrapper'>{this.renderLoading()}</section>
      );
    else {
      if (!this.state.userRegistered)
        return <section className='login-wrapper'>{this.renderForm()}</section>;
      else
        return (
          <section className='login-welcome'>
            <RegisterationSuccesPage
              resetToLogin={this.resetToLogin}
              props={this.props}
            />
          </section>
        );
    }
  };
  render() {
    return (
      <>
        {this.renderPage()}
        {this.state.uid && this.state.token ? (
          <ResetPassword
            visible={this.state.showResetPassword}
            onCancel={() =>
              this.onToggleCancel(
                'showResetPassword',
                this.state.showResetPassword
              )
            }
            api={this.props.forgotPassword}
            forgotPasswordAction={this.props.forgotPasswordAction}
            passwordErrowMessage
            {...this.props}
          />
        ) : (
          ''
        )}
      </>
    );
  }
}
WelcomePage.PropType = {
  className: PropType.string,
  value: PropType.string,
  emailError: PropType.string,
  type: PropType.string,
};
