import {BaseIcon, sizeType} from './BaseIcon';
import React from 'react';

const path = (
  <svg
    width='20'
    height='18'
    viewBox='0 0 20 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 18V0H10V4H20V18H0ZM2 16H8V14H2V16ZM2 12H8V10H2V12ZM2 8H8V6H2V8ZM2 4H8V2H2V4ZM10 16H18V6H10V16ZM12 10V8H16V10H12ZM12 14V12H16V14H12Z'
      fill='#1C1B1F'
    />
  </svg>
);
const GroupsIcon: React.FC<sizeType> = ({size}) => (
  <BaseIcon size={size}>{path}</BaseIcon>
);

const GroupsIconWithBackground = () => <BaseIcon background>{path}</BaseIcon>;

export {GroupsIcon, GroupsIconWithBackground};
