import axios, { AxiosResponse } from 'axios';
import {API_BASE_URL} from 'config/constants';
import { AbortableRequestConfig } from 'types/Request';
import { PaginatedTag, Tag, TagType } from 'types/Tags';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getTags(): {
  call: Promise<AxiosResponse<PaginatedTag>>;
  controller: AbortController;
} {
  const controller = new AbortController();
  return {
    call: axios.get(`${API_BASE_URL}tags/`, {
      signal: controller.signal,
      ...headers(),
    } as AbortableRequestConfig),
    controller,
  };
}

export function getTagById(id: number): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.get(`${API_BASE_URL}tags/${id}/`, headers()),
  };
}

export function getTagDetailsById(id: number): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.get(`${API_BASE_URL}tags/${id}/detail/`, headers()),
  };
}
export function postTags(data: any): {
  call: Promise<AxiosResponse<any>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}tags/`, data, headers()),
  };
}

export function patchTags(data: any): {
  call: Promise<AxiosResponse<Tag>>;
} {
  return {
    call: axios.patch(
      `${API_BASE_URL}tags/${data.id}/`,
      data,
      headers()
    ),
  };
}

export function deleteTag(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}tags/${id}/`, headers()),
  };
}

export function removeTag(data: any): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}tags/delete/`, {data, ...headers()}),
  };
}

export async function removeTagsForArray(tagArray:Tag[], type:TagType, type_id:number ) {
  const removeTagPromises = tagArray.map(tag => removeTag({
      id: tag.id,
      type,
      type_id,
    }).call);

  try {
    await Promise.all(removeTagPromises);
  } catch (error) {
    console.error('Error removing tags:', error);
  }
}
