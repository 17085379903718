import React from 'react';
import { Modal } from 'antd';
import './AboutTcModal.scss';

/**
 * Renders the AboutTcModal component.
 *
 * @param {Object} visible - Determines if the modal is visible or not.
 * @param {Function} onCancel - Callback function to handle cancel event.
 * @param {Array} selectTacticInformation - Array containing information about the selected tactic.
 * @return {JSX.Element} The rendered AboutTcModal component.
 */
const AboutTcModal = ({ visible, onCancel, selectTacticInformation }) => {
  return (
    <div className='AboutCompetencyModal'>
      <Modal open={visible} onCancel={onCancel} footer={false}>
        <div className='flex-vertical'>
          <h1>About this Tactic</h1>
        </div>
        <p className='text-black my-10'>
          <b>What is this?</b>
        </p>
        <p dangerouslySetInnerHTML={{ __html: selectTacticInformation[0]?.what_is_this.replace(/\n/g, "<br /> <br />") }} />
        <p className='text-black my-10'>
          <b>How can this improvement tactic drive improvement of your overall goals?</b>
        </p>
        <p>{selectTacticInformation[0]?.how_drive_improvement}</p>
      </Modal>
    </div>
  );
};

export default AboutTcModal;
