import React from 'react';
import {Pagination as PageComponent} from 'antd';
import PropTypes from 'prop-types';
import './style.scss';
export default function Pagination(props) {
  const fixed = {
    showSizeChanger: true,
  };
  return (
    <div className='pagination-block'>
      <PageComponent
        id='result-pagination-component'
        {...props}
        {...fixed}
        itemRender={itemRender}
      />
    </div>
  );
}
/* eslint-disable max-params */
function itemRender(current, type, originalElement) {
  if (type === 'page') {
    return (
      <a className='ant-pagination-item-link'>
        {Number(current).toLocaleString()}
      </a>
    );
  }
  return originalElement;
}
Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
};
