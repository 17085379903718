import React from 'react';
import {Dropdown} from 'antd';
import moment from 'moment';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import {Button} from 'Components/atoms';

import {useTranslation} from 'react-i18next';
import TagFloatingInfo from 'Components/TagFloatingInfo';
import { Tag } from 'types/Tags';

interface MissionCardProps {
  end: string;
  menu: React.ReactNode | null;
  name: string;
  tags?: Tag[] |string[];
  percentage: number;
  sprints: number;
  status?: string;
  start: string;
  onDropdownClick: () => void;
  onClick: () => void;
  onSprintsClick: () => void;
}

export const MissionCard: React.FC<MissionCardProps> = ({
  end,
  menu,
  name,
  percentage,
  sprints,
  start,
  status,
  tags = [],
  onDropdownClick,
  onClick,
  onSprintsClick,
}) => {
  const statusName = status === 'COMPLETED' ? 'Completed' : 'In progress',
    statusClass = status === 'COMPLETED' ? 'completedTag' : 'progressTag';

  // i18n translation hook
  const [t] = useTranslation();

  return (
    <div
      className={`relative flex flex-col gap-8 justify-between bg-white py-4 px-6 rounded-lg text-center shadow-lg min-h-[350px]`}>
      <div>
        <div className='flex justify-between mb-2'>
          <p className={`${statusClass} font-medium`}>{statusName}</p>
          <TagFloatingInfo tags={tags as Tag[]}/>
          <div className='mission_card_option pointer'>
            <Dropdown
              // @ts-ignore
              overlay={menu}
              trigger={['click']}
              onClick={onDropdownClick}>
              <svg viewBox='0 0 2.118 8.471'>
                <path
                  data-name='Path 268'
                  d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                  fill='#818e9b'
                />
              </svg>
            </Dropdown>
          </div>
        </div>
        <div className='cursor-pointer grid gap-2 group' onClick={onClick}>
          <div className='mission_card_circular mb-10'>
            <CircularProgressbarWithChildren
              value={percentage}
              styles={buildStyles({
                rotation: 1,
                strokeLinecap: 'butt',
                trailColor: '#969696',
                pathColor: '#026F7E',
              })}>
              <span className='text-lg font-semibold tracking-tight'>
                {percentage}%
              </span>
            </CircularProgressbarWithChildren>
          </div>
          <h3 className='text-lg text-primary leading-[1.4] line-clamp-3 group-hover:text-primary-hover'>
            {name}
          </h3>
          <div className='flex gap-3 justify-center items-center'>
            <p className='text-sm font-medium text-black'>
              {t('commons.interventions')}
            </p>
            <p className='text-base font-medium text-primary'>{sprints}</p>
          </div>
          <div className='flex justify-around text-base text-black font-normal gap-1'>
            <dl className='grow'>
              {!!start && (
                <>
                  <dt>{t('Mision_Card.Start_date')}</dt>
                  <dd>{moment(start).format('MM/DD/YY')}</dd>
                </>
              )}
            </dl>
            <dl className='grow'>
              {!!end && (
                <>
                  <dt>{t('Mision_Card.End_date')}</dt>
                  <dd>{moment(end).format('MM/DD/YY')}</dd>
                </>
              )}
            </dl>
          </div>
        </div>
      </div>

      <div className='flex justify-end'>
        <Button
          data-testid='goToSprintsButton'
          onClick={onSprintsClick}
          size='xs'
          type='submit'>
          {t('commons.goTo')} {t('commons.interventions')}
        </Button>
      </div>
    </div>
  );
};
