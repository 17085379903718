import {
  PATS_PER_PRACTICE_COLORS,
  PHASE_OVERVIEW_COLORS,
} from 'Routes/Dashboard/Dashboard';
import React from 'react';

interface Phase {
  name: string;
  count: number;
}

export const ProgressLabel: React.FC<{
  labels: string[];
  progressType: 'PATPerPractice' | 'PhaseOverview';
  phases: Phase[];
}> = ({labels, progressType, phases}) => {
  const totalPractices = phases.reduce(
    (total, phase) => total + phase.count,
    0
  );

  return (
    <div className='flex flex-row gap-4'>
      {labels.map((label, index) => {
        const phaseCount = phases[index]?.count || 0;
        const backgroundColor =
          progressType === 'PhaseOverview'
            ? PHASE_OVERVIEW_COLORS[index]
            : PATS_PER_PRACTICE_COLORS[index];

        return (
          <div key={index} className='flex flex-row items-center gap-1'>
            <div
              className={`w-2 h-2 rounded-full font-gray-70 ${backgroundColor}`}></div>
            <div>
              {progressType === 'PhaseOverview'
                ? `${label}  (${((phaseCount / totalPractices) * 100).toFixed(
                    2
                  )}%)`
                : label}
            </div>
          </div>
        );
      })}
    </div>
  );
};
