
// import TagSelect from 'Components/TagSelect';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { Tag } from 'types/Tags';
// import { EditOutlined } from '@ant-design/icons';

type TagFloatingInfoProps = {
  tags: Tag[];
}

const TagFloatingInfo: React.FC<TagFloatingInfoProps> = ({tags}) => {
  const tag = 'Tags'
  const [open, setOpen] = useState(false);
  // const [isEditing, setIsEditing] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={
        <div className='max-w-52 h-auto flex flex-row flex-wrap justify-center align-middle gap-2'>
            {/* {isEditing ? <TagSelect /> : mockTags?.map((currentTag => (
                <p className={`progressTag font-medium`}>{currentTag}</p>
            )))} */}
            {tags?.length > 0 ?
              tags?.map((currentTag => (
                  <span key={currentTag?.id} className={`h-6 progressTag font-medium`}>{currentTag?.name}</span>
              ))) : <p>No Data</p>}
        </div>
      }
      title={
        <div className='flex flex-row justify-between'>
            <h4>Tags</h4>
            {/* <EditOutlined onClick={() => {setIsEditing(prev => !prev)}}/> */}
        </div>
      }
      trigger="click"
      open={open}
      placement='bottom'
      onOpenChange={handleOpenChange}
    >
      <p className={`progressTag font-medium cursor-pointer hover:opacity-65`}>{`${tags?.length ?? 0} ${tag}`}</p>
    </Popover>
  );
};

export default TagFloatingInfo;