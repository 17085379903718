import { connect } from 'react-redux';
import {
  addEmployeeAction,
  addInsightsFormChangeAction,
  fetchEmployeesListAction,
  fetchOrganisationAction,
  getDepartmentsAction,
  getDesignationsAction,
  listMissionsAction,
  updateMissionAction,
  deleteMission,
  removeEmployeeAction,
  UserListAction
} from '../../../../Redux/Actions';
import ViewDetails from './ViewDetails';
import { SelectState } from './Selector';

const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.UpdateMissionReducer,
    ...state.MissionReducer,
    ...state.DeleteMissionReducer,
    ...state.UserReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganisationAction: (params) => {
      dispatch(fetchOrganisationAction(params));
    },
    listMissions: (params, callback) => {
      dispatch(listMissionsAction(params, callback));
    },
    getDepartments: (params) => {
      dispatch(getDepartmentsAction(params));
    },
    getDesignations: (params) => {
      dispatch(getDesignationsAction(params));
    },
    addInsightsFormChange: (params) => {
      dispatch(addInsightsFormChangeAction(params));
    },
    addEmployees: (params) => {
      dispatch(addEmployeeAction(params));
    },
    removeEmployees: (params, callback) => {
      dispatch(removeEmployeeAction(params, callback));
    },
    fetchEmployeesList: (params) => {
      dispatch(fetchEmployeesListAction(params));
    },
    UserListAction: (params) => {
    dispatch(UserListAction(params));
    },
    ...missionHelper(dispatch),
  };
};

function missionHelper(dispatch) {
  return {
    updateMission: (params, callBack) => {
      dispatch(updateMissionAction(params, callBack));
    },
    deleteMission: (params) => {
      dispatch(deleteMission(params));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetails);
