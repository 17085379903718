import _ from 'lodash';
import { 
  getPatCategories, 
  getUsersPatDetail, 
  postUsersPat, 
  patchUsersPat,
  getCollaborators,
  deleteCollaborator,
  PatTypes,
} from 'Services/UserPat.service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PatOption, PatStatus } from 'types/Pat';
import { Tag } from 'types/Tags';
import { convertTagsToStringArray } from 'Components/TagSelect/TagSelect';

const MESSAGES = {
  DRAFT_SAVED: 'Draft saved',
  COMPLETED_SAVED: 'PAT completed successfully',
  COLLABORATOR_DELETED: 'Collaborator removed',
};

type PatPayloadOption = {
  id?: number;
  user_pat?: number;
  pat_option: PatOption;
}

type PatState = {
  _id?: number | null; // If value's set, pft journey is triggered. This is a duplicate field, used for api calls.
  value: number | null;
  title: string;
  tags: Tag[] | string[];
  type: PatTypes | string | null;
  status: 'idle' | 'loading' | 'failed' | 'success';
  statusMessage: string;
  answers: SimplePatOption[];
  questions: any[];
  collaborators: any[];
  currentQuestionIndex: number;
  isComplete: boolean;
};

type SimplePatOption = {
  competency: number;
  pat_option?: number;
  notes?: string;
}

const initialState: PatState = {
  _id: null,
  value: null,
  title: '',
  type: PatTypes.blueAgilis,
  tags: [],
  status: 'idle',
  statusMessage: '',
  answers: [], // a simplified combination of pat_option and competency_notes
  questions: [] as any[],
  collaborators: [] as any[], // no reducers for collaborators, this is just for display. business logic is in AssesmentTool.jsx
  currentQuestionIndex: 0,
  isComplete: false,
};

const allQuestionsAnswered = (answers: SimplePatOption[], numQuestions: number):boolean =>  {
  const answersWithPatOption = answers.filter(answer => answer.pat_option);
  return answersWithPatOption.length >= numQuestions;
}

/**
 * Given the PAT options payload, simplify the data structure for the store.
 */
const simplifyAnswers = (pat_options: any[], competency_notes: any[]) => {
  
  /** 
   * define answers as the array of each pat_options's 'pat_option'. use lodash.
   * from the resulting object, the only needed keys are 'pat_option' (currently 'id')
   * and 'pat_competency' (currently 'competency').
   */
  const answers: SimplePatOption[] = _.chain(pat_options)
    .map('pat_option')
    .map(item => ({
      pat_option: item.id,
      competency: item.competency
    }))
    .value();

  // define notes as competency_notes, but with each competency_note's 'competency' as what used to be 'pat_competency'
  const notes: SimplePatOption[] = _.chain(competency_notes)
    .map(item => ({
      notes: item.notes,
      competency: Number(item.pat_competency)
    }))
    .value();

  const answersNotesUnion: SimplePatOption[] = _.chain(answers)
    .concat(notes)
    .groupBy('competency')
    .map(group => _.merge({}, ...group))
    .value();

  return answersNotesUnion;
};

const simplifyOptions = (options: any[]) => {
  const simplifiedOptions = options.map((option: any) => {
    return _.omit(option, ['order', 'phase']);
  });
  return simplifiedOptions;
};

const simplifyQuestions = (categories: any[]) => {
  //using lodash, create a flat array of questions. the name from each category should be included in each question as category_name. use chain if necessary.
  const questions = _.chain(categories)
    .map((category) => {
      return category.pat_competencies.map((competency: any) => {
        return {
          id: competency.id,
          title: competency.title,
          description: competency.description,
          options: simplifyOptions(competency.pat_options),
          category_name: category.name,
        }
      });
    })
    .flatten()
    .value();
  return questions;
};

const updateAnswerInSlice = (answers :any[], { payload }: any) => {
  const updatedAnswers = answers.map(answer => {
    if (answer.competency === payload.competency) {
      return { ...answer, pat_option: payload.pat_option };
    }
    return answer;
  });

  // If the answer wasn't found, add a new one
  if (!updatedAnswers.some(answer => answer.competency === payload.competency)) {
    updatedAnswers.push({ competency: payload.competency, pat_option: payload.pat_option });
  }

  return updatedAnswers;
};


const updateAnswerNoteInSlice = (answers: any[], {payload}: any) => {
  const updatedAnswers = answers.map(answer => {
    if (answer.competency === payload.competency) {
      return { ...answer, notes: payload.notes };
    }
    return answer;
  });

  if (!updatedAnswers.some(answer => answer.competency === payload.competency)) {
    updatedAnswers.push({ competency: payload.competency, notes: payload.notes });
  }

  return updatedAnswers;
};


export const fetchPatById = createAsyncThunk(
  'pat/fetchPatById',
  async (id: number) => {
    try {
      const res = await getUsersPatDetail(id).call;
      return res.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const fetchCollaboratorsByPatId = createAsyncThunk(
  'pat/fetchCollaboratorsByPatId',
  async (id: number) => {
    try {
      const res = await getCollaborators(id).call;
      return res.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const fetchPatCategories = createAsyncThunk(
  'pat/fetchPatCategories',
  async (type:PatTypes = PatTypes.blueAgilis) => {
    try {
      const res = await getPatCategories(type).call;
      return res.data;
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const deleteCollaboratorById = createAsyncThunk(
  'pat/deleteCollaboratorById',
  async (id: number) => {
    try {
      await deleteCollaborator(id).call;
      return {id: id};
    } catch (error) {
      console.error('error', error);
    }
  }
);

export const createOrUpdatePat = createAsyncThunk(
  'pat/createOrUpdatePat',
  async (status: string, {getState}) => {
    try {
      //@ts-ignore
      const stateAnswers: any = getState()?.pat?.answers;
      //@ts-ignore
      const stateTitle: string = getState()?.pat?.title;
      //@ts-ignore
      const stateId: number = getState()?.pat?._id;
      //@ts-ignore
      const networkId: string = getState()?.network?.value;
      //@ts-ignore
      const patType: string = getState()?.pat?.type;
      //@ts-ignore
      const patTags: Tag[] = getState()?.pat?.tags;
      
      /**
       * define patOptions. using lodash, chain on stateAnswers. remove competency and notes from each answer, then return all non-empty objects.
       */
      const patOptions = _.chain(stateAnswers)
        .map((answer: any) => _.omit(answer, ['competency', 'notes']))
        .filter((answer: any) => answer.pat_option)
        .value();

      const competencyNotes = _.chain(stateAnswers)
        .filter((answer: any) => answer.notes)
        .map((answer: any) => {
          return {
            notes: answer.notes,
            pat_competency: answer.competency,
          }
        })
        .value();
      
      const payload = {
        'id': stateId,
        'type': patType,
        'organization': networkId,
        'title': stateTitle && stateTitle.trim() !== '' ? stateTitle : 'Untitled',
        'status' : status ?? PatStatus.IN_PROGRESS,
        'pat_options' : patOptions,
        'competency_notes' : competencyNotes,
        'tags': patTags
      };
      if (stateId) {
        //@ts-ignore
        const res = await patchUsersPat(payload).call;
        return {
          id: res.data.id,
          message: status == PatStatus.COMPLETED ? MESSAGES.COMPLETED_SAVED : MESSAGES.DRAFT_SAVED,
        };
      } else {
        const res = await postUsersPat(payload).call;
        return {
          id: res.data.id,
          message: status == PatStatus.COMPLETED ? MESSAGES.COMPLETED_SAVED : MESSAGES.DRAFT_SAVED,
        };
      }

    } catch (error) {
      console.error('error', error);
    }
  }
);


export const patSlice = createSlice({
  name: 'pat',
  initialState,
  reducers: {
    selectPat: (state, { payload }) => {
      return {
        ...state,
        value: payload,
      };
    },
    cleanPat: () => {
      return {...initialState};
    },
    cleanStatusMessage: (state) => {
      return {
        ...state,
        status: 'idle',
        statusMessage: '',
      }
    },
    updateAnswer: (state, { payload }) => {
      const updatedAnswers = updateAnswerInSlice(state.answers, { payload });
      return {
        ...state,
        answers: updatedAnswers,
        isComplete: allQuestionsAnswered(updatedAnswers, state.questions.length),
      }
    },
    updateAnswerNote: (state, { payload }) => {
      return {
        ...state,
        answers: updateAnswerNoteInSlice(state.answers, { payload })
      }
    },
    updatePatTitle: (state, { payload }) => {
      return {
        ...state,
        title: payload,
      };
    },
    updatePatTags: (state, { payload }) => {
      return {
        ...state,
        tags: payload,
      };
    },
    updatePatType: (state, { payload }) => {
      return {
        ...state,
        type: payload,
      };
    },
    selectNextQuestion(state) {
      if(state.currentQuestionIndex < state.questions.length - 1) {
        state.currentQuestionIndex++;
      }
    },
    selectPrevQuestion(state) {
      if(state.currentQuestionIndex > 0) {
        state.currentQuestionIndex--;
      }
    },    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPatById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPatById.fulfilled, (state, { payload }) => {
        if(!_.isEmpty(payload)) {
          const allAnswers = simplifyAnswers(payload?.pat_options, payload?.competency_notes);
          return {
            ...state,
            status: 'success',
            _id: payload?.id,
            value: (payload.status == PatStatus.IN_PROGRESS) ? null : payload?.id, // if there's a value here, the pft screen will load
            title: payload?.title,
            tags:convertTagsToStringArray(payload?.tags ?? []),
            type: payload?.type?.code,
            answers: allAnswers,
            isComplete: allQuestionsAnswered(allAnswers, state.questions.length)
          };
        }
      });

    builder
      .addCase(fetchPatCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPatCategories.fulfilled, (state, { payload }) => {
        if(!_.isEmpty(payload)) {
          const allQuestions = simplifyQuestions(payload ?? []);
          return {
            ...state,
            status: 'success',
            questions: allQuestions,
            isComplete: allQuestionsAnswered(state.answers, allQuestions.length)
          };
        }
      });

    builder
      .addCase(fetchCollaboratorsByPatId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollaboratorsByPatId.fulfilled, (state, { payload }) => {
        if(!_.isEmpty(payload)) {
          return {
            ...state,
            status: 'success',
            collaborators: payload?.results ?? [],
          };
        }
      });

    builder
      .addCase(createOrUpdatePat.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createOrUpdatePat.fulfilled, (state, { payload }) => {
        if(!_.isEmpty(payload)) {
          return {
            ...state,
            _id: payload?.id,
            status: 'success',
            statusMessage: payload?.message,
          }
        }
      });

    builder
      .addCase(deleteCollaboratorById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCollaboratorById.fulfilled, (state, { payload }) => {
        if(!_.isEmpty(payload)) {
          return {
            ...state,
            status: 'success',
            statusMessage: MESSAGES.COLLABORATOR_DELETED,
            collaborators: state.collaborators.filter((collaborator) => collaborator.id !== payload.id),
          }
        }
      });
  },
});

export const { 
  selectPat, 
  cleanPat,
  cleanStatusMessage,
  selectNextQuestion,
  selectPrevQuestion,
  updateAnswer,
  updateAnswerNote,
  updatePatTitle,
  updatePatType,
  updatePatTags,
} = patSlice.actions;
