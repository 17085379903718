import React from "react"
import { Input, Slider } from "antd"

import { marks } from "./marks"

import { useTranslation } from "react-i18next"

/**
 * Renders the feedback step 5 component based on the provided values.
 *
 * @param {Object} step1Value - The value of step 1.
 * @param {string} step5BugValue - The value of step 5 bug.
 * @param {function} setStep5BugValue - The function to set the value of step 5 bug.
 * @param {string} step5FeatureValue - The value of step 5 feature.
 * @param {function} setStep5FeatureValue - The function to set the value of step 5 feature.
 * @param {number} step5RequestValue - The value of step 5 request.
 * @param {function} setStep5RequestValue - The function to set the value of step 5 request.
 * @return {JSX.Element} The rendered feedback step 5 component.
 */
const FeedbackStep5 = ({
  step1Value,
  step5BugValue,
  setStep5BugValue,
  step5FeatureValue,
  setStep5FeatureValue,
  step5RequestValue,
  setStep5RequestValue,
}) => {

  const [t] = useTranslation()

  return (
    <>
      {step1Value === "bug" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step5.Bug_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step5.Bug_message')}</p>
          <Input allowClear size="small" value={step5BugValue} onChange={(e) => setStep5BugValue(e.target.value)} />
        </>
      )}
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step5.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step5.Feature_message')}</p>
          <Input allowClear required size="small" value={step5FeatureValue} onChange={(e) => setStep5FeatureValue(e.target.value)} />
        </>
      )}
      {step1Value === "request" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step5.Request_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step5.Request_message')}</p>
          <Slider marks={marks} defaultValue={5} min={1} max={5} step={1}
            value={step5RequestValue}
            onChange={(value) => setStep5RequestValue(value)}
          />
        </>
      )}
    </>
  )
}

export default FeedbackStep5