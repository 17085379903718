import React from "react"
import { Slider } from "antd"

import { marks } from "./marks"

import { useTranslation } from "react-i18next"

/**
 * Generates a feedback step 7 component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.step1Value - The value of step 1.
 * @param {number} props.step7FeatureValue - The value of step 7 feature.
 * @param {Function} props.setStep7FeatureValue - The function to set the value of step 7 feature.
 * @return {JSX.Element} The feedback step 7 component.
 */
const FeedbackStep7 = ({
  step1Value,
  step7FeatureValue,
  setStep7FeatureValue,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <>
      {step1Value === "feature" && (
        <>
          <h2 className="mb-10">{t('Feedback_Modal.Step7.Feature_header')}</h2>
          <p className="mb-10">{t('Feedback_Modal.Step7.Feature_message')}</p>
          <Slider marks={marks} defaultValue={5} min={1} max={5} step={1}
            value={step7FeatureValue}
            onChange={(value) => setStep7FeatureValue(value)}
          />
        </>
      )}
    </>
  )
}

export default FeedbackStep7