import axios, { AxiosResponse } from "axios"
import { API_BASE_URL } from "../../config/constants"
import { Feedback } from "types/Feedback"

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  }
}

export const postFeedback = (_data: any): {
  call: Promise<AxiosResponse<Feedback>>;
} => {
  const data = new FormData()
  for (const key in _data)
    data.append(key, _data[key])
  return {
    call: axios.post(`${API_BASE_URL}feedback/`, data, headers()),
  }
}