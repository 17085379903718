export const teamInfoGetDepartmentStarted = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      idDepartmentLoading: true,
      error: false,
    },
  };
};

export const teamInfoGetDepartmentSuccess = (state, payload) => {
  return {
    ...state,
    teamInfo: {
      departmentList: payload,
      isDepartmentLoading: false,
      error: false,
    },
  };
};

export const teamInfoGetDepartmentFailed = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      isDepartmentLoading: false,
      error: true,
    },
  };
};

export const teamInfoGetDesignationStarted = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: state.teamInfo.desigationList,
      isDepartmentLoading: false,
      error: false,
    },
  };
};

export const teamInfoGetDesignationSuccess = (state, payload) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: payload,
      isDepartmentLoading: false,
      error: false,
    },
  };
};

export const teamInfoGetDesignationFailed = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: state.teamInfo.desigationList,
      isDepartmentLoading: false,
      error: true,
    },
  };
};

export const teamInfoGetEmployeeStarted = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: state.teamInfo.designationList,
      employeeList: state.teamInfo.employeeList,
      isDepartmentLoading: false,
      error: false,
    },
  };
};

export const teamInfoGetEmployeeSuccess = (state, payload) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: state.teamInfo.designationList,
      employeeList: payload,
      isDepartmentLoading: false,
      error: false,
    },
  };
};

export const teamInfoGetEmployeeFailed = (state) => {
  return {
    ...state,
    teamInfo: {
      departmentList: state.teamInfo.departmentList,
      designationList: state.teamInfo.designationList,
      employeeList: state.teamInfo.employeeList,
      isDepartmentLoading: false,
      error: true,
    },
  };
};

export const getTeamDetailsStart = (state) => {
  return {
    ...state,
    missionTeamDetails: [],
  };
};

export const getTeamDetailsSuccess = (state, payload) => {
  let response =
    payload && payload[0] && payload[0].payload
      ? JSON.parse(payload[0].payload)
      : null;
  return {
    ...state,
    missionTeamDetails: response?.teamInfoTeam,
  };
};

export const getTeamDetailsFailed = (state) => {
  return {
    ...state,
  };
};

export const addNewMemberToList = (state, payload) => {
  if (!state.missionTeamDetails || state.missionTeamDetails.length === 0) {
    return {
      ...state,
      // missionTeamDetails: [
      //   {
      //     ...payload,
      //   },
      // ],
      newTeamMembers: [...state.newTeamMembers, payload],
    };
  } else {
    if (payload.isupdate && payload.seg) {
      state.missionTeamDetails[payload.id].segregation.checked =
        payload.segregation;
    }
    if (payload.isupdate && payload.imp) {
      state.missionTeamDetails[payload.id][5].checked = payload.impact;
    }
    // let selectedList = state.missionTeamDetails[payload.id];
    return {
      ...state,
      missionTeamDetails: [...state.missionTeamDetails],
      newTeamMembers: [...state.newTeamMembers],
    };
  }
};
