import React from 'react';
import {Modal} from 'antd';

type ModalProps = {
  cancelText: string;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
  okText: string;
  title: string;
  children?: React.ReactNode;
  width?: number | string;
};

export const BaseModal: React.FC<ModalProps> = ({
  cancelText,
  isOpen,
  onCancel,
  onOk,
  okText,
  title,
  children,
  width,
}) => {
  return (
    <Modal
      wrapClassName='rounded-2xl'
      open={isOpen}
      onCancel={onCancel}
      footer={false}
      width={width ?? 550}>
      <div className='grid gap-4 mt-4'>
        <h1 className='text-4xl leading-tight px-8'>{title}</h1>
        <div className='text-black px-8'>{children}</div>
        <div className='flex justify-end pt-5 gap-2'>
          <button
            type='button'
            onClick={onOk}
            className='px-4 py-2 text-base font-medium self-center'>
            {okText}
          </button>
          <button
            type='button'
            onClick={onCancel}
            className={`px-4 py-2 text-base font-medium bg-primary text-white rounded 
              hover:bg-primary-hover`}>
            {cancelText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
