import React, { Component } from 'react';
import style from './style.module.scss';
class PageLoader extends Component {
  render() {
    return (
      <div className={style.pageLoader} >
        <div className={style.pageLoader__inner}>
          <span className={style.loading} />
        </div>
      </div>
    );
  }
}
export default PageLoader;
