import {Col, Row, Tooltip} from 'antd';
import classNames from 'classnames';
import React, {Component} from 'react';
import {BiLock} from 'react-icons/bi';
import {withRouter} from 'react-router-dom';
import {Breadcrumbs} from '../../Components/Breadcrumbs';
import SVGIcons from '../../Components/SVGIcons';
import {makeWebUrl, parseWebUrl} from '../../utils/queryStringUtils';
import OrganisationProfile from './Children/ViewDetails/Children/OrganisationProfile';
import {PAGE_BREADCRUMB, STEPS} from './constants';
import style from './style.module.scss';
import ItemTab from './Components/ItemTab/ItemTab';
import {Provider} from 'react-redux';
import ReactGA from 'react-ga';
import store from '../../ReduxV2/store';

class OrganisationInsights extends Component {
  constructor(props) {
    super(props);
    let data;
    this.state = {
      isViewEmployees: false,
      currentPage: 1,
      showAddMissionModal: false,
      activeStep: this.getCurrentTabId(),
      employeePageLimit: 6,
      departmentPageLimit: 6,
      designationPageLimit: 3,
      showForm: false,
    };
    if (this.props.location.data) {
      data = this.props.location.data;
      localStorage.setItem(
        'pageUrlData',
        JSON.stringify(this.props.location.data)
      );
    } else data = JSON.parse(localStorage.getItem('pageUrlData'));

    this.datatoRender = data;
  }
  componentWillMount() {
    ReactGA.pageview(window.location.pathname);
    let {params} = this.props.match;
    this.props.fetchOrganisationAction(params.id);
    localStorage.setItem('organizationId', params.id);
  }

  componentDidUpdate(prevProps) {
    const {params} = this.props.match;
    // Check if the relevant prop has changed
    if (prevProps) {
      if (prevProps.match.params.id !== params.id) {
        ReactGA.pageview(window.location.pathname);
        // Perform actions or state updates based on the prop change
        this.props.fetchOrganisationAction(params.id);
        localStorage.setItem('organizationId', params.id);
      }
    }
  }

  getCurrentTabId = () => parseWebUrl().tab || STEPS[0].id;

  getCurrentTab = () => STEPS.find((step) => step.id == this.getCurrentTabId());

  getOrganisationData = (organisationData) => {
    return (
      <>
        <li>
          <Tooltip title='Official Website'>
            <span>
              <SVGIcons type={'SVG-web'} />
            </span>
          </Tooltip>
          <span>{organisationData.website}</span>
        </li>
        <li>
          <Tooltip title='Contact Phone'>
            <span>
              <SVGIcons type={'SVG-phone'} />
            </span>
          </Tooltip>
          <span>{organisationData.phone}</span>
        </li>
        <li>
          <Tooltip title='Contact Email'>
            <span>
              <SVGIcons type={'SVG-email'} />
            </span>
          </Tooltip>
          <span>{organisationData.contact_email}</span>
        </li>
      </>
    );
  };

  onSelectStep = (step) => {
    this.setState({activeStep: step.id, showForm: false});
    const params = parseWebUrl();
    params.tab = step.id;
    let URL = window.location.pathname + makeWebUrl(params);
    ReactGA.pageview(URL);
    if(step?.label === 'Projects') URL += '&refresh=true'
    this.props.history.push(URL);
  };

  getSidebarSection = (item, index) => {
    if (item.label === 'Sprint') {
      return (
        <li
          style={
            this.getCurrentTabId() == item.id
              ? {backgroundColor: '#f5f5f5'}
              : null
          }
          onClick={() => {
            this.onSelectStep(item);
            const params = parseWebUrl();
            params.tab = item.id;
            const URL = window.location.pathname + makeWebUrl(params);
            // const URL =  `/view-sprint-organization/${localStorage.getItem(
            //     'organizationId'
            //   )}/?orgName=${parseWebUrl().orgName}`

            this.props.history.push({
              pathname: URL,
              data: localStorage.getItem('organizationId'),
            });
          }}
          className={item?.bgColor === true ? style.bg__orange : ''}>
          <a
            className={classNames({
              [style.current]: this.getCurrentTabId() == item.id,
            })}>
            <span>
              <span>
                <svg viewBox='0 0 13.823 13.824'>
                  <path
                    data-name='Path 294'
                    d='M-1975,13.824v-5.8h5.8V9.81h1.561a4.017,4.017,0,0,0,4.013-4.013,4.017,4.017,0,0,0-4.013-4.013,4.017,4.017,0,0,0-4.007,3.79h.692l-1.59,1.909-1.591-1.909h.7A5.8,5.8,0,0,1-1967.642,0a5.8,5.8,0,0,1,5.8,5.8,5.806,5.806,0,0,1-3.568,5.351h1.561v-.892h2.676v3.121h-2.676v-.892h-5.35v1.338Zm.445-.446h4.905V8.
                    473h-4.905Zm11.148-.446h1.784V10.7h-1.784Zm-5.8-.892h5.35v-.446h-5.35Zm0-.892h1.561a5.357,5.357,0,0,0,5.35-5.351,5.357,5.357,0,0,0-5.35
                    -5.351,5.358,5.358,0,0,0-5.351,5.351V6.02h-.193l.639.767.638-.767h-.193V5.8a4.465,4.465,0,0,1,4.46-4.46,4.464,4.464,0,0,1,4.458,4.46,4.464,4.464,0,0,1-4.458,4.459h-1.561Zm-1.338,1.784v-.446h.446v.446Zm-.892,0v-.446h.446v.446Zm-2.675,0v-.446h2.229v.446Zm11.147-.446V12.04h.892v.446Zm-11.147-.446v-.
                    446h4.013v.446Zm11.147-.446v-.446h.892v.446Zm-11.147-.
                    446V10.7h4.013v.446Zm0-.892V9.81h4.013v.446Zm1.783-.892V8.
                    918h2.23v.446Zm-.892,0V8.918h.446v.446Zm-.891,0V8.918h.446v.
                    446Zm4.682-3.568V5.574h2.229V3.344h.223a2.456,2.456,0,0,1,2.452,2.453,2.455,2.455,0,0,1-2.452,2.452A2.455,2.455,0,0,1-1969.
                    426,5.8Zm2.453,2.007a2.009,2.009,0,0,0,1.993-1.784h-3.988A2.01,2.01,0,0,0-1966.973,7.8Zm.223-2.23h1.77a2.012,2.012,0,0,0-1.77-1.771Zm.446,1.338V6.466h.446v.446Zm-.892,0V6.466h.446v.446Zm-.892,0V6.466h.446v.446Zm-2.229-1.784V4.905a2.456,2.456,0,0,1,2.453-2.453h.223V5.128Zm.458-.446h1.771V2.911A2.012,2.012,0,0,0-1969.86,4.682Z'
                    transform='translate(1975)'
                  />
                </svg>
              </span>
            </span>
            <span>{item.label}</span>
          </a>
        </li>
      );
    } else if (item.hide) {
      return;
    } else {
      return (
        <li
          data-testid="sectionItemHyperlink"
          // className={`${item?.bgColor === true && style.bg__orange} ${
          //   item?.locked === true && style.bg_color_active
          // }`}
          className={`${
            this.getCurrentTabId() == item.id &&
            'bg-black text-white rounded-lg'
          }`}>
          {item.id == '9' || item.id == '12' || item.id == '1' ? (
            <Provider store={store}>
              <ItemTab
                item={item}
                active={this.getCurrentTabId() == item.id}
                onSelectStep={this.onSelectStep}
              />
            </Provider>
          ) : (
            <a
              onClick={() => item?.locked ?? this.onSelectStep(item)}
              className={`${
                this.getCurrentTabId() == item.id && 'text-white rounded-lg'
              }`}>
              <span>{item.icon}</span>
              <span
                className={`${
                  this.getCurrentTabId() == item.id && 'text-white'
                }`}>
                {item.label} &nbsp;{' '}
                {item?.locked && (
                  <BiLock
                    className={style.float_right}
                    size='1.3rem'
                    // color='black'
                  />
                )}
              </span>
            </a>
          )}
        </li>
      );
    }
  };
  /* eslint-disable */

  setShowForm = (val) => {
    this.setState({showForm: val});
  };
  renderStepContents = (step) => (
    <div>
      <step.content
        currentState={this.props.viewMissionDetails}
        saveMission={this.props.saveMission}
        getMissionDetails={this.props.getMissionDetails}
        updateMission={this.props.updateMission}
        addInsightsForm={this.props.addInsightsForm}
        getDepartments={this.props.getDepartments}
        getDesignations={this.props.getDesignations}
        designationList={this.props.designationList}
        departmentList={this.props.departmentList}
        addInsightsFormChange={this.props.addInsightsFormChange}
        addEmployees={this.props.addEmployees}
        removeEmployees={this.props.removeEmployees}
        resetEmployeeForm={this.props.resetEmployeeForm}
        fetchEmployeesList={this.props.fetchEmployeesList}
        employeesList={this.props.employeesList}
        addDesignationAction={this.props.addDesignationAction}
        addDepartmentAction={this.props.addDepartmentAction}
        employeePageLimit={this.state.employeePageLimit}
        departmentPageLimit={this.state.departmentPageLimit}
        designationPageLimit={this.state.designationPageLimit}
        addNotes={this.props.addNotes}
        getNotes={this.props.getNotes}
        notesList={this.props.notesList}
        addNotesApi={this.props.addNotesApi}
        getNotesApi={this.props.getNotesApi}
        updateNotes={this.props.updateNotes}
        totalNotesCount={this.props.totalNotesCount}
        getPresignedUrl={this.props.getPresignedUrl}
        uploadFile={this.props.uploadFile}
        integrateApi={this.props.integrateApi}
        deleteDepartment={this.props.deleteDepartment}
        deleteNotes={this.props.deleteNotes}
        showForm={this.state.showForm}
        setShowForm={this.setShowForm}
        deleteSummary={this.props.deleteSummary}
        deleteSummarystate={this.props.deleteSummarystate}
        getSprints={this.props.getSprints}
        isProcessing={false}
        sprintList={this.props.sprintList}
        updateSprintApi={this.props.updateSprintApi}
        deleteSprints={this.props.deleteSprints}
        updateSprint={this.props.updateSprint}
        {...this.props}
      />
    </div>
  );
  renderInsightHeading = (organisationName) => (
    <div className={style.insightsAside__header}>
      <h3>Network Insights</h3>
      <h4>{organisationName}</h4>
    </div>
  );
  renderBreadCrumbs = (organisationName) => (
    <Breadcrumbs data={PAGE_BREADCRUMB(this.datatoRender, organisationName)} />
  );

  render() {
    const organisationName = parseWebUrl().orgName;
    let {organisationData} = this.props;
    const step = this.getCurrentTab();

    return (
      <section className={style.organisationInsights}>
        <div className={style.insightsContent}>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <div className='flex flex-col border-solid border-2 border-gray-400 py-9 px-7 mx-5 my-7 rounded-lg'>
                <div className='flex flex-col lg:flex-row items-center justify-center text-center lg:justify-between lg:text-start gap-8'>
                  <img
                    className='w-[76px] rounded-lg'
                    src={
                      organisationData ? organisationData.profile_image_url : ''
                    }></img>
                  <h2>
                    {organisationData
                      ? organisationData.name
                      : 'Unknown Network'}
                  </h2>
                </div>
                <div className='mt-8'>
                  <ul className={style.insightsAside__list}>
                    {STEPS.map(this.getSidebarSection)}
                  </ul>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={17}
              lg={17}
              xl={17}
              className='overflow-x-hidden'>
              <div className='mt-15'>{this.renderStepContents(step)}</div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default withRouter(OrganisationInsights);
