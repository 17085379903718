import axios from 'axios';
import { API_BASE_URL } from '../../../../config/constants';

export const GetAllPopulateAllTestData = (data) => {
  return {
    type: 'getallpopulatealltestdata',
    payload: data,
    loader: false,
  };
};

export const GetDraftPopulateAllTestData = (data) => {
  return {
    type: 'getdraftpopulatealltestdata',
    payload: data,
    loader: false,
  }
}

export const getallpopulatealltest = () => {
  return (dispatch) => {
    axios({
      url: `${API_BASE_URL}toolbox/results/`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        'content-type': 'application/json',
      },
    }).then(
      (result) => {
        dispatch(GetAllPopulateAllTestData(result));
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const getAllAssessmentDraftData = () => {
  return (dispatch) => {
    axios({
      url: `${API_BASE_URL}toolbox/drafts/`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('idToken')}`,
        'content-type': 'application/json',
      },
    }).then(
      (result) => {
        dispatch(GetDraftPopulateAllTestData(result));
      },
      (error) => {
      }
    );
    //  dispatch(GetDraftPopulateAllTestData());
  }
}
