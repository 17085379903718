import {
  GET_MISSION_HYPOTHESIS_FAILED,
  GET_MISSION_HYPOTHESIS_START,
  GET_MISSION_HYPOTHESIS_SUCCESS,
  GET_SPRINTS_FAILED,
  GET_SPRINTS_SUCCESS,
  GET_SPRINTS_START,
  ADD_SPRINT_FAILED,
  ADD_SPRINT_SUCCESS,
  ADD_SPRINT_START,
  GET_SPRINT_BY_ID_FAILED,
  GET_SPRINT_BY_ID_SUCCESS,
  GET_SPRINT_BY_ID_START,
  UPDATE_SPRINT_START,
  UPDATE_SPRINT_FAILED,
  UPDATE_SPRINT_SUCCESS,
  DELETE_SPRINT_START,
  DELETE_SPRINT_FAILED,
  DELETE_SPRINT_SUCCESS,
  ADD_SPRINT_VALIDATION_ERRORS,
  UPDATE_SPRINT_ERROR_MESSAGE,
} from '../../Redux.constants';
import {
  getMissionHypothesisFailed,
  getMissionHypothesisStart,
  getMissionHypothesisSuccess,
  getSprintsFailed,
  getSprintsStart,
  getSprintsSuccess,
  addSprintsFailed,
  addSprintsStart,
  addSprintsSuccess,
  getSprintByIdFailed,
  getSprintByIdStart,
  getSprintByIdSuccess,
  updateSprintFailed,
  updateSprintStart,
  updateSprintSuccess,
  deleteSprintStart,
  deleteSprintSuccess,
  deleteSprintFailed,
  addSprintValidationErrors,
  updateSprintErrorMessage,
} from './Helper';

const INITIAL_STATE = {
  missionHypothesis: {
    isProcessing: null,
    success: null,
    error: null,
  },
  sprints: {
    isProcessing: null,
    success: null,
    error: null,
  },
  addSprintApi: {
    isProcessing: null,
    success: null,
    error: null,
  },
  sprintById: {
    isProcessing: null,
    success: null,
    error: null,
    data: null,
  },
  updateSprintApi: {
    isProcessing: false,
    success: null,
    error: null,
  },
  deleteSprints: {
    isProcessing: false,
    error: null,
    success: null,
  },
  hypothesisList: [],
  sprintList: [],
  limit: 11,
  totalSprintCount: null,
  name: {
    name: 'name',
    errorMessage: '',
  },
  start_date: {
    name: 'start_date',
    errorMessage: ' ',
  },
  end_date: {
    name: 'end_date',
    errorMessage: ' ',
  },
  max_number_of_cadence: {
    name: 'max_number_of_cadence',
    errorMessage: '',
  },
  sprint_frequency: {
    name: 'sprint_frequency',
    errorMessage: '',
  },
  hypothesis: {
    name: 'hypothesis',
    errorMessage: '',
  },
  isProcessingLoader: null,
  sprintCount: '',
};

/*eslint-disable*/
export default function ViewSprintReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MISSION_HYPOTHESIS_START:
      return getMissionHypothesisStart(state);
    case GET_MISSION_HYPOTHESIS_SUCCESS:
      return getMissionHypothesisSuccess(state, action.payload);
    case GET_MISSION_HYPOTHESIS_FAILED:
      return getMissionHypothesisFailed(state, action.payload);
    case GET_SPRINTS_START:
      return getSprintsStart(state);
    case GET_SPRINTS_SUCCESS:
      return getSprintsSuccess(state, action.payload);
    case GET_SPRINTS_FAILED:
      return getSprintsFailed(state, action.payload);
    case ADD_SPRINT_START:
      return addSprintsStart(state);
    case ADD_SPRINT_SUCCESS:
      return addSprintsSuccess(state, action.payload);
    case UPDATE_SPRINT_ERROR_MESSAGE:
      return updateSprintErrorMessage(state, action.payload);
    case ADD_SPRINT_VALIDATION_ERRORS:
      return addSprintValidationErrors(state, action.payload);
    case ADD_SPRINT_FAILED:
      return addSprintsFailed(state, action.payload);
    case GET_SPRINT_BY_ID_START:
      return getSprintByIdStart(state);
    case GET_SPRINT_BY_ID_SUCCESS:
      return getSprintByIdSuccess(state, action.payload);
    case GET_SPRINT_BY_ID_FAILED:
      return getSprintByIdFailed(state, action.payload);
    case UPDATE_SPRINT_START:
      return updateSprintStart(state);
    case UPDATE_SPRINT_SUCCESS:
      return updateSprintSuccess(state, action.payload);
    case UPDATE_SPRINT_FAILED:
      return updateSprintFailed(state, action.payload);
    case DELETE_SPRINT_START:
      return deleteSprintStart(state);
    case DELETE_SPRINT_SUCCESS:
      return deleteSprintSuccess(state);
    case DELETE_SPRINT_FAILED:
      return deleteSprintFailed(state);
    default:
      return {...state};
  }
}
