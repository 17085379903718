import React from 'react';
import { Provider } from 'react-redux';
import { Intel } from '../../../Intel'
import store from '../../../../ReduxV2/store';
import './styles/IntelNetwork.scss';

/**
 * Creates an IntelNetwork component and wraps it with the Redux Provider component, passing in the store prop.
 *
 * @return {React.Component} The rendered IntelNetwork component.
 */
const IntelNetwork = () => {
  return <Provider store={store}><Intel /></Provider>;
};

export default IntelNetwork;
