/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import store from '../../../../ReduxV2/store';
import { Teams } from '../../../Teams';

/* eslint-disable */
import { Provider, useSelector } from 'react-redux';


function Emersion({
  currentState,
  updateState,
  updateApi,
  getMissionDetailsApi,
  getMissionDetails,
  match,
  updateMission,
  getPresignedUrl,
  uploadFile,
  integrateApi,
  saveMission,
}) {
  const [props, setProps] = useState();

  const data = useSelector((state) => state.viewMissionDetails);
  const data1 = useSelector((state) => state.ViewMissionReducer);
  useEffect(() => {
    if (data1?.viewMissionDetails?.emersion) {
      setProps({ ...props, persona: data1?.viewMissionDetails?.emersion });
    }
  }, [data1]);


  return (
    <Provider store={store}><Teams /></Provider>
  );
}
export default withRouter(Emersion);
