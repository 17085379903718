import React from 'react';
import {Collapse} from 'antd';
import {General, Huddles, MVS, Personas} from '../Planning/components';
import {CreateWorkflow} from 'Components/organisms/form/sprint/CreateWorkflow';
import './styles/Planning.scss';

const {Panel} = Collapse;

/**
 * Renders the Planning component.
 *
 * @return {JSX.Element} The rendered Planning component.
 */
const Planning = () => {
  return (
    <div className='planning'>
      <div className='my-15'>
        <Collapse
          expandIconPosition='end'
          defaultActiveKey={['1', '2', '3', '4', '5']}>
          <Panel header='Overview' key='1'>
            <General />
          </Panel>
          <Panel header='Personas and Archetypes' key='2'>
            <Personas />
          </Panel>
          <Panel header='Minimal Viable Solution' key='3'>
            <CreateWorkflow />
            <MVS />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Planning;
