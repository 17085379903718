import { connect } from 'react-redux';
import { inputFormChange } from '../../Redux/Actions';
import { SelectState } from './Selector';
import MainFrame from './MainFrame';
const mapStateToProps = state => {
    return { ...SelectState(state) };
};

const mapDispatchToProps = dispatch => {
    return {
        formChange: params => {
            dispatch(inputFormChange(params));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainFrame);
