import {ApiBase} from '../../../utils/apiBase';
import {
  UPDATE_MISSION_DETAILS_FAILED,
  UPDATE_MISSION_DETAILS_START,
  UPDATE_MISSION_DETAILS_SUCCESS,
  FETCH_MISSION_DETAILS_FAILED,
  FETCH_MISSION_DETAILS_SUCCESS,
  FETCH_MISSION_DETAILS_START,
  ADD_NEW_MEMBER_TO_LIST,
} from '../../Redux.constants';
import {getNotification} from '../../../Components/GetNotification';
export * from './Helper/TeamInfoActionHelper';

function saveMissionStart(dispatch) {
  dispatch({
    type: UPDATE_MISSION_DETAILS_START,
    payload: {},
  });
}
/* eslint-disable   max-lines-per-function */
export function saveMission(payload, callback) {
  let requestBody = {
    payload: JSON.stringify(payload.payload),
  };
  if (payload.endPoint === 'persona') {
    let formData = new FormData();
    for (const [key, value] of Object.entries(payload.persona)) {
      formData.append(key.toString(), value);
    }
    formData.append('organization', localStorage.getItem('organizationId'));
    requestBody = formData;
  }

  return async (dispatch) => {
    saveMissionStart(dispatch);
    const apiBaseInstance = new ApiBase();
    const url =
      payload.endPoint === 'persona'
        ? `/organization-persona/`
        : `/mission/${payload.missionId}/${payload.endPoint}/`;
    apiBaseInstance.instance
      .post(url, requestBody, {
        headers: {
          'Content-Type':
            payload.endPoint === 'persona'
              ? 'multipart/form-data'
              : 'application/json',
        },
      })
      .then((response) => {
        dispatch({
          type: UPDATE_MISSION_DETAILS_SUCCESS,
          payload: {
            data: response.data,
            objectives: payload.payload?.objectives || null,
          },
        });
        callback && callback(true);
      })
      .catch((e) => {
        dispatch({
          type: UPDATE_MISSION_DETAILS_FAILED,
          payload: {error: e},
        });
        showErrorStatus('Failed to update project details');
        callback && callback(false);
      });
  };
}

export function getMissionDetails(payload) {
  return async (dispatch) => {
    dispatch({
      type: FETCH_MISSION_DETAILS_START,
      payload: {},
    });
    const apiBaseInstance = new ApiBase();
    /*eslint-disable no-console */
    const url =
      payload.endPoint === 'emmersion'
        ? payload?.pageURL
          ? payload?.pageURL
          : `/organization-persona/?organization=${localStorage.getItem(
              'organizationId'
            )}`
        : `/mission/${payload.missionId}/${payload.endPoint}/?mission=${payload.missionId}`;
    apiBaseInstance.instance
      .get(url, payload)
      // .then((response => response.json()))
      .then((response) => {
        dispatch({
          type: FETCH_MISSION_DETAILS_SUCCESS,
          payload: {
            data:
              payload.endPoint === 'emmersion'
                ? {
                    emersion: {
                      ...response.data,
                      pageNumber: payload?.pageNumber,
                    },
                  }
                : parseData(response.data, payload.endPoint),
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: FETCH_MISSION_DETAILS_FAILED,
          payload: {
            error: e,
          },
        });
        // showErrorStatus('Failed to fetch mission details');
      });
  };
}
function updateMissionStart(dispatch) {
  dispatch({
    type: UPDATE_MISSION_DETAILS_START,
    payload: {},
  });
}
export function updateMission(payload) {
  const requestBody = {
    payload: JSON.stringify(payload.payload),
    patch_id: payload.patch_id,
  };
  return async (dispatch) => {
    updateMissionStart(dispatch);
    const apiBaseInstance = new ApiBase();
    apiBaseInstance.instance
      .patch(`/mission/${payload.missionId}/${payload.endPoint}/`, requestBody)
      .then((response) => {
        dispatch({
          type: UPDATE_MISSION_DETAILS_SUCCESS,
          payload: {
            data: response.data,
            objectives: payload.payload?.objectives || null,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: UPDATE_MISSION_DETAILS_FAILED,
          payload: {error: e},
        });
        showErrorStatus('Failed to update project details');
      });
  };
}

export const addNewMemberToList = (params, callback) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_NEW_MEMBER_TO_LIST,
      payload: params.data,
    });
    callback && callback();
  };
};

const showErrorStatus = (message) => {
  getNotification('error', {
    header: 'Failed!',
    body: message,
  });
};

export * from './searchOnGoogle';
const showSuccessStatus = (message) => {
  getNotification('success', {
    header: 'Success!',
    body: message,
  });
};

const parseData = (data, endPoint) => {
  if (data && data.length) {
    let response = {[endPoint]: JSON.parse(data[0].payload)};
    response[endPoint].id = data[0].id;
    return response;
  }
  return {};
};
