/* eslint-disable max-len */
import {BaseIcon, sizeType} from './BaseIcon';
import React from 'react';

const path = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10Z'
      // fill='#1C1B1F'
    />
    <path
      d='M4 13C5.65685 13 7 11.6569 7 10C7 8.34315 5.65685 7 4 7C2.34315 7 1 8.34315 1 10C1 11.6569 2.34315 13 4 13Z'
      // fill='#1C1B1F'
    />
    <path
      d='M20 13C21.6569 13 23 11.6569 23 10C23 8.34315 21.6569 7 20 7C18.3431 7 17 8.34315 17 10C17 11.6569 18.3431 13 20 13Z'
      // fill='#1C1B1F'
    />
    <path
      d='M15.8895 17.7937C15.2323 18.2292 14.6044 18.5307 14.0059 18.6982C11.8839 19.2916 9.92348 18.9848 8.12472 17.7778C8.11143 17.7688 8.09591 17.7636 8.07985 17.7627C8.06378 17.7619 8.04778 17.7654 8.03359 17.7729C8.01941 17.7805 8.00757 17.7917 7.99937 17.8055C7.99117 17.8192 7.98693 17.8349 7.9871 17.8509V19.9603C7.98668 19.9709 7.98228 19.981 7.97483 19.9884C7.96737 19.9958 7.95743 20 7.94709 20H1.10092C1.07503 20 1.0502 19.9896 1.0319 19.9711C1.01359 19.9527 1.00331 19.9276 1.00331 19.9014C1.01077 18.7093 1.00971 17.4726 1.0001 16.1914C0.993703 15.3945 1.28069 14.7873 1.86108 14.3698C2.10592 14.1949 2.59242 14.0328 2.89008 14.0296C4.07592 14.0185 5.33537 13.9533 6.52761 14.0614C7.0205 14.107 7.43232 14.3285 7.76305 14.7258C8.04364 15.0628 8.35517 15.3919 8.69764 15.713C9.06464 16.0584 9.5218 16.353 10.0691 16.5968C10.5556 16.8129 11.0714 16.9406 11.6166 16.9798C12.8126 17.0678 13.9099 16.7668 14.9085 16.077C15.236 15.8502 15.507 15.6028 15.7214 15.3346C15.9946 14.9924 16.2922 14.6903 16.6144 14.4286C16.9441 14.1594 17.3538 14.0243 17.8435 14.0233C18.7844 14.0201 19.7473 14.0217 20.732 14.028C21.6378 14.0328 22.3884 14.2887 22.8124 15.1503C22.9426 15.4152 23.005 15.7718 22.9997 16.22C22.9826 17.568 22.9815 18.7914 22.9965 19.8903C22.9967 19.9019 22.9945 19.9135 22.9901 19.9243C22.9857 19.935 22.9792 19.9449 22.9708 19.9532C22.9625 19.9614 22.9525 19.968 22.9415 19.9725C22.9306 19.977 22.9188 19.9793 22.9069 19.9793H16.0783C16.0186 19.9793 15.9887 19.9497 15.9887 19.8903V17.8461C15.9887 17.7677 15.9556 17.7502 15.8895 17.7937Z'
      // fill='#1C1B1F'
    />
    <path
      d='M7.4709 13.36C7.91534 13.6 8.2963 13.93 8.59259 14.35C9.40761 15.3572 10.7044 16 12 16C12.6772 16 13.3228 15.85 13.9259 15.57C14.5291 15.29 15.0159 14.88 15.4074 14.35C15.7037 13.93 16.0847 13.6 16.5397 13.36C17.0053 13.12 17.4921 13 18 13C17.619 12.39 16.7937 11.9 15.545 11.54C14.2963 11.18 13.1111 11 12 11C10.8889 11 9.7037 11.18 8.45503 11.54C7.20635 11.9 6.39153 12.38 6 13C6.5291 13 7.01587 13.12 7.4709 13.36Z'
      // fill='#1C1B1F'
    />
  </svg>
);
const NetworksIcon: React.FC<sizeType> = ({size}) => (
  <BaseIcon size={size}>{path}</BaseIcon>
);

const NetworksIconWithBackground = () => <BaseIcon background>{path}</BaseIcon>;

export {NetworksIcon, NetworksIconWithBackground};
