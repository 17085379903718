import React from 'react';
import SVGIcons from '../../Components/SVGIcons';
import style from './style.module.scss';
export function InitialCard({
  children,
  text,
  customButton,
  customClass,
  customButtonText,
}) {
  return (
    <div className={style.addInitialCard}>
      <div className={style.addInitialCard__block + " " + customClass}>
        <h3>{text}</h3>
        <div>{children}</div>
        {customButton && (
          <div className={style.initialFooter}>
            <a onClick={customButton}>
              <span>
                <SVGIcons type={'SVG-plus'} />
              </span>
              <span>{customButtonText}</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
