import {Icon} from 'Components/atoms';
import { DoubleRightOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import {TagSteps} from 'Components/molecules/tag';
import {
  getPftCompetencies,
  deleteTJCompetencyImprovementTactic,
  getUserPatPhaseGoals,
  getMilestonesCompetencyIds,
} from 'Services/PftJourney.service';
import {useDispatch} from 'react-redux';
import {Card} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getImprovementTacticInfo} from 'Services/ImprovementTactic.service';
import {
  createSprintForTJCTactic,
  completeTJCompetency,
} from 'Services/PftJourney.service';
import {
  openImprovementTacticModal,
  setPftCategoryScores,
  setPftJourneyPhases,
} from 'ReduxV2/state';
import {
  CompleteMilestoneModal,
  ImprovementTacticModal,
} from 'Components/molecules/modal';
import {DifficultyTag} from 'Components/atoms';
import {NetworkMeasureSelect} from 'Components/atoms/select';
import {getNotification} from 'Components/GetNotification';
import {getPatCategories, getUsersPatInspect} from 'Services/UserPat.service';

import { useTranslation } from "react-i18next"
import { loadPatCompetenciesArgs } from '../PftLibrary/PftLibrary';
import { PatOption } from 'types/Pat';


type ImprovementTacticProps = {
  id: number;
  disabled: boolean;
  description: string;
  pat_competency: number;
  tj_competency: number;
  completed: boolean;
  tjc_improvement_tactic: number;
  sprint: number | null;
  onClickTrash: () => void;
  onClickInfo: () => void;
  onClickCreateSprint: () => void;
  onClickFinishSprint: () => void;
  tier: 'EASY' | 'MEDIUM' | 'HARD';
};

type ForMilestoneCardProps = {
  onClickAddOtherTactic: () => void;
  missionMeasureIds: number[];
  onClickCompleteMilestone: () => void;
  tjcMissionMeasures: any[];
  competency: any;
};

const PhaseInfo: React.FC<{phases: any[]}> = ({phases}) => {
  const currentPhase = phases.find((item: any) => item.current === true);
  const currentPhaseIndex = phases.indexOf(currentPhase) + 1;
  let phaseText = '';

  if (currentPhaseIndex === phases.length) {
    phaseText = `You are in Phase ${currentPhaseIndex}`;
  } else {
    phaseText = `You are moving from Phase ${currentPhaseIndex} to Phase ${
      currentPhaseIndex + 1
    }`;
  }

  return (
    <h2 className='mb-4 flex items-center gap-x-4'>
      <span className='font-bold text-3xl whitespace-nowrap'>Summary:</span>
      <span className='font-semibold text-2xl'>{phaseText}</span>
    </h2>
  );
};

const AddMilestoneButton: React.FC<{onClick: () => void}> = ({onClick}) => {
  return (
    <button className='group' onClick={onClick}>
      <div className='flex flex-col gap-y-2 items-center'>
        <div className='rounded bg-primary flex place-content-center w-12 h-12 transition-colors group-hover:bg-primary-hover'>
          <Icon name='add' />
        </div>
        <span className='text-2xl font-semibold'>Add Milestone</span>
      </div>
    </button>
  );
};

const ImprovementTactic: React.FC<ImprovementTacticProps> = ({
  id,
  description,
  pat_competency,
  tj_competency,
  completed,
  tjc_improvement_tactic,
  sprint,
  disabled = false,
  tier,
  onClickTrash,
  onClickInfo,
  onClickCreateSprint,
  onClickFinishSprint,
}) => {
  const [sprintCreated, setSprintCreated] = useState(Boolean(sprint));
  const {t} = useTranslation();

  return (
    <div className='grid lg:!flex justify-between gap-2'>
      <div className='grid gap-2 max-w-[534px]'>
        <div className='flex gap-x-2 items-center'>
          <span className='text-lg font-semibold'>
            Improvement Tactic #{id}:{' '}
          </span>
          <button className='flex place-content-center' onClick={onClickInfo}>
            <Icon name='information-circle' />
          </button>
          {!!tier && <DifficultyTag difficulty={tier} />}
        </div>
        <span className='font-medium'>{description}</span>
      </div>
      <div className='flex grow shrink-0 items-center gap-4 justify-end'>
        {!sprintCreated ? (
          <button
            className={`py-2 px-4 rounded font-medium text-white bg-primary transition-colors ${
              disabled ? 'opacity-50' : 'hover:bg-primary-hover'
            }`}
            disabled={disabled}
            onClick={() => {
              onClickCreateSprint();
              setSprintCreated(true);
            }}>
            Run {t('commons.intervention')}
          </button>
        ):
        <button
            className='py-2 px-4 rounded font-medium text-white bg-primary-hover'
            disabled={true}>
            {t('commons.intervention')} Created
          </button>
        }
        <button
          className='py-2 px-4 rounded font-medium text-gray-700/40 border border-solid border-gray-400'
          disabled={true}>
          Capabilities
        </button>
        <button
          className={`w-10 h-10 ${disabled ? 'opacity-50' : 'opacity-100'}`}
          onClick={onClickTrash}
          disabled={disabled}>
          <Icon name='trashcan' />
        </button>
        <button
          className='w-10 h-10'
          onClick={onClickFinishSprint}
          disabled={true}>
          {completed && (
            <Icon name={completed ? 'check-green-square' : 'check'} />
          )}
        </button>
      </div>
    </div>
  );
};

const ForMilestoneCard: React.FC<ForMilestoneCardProps> = ({
  competency,
  onClickAddOtherTactic,
  missionMeasureIds,
  onClickCompleteMilestone,
  tjcMissionMeasures,
}) => {
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<boolean>(false);
  const [improvementTactics, setImprovementTactics] = useState<any>([]);
  const [competencyState] = useState<any>(competency);
  const dispatch = useDispatch();
  const deleteImprovementTactic = async (id: number) => {
    try {
      await deleteTJCompetencyImprovementTactic(id).call;
      const newImprovementTactics = improvementTactics.filter(
        (item: any) => item.tjc_improvement_tactic !== id
      );
      setImprovementTactics(newImprovementTactics);
      getNotification('success', {
        header: 'Success',
        body: 'Improvement tactic removed from milestone.',
      });
    } catch (error) {}
  };

  const loadImprovementTacticInfo = async (tacticId: number) => {
    try {
      const res = await getImprovementTacticInfo(tacticId).call;
      if (res?.data) {
        dispatch(openImprovementTacticModal(res.data[0]));
      }
    } catch (error) {}
  };

  const postCreateSprint = async (tacticId: number) => {
    try {
      const res = await createSprintForTJCTactic(tacticId).call;
      if (res?.data) {
        getNotification('success', {
          header: 'Success',
          body: 'Sprint created successfully',
        });
      }
    } catch (error) {}
  };

  /**
   * A milestone can't be completed if:
   * 1. It's already completed (NO LONGER ACCURATE, completed sprints can be "reassessed")
   * 2. There's more than one sprint, and the number of completed sprints is less than the number of sprints
   */
  const canBeCompleted = (): boolean => {
    // 1.
    // if (competencyState.completed == true) {
    //   return false;
    // }

    // 2.
    const improvementTactics = competencyState.improvement_tactics;

    let countImprovementTacticsWithSprints = 0;
    let countImprovementTacticsWithCompletedSprints = 0;
    for (const item of improvementTactics) {
      if (item.sprint !== null) {
        countImprovementTacticsWithSprints++;
      }
      if (item.completed == true) {
        countImprovementTacticsWithCompletedSprints++;
      }
    }

    if (
      countImprovementTacticsWithSprints > 0 &&
      countImprovementTacticsWithCompletedSprints <
        countImprovementTacticsWithSprints
    ) {
      return false;
    }

    // otherwise,
    return true;
  };

  useEffect(() => {
    // Flatten the improvement tactics
    const tactics = competencyState.improvement_tactics.map((item: any) => {
      return {
        ...item.improvement_tactic,
        tj_competency: item.tj_competency,
        tjc_improvement_tactic: item.id,
        completed: item.completed,
        sprint: item.sprint,
      };
    });
    setImprovementTactics(tactics);
  }, []);

  return (
    <Card className='border-none rounded-2xl shadow-lg'>
      <div className='grid gap-4'>
        <div className='w-full flex justify-between gap-2'>
          <h2 className='flex items-center gap-4 text-xl tracking-tight font-semibold '>
              {competency?.pat_competency?.title?.includes('Milestone #') ? (
                  <span>{competency?.pat_competency?.title}</span>
                )
                : (
                  <span>{`For Milestone #${competency?.pat_competency?.ref_id} - ${competency?.pat_competency?.title}`}</span>
                )
              }
 
            {!!competency?.completd && <Icon name='check-green-square' />}
              <DoubleRightOutlined style={{transform:'rotate(-90deg)'}} className='text-base text-red'/>
          </h2>
          <div>
            <button
              className='shrink-0 text-sm text-primary underline hover:text-primary-hover'
              onClick={() => {
                setIsMoreInfoOpen(true);
              }}>
              View More Information
            </button>
          </div>
        </div>
        <div
          className={`max-w-[600px] transition-all overflow-hidden ${
            isMoreInfoOpen ? 'max-h-[500px]' : 'max-h-0'
          }`}>
          <span>{competency?.pat_competency?.about}</span>
        </div>
        <div className='grid gap-4'>
          <div className='flex items-center justify-between'>
            <h3 className='text-lg tracking-tight font-semibold'>
              Link to measures across your network.
            </h3>
            {!!canBeCompleted() && (
              <button
                className='py-1 px-2 rounded font-medium text-white bg-primary hover:bg-primary-hover'
                onClick={onClickCompleteMilestone}>
                {competency?.completed
                  ? 'Reassess Milestone'
                  : 'Complete Milestone'}
              </button>
            )}
          </div>
          <div className='max-w-[600px]'>
            <NetworkMeasureSelect
              placeholder='Search for measures that will improve.'
              defaultValue={missionMeasureIds}
              tjCompetencyId={competency?.id}
              tjcMissionMeasures={tjcMissionMeasures}
              disabled={competency?.completed ? true : false}
              disabledMessage='This milestone has been completed.'
            />
          </div>
          <div className='grid gap-4'>
            {improvementTactics.map((item: any, index: number) => {
              return (
                <ImprovementTactic
                  {...item}
                  key={item.id}
                  disabled={false}
                  onClickTrash={() => {
                    deleteImprovementTactic(item.tjc_improvement_tactic);
                  }}
                  onClickInfo={() => {
                    loadImprovementTacticInfo(item.id);
                  }}
                  onClickCreateSprint={() => {
                    postCreateSprint(item.tjc_improvement_tactic);
                  }}
                  onClickFinishSprint={() => {
                    console.log('@todo finish sprint');
                  }}
                />
              );
            })}
            <div>
              <button
                className={`border border-solid border-primary rounded text-gray-900 font-medium py-1 px-2 text-sm opacity-100`}
                onClick={onClickAddOtherTactic}
                disabled={false}>
                Add other Improvement Tactic
              </button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const ScreenHeader: React.FC<{title: string}> = ({title}) => (
  <div className='flex items-center justify-between mb-4'>
    <h2 className='text-4xl font-semibold'>{title}</h2>
  </div>
);

export const PftPlan: React.FC<{onClickNewMilestone: () => void}> = ({
  onClickNewMilestone,
}) => {
  const [collapsedPrimaryCompetencies, setCollapsedPrimaryCompetencies] = useState<boolean>(false);
  const [collapsedSecondaryCompetencies, setCollapsedSecondaryCompetencies] = useState<boolean>(true);
  //@ts-ignore
  const phases = useSelector((state) => state.pftJourney.phases);
  //@ts-ignore
  const pftId = useSelector((state) => state.pftJourney.id);
  //@ts-ignore
  const patId = useSelector((state) => state.pat.value);

  const [primaryCompetencies, setPrimaryCompetencies] = useState<any>([]);
  const [secondaryCompetencies, setSecondaryCompetencies] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCompetency, setModalCompetency] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<PatOption | undefined>();
  const dispatch = useDispatch();

  const loadPftCompetencies = async () => {
    try {
      if (pftId && patId) {
        const options: loadPatCompetenciesArgs = {
          pat_id: patId,
          competency_full_info: true,
        };
  
        const res = await getPftCompetencies(pftId).call;
        const results = res?.data?.results
        setPrimaryCompetencies(results?.filter((r:any) => r?.hierarchy == 1))
        setSecondaryCompetencies(results?.filter((r:any) => !Object.hasOwn(r, 'hierarchy')))
      }
    } catch (error) {}
  };

  const finishTJCompetency = async (tj_competency: number) => {
    try {
      const res = await completeTJCompetency({
        tj_competency,
        completed: true, //TODO: check cases when complete should be true i.e. selecteOption?.order == 4,
        phase: selectedOption?.phase as number,
      }).call;
      if (res?.data) {
        getNotification('success', {
          header: 'Success',
          body: 'Milestone completed',
        });
      }
    } catch (error) {}
  };

  const updatePhasesAndScores = async () => {
    try {
      if (patId) {
        const res = await getUsersPatInspect(patId).call;
        if (res?.data) {
          dispatch(setPftJourneyPhases(res?.data?.phases));
          dispatch(setPftCategoryScores(res?.data?.category_scores));
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    loadPftCompetencies();
  }, []);

  const handleCollapse = (setCollapsed:React.Dispatch<React.SetStateAction<boolean>>) => {
    setCollapsed(prev => !prev)
  }

  return (
    <div id='pft-plan'>
      <ImprovementTacticModal />
      <ScreenHeader title='Your Transformation Journey: Plan' />
      <div className='mb-4'>
        <PhaseInfo phases={phases} />
        <TagSteps tags={phases} />
      </div>
      <div className='grid gap-4'>
        <div onClick={() => handleCollapse(setCollapsedPrimaryCompetencies)} className='pb-2 border-solid border-b-2 border-cyan-500 flex justify-between cursor-pointer'>
          <h4 className='text-lg'>Primary Milestones</h4>
          <div>
            {collapsedPrimaryCompetencies ? <DownOutlined className='text-xs text-cyan-500' /> : <UpOutlined className='text-xs text-cyan-500'/>}
          </div>
        </div>
        {!collapsedPrimaryCompetencies && primaryCompetencies.map((competency: any, index: number) => {
          const missionMeasureIds = competency.mission_measures.map(
            (item: any) => item.mission_measure.id
          );

          return (
            <ForMilestoneCard
              key={index}
              competency={competency}
              onClickAddOtherTactic={onClickNewMilestone}
              onClickCompleteMilestone={() => {
                setModalCompetency(competency);
                setModalOpen(true);
              }}
              missionMeasureIds={missionMeasureIds}
              tjcMissionMeasures={competency.mission_measures}
            />
          );
        })}
        <div onClick={() => handleCollapse(setCollapsedSecondaryCompetencies)} className='pb-2 border-solid border-b-2 border-cyan-500 flex justify-between cursor-pointer'>
          <h4 className='text-lg'>Secondary Milestones</h4>
          <div>
            {collapsedSecondaryCompetencies ? <DownOutlined className='text-xs text-cyan-500' /> : <UpOutlined className='text-xs text-cyan-500'/>}
          </div>
        </div>
        {!collapsedSecondaryCompetencies && secondaryCompetencies.map((competency: any, index: number) => {
          const missionMeasureIds = competency.mission_measures.map(
            (item: any) => item.mission_measure.id
          );

          return (
            <ForMilestoneCard
              key={index}
              competency={competency}
              onClickAddOtherTactic={onClickNewMilestone}
              onClickCompleteMilestone={() => {
                setModalCompetency(competency);
                setModalOpen(true);
              }}
              missionMeasureIds={missionMeasureIds}
              tjcMissionMeasures={competency.mission_measures}
            />
          );
        })}
        <AddMilestoneButton onClick={onClickNewMilestone} />
      </div>
      <CompleteMilestoneModal
        competency={modalCompetency}
        isOpen={modalOpen}
        setSelectedOption={setSelectedOption}
        onCancel={() => {
          setModalOpen(false);
          setSelectedOption(undefined);
        }}
        onOk={() => {
          setModalOpen(false);
          const primaryCompetencyIndex = primaryCompetencies.findIndex(
            (item: any) => item.id === modalCompetency.id
          );
          const secondaryCompetencyIndex = secondaryCompetencies.findIndex(
            (item: any) => item.id === modalCompetency.id
          );

          const thisCompetencyIndex = primaryCompetencies != -1 ? primaryCompetencyIndex : secondaryCompetencyIndex; 

          const updatedCompetencies = [...primaryCompetencies];
          updatedCompetencies[thisCompetencyIndex] = {
            ...updatedCompetencies[thisCompetencyIndex],
            completed: true,
          };

          primaryCompetencies != -1 ? setPrimaryCompetencies(updatedCompetencies) : setSecondaryCompetencies(updatedCompetencies); 
          finishTJCompetency(modalCompetency.id);
          updatePhasesAndScores();
          setSelectedOption(undefined);
        }}
      />
    </div>
  );
};
