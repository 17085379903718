export * from './ViewSprintReducerHelper';
export function getMissionHypothesisStart(state) {
  return {
    ...state,
    missionHypothesis: {
      isProcessing: true,
      success: null,
      error: null,
    },
    hypothesisList: [],
  };
}
export function getMissionHypothesisSuccess(state, payload) {
  return {
    ...state,
    missionHypothesis: {
      isProcessing: false,
      success: true,
      error: null,
    },
    hypothesisList: payload.data ? payload.data.results : [],
  };
}
export function getMissionHypothesisFailed(state, payload) {
  return {
    ...state,
    missionHypothesis: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function getSprintsStart(state) {
  return {
    ...state,
    sprints: {
      isProcessing: true,
      success: null,
      error: null,
    },
    // sprintList: [],
  };
}
export function getSprintsSuccess(state, payload) {
  let isEmpty = false;
  if (payload.data.count === 0) {
    isEmpty = true;
  }
  return {
    ...state,
    sprints: {
      isProcessing: false,
      success: true,
      error: null,
    },
    sprintList: payload.data ? payload.data.results : [],
    totalSprintCount: payload.data.mission_sprint_count,
    sprintIsEmpty: isEmpty,
    sprintCount: payload.data.count,
  };
}
export function getSprintsFailed(state, payload) {
  return {
    ...state,
    sprints: {
      isProcessing: false,
      success: null,
      error: 'Error in fetching list',
    },
  };
}

export function addSprintsStart(state) {
  return {
    ...state,
    addSprintApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
    isProcessingLoader: true,
  };
}
export function addSprintsSuccess(state, payload) {
  return {
    ...state,
    addSprintApi: {
      isProcessing: false,
      success: true,
      error: false,
    },
  };
}
export function addSprintsFailed(state, payload) {
  return {
    ...state,
    addSprintApi: {
      isProcessing: false,
      success: false,
      error: 'Error in adding sprint',
    },
    isProcessingLoader: false,
  };
}
export function addSprintValidationErrors(state, payload) {
  const newState = {...state};
  newState.addSprintApi.isProcessing = false;
  for (let item of payload) {
    newState[item.path] = {
      ...newState[item.path],
      errorMessage: item.message,
    };
  }
  return {
    ...state,
    ...newState,
    isProcessingLoader: false,
  };
}

export function updateSprintErrorMessage(state, payload) {
  return {
    ...state,
    [payload.name]: {
      ...state[payload.name],
      name: payload.name,
      errorMessage: payload.errorMessage,
    },
    isProcessingLoader: false,
  };
}

export function getSprintByIdStart(state) {
  return {
    ...state,
    sprintById: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function getSprintByIdSuccess(state, payload) {
  return {
    ...state,
    sprintById: {
      isProcessing: false,
      success: true,
      error: null,
      data: payload.data,
    },
  };
}
export function getSprintByIdFailed(state, payload) {
  return {
    ...state,
    sprintById: {
      isProcessing: false,
      success: null,
      error: 'Error in adding sprint',
    },
  };
}
