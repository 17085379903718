import { ApiBase } from '../../../utils/apiBase';
import { LIST_ORGANIZATION_GROUP } from '../../Api.constants';
import {
  LIST_ORGANISATIONS_GROUP_FAILED,
  LIST_ORGANISATIONS_GROUP_STARTED,
  LIST_ORGANISATIONS_GROUP_SUCCESS,
  LIST_GROUP_ORGANISATIONS_STARTED,
  LIST_GROUP_ORGANISATIONS_SUCCESS,
  LIST_GROUP_ORGANISATIONS_FAILED,
} from '../../Redux.constants';
import * as yup from 'yup';
import { getNotification } from '../../../Components/GetNotification';

function listOrganisationGroupStarted(dispatch) {
  dispatch({
    type: LIST_ORGANISATIONS_GROUP_STARTED,
    payload: {},
  });
}
function listGroupOrganizationStarted(dispatch) {
  dispatch({
    type: LIST_GROUP_ORGANISATIONS_STARTED,
    payload: {},
  });
}
const failedNotification = () => {
  return getNotification('error', {
    header: 'Failed',
    body: 'Failed to fetch Groups',
  });
};
export function listOrganisationGroupAction(payload, callback) {
  return async (dispatch) => {
    listOrganisationGroupStarted(dispatch);
    try {
      const apiBaseInstance = new ApiBase();
      let queryParams = '';
      let holdOn = 'False';
      if (payload.is_on_hold) holdOn = 'True';
      if (payload.q) queryParams = payload.q;
      let response = await apiBaseInstance.instance.get(
        `${LIST_ORGANIZATION_GROUP}?limit=${payload.limit}&offset=${payload.offset}&is_on_hold=${holdOn}&q=${queryParams}`
      );

      dispatch({
        type: LIST_ORGANISATIONS_GROUP_SUCCESS,
        payload: {
          listOrgResponse: response.data,
          offset: payload.offset,
        },
      });
      callback && callback(true);
    } catch (error) {
            failedNotification();
      dispatch({
        type: LIST_ORGANISATIONS_GROUP_FAILED,
        payload: { error },
      });
      callback && callback(false);
    }
  };
}

export function listGroupOrganizationsAction(payload, callback) {
  return async (dispatch) => {
    listGroupOrganizationStarted(dispatch);
    try {
      const apiBaseInstance = new ApiBase();
      let queryParams = '';
      let holdOn = 'False';
      if (payload.is_on_hold) holdOn = 'True';
      if (payload.q) queryParams = payload.q;
      let response = await apiBaseInstance.instance.get(
        `/organization/?limit=${payload.limit}&offset=${payload.offset}&is_on_hold=${holdOn}&q=${queryParams}&group_id=${payload.id}`,
        { grp_id: payload.id }
      );

      dispatch({
        type: LIST_GROUP_ORGANISATIONS_SUCCESS,
        payload: {
          listOrgResponse: response?.data.results,
          offset: payload?.offset,
        },
      });
      callback && callback(true);
    } catch (error) {
      // failedNotification();
      dispatch({
        type: LIST_GROUP_ORGANISATIONS_FAILED,
        payload: { error },
      });
      callback && callback(false);
    }
  };
}
