import { connect } from 'react-redux';
import {
  inputFormChange,
  login,
  signUpFormChange,
  signUp,
  forgotPasswordAction,
  resetPasswordAction
} from '../../Redux/Actions';
import WelcomePage from './WelcomePage';
import { SelectState } from './Selector';

function mapStateToProps(state) {
  return { ...SelectState(state) };
}
function mapDispatchToProps(dispatch) {
  return {
    inputFormChange: (params) => {
      dispatch(inputFormChange(params));
    },
    login: (input, callback) => {
      dispatch(login(input, callback));
    },
    signUpFormChange: (params) => {
      dispatch(signUpFormChange(params));
    },
    signUp: (input, callback) => {
      dispatch(signUp(input, callback));
    },
    forgotPasswordAction: (input, callback) => {
      dispatch(forgotPasswordAction(input, callback));
    },
    resetPasswordAction: (params, callback) => {
      dispatch(resetPasswordAction(params, callback));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
