import axios from 'axios';
import moment from 'moment';
import {API_BASE_URL} from '../../config/constants';

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getConstraintsCatalog(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}constraints/`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function getMissionConstraints(id) {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}missions-constraints/?mission=${id}`, {
      signal: controller.signal,
      ...headers(),
    }),
    controller,
  };
}

export function postMissionConstraints(data) {
  return {
    call: axios.post(`${API_BASE_URL}missions-constraints/`, data, headers()),
  };
}

export function patchMissionConstraints(data) {
  return {
    call: axios.patch(
      `${API_BASE_URL}missions-constraints/${data.id}/`,
      data,
      headers()
    ),
  };
}