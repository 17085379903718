import React, { useState } from 'react';

export const BreadCrumbContext = React.createContext()

const BreadCrumbContextProvider = (props) => {

   const [breadcontext,setbreadcontext] = useState(false)

   const updatebreadcontext = (val) => {
       setbreadcontext(val)
   }

    return (
        <BreadCrumbContext.Provider value={{breadcontext, updatebreadcontext}}>
            {props.children}
        </BreadCrumbContext.Provider>
    )
}

export default BreadCrumbContextProvider;