import {AutoComplete} from 'antd';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Input} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
const characterLimit = 50;
/*eslint-disable */

export const AutoCompleteInput = (props) => {
  const [isLimitexceeded, setIsLimitexceeded] = useState(false);

  const inputChange = (event) => {
    let limit = props.maxLength || characterLimit;
    if (event?.length >= limit) setIsLimitexceeded(true);
    else {
      setIsLimitexceeded(false);
    }
    props.onChange && props.onChange(event);
  };

  var clear = true;
  function clear(obj) {
    if (clear) {
      obj.value = '';
      clear = false;
    }
  }
  return (
    <>
      <AutoComplete
        autoFocus={props.autoFocus}
        allowClear={props.allowClear}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
        onSelect={props.onSelect}
        options={props.options}
        onSearch={props.onSearch}
        onChange={inputChange}
        value={props.value}
      />
      {isLimitexceeded && (
        <Alert
          message={`Character limit (maximum : ${
            props.maxLength || characterLimit
          }) reached!`}
          banner
        />
      )}
    </>
  );
};
AutoCompleteInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  value: PropTypes.any,
  htmlType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};
