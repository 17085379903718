import {getNotification} from '../../../Components/GetNotification';
import {ApiBase} from '../../../utils/apiBase';
/* eslint-disable */
export const addDepartmentAction = (payload, callback) => {
  return async (dispatch) => {
    if (!payload.isEditMode) {
      const apiBaseInstance = new ApiBase();
      apiBaseInstance.instance
        .post('/department/', payload)
        .then((response) => {
          showSuccessStatus('Successfully added new department');
          callback && callback(true);
        })
        .catch((error) => {
          showErrorStatus('Failed to add new department');
          callback && callback(false);
        });
    } else {
      const apiBase = new ApiBase();
      apiBase.instance
        .patch(`/department/${payload.updateDeptId}/ `, payload)
        .then(({data}) => {
          dispatch({type: ''});
          showSuccessStatus('Updated successfully');
          callback && callback(true);
        })
        .catch((e) => {
          dispatch({type: ''});
          showErrorStatus('Failed to update');
          callback && callback(false);
        });
    }
  };
};

export const deleteDepartment = (params, callback) => {
  return async (dispatch) => {
    const apiBase = new ApiBase();
    apiBase.instance
      .delete(`/department/${params}/ `)
      .then(({data}) => {
        dispatch({type: ''});
        showSuccessStatus('Deleted successfully');
        callback && callback({isSuccess: true});
      })
      .catch((e) => {
        dispatch({type: ''});
        showErrorStatus('Failed to delete');
        callback && callback({isSuccess: false});
      });
  };
};
export const updateDepartment = (params, callback) => {
  return async (dispatch) => {
    const apiBase = new ApiBase();
    apiBase.instance
      .patch(`/department/${params.updateDeptId}/ `, params)
      .then(({data}) => {
        dispatch({type: ''});
        showSuccessStatus('Updated successfully');
        callback && callback({isSuccess: true});
      })
      .catch((e) => {
        dispatch({type: ''});
        showErrorStatus('Failed to update');
        callback && callback({isSuccess: false});
      });
  };
};

const showErrorStatus = (message) => {
  getNotification('error', {
    header: 'Failed!',
    body: message,
  });
};

const showSuccessStatus = (message) => {
  getNotification('success', {
    header: 'Success!',
    body: message,
  });
};
