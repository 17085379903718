import { ApiBase } from '../../../utils/apiBase';
import { LIST_MISSIONS } from '../../Api.constants';
import {
  UPDATE_MISSION_START,
  UPDATE_MISSION_FAILED,
  UPDATE_MISSION_SUCCESS,
} from '../../Redux.constants';

export const updateMissionAction = (updateRequest, callBack) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_MISSION_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.patch(
        LIST_MISSIONS + `${updateRequest.id}/`,
        updateRequest
      );
      dispatch({
        type: UPDATE_MISSION_SUCCESS,
      });
      callBack && callBack(true);
    } catch (error) {
      dispatch({
        type: UPDATE_MISSION_FAILED,
        payload: { apiError: error },
      });
      callBack && callBack(false);
    }
  };
};
