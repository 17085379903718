import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from '../../config/constants';
import { MissionMostValuableGoal } from 'types/Mission';
import { AbortableRequestConfig as RequestConfig } from 'types/Request';


const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    },
  };
};

export function getTemplateMVGs(): {
  call: Promise<AxiosResponse<MissionMostValuableGoal>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}mv-goals-templates/`, {
      signal: controller.signal,
      ...headers(),
    } as RequestConfig), 
    controller,
  };
}

export function copyTemplateMVG(mission_id: string, mvg_id: string): {
  call: Promise<AxiosResponse<MissionMostValuableGoal>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.post(`${API_BASE_URL}missions/${mission_id}/clonemvgoals/?mvgoal=${mvg_id}`, {
      signal: controller.signal,
    }, headers() as RequestConfig),
    controller,
  };
}