import React, { useMemo } from 'react';
import { LeftOutlined, RightOutlined} from "@ant-design/icons"
import { useHistory } from 'react-router-dom';
import { Table} from 'antd';
import tagListColumns from './TagListColumns';
import { Tag as TagType} from 'types/Tags';

type TagListType = {
  data: TagType[],
  setSelectedTag: React.Dispatch<React.SetStateAction<any>>,
  setIsOpenEditTag: React.Dispatch<React.SetStateAction<boolean>>,
  setIsOpenDeleteTag: React.Dispatch<React.SetStateAction<boolean>>,
};
/**
 * Renders the Tag Page.
 *
 * @return {JSX.Element} The rendered Tag Page.
 */
const TagList: React.FC<TagListType>  = ({data, setSelectedTag, setIsOpenEditTag, setIsOpenDeleteTag}) => {
  const navigate = useHistory();

  const columns:any  = useMemo(() => tagListColumns({setSelectedTag, setIsOpenEditTag, setIsOpenDeleteTag}), [])

  const itemRender = (current:any, type:string, originalElement:any) => {
    if (type === 'prev') {
      return <div className='flex flex-row gap-2'>
          <LeftOutlined/>
          <span>Back</span>
        </div>
    }
    if (type === 'next') {
      return <div className='flex flex-row gap-2'>
          <span>Next</span>
          <RightOutlined/> 
        </div>
    }
    return originalElement;
  };

  return (
    <div id='tagList'>
      <Table
        columns={columns}
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate.push(`/tag/${record?.id}`);
            }
          }
        }}
        pagination={{
          pageSize: 20, // Set the number of records per page
          showSizeChanger: false, // Hide the page size changer
          showQuickJumper: true, // Show quick jumper for navigation
          simple: true, // Use simple pagination, similar to the image
          itemRender: itemRender, // Custom item render function
        }}
      />
    </div>
  );
};

export default TagList;
