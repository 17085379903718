const allpopulateallDraftdata = {data: [], loading: true};

export const GetDraftPopulateAllTestDataReducer = (state = allpopulateallDraftdata, action) =>{
    switch(action.type){
        case 'getdraftpopulatealltestdata':
            return{
                data:action.payload,
                loading:action.loader
            }
        default:
            return state;
    }
}