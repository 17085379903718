import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Col, Row, Select, Button, Modal, Tabs, Input, Table, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { Controller } from 'react-hook-form';
import { AUTOSAVING_DELAY } from '../../../../../../../../../config/constants';
import { CheckboxDebounce } from '../../../../../../../../Components/ChecboxDebounce';
import { getMeasureMetrics, patchMeasureMetrics, postMeasureMetrics } from '../../../../../../../../Services/Measure.Metrics.service';
import DateDebounce from '../../../../../../../../Components/DateDebounce/DateDebounce';
import moment from 'moment';
import './styles/Measures.scss';
import InputNumberDebounce from '../../../../../../../../Components/InputNumberDebounce/InputNumberDebounce';
import TextareaDebounce from '../../../../../../../../Components/TextareaDebounce/TextareaDebounce';

const { TabPane } = Tabs
const { Column } = Table

/**
 * Renders a component that displays measures.
 *
 * @param {object} control - The control object.
 * @param {array} fields_measures - The array of measures.
 * @param {function} append_measures - The function to append measures.
 * @param {function} remove_measures - The function to remove measures.
 * @param {function} setValue - The function to set value.
 * @return {JSX.Element} - The rendered component.
 */
const Measures = ({
  control,
  fields_measures,
  append_measures,
  remove_measures,
  setValue,
  measuresData,
}) => {
  const refForm = useRef()
  const [showEditMetrics, setShowEditMetrics] = useState(false)
  const [currentMetrics, setCurrentMetrics] = useState([])
  const [targetMetrics, setTargetMetrics] = useState([])
  const [measure, setMeasure] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [activeIndex, setActiveIndex] = useState(0)
  const [currentValue, setCurrentValue] = useState(0)
  const [targetValue, setTargetValue] = useState(0)
  const [editedCurrentValue, setEditedCurrentValue] = useState(false)
  const [editedTargetValue, setEditedTargetValue] = useState(false)

  const activeSubmitFormGeneral = () => {
    refForm?.current.click();
  };

  const debounceMeasures = useCallback(
    debounce(activeSubmitFormGeneral, AUTOSAVING_DELAY),
    []
  );

  /**
   * Updates the main measure value for a given index.
   *
   * @param {number} indexChange - The index of the measure to update.
   * @return {void} This function does not return a value.
   */
  const checkMainMeasure = (indexChange) => {
    fields_measures.map((item, index) => {
      if (index !== indexChange) {
        setValue(`measures.${index}.main`, false);
      }
    });
    debounceMeasures();
  };

  const loadMetrics = async (index) => {
    const formatData = (data) => {
      return data.map((item) => {
        return {
          ...item,
          edited: false,
        }
      })
    }
    try {
      const measure = measuresData[index]
      const res1 = await getMeasureMetrics(measure?.id, "CURRENT").call
      const res2 = await getMeasureMetrics(measure?.id, "TARGET").call
      setCurrentMetrics(formatData(res1.data.results))
      setTargetMetrics(formatData(res2.data.results))
      setCurrentValue(res1.data.results[0]?.value || 0)
      setTargetValue(res2.data.results[0]?.value || 0)
      setMeasure(measure)
    } catch (error) { }
  }

  const editMetricModal = (index, field) => {
    return (
      <Button
        className='btn-edit-measure'
        onClick={async () => {
          setActiveIndex(index)
          await loadMetrics(index)
          setActiveTab(field == "CURRENT" ? "1" : "2")
          setShowEditMetrics(true)
        }}
      >
        <EditOutlined />
      </Button>
    )
  }

  const saveMetricModal = async () => {
    const notifyError = (error) => {
      notification.error({
        message: "Attention",
        description:
          "An error occurred while saving the records, please try again later",
      })
      console.error(error)
    }
    try {
      currentMetrics.map(async (item) => {
        if (item.edited) {
          const data = {
            "value": item.value,
          }
          await patchMeasureMetrics(item?.id, data).call
        }
        return item
      })
      targetMetrics.map(async (item) => {
        if (item.edited) {
          const data = {
            "value": item.value,
          }
          await patchMeasureMetrics(item?.id, data).call
        }
        return item
      })
    } catch (e) {
      notifyError(e)
      return
    }
    try {
      //current
      const value1 = currentValue
      const data1 = {
        "measure": measure?.id,
        "metric_type": "CURRENT",
        "value": value1,
      }
      if (editedCurrentValue) await postMeasureMetrics(data1).call
      setEditedCurrentValue(false)
      //target
      const value2 = targetValue
      const data2 = {
        "measure": measure?.id,
        "metric_type": "TARGET",
        "value": value2,
      }
      if (editedTargetValue) await postMeasureMetrics(data2).call
      setEditedTargetValue(false)
      setShowEditMetrics(false)
      activeSubmitFormGeneral()
      setValue(`measures.${activeIndex}.current_state`, value1)
      setValue(`measures.${activeIndex}.target`, value2)
    } catch (e) {
      notifyError(e)
    }
  }

  const TabMetric = ({ field }) => {
    const [value, setValue] = useState(0)

    useEffect(() => {
      setValue(field == "CURRENT" ? currentValue : targetValue)
    }, [])

    if (!measure) return <></>
    const data = field == "CURRENT" ? currentMetrics : targetMetrics

    const TabInput = ({ text, index }) => {
      const [inputValue, setInputValue] = useState(text)

      return (
        <Input
          value={inputValue}
          maxLength={10}
          placeholder="Metric Number"
          onChange={(e) => {
            setInputValue(e.target.value)
            data[index].edited = true
            data[index].value = e.target.value
            if (field == "CURRENT") setCurrentMetrics(data)
            else setTargetMetrics(data)
          }}
        />
      )
    }

    return (
      <>
        Current Metric
        <Input
          addonBefore={measure?.measure_type == "PERCENTAGE" ? "%" : "#"}
          placeholder="Enter data point here"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          maxLength={10}
          onBlur={() => {
            if (field == "CURRENT") {
              setCurrentValue(value)
              setEditedCurrentValue(true)
            } else {
              setTargetValue(value)
              setEditedTargetValue(true)
            }
          }}
        />
        <p className="text-center mt-5 mb-5">Historic Metrics</p>
        <Table
          dataSource={data || []}
          showHeader={false}
          size="small"
          pagination={false}
        >
          <Column
            className="pointer"
            dataIndex="created_at"
            key="created_at"
            render={(text) => moment(text).format("MM/DD/YYYY")}
          />
          <Column
            className="pointer"
            dataIndex="value"
            key="value"
            render={(text, item, index) => (
              <TabInput text={text} index={index} />
            )}
          />
        </Table>
      </>
    )
  }

  return (
    <>
      <div className='measures'>
        <Row className='mb-10 bg-gray-prep'>
          <Col span={1}>
            <p className='text-black'>Main</p>
          </Col>
          <Col span={5}>
            <p className='text-black'>Measure</p>
          </Col>
          <Col span={3}>
            <p className='text-black'>% or #</p>
          </Col>
          <Col span={3}>
            <p className='text-black'>Current State</p>
          </Col>
          <Col span={3}>
            <p className='text-black'>Target</p>
          </Col>
          <Col span={4}>
            <p className='text-black'>Date</p>
          </Col>
          <Col span={2}>
            <p className='text-black'>Success Thresholds (optional)</p>
          </Col>
          <Col span={2}>
            <p className='text-black'>Failure Threshold (optional)</p>
          </Col>
          <Col span={1}></Col>
        </Row>
        <Row>
          <Col span={24}>
            {fields_measures.length ? (
              <div>
                {fields_measures.map((measure, index) => (
                  <Row gutter={10} className='mt-10' key={measure.id}>
                    <Col span={1}>
                      <CheckboxDebounce
                        name={`measures.${index}.main`}
                        control={control}
                        debounceAction={() => checkMainMeasure(index)}
                      />
                    </Col>
                    <Col span={5}>
                      <TextareaDebounce
                        name={`measures.${index}.name`}
                        control={control}
                        className='min-w-auto'
                        placeholder='Enter text'
                        autoheight={true}
                        maxlength={250}
                        debounceAction={debounceMeasures}
                      />
                    </Col>
                    <Col span={3}>
                      <Controller
                        name={`measures.${index}.measure_type`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            className='select-class min-w-auto'
                            placeholder='Choose an option'
                            optionFilterProp='children'
                            bordered={false}
                            onChange={(net) => {
                              field.onChange(net);
                              debounceMeasures();
                            }}
                            options={[
                              { value: 'PERCENTAGE', label: '%' },
                              { value: 'NUMBER', label: '#' },
                            ]}
                          />
                        )}
                      />
                    </Col>
                    <Col span={3}>
                      <InputNumberDebounce
                        name={`measures.${index}.current_state`}
                        control={control}
                        debounceAction={debounceMeasures}
                        className='w-100'
                        placeholder='Enter number'
                        disabled={true}
                        suffix={
                          <>
                            {editMetricModal(index, 'CURRENT')}
                          </>
                        }
                      />
                    </Col>
                    <Col span={3}>
                      <InputNumberDebounce
                        name={`measures.${index}.target`}
                        control={control}
                        debounceAction={debounceMeasures}
                        className='w-100'
                        placeholder='Enter number'
                        disabled={true}
                        suffix={
                          <>
                            {editMetricModal(index, 'TARGET')}
                          </>
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <DateDebounce
                        name={`measures.${index}.date`}
                        control={control}
                        debounceAction={debounceMeasures}
                        className='w-100 b-rounded-5'
                      />
                    </Col>
                    <Col span={2}>
                      <InputNumberDebounce
                        name={`measures.${index}.success_threshold`}
                        control={control}
                        debounceAction={debounceMeasures}
                        className='w-100'
                        placeholder='0.0'
                      />
                    </Col>
                    <Col span={2}>
                      <InputNumberDebounce
                        name={`measures.${index}.failure_threshold`}
                        control={control}
                        debounceAction={debounceMeasures}
                        className='w-100'
                        placeholder='0.0'
                      />
                    </Col>
                    <Col span={1} className='flex-vertical'>
                      <div
                        className='close-icon'
                        onClick={() => {
                          remove_measures('measure', index);
                        }}>
                        -
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            ) : (
              <div className='text-center'>
                <p>No member added yet</p>
              </div>
            )}
            <div
              className='add-button-container'
              onClick={() => append_measures()}>
              <p className='text-default-blue'>Add New Measure</p>
            </div>
          </Col>
        </Row>
        <button
          type='submit'
          className='btn-edit d-none'
          ref={(e) => (refForm.current = e)}>
          Save
        </button>
      </div>
      <Modal
        open={showEditMetrics}
        className="modal-edit-metrics"
        onCancel={() => setShowEditMetrics(false)}
        footer={[
          <Button key="save" type="primary" onClick={() => saveMetricModal()}>Save</Button>,
          <Button key="cancel" type="text" onClick={() => setShowEditMetrics(false)}>Cancel</Button>,
        ]}
      >
        <h2 className="text-center">Edit Metrics</h2>
        <p className="text-center">You can view and edit current and past metric data here.</p>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onTabClick={(key) => setActiveTab(key)}
        >
          <TabPane tab="Current State" key="1">
            <TabMetric field="CURRENT" />
          </TabPane>
          <TabPane tab="Target" key="2">
            <TabMetric field="TARGET" />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Measures;
