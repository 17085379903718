import { connect } from 'react-redux';
import {
  listOrganisationAction,
  getPresignedUrl,
  updateErrorMessage,
  updateOrganisation,
  deleteOrganisation,
  listMissionsAction,
} from '../../Redux/Actions';
import { listOrganisationGroupAction } from '../../Redux/Actions/ListOrganizationGroupAction';
import { uploadImage } from '../../Redux/Actions/UploadImageAction';
import { deleteGroup } from '../../Redux/Actions/DeleteGroupAction';
import Groups from './Groups';
import { SelectState } from './Selector';
const mapStateToProps = (state) => {
  return {
    ...SelectState(state),
    ...state.DeleteOrganisationReducer,
    ...state.DeleteGroupReducer,
    ...state.UpdateOrganisationReducer,
    ...state.updateGroupReducer,
    ...state.OrganisationReducer,
    ...state.OrganizationGroupReducer,
    ...state.UpdateMissionReducer,
    ...state.MissionReducer,
    ...state.DeleteMissionReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrganisationList: (params, callback) => {
      dispatch(listOrganisationAction(params, callback));
    },
    getOrganisationGroupList: (params, callback) => {
      dispatch(listOrganisationGroupAction(params, callback));
    },
    getPresignedUrl: (params, callback) => {
      dispatch(getPresignedUrl(params, callback));
    },
    uploadImage: (params) => {
      dispatch(uploadImage(params));
    },
    updateErrorMessage: (params) => {
      dispatch(updateErrorMessage(params));
    },
    deleteOrganisation: (params) => {
      dispatch(deleteOrganisation(params));
    },
    deleteGroup: (params) => {
      dispatch(deleteGroup(params));
    },
    updateOrganisation: (params, callback) => {
      dispatch(updateOrganisation(params, callback));
    },
    listMissions: (params, callback) => {
      dispatch(listMissionsAction(params, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
