import React, {useEffect, useState} from 'react';
import {Dropdown, Menu, MenuProps, Select, Tabs, Tooltip} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, RouteComponentProps} from 'react-router-dom';
import {selectNetwork} from '../../ReduxV2/state/networks';
import {getAllNetworks} from '../../Services/Network.service';
import {ClearLocalStorage} from '../../utils/localStorageOperations';
import {
  AssesmentTool,
  Demographics,
  Documents,
  Observations,
  Workflow,
} from './components';
import {PftLibrary} from './components/PlanTransformation/components/PftLibrary';
import {PftScores} from './components/PlanTransformation/components/PftScores';
import {PftPlan} from './components/PlanTransformation/components/PftPlan';
import PlanTransformation from './components/PlanTransformation/PlanTransformation';

import './styles/Intel.scss';
import {cleanPat, cleanPftJourney} from 'ReduxV2/state';
import {Icon} from 'Components/atoms';

const {TabPane} = Tabs;

type RouteParams = {
  id?: string;
  organizationId?: string;
};

type NetworkNames = {
  value: string;
  label: string;
};

/**
 * Renders the Intel component.
 *
 * @return {JSX.Element} The rendered Intel component.
 */
const Intel: React.FC<RouteComponentProps<RouteParams>> = () => {
  const [networks, setNetworks] = useState<NetworkNames[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('1');
  const [selectedMenu, setSelectedMenu] = useState<string>('2.3');

  const {id, organizationId} = useParams<RouteParams>();

  const dispatch = useDispatch();

  const selectedNetwork = useSelector((state) => {
    //@ts-ignore
    return state.network.value;
  });
  //@ts-ignore
  const patId = useSelector((state) => state.pat.value);

  const getAllNetworksApi = async () => {
    try {
      const allNetworks = await getAllNetworks().call;
      if (allNetworks?.data?.results?.length) {
        setNetworks(
          allNetworks.data.results.map((item) => {
            return {value: item.id, label: item.name};
          })
        );
        if (id) {
          dispatch(selectNetwork({id}));
        } else {
          dispatch('selecting network from list of all networks');
          dispatch(selectNetwork({id: allNetworks.data?.results[0]?.id}));
        }
      } else {
        dispatch(selectNetwork({id: ''}));
      }
    } catch (error) {
      ClearLocalStorage();
    }
  };

  // Logs as part of the routing effort
  // console.log('organization id from old route', id);
  // console.log('organization Id from new route', organizationId);
  // console.log('organitzation id from redux', selectedNetwork);

  useEffect(() => {
    getAllNetworksApi();
    return () => {
      console.log('clearing network on intel unmount');
      dispatch(selectNetwork({id: ''}));
    };
  }, []);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    e.domEvent.preventDefault();
    setSelectedMenu(e.key);
    return false;
  };

  const pftDropdown = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item data-testid='overviewDropdownOption' key='2.1'>Overview</Menu.Item>
      <Menu.Item data-testid='scoreDropdownOption' key='2.2'>Score</Menu.Item>
      <Menu.Item data-testid='libraryDropdownOption' key='2.3'>Library</Menu.Item>
    </Menu>
  );

  const pftPaneInner = (key: string) => {
    switch (key) {
      case '2.1':
        return (
          <PftPlan
            onClickNewMilestone={() => {
              setSelectedMenu('2.3');
            }}
          />
        );
      case '2.2':
        return <PftScores />;
      case '2.3':
        return (
          <PftLibrary
            onClickNext={() => {
              setSelectedTab('2');
              setSelectedMenu('2.1');
            }}
          />
        );
      default:
        return (
          <PftLibrary
            onClickNext={() => {
              setSelectedTab('2');
              setSelectedMenu('1.3');
            }}
          />
        );
    }
  };

  return (
    <div className='intel'>
      <h1>Intel</h1>
      {!id && (
        <Select
          className='select-class'
          placeholder='Select a Network'
          bordered={false}
          value={selectedNetwork}
          defaultValue={selectedNetwork}
          options={networks}
          onChange={(e) => {
            dispatch(selectNetwork({id: e}));
          }}
        />
      )}
      <Tabs
        type='card'
        onChange={(e) => {
          setSelectedTab(e);
        }}
        activeKey={selectedTab}
        onTabClick={(e) => {
          if (Number(e) === 1) {
            dispatch(cleanPat());
            dispatch(cleanPftJourney());
          }
        }}>
        <TabPane tab='Practice Assessment Tool' key='1'>
          <AssesmentTool
            onClickNext={() => {
              setSelectedTab('2');
              setSelectedMenu('2.3');
            }}
          />
        </TabPane>
        {/* <TabPane tab='Plan for Transformation' key='2'>
          <PlanTransformation />
        </TabPane> */}
        {!!patId && (
          <TabPane
            tab={
              <Dropdown overlay={pftDropdown} className='-mx-4 px-4'>
                <span className='flex gap-x-1 items-center'>
                  <span data-testid='planForTransformationTab'>Plan for Transformation</span>
                  <Icon
                    name='chevron-down'
                    className='w-4 h-4 pointer-events-none'
                  />
                </span>
              </Dropdown>
            }
            key='2'>
            {pftPaneInner(selectedMenu)}
          </TabPane>
        )}
        {!patId && (
          <TabPane
            tab={
              <Tooltip title='Please select a practice assessment.'>
                <span data-testid='planForTransformationTab'>Plan for Transformation</span>
              </Tooltip>
            }
            key='2'
            disabled={true}>
            <div></div>
          </TabPane>
        )}
        <TabPane tab='Action Plan' key='ma-action-plans'>
          <PlanTransformation activeKey={selectedTab} />
        </TabPane>
        <TabPane tab='Observations' key='observations'>
          <Observations />
        </TabPane>
        <TabPane tab='Practice Workflows' key='practice-workflows'>
          <Workflow />
        </TabPane>
        <TabPane tab='Demographics' key='demographics'>
          <Demographics />
        </TabPane>
        <TabPane tab='Documents' key='documents'>
          <Documents activeKey={selectedTab} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Intel;
