import React, { useEffect, useState } from 'react';
import { Col, Row, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getNotification } from '../../../../../../../../Components/GetNotification';
import {
  deleteMissionEngagged,
  getMissionEngaged,
  getMissionEngagedById,
  patchMissionEngagged,
  postMissionEngagged,
} from '../../../../../../../../Services/MissionTeams.service';
import {
  getMemberList,
  postTeamPersona,
  updateMember,
} from '../../../../../../../../Services/Teams.service';
import TeamCard from '../TeamCard/TeamCard';
import './styles/MissionTeam.scss';
import { InviteMembers } from '../../../../../../../Teams/Components';
import ModalPersona from '../../../ModalPersona/ModalPersona';

/**
 * Renders the MissionTeam component.
 *
 * @param {Object} saved - The saved state.
 * @return {JSX.Element} The rendered component.
 */
const MissionTeam = ({ saved }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [memberModal, setMemberModal] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [allStaff, setAllStaff] = useState({});
  const [namePersona, setNamePersona] = useState('');
  const [selectedPersona, setSelectedPersona] = useState({});
  const [modalTitle, setModalTitle] = useState('Add Team Member');

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      demand_prob_score: '1',
      trust_other_score: '1',
      how_serious: '1',
      difficulty: '1',
      is_problem: 'yes',
      persona: null,
      right_influencer: '2',
    },
  });

  const mission = useSelector((state) => {
    return state.mission.value.id;
  });

  const watchIsProblemField = watch('is_problem');
  const watchPersonaId = watch('persona');

  const { id } = useParams();

  /**
   * Saves staff information.
   *
   * @param {object} params - The parameters for saving staff information.
   * @return {Promise} A promise that resolves to undefined.
   */
  const saveStaff = async (params) => {
    const data = {
      ...params,
      mission,
      is_problem: params?.is_problem == 'yes' ? true : false,
      right_influencer: params?.right_influencer == '1' ? true : false,
    };
    try {
      if (params?.id) {
        await patchMissionEngagged(data).call;
      } else {
        await postMissionEngagged(data).call;
      }
      await updateMember({ id: watchPersonaId, ...selectedPersona });
      setVisibleModal(false);
      loadMessenger();
    } catch (error) {
      getNotification('error', {
        header: 'Error',
        body: error.response.data?.non_field_errors.join('.'),
      });
    }
  };

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call;
      setAttendees(
        res.data?.results?.map((att) => ({ value: att.id, label: att.name }))
      );
    } catch (error) { }
  };

  const loadMessenger = async () => {
    if (mission) {
      try {
        const res = await getMissionEngaged(mission).call;
        setAllStaff(res?.data);
      } catch (error) { }
    }
  };

  /**
   * Delete the messenger with the given ID.
   *
   * @param {number} id - The ID of the messenger to delete.
   * @return {void} This function does not return a value.
   */
  const deleteMessenger = async (id) => {
    try {
      const res = await deleteMissionEngagged(id).call;
      getNotification('success', {
        header: 'success',
        body: 'The messenger was successfully deleted',
      });
      loadMessenger();
    } catch (error) { }
  };

  /**
   * Edits the messenger with the given ID.
   *
   * @param {number} id - The ID of the messenger to edit.
   * @return {Promise<void>} A promise that resolves once the editing is complete.
   */
  const editMessenger = async (id) => {
    reset();
    try {
      const res = await getMissionEngagedById(id).call;
      Object.keys(res?.data).map((key) => {
        if (key == 'is_problem') {
          setValue(key, res?.data[key] ? 'yes' : 'no');
        } else if (key == 'persona') {
          setValue(key, res?.data[key].id);
        } else if (key == 'right_influencer') {
          setValue(key, res?.data[key] ? '1' : '2');
        } else {
          setValue(key, String(res?.data[key]));
        }
      });
      setModalTitle('Edit Team Member');
      setVisibleModal(true);
    } catch (error) { }
  };

  /**
   * Updates the persona data with the given key and data.
   *
   * @param {string} key - The key to update in the persona data.
   * @param {any} data - The new data to update the persona with.
   * @return {void} This function does not return anything.
   */
  const updatePersonaData = (key, data) => {
    setSelectedPersona({ ...selectedPersona, [key]: data });
    loadMessenger();
  };

  const addPersona = async () => {
    try {
      await postTeamPersona([
        {
          name: namePersona,
          email: '',
          user: '',
          organization: id,
        },
      ]).call;
      setNamePersona('');
      loadAttendees();
    } catch (error) { }
  };

  useEffect(() => {
    loadAttendees();
  }, [memberModal]);

  useEffect(() => {
    loadAttendees();
    loadMessenger();
  }, [mission]);

  return (
    <div className='messenger'>
      <div className='flex justify-beetween items-center'>
        <h4>Who needs to be engaged in this project?</h4>
        <div>
          <button
            type='button'
            className='modal-dont-delete b-rounded-5 ml-10'
            onClick={() => {
              setModalTitle('Add Team Member');
              setVisibleModal(true);
              reset();
            }}>
            Add Team Member +
          </button>
        </div>
      </div>
      <div>
        <Row gutter={10}>
          {allStaff?.results?.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={6} key={index}>
              <TeamCard
                item={item}
                deleteMember={deleteMessenger}
                editMember={editMessenger}
                isMission
              />
            </Col>
          ))}
        </Row>
      </div>
      <div
        className={`${allStaff?.results?.length ? 'hidden' : 'staff-no-data'}`}>
        Empty
      </div>
      <Modal
        title={modalTitle}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={false}
        className='mission-modal'
        style={{ height: 'calc(100vh - 100px)' }}
        bodyStyle={{ overflowY: 'scroll' }}>
        <form onSubmit={handleSubmit(saveStaff)}>
          <ModalPersona
            attendees={attendees}
            control={control}
            errors={errors}
            getValues={getValues}
            register={register}
            watchPersonaId={watchPersonaId}
            watchIsProblemField={watchIsProblemField}
            setVisibleModal={setVisibleModal}
            addPersona={addPersona}
            namePersona={namePersona}
            setNamePersona={setNamePersona}
            updatePersonaData={updatePersonaData}
          />
        </form>
      </Modal>
      <InviteMembers
        visible={memberModal}
        closeModal={() => setMemberModal(false)}
        saved={saved}
      />
    </div>
  );
};

export default MissionTeam;
