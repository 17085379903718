import { ApiBase } from '../../../utils/apiBase';
import { FETCH_ORG_DETAILS } from '../../Api.constants';
import {
    FETCH_ORG_DETAILS_FAILED,
    FETCH_ORG_DETAILS_STARTED,
    FETCH_ORG_DETAILS_SUCCESS
} from '../../Redux.constants';
import * as yup from 'yup';
import { getNotification } from '../../../Components/GetNotification';

export function fetchOrganisationAction(input) {
    return async (dispatch) => {
        dispatch({
            type: FETCH_ORG_DETAILS_STARTED,
            payload: {},
        });
        try {
            const apiBaseInstance = new ApiBase();
            let response = await apiBaseInstance.instance.get(FETCH_ORG_DETAILS + `/${input}/`);
            dispatch({
                type: FETCH_ORG_DETAILS_SUCCESS,
                payload: {
                    orgDetails: response.data,
                },
            });
        } catch (error) {
            // let validationErrors = await fetchValidationErrors(input);
            dispatch({
                type: FETCH_ORG_DETAILS_FAILED,
                payload: { error },
            });
            // getNotification('error', {
            //     header: 'Failed!',
            //     body: 'Failed to fetch network details'
            // });
        }
    };
}
