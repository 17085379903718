import {
  ADD_CONNECTED_NETWORK_FAILED,
  ADD_CONNECTED_NETWORK_STARTED,
  ADD_CONNECTED_NETWORK_SUCCESS,
  DELETE_CONNECTED_NETWORK_FAILED,
  DELETE_CONNECTED_NETWORK_STARTED,
  DELETE_CONNECTED_NETWORK_SUCCESS,
  GET_CONNECTED_NETWORKS_FAILED,
  GET_CONNECTED_NETWORKS_STARTED,
  GET_CONNECTED_NETWORKS_SUCCESS,
} from '../../Redux.constants';
import {
  addConnectedNetwrokFailed,
  addConnectedNetwrokStarted,
  addConnectedNetwrokSuccess,
  deleteConnectedNetwrokFailed,
  deleteConnectedNetwrokStarted,
  deleteConnectedNetwrokSuccess,
  getConnectedNetwrokFailed,
  getConnectedNetwrokStarted,
  getConnectedNetwrokSuccess,
} from './Helper/getConnectedNetworkHelper';

const INITIAL_STATE = {
  getConnectedNetwork: {
    isProcessing: false,
    success: null,
    error: null,
  },
  addConnectedNetwork: {
    isProcessing: false,
    success: null,
    error: null,
  },
  deleteConnectedNetwork: {
    isProcessing: false,
    success: null,
    error: null,
  },
  connectedNetworkList: [],
  connectedNetworkMissionDetails: {},
  connectNetworkTotal: 0,
};

const connectedNetworkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONNECTED_NETWORKS_STARTED:
      return getConnectedNetwrokStarted(state);
    case GET_CONNECTED_NETWORKS_SUCCESS:
      return getConnectedNetwrokSuccess(state, action);
    case GET_CONNECTED_NETWORKS_FAILED:
      return getConnectedNetwrokFailed(state);
    case ADD_CONNECTED_NETWORK_STARTED:
      return addConnectedNetwrokStarted(state);
    case ADD_CONNECTED_NETWORK_SUCCESS:
      return addConnectedNetwrokSuccess(state);
    case ADD_CONNECTED_NETWORK_FAILED:
      return addConnectedNetwrokFailed(state);
    case DELETE_CONNECTED_NETWORK_STARTED:
      return deleteConnectedNetwrokStarted(state);
    case DELETE_CONNECTED_NETWORK_SUCCESS:
      return deleteConnectedNetwrokSuccess(state);
    case DELETE_CONNECTED_NETWORK_FAILED:
      return deleteConnectedNetwrokFailed(state);
    default:
      return {...state};
  }
};

export default connectedNetworkReducer;
