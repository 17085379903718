import React, { Component } from 'react';
import { ButtonElement } from '../../../../Components/ButtonElement';
import FormBlock from '../../../../Components/FormBlock';
export default class Form extends Component {
  constructor(props) {
    super(props);
  }
  signupFirstName = () => (
    <FormBlock
      htmlForName='firstName'
      htmlType='text'
      className='login-input'
      placeholder='First Name'
      onChange={(evt) => this.props.signUpInputChange(evt, 'firstName')}
      value={this.props.firstName.value}
      error={this.props.firstName.errorMessage}
    />
  );
  signupLastName = () => (
    <FormBlock
      htmlForName='lastName'
      htmlType='text'
      className='login-input'
      placeholder='Last Name'
      onChange={(evt) => this.props.signUpInputChange(evt, 'lastName')}
      value={this.props.lastName.value}
      error={this.props.lastName.errorMessage}
    />
  );
  signupDesignation = () => (
    <FormBlock
      htmlForName='designation'
      htmlType='text'
      className='login-input'
      placeholder='Designation'
      onChange={(evt) => this.props.signUpInputChange(evt, 'designation')}
      value={this.props.designation.value}
      error={this.props.designation.errorMessage}
    />
  );
  signupEmail = () => (
    <FormBlock
      htmlForName='email'
      htmlType='text'
      className='login-input'
      placeholder='Email address'
      type='email'
      onChange={(evt) => this.props.signUpInputChange(evt, 'email')}
      value={this.props.email.value?.toString()?.toLowerCase()}
      error={this.props.email.errorMessage}
    />
  );
  signupPassword = () => (
    <FormBlock
      htmlForName='password'
      htmlType='password'
      className='login-input'
      placeholder='Password'
      onChange={(evt) => this.props.signUpInputChange(evt, 'password')}
      value={this.props.password.value}
      error={this.props.password.errorMessage}
      maxLength={500}
      hint={
        'The password must have at least an uppercase letter, a lowercase letter, a digit & a special character.'
      }
      iconRender={true}
      elementType='password'
    />
  );
  signupConfirmPassword = () => (
    <FormBlock
      htmlForName='confirmPassword'
      htmlType='password'
      className='login-input'
      placeholder='Confirm Password'
      onChange={(evt) => this.props.signUpInputChange(evt, 'confirmPassword')}
      value={this.props.confirmPassword.value}
      error={this.props.confirmPassword.errorMessage}
      onPressEnter={this.props.signUpHandleSubmit}
      maxLength={500}
      iconRender={true}
      elementType='password'
    />
  );
  createSignUpForm = () => {
    return (
      <React.Fragment>
        <li>
          <div className='signup-split'>
            <div className='signup-split__item'>{this.signupFirstName()}</div>
            <div className='signup-split__item'>{this.signupLastName()}</div>
          </div>
        </li>
        <li>
          <div className='signup-split'>
            <div className='signup-split__item'>{this.signupEmail()}</div>
          </div>
        </li>
        <li>{this.signupPassword()}</li>
        <li>{this.signupConfirmPassword()}</li>
        <li>
          <ButtonElement
            className='login-btn'
            loading={this.props.signupApi.isProcessing}
            onClick={this.props.signUpHandleSubmit}
            children='SignUp'
            type='primary'
          />
        </li>
      </React.Fragment>
    );
  };
  loginEmailAddress = () => (
    <FormBlock
      name='loginEmail'
      htmlForName='loginEmail'
      htmlType='text'
      className='login-input'
      placeholder='Email address'
      onChange={(evt) => this.props.inputChange(evt, 'loginEmail')}
      onPressEnter={this.props.handleSubmit}
      value={this.props.loginEmail.value}
      error={this.props.loginEmail.errorMessage}
    />
  );
  loginPassword = () => (
    <FormBlock
      name='loginPassword'
      htmlForName='loginPassword'
      htmlType='password'
      placeholder='Password'
      className='login-input'
      onChange={(evt) => this.props.inputChange(evt, 'loginPassword')}
      onPressEnter={this.props.handleSubmit}
      value={this.props.loginPassword.value}
      error={this.props.loginPassword.errorMessage}
      maxLength={500}
      iconRender={true}
      elementType='password'
    />
  );
  createLoginForm = () => {
    return (
      <React.Fragment>
        <li>{this.loginEmailAddress()}</li>
        <li>{this.loginPassword()}</li>
        <li>
          <ButtonElement
            className='login-btn'
            onClick={this.props.handleSubmit}
            children='Login'
            type='primary'
            loading={this.props.loginApi.isProcessing}
          />
        </li>
      </React.Fragment>
    );
  };

  render() {
    return (
      <form className='form-pane'>
        <ul className='form-pane__list'>
          {this.props.showSignIn
            ? this.createLoginForm()
            : this.createSignUpForm()}
        </ul>
      </form>
    );
  }
}
