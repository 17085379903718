import React, { useEffect } from 'react';
import { ROUTES } from '../../Routes.constants';
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga';
import './styles/PublicLayout.scss';

import { useTranslation } from "react-i18next"

/**
 * Renders the public layout component.
 *
 * @param {Object} props - The props object containing the children and changeColor properties.
 * @param {ReactNode} props.children - The child components to be rendered.
 * @param {boolean} props.changeColor - Indicates whether the color should be changed.
 * @return {ReactNode} - The rendered public layout component.
 */
const PublicLayout = ({ children, changeColor }) => {
  const navigate = useHistory();

  useEffect(() => {
    navigate.listen((location) => ReactGA.pageview(location.pathname));
  }, []);

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <div className='publiclayout'>
      <div className="publiclayout-navbar">
        <a href={ROUTES.SEARCH}>
          <img className="publiclayout-navbar-logo pointer" src={"/img/blueagilis-whitelogo-1.png"} alt="" />
        </a>
        <div className="flex">
          <p className={`text-white transition-1-linear mr-15 pointer`} onClick={() => navigate.push(ROUTES.CONTACT)}>{t("Button.Contact")}</p>
          <p className={`text-white transition-1-linear pointer`} onClick={() => navigate.push(ROUTES.DASHBOARD)}>{t("Button.Login")} </p>
          {/* 	<span className={`text-white mx-15 transition-1-linear`}> | </span>
					<p className={`text-white transition-1-linear pointer`} onClick={() => navigate.push(ROUTES.CONTACT)}>Sign up</p> */}
        </div>
      </div>
      <hr className={"hr-white"} />
      <div className="publiclayout-content">
        {children}
      </div>
      <footer className={`${changeColor ? "publiclayout-main-footer" : "publiclayout-footer"} `}>
        <div className="flex">
          <p className={`text-white mr-15 transition-1-linear`}>© {new Date().getFullYear()} {t("Company_name")}</p>
          <p className={`text-white transition-1-linear`}>{t("Footer.Reserved")}.</p>
        </div>
        <p className={`text-white transition-1-linear`}>{t("Footer.Privacy")}</p>
      </footer>
    </div >
  );
};

export default PublicLayout;
