import React from 'react';
import {
  Modal,
  Input,
  Form,
  Select
} from 'antd';
import { useTranslation } from "react-i18next"

import UserList from '../../Routes/OrganisationInsights/Children/ViewDetails/UserList';
import { useState } from 'react';
const { Option } = Select;


/**
 * Renders a modal component for displaying member details and allowing user interaction.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - {string} title: The title of the modal.
 *   - {boolean} visible: Whether the modal is visible.
 *   - {function} onOk: The callback function when the OK button is clicked.
 *   - {function} onCancel: The callback function when the Cancel button is clicked.
 *   - {Array} data: The data array containing member details.
 *   - {string} id: The ID of the member.
 *   - {function} emailChange: The callback function when the email input value changes.
 *   - {function} permissionChange: The callback function when the permission select value changes.
 *   - {string} typeEntity: The type of entity.
 * @return {JSX.Element} The rendered modal component.
 */
const MemberModal = ({ title, visible, onOk, onCancel, data, id, emailChange, permissionChange, typeEntity }) => {

  const [email, setEmail] = useState('');

  // i18n translation hook
  const [t] = useTranslation()

  function sendData() {
    onOk();
    setEmail('')
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={sendData}
      onCancel={onCancel}>
      <Form style={{ 'display': 'flex' }} layout="vertical">
        <Form.Item label='Email' style={{ 'flex': '1', 'margin-right': '10px' }}>
          <Input
            onChange={(e) => {
              emailChange(e.target.value);
              setEmail(e.target.value)
            }
            }
            value={email}
          />
        </Form.Item>
        <Form.Item label='Role'>
          <Select
            defaultValue="view"
            onChange={(e) => permissionChange(e)}
          >
            <Option value="view">{t("Options.View")}</Option>
            <Option value="edit">{t("Options.Editor")}</Option>
          </Select>
        </Form.Item>

      </Form>
      <p>User Details</p>
      <UserList
        data={data}
        id={id}
        visible={visible}
        typeEntity={typeEntity}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default MemberModal;
