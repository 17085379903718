import {ApiBase} from '../../../utils/apiBase';
import {LIST_ORGANISATIONS} from '../../Api.constants';
import {
  UPDATE_ORGANISATION_START,
  UPDATE_ORGANISATION_FAILED,
  UPDATE_ORGANISATION_SUCCESS,
  CREATE_ORGANISATION_VALIDATION_ERROR,
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION,
  CREATE_ORGANISATION_SUCCESS,
} from '../../Redux.constants';
import * as yup from 'yup';
import {getNotification} from '../../../Components/GetNotification';

const UPDATE_ORGANISATION_FORM_SCHEMA = yup.object().shape({
  name: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Organisation title should only have Alphabets.')
    .required('Network Title is required'),
  point_of_contact: yup
    .string()
    // .matches(/^[A-Za-z]+$/, 'Point of contact should only have Alphabets.')
    .required('Point of contact required'),
  country: yup.string().required('Location is required'),
  contact_email: yup
    .string()
    .email(
      'Email id is invalid. Please enter a valid email id. (eg: blueagilis@gmail.com)'
    )
    .required('Contact Email is required'),
  phone: yup
    .string()
    .min(10)
    .max(14, 'Phone number must be [+91] [10 digit subscriber number]')
    .matches(
      /^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/,
      `Phone number is invalid. Enter the phone number in the format [+91][10 digit subscriber number] or 
      [10 digit subscriber number]`
    )
    .required('Phone number is required'),
  website: yup
    .string()
    .matches(
      /* eslint-disable max-len*/
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      'Enter a valid url. ( eg: http://example.com , www.example.com  , example.com )'
    )
    .required('Website is required'),
});

function fetchValidationErrors(input) {
  return new Promise((resolve, reject) => {
    UPDATE_ORGANISATION_FORM_SCHEMA.validate(input, {abortEarly: false}).catch(
      (error) => {
        let validationList = error.inner.map((item) => {
          return {
            path: item.path,
            message: item.message,
          };
        });
        resolve(validationList);
      }
    );
  });
}

export function UpdateOrganisationDetails(input, callback) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ORGANISATION_SUCCESS,
    });
    try {
      // await UPDATE_ORGANISATION_FORM_SCHEMA.validate(input);
      await updateOrgAction({dispatch, input, callback});
    } catch (error) {
      let createOrganisationValidationErrors = await fetchValidationErrors(
        input
      );
      dispatch({
        type: CREATE_ORGANISATION_VALIDATION_ERROR,
        payload: createOrganisationValidationErrors,
      });
    }
  };
}

async function updateOrgAction({dispatch, input, callback}) {
  try {
    const id = input.get('id');
    const apiBaseInstance = new ApiBase();
    let response = await apiBaseInstance.instance.patch(
      LIST_ORGANISATIONS + `${id}/`,
      input,
      {headers: {'Content-Type': 'multipart/form-data'}}
    );
    dispatch({
      type: UPDATE_ORGANISATION_SUCCESS,
    });
    callback();
    getNotification('success', {
      header: 'Success!',
      body: 'Successfully updated network',
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORGANISATION_FAILED,
      payload: {apiError: error},
    });
    getNotification('error', {
      header: 'Failed!',
      body: 'Failed to update network',
    });
  }
}

export const updateOrganisation = (updateRequest, callback) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ORGANISATION_START,
    });
    try {
      const apiBaseInstance = new ApiBase();
      let response = await apiBaseInstance.instance.patch(
        LIST_ORGANISATIONS + `${updateRequest.id}/`,
        updateRequest
      );

      dispatch({
        type: UPDATE_ORGANISATION_SUCCESS,
      });
      callback && callback();
    } catch (error) {
      dispatch({
        type: UPDATE_ORGANISATION_FAILED,
        payload: {apiError: error},
      });
      callback && callback();
    }
  };
};
