/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { useDispatch } from 'react-redux';
import { ButtonElement } from '../../../../../Components/ButtonElement';
import FormBlock from '../../../../../Components/FormBlock';
import ModalWindow from '../../../../../Components/ModalWindow';

import axios from 'axios';
import { API_BASE_URL } from '../../../../../../config/constants';
import { getNotification } from '../../../../../Components/GetNotification';
import ImageUpload from '../../../../../Components/ImageUpload';
import Loader from '../../../../../Components/Loader';
import {
  CreateOrganisation, GetDepartments, UpdateOrganisationDetails
} from '../../../../../Redux/Actions';
import style from '../../../../Dashboard/style.module.scss';

export default function AddOrganisation(props) {
  const [fileList, setFileList] = useState(null);
  const [isSubmited, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState(
    props.groupList?.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    })
  );
  const submitOrganisationAction = (e) => {
    let formData = new FormData();
    if (fileList) formData.append('profile_image_url', fileList.originFileObj);
    if (props.organisationDetails.id)
      formData.append('id', props.organisationDetails.id);
    formData.append('name', e.target[0].value);
    formData.append('point_of_contact', e.target[1].value);
    formData.append('country', e.target[2].value);
    formData.append('contact_email', e.target[3].value);
    formData.append('phone', e.target[4].value);
    formData.append('group', e.target[5].value);

    if (props.isEditMode)
      dispatch(
        UpdateOrganisationDetails(formData, () => {
          props.getGroupOrganizationList({
            limit: props.limit,
            offset: 0,
            q: '',
            id: localStorage.getItem('groupId'),
          });
          props.onClose();
        })
      );
    else {
      dispatch(
        CreateOrganisation(
          formData,
          () => {
            props.getGroupOrganizationList({
              limit: props.limit,
              offset: 0,
              q: '',
              id: localStorage.getItem('groupId'),
            });

            props.onClose();
          },
          localStorage.getItem('groupId')
        )
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (props.uploadingImage)
      getNotification('info', {
        header: '',
        body: 'Image upload in progress, please wait.',
      });
    else submitOrganisationAction(e);
  };
  useEffect(() => {
    getOrganisationGroupList();
  }, []);

  async function getOrganisationGroupList() {
    const data = await axios.get(
      `${API_BASE_URL}organization-group/?limit=7&offset=0&is_on_hold=False&q=`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
        },
      }
    );
    setGroupList(
      data.data?.results?.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      })
    );
  }

  const onFileUpload = (fileList) => {
    setFileList(fileList);
  };
  const onChangeUpdateError = (event) => {
    props.updateErrorMessage({ name: event.currentTarget.name });
  };
  const onlyNumbers = (evt) => {
    let value = evt.key.match('[a-zA-Z._&^%$#*=/?:;"!~@,s]+');
    if (value && evt.keyCode != 8) {
      evt.preventDefault();
    }
  };

  let {
    name,
    contact_email,
    country,
    profile_image_url,
    website,
    point_of_contact,
    phone,
  } = props.organisationDetails;

  return (
    <ModalWindow
      title={`${props.isEditMode ? 'Edit' : 'Add a'} Network`}
      visible={props.showModal}
      className='addnew-window'
      onCancel={props.onClose}
      isNonMaskable={true}
      width={1000}
      footer={false}>
      <form
        onSubmit={(e) => {
          onSubmit(e);
        }}>
        <ul className='blu-vertical-list'>
          <li>
            <div className='split-block'>
              <div className='split-half'>
                <FormBlock
                  requiredIcon={true}
                  label='Network Title'
                  error={isSubmited ? props.nameError : ''}
                  name='name'
                  onChange={onChangeUpdateError}
                  defaultValue={name}
                  placeholder='The network title (maximum length: 50 characters)'
                />
              </div>
              <div className='split-half'>
                <FormBlock
                  requiredIcon={true}
                  label='Point of Contact'
                  error={isSubmited ? props.point_of_contactError : ''}
                  name='point_of_contact'
                  onChange={onChangeUpdateError}
                  placeholder='The Name of the Point of Contact'
                  defaultValue={point_of_contact}
                />
              </div>
            </div>
          </li>
          <li>
            <div className='split-block'>
              <div className='split-half'>
                <FormBlock
                  requiredIcon={true}
                  label='Location'
                  error={isSubmited ? props.countryError : ''}
                  name='country'
                  onChange={onChangeUpdateError}
                  placeholder='The location of the network'
                  defaultValue={country}
                />
              </div>
              <div className='split-half'>
                <FormBlock
                  requiredIcon={true}
                  label='Contact Email'
                  error={isSubmited ? props.contact_emailError : ''}
                  name='contact_email'
                  onChange={onChangeUpdateError}
                  placeholder='The email ID for contact  e.g. abc@blueagilis.com'
                  defaultValue={contact_email}
                />
              </div>
            </div>
          </li>
          <li>
            <div className='split-block'>
              <div className='split-half'>
                <FormBlock
                  requiredIcon={true}
                  label='Phone Number'
                  error={isSubmited ? props.phoneError : ''}
                  name='phone'
                  onKeyDown={onlyNumbers}
                  onChange={onChangeUpdateError}
                  placeholder='The network contact number'
                  defaultValue={phone}
                />
              </div>
              <div className='split-half'>
                <FormBlock
                  elementType='autoComplete'
                  // requiredIcon={true}
                  label='Group'
                  error={isSubmited ? props.groupError : ''}
                  name='group'
                  options={groupList}
                  placeholder={
                    props.group_name ? props.group_name : 'Select a group'
                  }
                  // value={group_name}
                  defaultValue={props.group_name}
                />
                {/* <FormBlock
                  requiredIcon={true}
                  label='Official Website'
                  error={isSubmited ? props.websiteError : ''}
                  name='website'
                  onChange={onChangeUpdateError}
                  placeholder='The website of the network. e.g. https://abc.com'
                  defaultValue={website}
                /> */}
              </div>
            </div>
          </li>
          <li>
            <div className={style.organisationUpload}>
              <label>
                Add the network logo by clicking on the 'upload' section below
                or, drag and drop the file there. Hover over the uploaded file
                to remove it.
              </label>
              <ImageUpload
                imageUploadMethod={props.getPresignedUrl}
                uploadImage={props.uploadImage}
                imageUrl={props.imageUrl}
                uploadingImage={props.uploadingImage}
                onFileUpload={onFileUpload}
                beforeUpload={(file) => {
                  const acceptedFormats = ['jpg', 'png', 'jpeg'];
                  let extension = file.name.split('.').pop();
                  if (acceptedFormats.includes(extension)) return true;
                  else {
                    getNotification('info', {
                      header: '',
                      body: `Please upload a file with extension .jpg, .jpeg
                       or .png`,
                    });
                    return Upload.LIST_IGNORE;
                  }
                }}
                defaultFileList={
                  profile_image_url ? [{ url: profile_image_url }] : []
                }
              />

              {props.uploadingImage ? (
                <div className={style.organisationUpload__loader}>
                  <Loader classList={['xs blue']} />
                  <span>Uploading image...</span>
                </div>
              ) : (
                []
              )}
              <div className={style.organisationUpload__hints}>
                Note: The logo can have the size only upto 1 MB. Files to be
                uploaded : .jpg, .jpeg or .png.
              </div>
            </div>
          </li>
        </ul>
        <div className='blu-list-submit'>
          <ButtonElement
            type=''
            children='Cancel'
            // type='link'
            onClick={props.onClose}
          />
          <ButtonElement
            type='primary'
            htmlType='submit'
            children='Save'
            loading={props.isProcessing}
            disabled={props.isProcessing}
          />
        </div>
      </form>
    </ModalWindow>
  );
}
