import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getSprint, getSprintHuddles, postSprintHuddle, deleteEntireSprintHuddle } from 'Services/Sprints.service';
import { Huddle } from './components';
import HuddleScorecard from './components/HuddleScorecard/HuddleScorecard';
import { getNotification } from 'Components/GetNotification';

import './styles/Action.scss';

const { Panel } = Collapse;

const MESSAGES = {
  HUDDLE_DELETED: 'The huddle has been deleted',
  SUCCESS: 'Success',
}

/**
 * Renders the Action component.
 *
 * @returns {JSX.Element} The rendered Action component.
 */
const Action = () => {
  const [allHuddles, setAllHuddles] = useState([]);

  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm();

  const sprintId = useSelector(state => {
    return state.sprint.value
  });

  const loadHuddles = async () => {
    if (sprintId) {
      try {
        const res = await getSprint(sprintId).call;

        setValue("huddle_frequency", res?.data?.huddle_frequency)
        setValue("custom_length", [res?.data?.custom_huddle_freq_start, res?.data?.custom_huddle_freq_end])
      } catch (error) { }
    }
  }

  const addHuddle = async () => {
    try {
      await postSprintHuddle({ mission_sprint: sprintId }).call;
      loadAllHuddles()
    } catch (error) {

    }
  }

  const deleteHuddle = async (huddleId) => {
    try {
      await deleteEntireSprintHuddle(huddleId).call;
      loadAllHuddles();
      getNotification('success', {
        header: MESSAGES.SUCCESS,
        body: MESSAGES.HUDDLE_DELETED,
      });
    } catch (error) {
      console.error(error);
    }
  }

  const loadAllHuddles = async () => {
    try {
      const res = await getSprintHuddles(sprintId).call;
      setAllHuddles(res.data)
    } catch (error) { }
  }

  useEffect(() => {
    reset()
    loadHuddles()
    loadAllHuddles()
  }, [sprintId]);

  const huddles = Array.from(allHuddles?.results || []);
  let firstHuddleId = null;
  if(huddles.length > 0){
    firstHuddleId = huddles[0].id
  }

  return (
    <div className='action grid gap-4 mt-9'>
      <Collapse expandIconPosition="end" defaultActiveKey={["1"]}>
        <Panel header="Update your Scorecard" key="1">
          <HuddleScorecard />
        </Panel>
      </Collapse>

      <div className="flex justify-between items-center">
        <h2 className="text-3xl">Huddles</h2>
        <button type="button" className="rounded px-4 py-2 bg-primary text-white hover:bg-primary-hover" onClick={addHuddle}>+ Add Huddle</button>
      </div>

      <div className="tabs">
        <Collapse expandIconPosition="end" ghost defaultActiveKey={[firstHuddleId]}>
          {
            huddles?.map((huddle) => (
              <Panel header={`Huddle #${huddle?.number}`} key={huddle?.id} className="bg-white">
                <Huddle id={huddle?.id} onDelete={() => {
                  deleteHuddle(huddle?.id);
                }}/>
              </Panel>
            ))
          }
        </Collapse>


      </div>
    </div>
  );
};

export default Action;
