import React from 'react';

interface CardProps {
  category: {title: string; total: number};
}
type Props = {
  patDummyData: {
    title: string;
    total: number;
  }[];
};

export const PatOverViewCards = ({patDummyData}: Props) => {
  const Card = ({category}: CardProps) => (
    <div className='bg-white border border-gray-300 p-4'>
      <div className='text-base text-center'>{category.title}</div>
      <div className='text-40 font-bold text-teal-700 mt-3'>
        {category.total}
      </div>
    </div>
  );

  return (
    <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mx-12'>
      {patDummyData.map((category, index) => (
        <Card key={index} category={category} />
      ))}
    </div>
  );
};
