import React from 'react';
import {Controller} from 'react-hook-form';
import './styles/InputDebounce.scss';

/**
 * Renders an input field with debounced action.
 *
 * @param {string} name - The name of the input field.
 * @param {object} control - The control object from react-hook-form.
 * @param {function} debounceAction - The debounced action to be executed.
 * @param {string} className - Additional CSS class for the input field.
 * @param {object} rules - Validation rules for the input field.
 * @param {string} placeholder - Placeholder text for the input field.
 * @param {string} type - The type of the input field.
 * @param {number} maxlength - The maximum length of the input field.
 * @param {boolean} isEmail - Indicates if the input field is for an email.
 * @return {JSX.Element} The rendered input field.
 */

type InputDebounceProps = {
  name: string;
  control: any;
  debounceAction?: () => void;
  className?: string;
  rules?: any;
  placeholder?: string;
  type?: string;
  maxlength?: number;
  isEmail?: boolean;
};

const InputDebounce: React.FC<InputDebounceProps> = ({
  name,
  control,
  debounceAction = () => {},
  className = '',
  rules = {},
  placeholder = '',
  type = 'text',
  maxlength = 250,
  isEmail = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field}) => (
        <input
          className={`input-class ${className}`}
          placeholder={placeholder}
          type={isEmail ? 'email' : type}
          {...field}
          maxLength={maxlength}
          autoComplete='off'
          onChange={(value) => {
            const filteredValue: string = isEmail
              ? value.target.value.toLowerCase()
              : value.target.value;
            field.onChange(filteredValue);
            debounceAction();
          }}
        />
      )}
    />
  );
};

export default InputDebounce;
