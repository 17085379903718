import React, { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Modal, Collapse } from 'antd';
import { getMeetingsDatails } from '../../../../../Services/Meetings.service';
import MeetingsPdf from '../MeetingsPdf/MeetingsPdf';
import './styles/PdfModal.scss';
import { noteInitalData, prepInitialData, reflectInitialData } from './model';

const { Panel } = Collapse;

/**
 * Renders a PDF modal component.
 *
 * @param {Object} id - The ID of the modal.
 * @param {boolean} visible - Whether the modal is visible or not.
 * @param {function} onCancel - Callback function when the modal is canceled.
 * @return {ReactNode} The rendered PDF modal component.
 */
const PdfModal = ({ id, visible, onCancel }) => {
  const [meetingData, setMeetingData] = useState({});
  const [meetPrep, setMeetPrep] = useState(prepInitialData);
  const [meetNotes, setMeetNotes] = useState(noteInitalData);
  const [meetReflect, setMeetReflect] = useState(reflectInitialData);

  /**
   * Updates the value of a meet preparation item at the specified index.
   *
   * @param {number} index - The index of the item to update.
   * @param {any} value - The new value to assign to the item.
   * @return {void} This function does not return a value.
   */
  const changePrep = (index, value) => {
    let prepArr = meetPrep;
    prepArr[index].value = value;
    setMeetPrep([...prepArr]);
  }

  /**
   * Updates a note value in the meetNotes array at the specified index.
   *
   * @param {number} index - The index of the note in the meetNotes array.
   * @param {any} value - The new value for the note.
   * @return {void} This function does not return a value.
   */
  const changeNotes = (index, value) => {
    let notesArr = meetNotes;
    notesArr[index].value = value;
    setMeetNotes([...notesArr]);
  }

  /**
   * Change the value of a specific element in the meetReflect array.
   *
   * @param {number} index - The index of the element to be changed.
   * @param {any} value - The new value to assign to the element.
   * @return {void} 
   */
  const changeReflect = (index, value) => {
    let reflectArr = meetReflect;
    reflectArr[index].value = value;
    setMeetReflect([...reflectArr]);
  }

  const loadData = async () => {
    if (id) {
      const meet = await getMeetingsDatails(id).call;
      setMeetingData(meet?.data)
    }
  }

  /**
   * Sets the value of all meetPrep items to the specified value.
   *
   * @param {type} value - The value to set for all meetPrep items.
   * @return {void} This function does not return a value.
   */
  const allPrepActive = (value) => {
    setMeetPrep(meetPrep.map(item => ({ ...item, value })));
  }

  /**
   * Updates the value of all meet notes to the given value.
   *
   * @param {any} value - The new value for all meet notes.
   * @return {void} This function does not return a value.
   */
  const allNotesActive = (value) => {
    setMeetNotes(meetNotes.map(item => ({ ...item, value })));
  }

  /**
   * Updates the `meetReflect` array by setting the `value` of all items to the specified value.
   *
   * @param {any} value - The value to set for all items in the `meetReflect` array.
   * @return {void} This function does not return a value.
   */
  const allReflectionActive = (value) => {
    setMeetReflect(meetReflect.map(item => ({ ...item, value })));
  }

  useEffect(() => {
    setMeetingData({})
    setMeetPrep(prepInitialData)
    setMeetNotes(noteInitalData)
    setMeetReflect(reflectInitialData)
    loadData()
  }, [visible]);

  return (
    <Modal
      title="Export PDF"
      visible={visible}
      onCancel={() => onCancel()}
      footer={false}
      className="mission-modal"
    >
      <div className='pdfmodal'>
        <Collapse collapsible="icon" defaultActiveKey={['1', '2', '3']} expandIconPosition="right">
          <Panel header={
            <div className="flex items-center">
              <input type="checkbox" onClick={e => allPrepActive(e.target.checked)} checked={meetPrep.filter(({ value }) => value).length === meetPrep.length} />
              <span className="ml-5 text-white">Meeting Prep</span>
            </div>
          } key="1">
            {
              meetPrep.map((item, index) => (
                <div className="flex items-center">
                  <input type="checkbox" checked={item.value} onClick={e => changePrep(index, e.target.checked)} />
                  <span className="ml-5">{item.label}</span>
                </div>
              ))
            }
          </Panel>
          <Panel header={
            <div className="flex items-center">
              <input type="checkbox" onClick={e => allNotesActive(e.target.checked)} />
              <span className="ml-5 text-white">Meeting Notes</span>
            </div>
          } key="2">
            {
              meetNotes.map((item, index) => (
                <div className="flex items-center">
                  <input type="checkbox" checked={item.value} onClick={e => changeNotes(index, e.target.checked)} />
                  <span className="ml-5">{item.label}</span>
                </div>
              ))
            }
          </Panel>
          <Panel header={
            <div className="flex items-center">
              <input type="checkbox" onClick={e => allReflectionActive(e.target.checked)} />
              <span className="ml-5 text-white">Meeting Reflection</span>
            </div>
          } key="3">
            {
              meetReflect.map((item, index) => (
                <div className="flex items-center">
                  <input type="checkbox" checked={item.value} onClick={e => changeReflect(index, e.target.checked)} />
                  <span className="ml-5">{item.label}</span>
                </div>
              ))
            }
          </Panel>
        </Collapse>

        <div className="w-80 flex justify-beetween mt-15 m-auto">
          <button type="button" className="modal-delete-btn" onClick={onCancel}>Cancel</button>
          <PDFDownloadLink document={<MeetingsPdf meetingData={meetingData} rules={[...meetPrep, ...meetNotes, ...meetReflect]} />} fileName={`Meeting-${meetingData?.title}.pdf`}>
            <button type="button" className="modal-dont-delete">Save</button>
          </PDFDownloadLink>
        </div>
      </div >
    </Modal>

  );
};

export default PdfModal;
