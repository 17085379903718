import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getSprint} from 'Services/Sprints.service';
import {Planning} from '../Planning';
import {Action} from '../Action';
import {Closed} from '../Closed';
import {TagStatus} from '../TagStatus';
import {Steps} from 'Components/molecules/steps/Steps';
import {ContinueWithoutSavingModal} from 'Components/molecules/modal';
import {SprintDetail} from 'types/Sprint';
import {Button, Icon} from 'Components/atoms';

import { useTranslation } from "react-i18next"


type HeaderProps = {
  name: string;
  uid: string | number;
  why_sprinting: string;
  status: string;
};

type FooterProps = {
  onClickNext: () => void;
  title: string;
  isLastStep: boolean;
};

const Header: React.FC<HeaderProps> = ({name, uid, why_sprinting, status}) => {
  const {t} = useTranslation();

  return (
    <header>
      <hr className='hr-color' />
      <div className='flex justify-around'>
        <div>
          <p>
            <b>Project Assigned</b>
          </p>
          <p>{name}</p>
        </div>
        <div>
          <p>
            <b># of {t('commons.intervention')}</b>
          </p>
          <p>{uid}</p>
        </div>
        <div>
          <p>
            <b>Reason of {t('commons.intervention')}</b>
          </p>
          <p>
            {why_sprinting === 'TO_CREATE_DEMAND'
              ? 'To create demand'
              : 'To implement an intervention'}
          </p>
        </div>
        <div>
          <p>
            <b>Status</b>
          </p>
          <p>
            <TagStatus tag={status} />
          </p>
        </div>
      </div>
      <hr className='hr-color' />
    </header>
  );
};

const Footer: React.FC<FooterProps> = ({onClickNext, title, isLastStep}) => (
  <footer className='flex justify-end'>
    {!isLastStep && (
      <Button onClick={onClickNext}>
        <span>{title}</span>
        <Icon name='arrow-right' />
      </Button>
    )}
  </footer>
);

/**
 * Renders the Menu component.
 *
 * @return {JSX.Element} The rendered Menu component.
 */
const Menu: React.FC = () => {
  const menuItems = [
    {
      title: 'Planning',
      description: 'Planning',
    },
    {
      title: 'In Action',
      description: 'In Action',
    },
    {
      title: 'Reflection',
      description: 'Reflection',
    },
  ];

  const [sprintData, setSprintData] = useState<SprintDetail>();
  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const currentStepTitle = menuItems[currentStep]?.title ?? '';
  const isLastStep = currentStep === menuItems.length - 1;

  const sprintId = useSelector((state) => {
    // @ts-ignore
    return state.sprint.value;
  });

  const isWorkflowEdited: boolean = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.isEdited ?? false
  );

  const changeStep = (current: number) => {
    if (isWorkflowEdited && currentStep === 0) {
      setNextStep(current);
      setIsModalVisible(true);
    } else {
      setCurrentStep(current);
    }
  };

  const goToNextStep = () => {
    if (isWorkflowEdited && currentStep === 0) {
      setIsModalVisible(true);
    } else {
      setNextStep(currentStep + 1);
      setCurrentStep(currentStep + 1);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  };
  const loadSprint = async () => {
    if (sprintId) {
      try {
        const res = await getSprint(sprintId).call;
        setSprintData(res.data);
      } catch (error) {}
    }
  };

  useEffect(() => {
    loadSprint();
  }, [sprintId]);

  return (
    <div className='menu' data-testid='menu'>
      {!!sprintData && (
        <Header
          name={sprintData?.mission?.name}
          uid={sprintData?.uid}
          why_sprinting={sprintData?.why_sprinting ?? ''}
          status={sprintData?.status}
        />
      )}
      <Steps current={currentStep} onChange={changeStep} items={menuItems} />
      <div className='grid gap-4 mb-4'>
        {currentStep == 0 && <Planning />}
        {currentStep == 1 && <Action />}
        {currentStep == 2 && <Closed />}
        <Footer
          onClickNext={goToNextStep}
          title={`Finish ${currentStepTitle}`}
          isLastStep={isLastStep}
        />
      </div>
      <ContinueWithoutSavingModal
        isOpen={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsModalVisible(false);
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setCurrentStep(nextStep);
        }}
      />
    </div>
  );
};

export default Menu;
