import React, {useEffect, useState} from 'react';
import './PerformanceDashboard.scss';
import {CategoriesDropdowns, OverviewCard} from './components';
import {Col, Row} from 'antd';
import {getOrganizationPerformance} from '../../Services/Performance.service';
import {useParams} from 'react-router-dom';
import {Performance} from 'types/Performance';

type PerformanceDashboardParams = {
  id: string;
};

/**
 * Renders the performance dashboard component.
 *
 * @return {JSX.Element} The performance dashboard component.
 */
const PerformanceDashboard: React.FC = () => {
  const [performanceData, setPerformanceData] = useState({} as Performance);
  const {id} = useParams<PerformanceDashboardParams>();

  const loadPerformancesCategories = async () => {
    try {
      const res = await getOrganizationPerformance(id).call;
      setPerformanceData(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    loadPerformancesCategories();
  }, []);

  return (
    <div className='PerformanceDashboard'>
      <p className='text-xl'>Overview</p>
      <Row gutter={12} className='mt-10'>
        <Col span={8}>
          <OverviewCard
            quantity={performanceData?.projects}
            key_card='projects'
          />
        </Col>
        <Col span={8}>
          <OverviewCard
            quantity={performanceData?.sprints}
            key_card='sprints'
          />
        </Col>
        <Col span={8}>
          <OverviewCard
            quantity={performanceData?.team_members}
            key_card='team_members'
          />
        </Col>
      </Row>
      <p className='text-xl my-10'>By Category</p>
      {performanceData?.categories && (
        <CategoriesDropdowns categories={performanceData.categories} />
      )}
    </div>
  );
};

export default PerformanceDashboard;
