import React, { useRef, useState } from 'react';
import { Input, Result, Collapse } from 'antd';
import Slider from "react-slick";
import PublicLayout from '../../Components/PublicLayout/PublicLayout';
import { getSmartSeach } from '../../Services/SmartSolution.service';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './styles/MainSearch.scss';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../Routes.constants';
import SolutionSidebar from './components/SolutionSidebar/SolutionSidebar';

const { Search } = Input;
const { Panel } = Collapse;

/**
 * SampleNextArrow is a function that takes in a props object and
 * returns a div element. It is used as the next arrow component
 * in a slider. The function receives a className and onClick
 * property from the props object, and assigns them to the
 * corresponding attributes of the returned div element.
 *
 * @param {object} props - The props object containing the className
 *   and onClick properties.
 * @return {JSX.Element} - The rendered div element.
 */
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    />
  );
}

/**
 * A function that represents a sample previous arrow.
 *
 * @param {object} props - The props object containing the className and onClick properties.
 * @return {JSX.Element} A JSX element representing the previous arrow.
 */
function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    />
  );
}

/**
 * Renders the MainSearch component.
 *
 * @return {jsx} The rendered MainSearch component.
 */
const MainSearch = () => {
  const [summary, setSummary] = useState("first");
  const [loading, setLoading] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [solutionSidebar, setSolutionSiderbar] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(false);
  const searchRef = useRef();
  const navigate = useHistory();

  const hiddenTitleAndColor = summary !== "first";

  /**
   * Searches for a value and updates the summary state with the result.
   *
   * @param {string} value - The value to search for.
   * @return {Promise<void>} - A promise that resolves when the search is complete.
   */
  const search = async (value) => {
    if (value.length) {
      try {
        setLoading(true)
        setSummary(null)
        const res = await getSmartSeach(value).call;
        setSummary(res.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
  }

  /**
   * Executes a new search with the given value.
   *
   * @param {any} value - The value to search for.
   * @return {void} No return value.
   */
  const newSearch = (value) => {
    setSelectedSolution(false)
    setValueSearch(value)
    search(value)
  }

  /**
   * Updates the selected solution and sets the solution sidebar.
   *
   * @param {any} value - The value to be set as the selected solution.
   * @return {void} No return value.
   */
  const selectPromnt = (value) => {
    setSelectedSolution(value)
    setSolutionSiderbar(true)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    margin: "50px",
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  const customPanelStyle = {
    background: 'transparent',
    borderRadius: 20,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
    border: "1px solid #fff"
  };

  return (
    <div className='mainsearch'>
      <div className={'mainsearch-bg-black'}>
        <PublicLayout changeColor={hiddenTitleAndColor}>
          <div className={` ${hiddenTitleAndColor ? "h-0 opacity-0" : "mainsearch-container"} text-center transition-1-ease`}>
            <h1 className="mainsearch-container-title">Let’s Solve Healthcare Challenges</h1>
          </div>
          <div className="mainsearch-container-search">
            <Search
              className="mainsearch-container-search-bar"
              placeholder="What do you want to solve today?"
              ref={e => searchRef.current = e} value={valueSearch}
              onChange={e => setValueSearch(e.target.value)}
              onSearch={value => search(value)} enterButton />
          </div>
          <hr className={` ${hiddenTitleAndColor ? "d-none" : "hr-yellow"}`} />
          <div className="h-100 mb-25">
            {
              loading ?
                <div className="text-center smartsolutions-container-loading">
                  <img src="/img/loading-search.gif" alt="" className="w-25" />
                </div>
                :
                <div>
                  {
                    summary !== null ?
                      summary !== "first" ?
                        <div className="flex">
                          <div className="w-70 collapse">
                            {
                              summary?.solutions?.map((item, index) => (
                                <Collapse key={index} collapsible={false} bordered={false} style={customPanelStyle} defaultActiveKey={["0"]}>
                                  <Panel header={`Solution ${index + 1}: ${item.solution}`} key={index} className="w-100" showArrow={false}>
                                    <div key={index}>
                                      <p className="text-white"><b>Impact:</b></p>
                                      <p className="text-white">{item.impact}</p>
                                      <p className="text-white mt-10"><b>Key Drivers:</b></p>
                                      {
                                        item.key_drivers.split(",").map((key, index) =>
                                          <p className="text-white ml-15" key={index}>• {key}</p>
                                        )
                                      }
                                      <p className="text-white mt-10"><b>Performance Metrics:</b></p>
                                      {
                                        item.performance_metrics.split(",").map((key, index) =>
                                          <p className="text-white ml-15" key={index}>• {key}</p>
                                        )
                                      }
                                      <p className="text-white mt-10"><b>Sources:</b></p>
                                      <p className="text-white">{item.sources}</p>
                                      <div className="flex justify-end mt-15">
                                        <button type="button" className="talk-btn pointer" onClick={() => selectPromnt(item)}>Show me how</button>
                                      </div>
                                    </div>
                                  </Panel>
                                </Collapse>
                              ))
                            }
                          </div>
                          <div className="mainsearch-related-search-container">
                            <div className={`mainsearch-related-search  ${!selectedSolution ? 'h-0 opacity-0' : 'h-650px'} mb-10 transition-1-ease`}>
                              <SolutionSidebar newPromnt={selectedSolution} visible={solutionSidebar} closeModal={() => setSolutionSiderbar(false)} />
                            </div>
                            <div className={`mainsearch-related-search p-20 h-300px`}>
                              <h3 className="text-white mb-10">Related Searches</h3>
                              {
                                summary?.related_searches?.split(",").map((key, index) =>
                                  <button className="mainsearch-related-search-btn mb-10" key={index} onClick={() => newSearch(key)}><span className="p-5">{key}</span></button>
                                )
                              }
                            </div>
                          </div>
                        </div>
                        : ""
                      :
                      <div className="">
                        <Result
                          status="warning"
                          title={<span className="text-white"> Sorry, our AI is saturated right now, please try again later.</span>}
                        />
                      </div>
                  }
                </div>
            }
          </div>
          <div className={` ${hiddenTitleAndColor ? "h-0 opacity-0" : "mainsearch-slider"} transition-1-ease`}>
            <p className="text-white my-25"><b>Blue Agilis</b> is your starting point for creating change in healthcare. Never show up empty-handed to your strategy meetings. We provide you a simple summary of the key drivers that will make a difference in solving your healthcare problem.</p>
            <div className={` ${hiddenTitleAndColor ? "d-none" : "mx-auto"}`}>
              <Slider {...settings}>
                <div className="text-center bg-white mainsearch-slider-border p-15">
                  <p className="text-blue">Meet our healthcare solution!</p>
                  <p><b>Scroll to see top searches</b></p>
                  <p>Ask real questions & get complete answers!</p>
                  <button type="button" className="search-btn-slide" onClick={() => navigate.push(ROUTES.CONTACT)}>Learn More</button>
                </div>
                <div className="mainsearch-slider-border p-15">
                  <p className="text-center text-white">"How to reduce ER Utilization Rates"</p>
                  <div className="btn-container-postion">
                    <button type="button" className="talk-btn-slide mt-5" onClick={() => newSearch("How to reduce ER Utilization Rates")}>Try it!</button>
                  </div>
                </div>
                <div className="mainsearch-slider-border p-15">
                  <p className="text-center text-white">"How to improve medication adherence in diabetic patients"</p>
                  <div className="btn-container-postion">
                    <button type="button" className="talk-btn-slide mt-5" onClick={() => newSearch("How to improve medication adherence in diabetic patients")}>Try it!</button>
                  </div>
                </div>
                <div className="mainsearch-slider-border p-15">
                  <p className="text-center text-white">"How to increase compliance with annual wellness visits"</p>
                  <div className="btn-container-postion">
                    <button type="button" className="talk-btn-slide mt-5" onClick={() => newSearch("How to increase compliance with annual wellness visits")}>Try it!</button>
                  </div>
                </div>
                <div className="mainsearch-slider-border p-15">
                  <p className="text-center text-white">“How to reduce readmission rates in COPD patients”</p>
                  <div className="btn-container-postion">
                    <button type="button" className="talk-btn-slide mt-5" onClick={() => newSearch("How to reduce readmission rates in COPD patients")}>Try it!</button>
                  </div>
                </div>
                <div className="mainsearch-slider-border p-15">
                  <p className="text-center text-white">"How to improve use of medications in CVD patients"</p>
                  <div className="btn-container-postion">
                    <button type="button" className="talk-btn-slide mt-5" onClick={() => newSearch("How to improve use of medications in CVD patients")}>Try it!</button>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </PublicLayout>

      </div >
    </div >
  );
};

export default MainSearch;
