import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { capitalize } from 'lodash';

interface TextAreaWithLimitProps {
  name: string;
  label: string;
  readOnly: boolean;
  dispatchAction: (value: string) => void;
  maxLength?: number;
  warningLength?: number;
  initialValue?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
}

const TextAreaWithLimit: React.FC<TextAreaWithLimitProps> = ({
  name,
  label = name,
  readOnly,
  dispatchAction,
  maxLength = 250,
  warningLength = 249,
  initialValue = '',
  placeholder = '',
  className = '',
  required = false,
}) => {
  // State to track the warning message
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  return (
    <Form.Item
      className={className}
      name={name}
      initialValue={initialValue}
      validateStatus={warningMessage ? 'error' : undefined}
      help={warningMessage}
      rules={[
        {
          required: required,
          message: `${capitalize(label)} is required`,
        },
        {
          validator: (_, value) => {
            if (value && value.length > maxLength) {
              return Promise.reject(
                new Error(`${capitalize(label)} cannot exceed ${maxLength} characters`)
              );
            } else if (value && value.length === maxLength) {
              setWarningMessage(`${capitalize(label)} has reached the maximum character limit of ${maxLength}`);
            } else {
              setWarningMessage(null);
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input.TextArea
        autoSize={{ minRows: 1, maxRows: 6 }}
        placeholder={placeholder}
        disabled={readOnly}
        onChange={(e) => {
          const newValue = e.target.value;
          dispatchAction(newValue);
          if (newValue.length >= warningLength && newValue.length < maxLength) {
            setWarningMessage(`${capitalize(label)} is approaching the character limit`);
          } else if (newValue.length < warningLength) {
            setWarningMessage(null); // Clear warning if length is below warning threshold
          }
        }}
        maxLength={maxLength}
        showCount
      />
    </Form.Item>
  );
};

export default TextAreaWithLimit;
