import React, { useEffect, useState} from 'react';
import { TagDetailType } from 'types/Tags';
import { useHistory, useParams } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';
import { getTagById, getTagDetailsById, removeTag } from 'Services/Tag.service';
import { Input } from 'antd';
import TagDetailList from './components/TagDetailList/TagDetailList';
import { getNotification } from 'Components/GetNotification';
import { RemoveTagModal } from './components/RemoveTagModal';

const { Search } = Input;
/**
 * Renders the Tag Page.
 *
 * @return {JSX.Element} The rendered Tag Page.
 */
const TagDetailPage: React.FC = () => {
  const navigate = useHistory();
  const { id }:any = useParams();

  const [tag, setTag] = useState<TagDetailType | undefined>({ id: 0, type:'', type_id: 0, tagged_elements: 0});
  const [isOpenDeleteTag, setIsOpenDeleteTag] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<TagDetailType | undefined>();
  const [dataSource, setDataSource] = useState<TagDetailType[]>([]);

  const handleSearch = (value:string) => {
    const filteredData = (tagDetailData?.data?.results ?? []).filter((item:any) =>
      Object.keys(item).some((key:any) =>
        String(item[key]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setDataSource(filteredData);
  };

  const { 
    data:tagData,
    error:tagError,
    callFunction: fetchTagById
  } = useManualApiWithLoading<any>({
    apiFunction: getTagById
  });

  useEffect(()=>{
    fetchTagById(id)
  }, [])

  const { 
    data:tagDetailData,
    error:tagDetailError,
    callFunction: fetchTagDetailById
  } = useManualApiWithLoading<any>({
    apiFunction: getTagDetailsById
  });

  const { 
    data: removeTagData,
    error: removeTagDataError,
    callFunction: removeTagFromObject
  } = useManualApiWithLoading<any>({
    apiFunction: removeTag
  });

  const handleDeleteTag = () => {
    removeTagFromObject({
      id: id,
      type: selectedTag?.type,
      type_id:selectedTag?.type_id
    })
    setIsOpenDeleteTag(false)
  }

  useEffect(()=> {
    setDataSource(tagDetailData?.data?.results)
  }, [tagDetailData])


  useEffect(() => {
    fetchTagDetailById(id)
  }, [removeTagData])

  useEffect(() => {
    setTag(tagData?.data)
    if(tagData?.data?.count > 0) {
      fetchTagDetailById(id)
    }

  }, [tagData])

  useEffect(() => {
    if(tagError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not retreive Tag info',
      });
    }
    if(tagDetailError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not retreive Tag object',
      });
    }
    if(removeTagDataError){
      getNotification('error', {
        header: 'Error',
        body: 'Could not retreive Tags list',
      });
    }
 }, [tagError, removeTagDataError, tagDetailError])

  return (
    <div className='tagPage'>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate.push('/') } >
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate.push('/tags') } >
          <span>Tags</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{tag?.name ?? 'Tag Details'}</span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <br/>
      <h1 className='font-mulish font-bold text-6xl'>{tag?.name ?? 'Tag Details'}</h1>
      <div className='flex flex-row justify-end'>
          <Search 
            placeholder='Search Tags' 
            style={{ width: 200 }}
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
          />

      </div>
      <br/>
      <TagDetailList
        data={dataSource ?? []}
        setSelectedTag={setSelectedTag}
        setIsOpenDeleteTag={setIsOpenDeleteTag}
      />
      <RemoveTagModal
        tag={selectedTag}
        open={isOpenDeleteTag}
        handleCancel={() => {setIsOpenDeleteTag(false)}}
        handleSave={handleDeleteTag}
      />
 

    </div>
  );
};

export default TagDetailPage;

  
