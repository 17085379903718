export function updateSprintStart(state) {
  return {
    ...state,
    updateSprintApi: {
      isProcessing: true,
      success: null,
      error: null,
    },
  };
}
export function updateSprintSuccess(state, payload) {
  return {
    ...state,
    updateSprintApi: {
      isProcessing: false,
      success: true,
      error: null,
    },
  };
}
export function updateSprintFailed(state, payload) {
  return {
    ...state,
    updateSprintApi: {
      isProcessing: false,
      success: null,
      error: 'Error in updating sprint',
    },
  };
}

export function deleteSprintStart(state, payload) {
  return {
    ...state,
    deleteSprints: {
      isProcessing: true,
      error: null,
      success: null,
    },
  };
}

export function deleteSprintSuccess(state, payload) {
  return {
    ...state,
    deleteSprints: {
      isProcessing: false,
      error: null,
      success: true,
    },
  };
}

export function deleteSprintFailed(state, payload) {
  return {
    ...state,
    deleteSprints: {
      isProcessing: false,
      error: true,
      success: false,
    },
  };
}
