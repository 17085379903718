import React from 'react';
import {Link} from 'react-router-dom';
import {Chip} from 'Components/atoms';
import {Dropdown} from 'Components/Dropdown';
import SVGIcons from 'Components/SVGIcons';
import moment from 'moment';
import global from 'styles/global/style.module.scss';

import { useTranslation } from "react-i18next"
import TagFloatingInfo from 'Components/TagFloatingInfo';
import { Tag } from 'types/Tags';

interface NetworkCardProps {
  id: number;
  group_name?: string;
  is_on_hold: boolean;
  profile_image_url: string;
  updated_at: string;
  name: string;
  mission_count: number;
  sprint_count: number;
  onArchiveClick: () => void;
  onDeleteClick: () => void;
  onDropdownClick: (id: number) => void;
  onEditClick: () => void;
  showDropdown: boolean;
  tags?: Tag[];
}

const stopEvent = (e: React.SyntheticEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const MESSAGES = {
  NOT_ASSIGNED: 'Not Assigned',
};

export const NetworkCard: React.FC<NetworkCardProps> = ({
  id,
  group_name,
  tags,
  profile_image_url,
  is_on_hold,
  updated_at,
  name,
  mission_count,
  sprint_count,
  onArchiveClick,
  onDeleteClick,
  onDropdownClick,
  onEditClick,
  showDropdown,
}) => {

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Link
      data-testid="networkCard"
      to={`${id}/organisation-insights/?orgName=${encodeURIComponent(name)}`}
      className='w-full bg-white p-4 rounded-lg shadow-lg min-h-[390px] flex flex-col text-center !gap-10 group'>
      <header className='w-full flex justify-between items-center relative z-10'>
        <div className='flex flex-row gap-2'>
          <Chip
            className={group_name ? 'bg-primary-selected' : 'bg-gray-200'}
            text={group_name ? group_name : MESSAGES.NOT_ASSIGNED}
          />
          <TagFloatingInfo tags={tags as Tag[]}/>
        </div>
        <div className={global.cardControls}>
          <div className={global.controlsItem}>
            <div
              onClick={(e) => {
                stopEvent(e);
                onDropdownClick(id);
              }}
              className={global.dropBox}>
              {/* @ts-ignore */}
              <Dropdown
                addOnClass={showDropdown}
                customButton={
                  <span className={global.dropIcon}>
                    <svg viewBox='0 0 2.118 8.471'>
                      <path
                        data-name='Path 268'
                        d='M0,7.412A1.059,1.059,0,1,1,1.059,8.471,1.059,1.059,0,0,1,0,7.412ZM0,4.236A1.059,1.059,0,1,1,1.059,5.294,1.059,1.059,0,0,1,0,4.236ZM0,1.059A1.059,1.059,0,1,1,1.059,2.118,1.059,1.059,0,0,1,0,1.059Z'
                        fill='#818e9b'
                      />
                    </svg>
                  </span>
                }>
                <ul className='card-dropdown'>
                  <li>
                    <button
                      type='button'
                      className='flex items-center w-full'
                      onClick={(e) => {
                        stopEvent(e);
                        onArchiveClick();
                      }}>
                      <span>
                        <SVGIcons
                          type={is_on_hold ? 'SVG-unarchive' : 'SVG-archive'}
                        />
                      </span>
                      <span className='grow'>
                        {is_on_hold ? 'Unarchive' : 'Archive'}
                      </span>
                    </button>
                  </li>
                  {!is_on_hold && (
                    <>
                      <li>
                        <button
                          type='button'
                          className='flex items-center w-full'
                          onClick={(e) => {
                            stopEvent(e);
                            onEditClick();
                          }}>
                          <span>
                            <SVGIcons type={'SVG-edit'} />
                          </span>
                          <span className='grow'>{t("Button.Edit")}</span>
                        </button>
                      </li>
                      <li>
                        <button
                          type='button'
                          className='flex items-center w-full'
                          onClick={(e) => {
                            stopEvent(e);
                            onDeleteClick();
                          }}>
                          <span>
                            <SVGIcons type={'SVG-delete'} />
                          </span>
                          <span className='grow'>{t("Button.Delete")}</span>
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
      <div className='w-full flex justify-center'>
        <div className='w-24 h-24 rounded-full bg-gray-200 overflow-hidden'>
          <img
            src={profile_image_url}
            className='w-full h-full object-contain object-center'
          />
        </div>
      </div>
      <div className='w-full text-center flex flex-col gap-2'>
        <h3 className='text-lg font-semibold text-primary line-clamp-3 group-hover:text-primary-hover'>
          {name}
        </h3>
        {updated_at && (
          <span className='text-sm font-medium text-black'>
            Updated on {moment(updated_at).format('MM/DD/YYYY')}
          </span>
        )}
        <ul className='flex flex-col gap-2 font-medium text-black'>
          <li className='flex gap-3 justify-center'>
            <span>Projects</span>
            <span className='text-primary'>{mission_count}</span>
          </li>
          <li className='flex gap-3 justify-center'>
            <span>{t("commons.interventions")}</span>
            <span className='text-primary'>{sprint_count || 0}</span>
          </li>
        </ul>
      </div>
    </Link>
  );
};
