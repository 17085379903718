/*eslint-disable*/
import {createSelector} from 'reselect';

const getMissionReducer = (state) => state.MissionReducer;

const getOrganisationReducer = (state) => state.OrganisationReducer;

const getEmployeeReducer = (state) => state.EmployeeReducer;

const mergeSelectedReducers = (
  MissionReducer,
  OrganisationReducer,
  EmployeeReducer
) => {
  return {
    missionList: MissionReducer.missionList,
    organisationData: OrganisationReducer.organisationData,
    getOrganisationsDetails: OrganisationReducer.getOrganisationsDetails,
    getMissions: MissionReducer.getMissions,
    departmentList: EmployeeReducer.departmentList,
    designationList: EmployeeReducer.designationList,
    addInsightsForm: EmployeeReducer.addInsightsForm,
    employeesList: EmployeeReducer.employeesList,
    limit: MissionReducer.limit,
    listMissionCount: MissionReducer.listMissionCount,
  };
};

export const SelectState = createSelector(
  [getMissionReducer, getOrganisationReducer, getEmployeeReducer],
  mergeSelectedReducers
);
