import {getNotification} from '../../../../Components/GetNotification';
export * from './objectivesHelper';
export * from './missionStepsHelper';

export function getMissionProblemTypeStart(state, payload) {
  return {
    ...state,
    missionProblemTypes: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}
export function getMissionProblemTypeSuccess(state, payload) {
  return {
    ...state,
    missionProblemTypes: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.data,
    },
  };
}
export function getMissionProblemTypeFailed(state, payload) {
  return {
    ...state,
    missionProblemTypes: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}

export function createMissionStart(state, payload) {
  return {
    ...state,
    createMission: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}
export function createMissionSuccess(state, payload) {
  getNotification('success', {
    header: 'Success!',
    body: 'Project created',
  });
  return {
    ...state,
    createMission: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.data,
    },
  };
}
export function createMissionFailed(state, payload) {
  getNotification('error', {
    header: 'Failed!',
    body: 'Failed to create project',
  });
  return {
    ...state,
    createMission: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}

export function fetchQuestionStart(state, payload) {
  return {
    ...state,
    fetchMissionQuestions: {
      ispending: true,
      completed: false,
      failed: false,
      data: [],
    },
  };
}
export function fetchQuestionSuccess(state, payload) {
  return {
    ...state,
    fetchMissionQuestions: {
      ispending: false,
      completed: true,
      failed: false,
      data: payload.data,
    },
  };
}
export function fetchQuestionFailed(state, payload) {
  return {
    ...state,
    fetchMissionQuestions: {
      ispending: false,
      completed: false,
      failed: true,
      data: [],
    },
  };
}
