import React from 'react';
import {Modal} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearImprovementTacticModal,
  closeImprovementTacticModal,
} from 'ReduxV2/state';

import { useTranslation } from "react-i18next"

export const ImprovementTacticModal: React.FC = () => {
  const {isOpen, improvement_tactic, what_is_this, how_drive_improvement} =
    useSelector(
      //@ts-ignore
      (state) => state.improvementTacticModal
    );

  const dispatch = useDispatch();

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Modal
      className='improvement-tactic-modal'
      wrapClassName='rounded-2xl'
      open={isOpen}
      footer={false}
      onCancel={() => {
        dispatch(closeImprovementTacticModal());
      }}
      afterClose={() => {
        dispatch(clearImprovementTacticModal());
      }}>
      <div className='grid gap-4 mt-4 px-8'>
        <h1 className='text-4xl leading-tight text-center'>{`Improvement Tactic #${improvement_tactic}`}</h1>
        <div>
          <h2 className='text-lg font-semibold'>{t("Improvement_tactic.What")}?</h2>
          <span className='text-sm font-medium'>{what_is_this}</span>
        </div>
        <div>
          <h2 className='text-lg font-semibold'>
            {t("Improvement_tactic.How")}?
          </h2>
          <span className='text-sm font-medium'>{how_drive_improvement}</span>
        </div>
        <div className='flex justify-center'>
          <button
            className='px-4 py-2 font-medium self-center'
            onClick={() => {
              dispatch(closeImprovementTacticModal());
            }}>
            {t("Button.Close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
