import React, {useEffect, useState} from 'react';
import _, { capitalize } from 'lodash';
import {Form, Input, Select} from 'antd';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {Icon} from 'Components/atoms';
import {getNotification} from 'Components/GetNotification';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next"
import {
  addStep,
  updateStep,
  moveStep,
  removeStep,
  addSubstepAfter,
  moveSubstep,
  removeSubstep,
  updateSubstep,
  updateWorkflow,
  selectWorkflow,
  revertSprint,
  cleanWorkflow,
  createSprintWorkflow,
  cleanSprintWorkflowSuccess,
  cleanSprintWorkflowError,
} from 'ReduxV2/state';
import {
  SprintWorkflowStep,
  PostPatchSprintWorkflowSubstep,
  SprintWorkflow,
} from 'types/SprintWorkflow';
import { TextAreaWithLimit } from 'Components/TextAreaWithLimit';

const MESSAGES = {
  ADD_STEP: 'Add Section',
  ADD_STEPS_INFO:
    'Add the Steps you need and assign a Role Responsible to each step.',
  USE_SOLUTION_SEARCH: 'Integrate Solution Search',
};

type ReorderStepParams = {
  stepId: number;
  oldOrder: number;
  newOrder: number;
};

type ReorderSubstepParams = {
  sw_step: number;
  oldOrder: number;
  newOrder: number;
};

type FormValues = {
  name: string;
  summary?: string;
  nameLoaded: boolean;
};

type SelectOption = {
  value: number;
  label: string;
};

const WorkflowSubStep: React.FC<
  PostPatchSprintWorkflowSubstep & {
    disableRemove: boolean;
    index: number;
    readOnly: boolean;
  }
> = ({
  sw_step,
  id,
  name,
  role_responsible,
  order,
  disableRemove,
  index,
  readOnly,
}) => {
  const dispatch = useDispatch();
  const updateSubstepTitle = (title: string) => {
      if (id) {
        dispatch(
          updateSubstep({
            sw_step: sw_step,
            sw_substep: id,
            name: title,
          })
        );
      }
  };

  const updateSubstepRole = (role: number) => {
    if (id) {
      dispatch(
        updateSubstep({
          sw_step: sw_step,
          sw_substep: id,
          role_responsible: role,
        })
      );
    }
  };

  const insertSubstepAfter = (index: number) => {
    dispatch(addSubstepAfter({sw_step: sw_step, index: index}));
  };

  const sprintWorkflowRoles = useSelector(
    (state: any) => state?.sprint?.sprintWorkflowRoleOptions
  );

  const filterOption = (
    input: string,
    option?: {label: string; value: string}
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className='flex gap-1 items-center'>
      <div className='relative flex items-center justify-center shrink-0'>
        <span className='w-6'>{order}.</span>
        <Icon name='vertical-drag' className='w-5 h-5' />
      </div>
      <div className='flex-1'>
        <TextAreaWithLimit
          name={`substep-${id}-name`}
          label={`Substep name`}
          readOnly={readOnly}
          dispatchAction={updateSubstepTitle}
          maxLength={1000}
          warningLength={1000}
          initialValue={name ?? ''}
          placeholder='Step Name'
          className='!rounded text-lg !font-medium'
        />
      </div>
      <div className='max-w=[300px]'>
      <Select
        showSearch
        placeholder='Role'
        disabled={readOnly}
        defaultValue={role_responsible}
        filterOption={filterOption}
        options={sprintWorkflowRoles}
        className='!min-w-[300px] !font-medium !rounded'
        onChange={(value) => {
          updateSubstepRole(value);
        }}
      />
      </div>
      <div className='shrink-0'>
        <button
          type='button'
          disabled={readOnly}
          className='w-8 h-8 bg-primary rounded flex items-center justify-center disabled:cursor-not-allowed'
          onClick={() => {
            insertSubstepAfter(index);
          }}>
          <Icon name='plus-square' className='w-5 h-5' />
        </button>
      </div>
      {!disableRemove && (
        <div className='shrink-0 flex items-center'>
          <button
            type='button'
            disabled={readOnly}
            className='w-8 h-8 rounded border border-solid border-gray-400 flex items-center justify-center disabled:cursor-not-allowed'
            onClick={() => {
              if (id) {
                dispatch(
                  removeSubstep({
                    sw_step: sw_step,
                    sw_substep: id,
                  })
                );
              }
            }}>
            <Icon name='minus-gray-900' className='w-5 h-5' />
          </button>
        </div>
      )}
    </div>
  );
};

const WorkflowStep: React.FC<
  SprintWorkflowStep & {readOnly: boolean; provided: any}
> = ({id, name, order, sub_steps, notes, readOnly, provided}) => {
  const dispatch = useDispatch();
  const disableRemove = sub_steps.length <= 1;

  const updateStepTitle = (title: string) => {
    dispatch(
      updateStep({
        stepId: id,
        name: title,
        order: order,
        notes: notes,
      })
    );
  };

  const updateStepNotes = (stepNotes: string) => {
      dispatch(
        updateStep({
          stepId: id,
          name: name,
          order: order,
          notes: stepNotes,
        })
      );
  };

  const reorderSubstep = ({
    sw_step,
    oldOrder,
    newOrder,
  }: ReorderSubstepParams): void => {
    if (oldOrder == newOrder) return;
    const substepToReorder = _.find(sub_steps, {
      order: oldOrder + 1,
    });

    if (substepToReorder) {
      dispatch(
        moveSubstep({
          sw_step: sw_step,
          sw_substep: substepToReorder.id,
          endOrder: newOrder,
        })
      );
    }
  };

  const [showNotesInput, setShowNotesInput] = useState(false);

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <>
      <div
        className={`rounded-lg p-4 border-2 border-solid  ${
          readOnly ? 'bg-gray-100 border-gray-100' : 'bg-white border-gray-200'
        }`}>
        <div className='flex gap-2 mb-2.5'>
          <div className='flex-1'>
            <TextAreaWithLimit
              name={`step-${id}-name`}
              label={`Step notes`}
              readOnly={readOnly}
              dispatchAction={updateStepTitle}
              maxLength={250}
              warningLength={250}
              initialValue={name ?? ''}
              placeholder="Name"
              className='!rounded text-lg !font-medium'
            />
         </div>
          <div className='flex-1 flex justify-center items-center'>
            <div {...provided.dragHandleProps}>
              <Icon name='horizontal-drag' className='w-10 h-5' />
            </div>
          </div>
          <div className='flex-1 flex justify-end items-center'>
            {!readOnly && (
              <button
                type='button'
                className='flex items-center py-1 px-4 bg-white gap-2 rounded border border-solid border-gray-400'
                onClick={() => {
                  dispatch(removeStep(id));
                }}>
                <span>{t("Button.Delete")}</span>
                <Icon name='trashcan' />
              </button>
            )}
          </div>
        </div>
        <div className='grid gap-4 -mb-4'>
          <DragDropContext
            onDragEnd={(result) => {
              if (!result.destination) return;
              reorderSubstep({
                sw_step: id,
                oldOrder: result.source.index,
                newOrder: result.destination.index,
              });
            }}>
            <Droppable
              droppableId={`workflow-substeps-${id}`}
              type={`workflow-substeps-${id}`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {_.orderBy(sub_steps, ['order']).map(
                    (step: any, index: number) => (
                      <Draggable
                        key={step.id}
                        draggableId={`workflow-step-${step.id}-substep`}
                        isDragDisabled={readOnly}
                        index={index}>
                        {(provided) => (
                          <div
                            className='pb-4'
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}>
                            <WorkflowSubStep
                              index={index}
                              sw_step={id}
                              id={step.id}
                              name={step.name}
                              order={step.order}
                              role_responsible={step.role_responsible?.id}
                              disableRemove={disableRemove}
                              readOnly={readOnly}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className='flex justify-end'>
          <button
            type='button'
            onClick={() => setShowNotesInput(!showNotesInput)}
            className='flex items-center p-4 gap-2'>
            <Icon name='speech-bubble-primary' className='w-8 h-8' />
            <span className='font-medium text-base'>{t("Button.Delete")}</span>
          </button>
        </div>
      </div>
      {showNotesInput && (
        <div className='mt-1 rounded-lg p-4 shadow-lg text-black'>
          <TextAreaWithLimit
            name={`step notes`}
            label={`Step notes`}
            readOnly={readOnly}
            dispatchAction={updateStepNotes}
            maxLength={1000}
            warningLength={1000}
            initialValue={notes ?? ''}
            placeholder='What are some nudges to help the provider implement this workflow?'
            className='w-full p-4 rounded resize-vertical bg-transparent outline-none'
          />
        </div>
      )}
    </>
  );
};

const WorkflowVersionSelect: React.FC<{workflowVersions: SprintWorkflow[]}> = ({
  workflowVersions = [] as SprintWorkflow[],
}) => {
  const dispatch = useDispatch();
  const workflowOptions = workflowVersions.map((workflow) => ({
    value: workflow.id,
    label: workflow.name || 'Untitled Version',
  }));

  return (
    <Select
      disabled={workflowOptions.length <= 1}
      className='!min-w-[300px] !rounded !font-medium'
      placeholder='Version Select'
      options={workflowOptions}
      onChange={(value) => {
        dispatch(selectWorkflow({workflow_id: value}));
      }}
    />
  );
};

const Header: React.FC<{title?: string; readOnly?: boolean}> = ({
  title = 'Creating a Workflow',
  readOnly = false,
}) => {
  const workflowVersions = useSelector(
    (state: any) => state?.sprint?.sprintWorkflowVersions
  );

  return (
    <div className='flex flex-wrap gap-4 justify-between mb-2'>
      <h2>{title}</h2>
      <div className='flex gap-4'>
        <WorkflowVersionSelect workflowVersions={workflowVersions} />
        {!readOnly && (
          <button
            type='button'
            disabled={true}
            className={`flex gap-2 py-2 px-4 rounded font-medium text-white bg-primary 
            hover:bg-primary-hover 
            disabled:bg-disabled disabled:text-disabled-text disabled:cursor-not-allowed`}>
            <span>Import</span>
            <Icon name='arrow-down-container' className='w-5 h-5' />
          </button>
        )}
        <button
          type='button'
          disabled={true}
          className={`flex gap-2 py-2 px-4 rounded font-medium text-white bg-primary 
          hover:bg-primary-hover 
          disabled:bg-disabled disabled:text-disabled-text disabled:cursor-not-allowed`}>
          <span>Share</span>
          <Icon name='arrow-up-container' className='w-5 h-5' />
        </button>
      </div>
    </div>
  );
};

export const CreateWorkflow: React.FC<{readOnly?: boolean; title?: string}> = ({
  title = 'Creating a Workflow',
  readOnly = false,
}) => {
  const [form] = Form.useForm<FormValues>();

  const sprintWorkflow = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.data
  );
  const sprintWorkflowError = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.error
  );
  const sprintWorkflowSuccess = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.success
  );

  const isEdited = useSelector(
    (state: any) => state?.sprint?.sprintWorkflow?.isEdited
  );

  const sprintWorkflowName = sprintWorkflow?.name ?? null;
  const sprintWorkflowSteps = sprintWorkflow?.steps ?? [];
  const dispatch = useDispatch();

  /**
   * reorder step based on _array position_ (starts with 0).
   * the renumeration of the order property (starts with 1) happens in the thunk.
   */
  const reorderStep = ({
    stepId,
    oldOrder,
    newOrder,
  }: ReorderStepParams): void => {
    if (oldOrder == newOrder) return;
    dispatch(moveStep({stepId: stepId, endOrder: newOrder}));
  };

  useEffect(() => {
    if (sprintWorkflowError === 'No workflow found') {
      dispatch(cleanWorkflow());
    } else if (sprintWorkflowError) {
      getNotification('error', {
        header: 'Error',
        body: sprintWorkflowError,
      });
      dispatch(cleanSprintWorkflowError());
    }
  }, [sprintWorkflowError]);

  useEffect(() => {
    if (sprintWorkflowSuccess) {
      getNotification('success', {
        header: 'Success',
        body: sprintWorkflowSuccess,
      });
      dispatch(cleanSprintWorkflowSuccess());
    }
  }, [sprintWorkflowSuccess]);

  useEffect(() => {
    if (sprintWorkflowName) {
      form.setFieldsValue({
        nameLoaded: true,
        name: sprintWorkflow.name,
        summary: sprintWorkflow.summary, 
      });
    }
  }, [sprintWorkflowName, selectWorkflow, form]);

  const onFinish = () => {
    form.validateFields().then(() => {
      dispatch(createSprintWorkflow());
    });
  };

  // i18n translation hook
  const [t] = useTranslation()

  return (
    <Form
      form={form}
      initialValues={{
        nameLoaded: false,
        name: '',
        summary: '',
      }}
      className='py-6 px-4 bg-white rounded-lg shadow-lg'
      onFinish={onFinish}>
      <Header title={title} readOnly={readOnly} />
      <div className='grid gap-4 mb-12 max-w-3xl'>
        <div className='pl-4 text-sm font-medium'>
          <span className='text-negative'>*</span>
          <span> {t("Create_Workflow.Required")}.</span>
        </div>
        
        <div className='grid gap-2'>
          <label htmlFor='name'>
            <span>
              <span className='font-medium text-base'>{t("Create_Workflow.Name")}</span>
            </span>
          </label>
          {(sprintWorkflow?.name || sprintWorkflow?.name === '') && (
            <div className='w-[600px]'>
             <TextAreaWithLimit
                name="name"
                label={`Name`}
                readOnly={readOnly}
                dispatchAction={(value:any) => dispatch(updateWorkflow({ name: value }))}
                maxLength={250}
                warningLength={250}
                initialValue={sprintWorkflow?.name}
                required
              />
           </div>
         )}
        </div>
        <div className='grid gap-2'>
          <label htmlFor='summary'>
            <span>
              <span className='font-medium text-base'>{t("Create_Workflow.Summary")}</span>
            </span>
          </label>
          {(sprintWorkflow?.summary || sprintWorkflow?.summary === '') && (
            <div className='w-[600px]'>
              <TextAreaWithLimit
                name="summary"
                label={`Summary`}
                readOnly={readOnly}
                dispatchAction={(value:any) => dispatch(updateWorkflow({ summary: value }))}
                maxLength={250}
                warningLength={250}
                initialValue={sprintWorkflow?.summary}
              />
            </div>
         )}
        </div>

        <div className='flex justify-between'>
          <div className='grid gap-3'>
            <span className='font-medium text-base'>{t("Create_Workflow.Steps")}</span>
            {!readOnly && (
              <div>
                <button
                  type='button'
                  disabled={true}
                  className={`py-2 px-4 font-medium text-base rounded border border-solid border-primary 
                  disabled:cursor-not-allowed`}>
                  {MESSAGES.USE_SOLUTION_SEARCH}
                </button>
              </div>
            )}
            <span className='text-base font-medium text-secondary-text'>
              {MESSAGES.ADD_STEPS_INFO}
            </span>
          </div>
          <div className='flex items-end'>
            {!readOnly && (
              <button
                className='-mr-px py-1 px-4 flex gap-2 border border-solid border-gray-400 rounded items-center'
                type='button'
                onClick={() => {
                  dispatch(addStep());
                }}>
                <span>{MESSAGES.ADD_STEP}</span>
                <Icon name='add-gray-700' className='w-5 h-5' />
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;
            const stepToReorder = _.find(sprintWorkflowSteps, {
              order: result.source.index + 1,
            });
            reorderStep({
              stepId: stepToReorder.id,
              oldOrder: result.source.index,
              newOrder: result.destination.index,
            });
          }}>
          <Droppable droppableId='workflow-sections' type='workflow-sections'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {_.orderBy(sprintWorkflowSteps, ['order']).map(
                  (step: any, index: number) => (
                    <Draggable
                      key={step.id}
                      draggableId={`workflow-step-${step.id}`}
                      isDragDisabled={readOnly}
                      index={index}>
                      {(provided) => (
                        <div
                          className='mb-4'
                          {...provided.draggableProps}
                          ref={provided.innerRef}>
                          <WorkflowStep
                            provided={provided}
                            readOnly={readOnly}
                            id={step.id}
                            name={step.name}
                            order={step.order}
                            sub_steps={step.sub_steps}
                            notes={step.notes}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className='flex justify-end gap-4'>
        <button
          className='py-2 px-4 text-base font-medium border border-solid border-primary rounded'
          type='button'
          onClick={() => {
            dispatch(revertSprint());
          }}>
          Cancel
        </button>
        <input
          disabled={!isEdited}
          className={`py-2 px-4 text-white text-base font-medium bg-primary hover:bg-primary-hover rounded 
            transition-colors duration-150
            disabled:bg-disabled disabled:text-disabled-text disabled:cursor-not-allowed`}
          type='submit'
          value='Save'
        />
      </div>
    </Form>
  );
};
