import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, DatePicker, Row, Select } from 'antd';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getNotification } from "../../../../../../../../../../Components/GetNotification";
import { deletePriorActionItems, getPriorActionItems, getSprint, patchPriorActionItems, patchSprints, postPriorActionItems } from '../../../../../../../../../../Services/Sprints.service';
import { getMemberList } from '../../../../../../../../../../Services/Teams.service';
import './styles/General .scss';
import DateDebounce from '../../../../../../../../../../Components/DateDebounce/DateDebounce';
import InputDebounce from '../../../../../../../../../../Components/InputDebounce/InputDebounce';
import DebounceLoading, { dialogStartSubject$, dialogStopSubject$ } from '../../../../../../../../../../Components/DebounceLoading/DebounceLoading';
import { AUTOSAVING_DELAY } from '../../../../../../../../../../../config/constants';
import { Evaluation } from '../Evaluation';

import { useTranslation } from "react-i18next"

const { RangePicker } = DatePicker;

/**
 * Renders the General component.
 *
 * @return {JSX.Element} The rendered General component.
 */
const General = () => {
  const [sprintData, setSprintData] = useState();
  const [attendees, setAttendees] = useState([]);
  const [disable, setDisable] = useState(false);
  const { register, handleSubmit, control, setValue, getValues, reset, formState: { errors }, watch } = useForm();

  const { id } = useParams();

  const [t] = useTranslation()

  const refForm = useRef();

  const watchLengthType = watch("length");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "actions"
  });
  const watchFieldArray = watch("actions");
  let controlledFields = [];

  if (watchFieldArray) {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index]
      };
    });
  }

  const location = useLocation();
  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };
  const queryParams = getQueryParams(location.search);
 
  const missionSprintIdParam = queryParams.get('mission_sprint');

  const sprintId = missionSprintIdParam ?? useSelector(state => {
    return state.sprint.value
  });

  /**
   * Saves general information.
   *
   * @param {Object} params - The parameters for saving the general information.
   * @param {string} params.id - The ID of the information.
   * @param {string} params.length - The length of the information.
   * @param {string} params.why_to_create_demand - The reason for creating the demand.
   * @param {Date} params.start - The start date of the information.
   * @param {Date} params.end - The end date of the information.
   * @param {string} params.trying_to_accomplish - The goal of the information.
   * @param {Date[]} params.custom_length - The custom length of the information.
   * @param {Object[]} params.actions - The actions associated with the information.
   * @param {string} params.actions[].id - The ID of the action.
   * @param {string} params.actions[].mission_sprint - The mission sprint of the action.
   * @return {Promise} A promise that resolves when the general information is saved.
   */
  const saveGeneralInformation = async (params) => {
    dialogStartSubject$.setSubject = true;
    const data = {
      id: params?.id,
      status: "PLANNING",
      length: params?.length,
      why_to_create_demand: params?.why_to_create_demand,
      start: params?.start ? moment(params?.start).format('YYYY-MM-DDTHH:mm:ss') : null,
      end: params?.end ? moment(params?.end).format('YYYY-MM-DDTHH:mm:ss') : null,
      trying_to_accomplish: params?.trying_to_accomplish,
    }

    if (params?.length == 'CUSTOM_LENGTH') {
      data.custom_length_start = moment(params?.custom_length[0]).format('YYYY-MM-DDTHH:mm:ss')
      data.custom_length_end = moment(params?.custom_length[1]).format('YYYY-MM-DDTHH:mm:ss')
    }

    try {
      await patchSprints(data)

      if (params?.actions?.length) {
        const actionsRes = params?.actions?.map(async action => {
          if (action?.id) {
            await patchPriorActionItems(action).call
          } else {
            await postPriorActionItems({ ...action, mission_sprint: sprintId }).call;
          }
        });

        await Promise.all(actionsRes);
      }

      getNotification('success', {
        header: '',
        body: 'The information has been saved successfully',
      });

    } catch (error) {
      console.log(error)
    }
    dialogStopSubject$.setSubject = false;
  }

  const loadSprint = async () => {
    if (sprintId) {
      try {

        const res = await getSprint(sprintId).call;
        setSprintData(res?.data);
        Object.keys(res?.data).map((key) => {
          setValue(key, res?.data[key]);
        });
        setValue("custom_length", [res?.data?.custom_length_start, res?.data?.custom_length_end])
        const priorAction = await getPriorActionItems(sprintId).call;
        priorAction?.data.results.map((action) => {
          append({ ...action, attendees: action.attendees.map(member => member.id) })
        })
      } catch (error) { }
    }
  }

  const loadAttendees = async () => {
    try {
      const res = await getMemberList(id).call
      setAttendees(res.data?.results?.map(att => ({ value: att.id, label: att.name })));
    } catch (error) {

    }
  }

  /**
   * Deletes an action at the specified index.
   *
   * @param {number} index - The index of the action to delete.
   * @param {object} action - The action object to delete.
   * @return {Promise<void>} - A Promise that resolves when the action is deleted.
   */
  const deleteAction = async (index, action) => {
    if (action.hasOwnProperty('mission_sprint')) {
      const res = await deletePriorActionItems(action.id);
    }
    remove(index)
  }

  const activeSubmitGeneralForm = () => {
    refForm?.current.click()
  }

  const debounceOverview = useCallback(
    debounce(activeSubmitGeneralForm, AUTOSAVING_DELAY)
    , []);

  useEffect(() => {
    reset()
    loadSprint()
    loadAttendees()
  }, [sprintId]);

  return (
    <div className='general '>
      <form onSubmit={handleSubmit(saveGeneralInformation)}>
        <div>
          <div className="flex justify-end font-18">
            <div>
              <div>
                <button type="submit" className="btn-edit d-none" ref={e => refForm.current = e}>Save</button>
              </div>
            </div>
          </div>
        </div>
        <Row gutter={10} className={`${disable ? 'pointer-events-none' : ''} p-10`}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <p>Start Planning Date</p>
            <DateDebounce name="start" control={control} debounceAction={debounceOverview} className="w-100" />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <p>End Planning Date</p>
            <DateDebounce name="end" control={control} debounceAction={debounceOverview} className="w-100" />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <p>{t('commons.intervention')} Length</p>
            <Controller
              name="length"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="select-class w-100"
                  placeholder="Select an option"
                  optionFilterProp="children"
                  bordered={false}
                  onChange={(net) => {
                    field.onChange(net)
                    debounceOverview()
                  }}
                  options={
                    [
                      { value: 'ONE_WEEK', label: 'One week' },
                      { value: 'TWO_WEEKS', label: 'Two weeks' },
                      { value: 'ONE_MONTH', label: 'One month' },
                      { value: 'CUSTOM_LENGTH', label: 'Custom' }
                    ]}
                />
              )}
            />
            <div className={watchLengthType == 'CUSTOM_LENGTH' ? "block" : "hidden"}>
              <p>Select Range</p>
              <Controller
                name="custom_length"
                control={control}
                render={({ field }) => (
                  <RangePicker
                    className="w-100"
                    format="MM-DD-YYYY"
                    value={field?.value?.length && field?.value[0] ? [moment(field?.value[0]), moment(field?.value[1])] : []}
                    onChange={(net) => {
                      field.onChange(net)
                      if (field?.value?.length && field?.value[0]) {
                        debounceOverview()
                      }
                    }}
                  />
                )}
              />
            </div>

          </Col>
        </Row>
        <Row gutter={10} className="p-10">
          <Col xs={24}>
            <p>What are you trying to accomplish in this {t('commons.intervention').toLowerCase()}?</p>
            <p>
              <Controller
                name="trying_to_accomplish"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    rows={5}
                    className="w-100"
                    {...register("trying_to_accomplish")}
                    onChange={(net) => {
                      field.onChange(net)
                      debounceOverview()
                    }}
                  />
                )}
              />
            </p>
          </Col>
        </Row>
        {
          sprintData?.why_sprinting == "TO_CREATE_DEMAND" &&
          <Row gutter={10} className="p-10">
            <Col>
              <p><b>To Create Demand</b></p>
              <p>What are you sprinting on to create demand? Pull in Demand Creation section from Project Team</p>
              <InputDebounce name="why_to_create_demand" control={control} debounceAction={debounceOverview} className="w-100" />
            </Col>
          </Row>
        }
        {
          sprintData?.why_sprinting == "TO_CREATE_DEMAND" &&
          <Row gutter={10} className="p-10">
            <Col xs={24}>
              <div className="w-100 mt-15">
                <p className="flex">Are there any items that need to be followed up on prior to running this {t('commons.intervention').toLowerCase()}?
                  <div className="borderArrayIcon ml-15" onClick={() => append({ description: "", attendees: [] })}>
                    <span>+</span>
                  </div>
                </p>
                {
                  controlledFields.map((action, index) => (
                    <Row gutter={20} className="mt-5" key={action.id}>
                      <Col span={12}>
                        <p>Action</p>
                        <InputDebounce name={`actions.${index}.description`} control={control} debounceAction={debounceOverview} className={`w-100 ${errors[`actions.${index}.description`] ? "border-error" : ""}`} />
                      </Col>
                      <Col span={10}>
                        <label>Attendees</label>
                        <Controller
                          name={`actions.${index}.attendees`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              className={`select-class-multiple ${errors[`actions.${index}.attendees`] ? "border-error" : ""}`}
                              mode="multiple"
                              size="default"
                              placeholder="Select attendees"
                              bordered={false}
                              onChange={(net) => {
                                field.onChange(net)
                                debounceOverview()
                              }}
                              options={attendees}
                            />
                          )}
                        />
                      </Col>
                      <Col span={2} className="flex items-center">
                        <div className="borderArrayIcon" onClick={() => {
                          deleteAction(index, action)
                          debounceOverview()
                        }}>
                          <span>-</span>
                        </div>
                      </Col>
                    </Row>
                  ))
                }
              </div>
            </Col>
          </Row>
        }
      </form>
      <Evaluation />
      <DebounceLoading />
    </div >
  );
};

export default General;
