// PDFDocument.js
import React from 'react';
import {type} from 'os';

import {Document, Page, Text, View, StyleSheet} from '@react-pdf/renderer';
import {MetricTrend} from 'types/PftJourney';

const styles = StyleSheet.create({
  page: {fontSize: '12px', fontWeight: 'thin', position: 'relative'},
  row: {flexDirection: 'row'},
  check: {
    width: '12px',
    height: '12px',
    border: '1px solid #3C5DD6',
    backgroundColor: '#3C5DD6',
    marginRight: '5px',
  },
  checkBlanck: {
    width: '12px',
    height: '12px',
    border: '1px solid #000',
    marginRight: '5px',
  },
  rowMargin: {flexDirection: 'row', marginTop: '15px'},
  rowMargin5: {flexDirection: 'row', marginTop: '5px'},
  rowHeaderAtte: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#102431',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderNote: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#E29016',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderObservation: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#5B0949',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderBarries: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#27339F',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderAction: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#236E08',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderFeed: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#A63434',
    color: '#fff',
    padding: '5px',
  },
  w100: {width: '100%'},
  w70: {width: '70%'},
  w50: {width: '50%'},
  w33: {width: '33%'},
  w25: {width: '25%'},
  mt80: {marginTop: '80px'},
  mt50: {marginTop: '50px'},
  mt20: {marginTop: '20px'},
  mt15: {marginTop: '15px'},
  mt10: {marginTop: '10px'},
  mr15: {marginRight: '15px'},
  font15: {fontSize: '17px', fontWeight: '900'},
  list: {
    flexDirection: 'row',
    marginTop: '10px',
    marginBottom: '5px',
  },
  hrGray: {borderBottom: '.5px', height: '5px', width: '100%', opacity: '.5'},
  hrBlue: {
    borderBottom: '1px',
    borderBottomColor: '#4DA3D9',
    height: '5px',
    width: '100%',
  },
  logo: {width: '58px', height: '39px', marginBottom: '15px'},
  hidden: {opacity: '0', height: '0px', width: '0px'},

  blueContainer: {
    backgroundColor: '#4272EF',
    padding: '25px',
    color: '#fff',
    position: 'relative',
  },
  whiteContainer: {
    padding: '25px',
    paddingTop: '30px',
    paddingBottom: '100px',
    color: 'black',
    position: 'relative',
  },
  fontXS: {
    fontSize: '8px',
  },
  fontSM: {
    fontSize: '10px',
  },
  fontMD: {
    fontSize: '14px',
  },
  subtitle: {
    fontSize: '12px',
    marginBottom: '5px',
  },
  updatedOn: {
    fontSize: '22px',
    border: '1px dotted #fff',
    padding: '5px 10px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '25px',
    marginBottom: '5px',
  },
  circle: {
    border: '8px solid #FCC41D',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  positionCircle: {
    position: 'absolute',
    right: '25px',
    bottom: '-50px',
  },
  boldedQuestions: {
    fontWeight: '600',
    fontSize: '18px',
    letterSpacing: '-0.5px',
    marginBottom: '10px',
  },
  responses: {
    fontWeight: '500',
    fontSize: '14px',
    marginBottom: '10px',
  },
  table: {display: 'table', width: 'auto', marginBottom: 10},
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#EAEAEA',
    color: 'black',
    paddingTop: 8,
    paddingBottom: 8,
  },
  tableRow: {flexDirection: 'row'},
  tableCell: {flex: 1, padding: 4},
  // New styles for the donut shape
  donutOuter: {
    backgroundColor: '#FCC41D',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    zIndex: 1, // To ensure it's above other elements
  },
  donutInner: {
    backgroundColor: '#4272EF',
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    zIndex: 2, // To ensure it's above the outer circle
  },
});

export const ActionPlanPDF = ({data}) => {
  const goals = data.goals ?? [];

  function formatMetricTrend(metricTrend) {
    // Split the string by underscores and capitalize the first letter of each word
    const words = metricTrend
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase());

    // Join the words with a space to create the desired format
    return words.join(' ');
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.blueContainer}>
          <View style={styles.mt50}>
            <Text style={styles.subtitle}>Author: {data.author} </Text>
            <Text style={styles.title}>{data.title}</Text>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={styles.updatedOn}>Updated on {data.updatedOn}</Text>
            </View>
          </View>
        </View>

        <View style={styles.whiteContainer}>
          <View style={styles.mt20}>
            <Text style={styles.boldedQuestions}>
              What is the practice's vision for transformation?
            </Text>
            <Text style={styles.responses}>{data.vision}</Text>
            <Text style={styles.boldedQuestions}>
              What are the current priorities of the practice?
            </Text>
            <Text style={styles.responses}>{data.priorities}</Text>
            <Text style={styles.boldedQuestions}>
              Is there alignment between current priorities and goals?
            </Text>
            <Text style={styles.responses}>{data.alignment}</Text>
            <Text style={styles.boldedQuestions}>Strategic Goal(s)</Text>
            <Text style={styles.responses}>{data.strategicGoals}</Text>
            {/* Table */}
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <View style={styles.tableCell}>
                  <Text>Metric</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Target State</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Current State</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>Current Status</Text>
                </View>
              </View>
              {goals.map((row, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableCell}>
                    <Text>{row.title}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{row.target}</Text>
                  </View>
                  <View>
                    <Text>{row.current}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text
                        style={{
                          backgroundColor:
                            row.trend === MetricTrend.BELOW_TARGET
                              ? '#B50000'
                              : row.trend === MetricTrend.ABOVE_TARGET
                              ? '#258750'
                              : '#FFCB00',
                          borderRadius: '20px',
                          padding: '5px 10px',
                          color: '#fff',
                        }}>
                        {row.trend ? formatMetricTrend(row.trend) : 'N/A'}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </View>

        {/* Donut shape: Outer circle */}
        <View style={styles.donutOuter}></View>

        {/* Donut shape: Inner circle (transparent) */}
        <View style={styles.donutInner}></View>
      </Page>
    </Document>
  );
};
