import React, {useEffect, useState} from 'react';
import {Select, Tooltip} from 'antd';
import {getNetworkMeasures} from 'Services/Network.service';
import {useDispatch, useSelector} from 'react-redux';
import {selectNetworkMeasures} from 'ReduxV2/state';
import {
  updateTJCMissionMeasures,
  deleteTJCMissionMeasure,
} from 'Services/PftJourney.service';
import {getNotification} from 'Components/GetNotification';

type NetworkMeasureSelectProps = {
  disabled?: boolean;
  placeholder?: string;
  tjCompetencyId: number | null;
  defaultValue?: number[];
  tjcMissionMeasures: any[];
  disabledMessage?: string;
};

const findAddedAndRemovedValues = (
  before: number[],
  after: number[]
): {added: number[]; removed: number[]} => {
  const added: number[] = after.filter((value) => !before.includes(value));
  const removed: number[] = before.filter((value) => !after.includes(value));

  return {added, removed};
};

export const NetworkMeasureSelect: React.FC<NetworkMeasureSelectProps> = ({
  disabled,
  placeholder,
  tjCompetencyId,
  defaultValue,
  tjcMissionMeasures,
  disabledMessage = 'Please add this milestone first.',
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [tjcMeasures, setTJCMeasures] = useState<any[]>(
    tjcMissionMeasures ?? []
  );
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    defaultValue ?? []
  );
  const dispatch = useDispatch();
  let isMounted = false;

  //@ts-ignore
  const storeMeasures = useSelector((state) => state.networkMeasure.measures);
  //@ts-ignore
  const orgId = useSelector((state) => state.network.value);

  const updateMissionMeasures = async (
    tjCompetencyId: number,
    measures: number[]
  ) => {
    try {
      const response = await updateTJCMissionMeasures(tjCompetencyId, measures)
        .call;
      if (response?.data) {
        const tjcMissionMeasureToAdd = response?.data[0];
        setTJCMeasures([...tjcMeasures, tjcMissionMeasureToAdd]);
      }
    } catch (error) {}
  };

  const fetchNetworkMeasures = async (orgId: number) => {
    try {
      const response = await getNetworkMeasures(orgId).call;
      if (isMounted && response?.data?.results) {
        dispatch(selectNetworkMeasures(response.data.results));
        setOptions(response.data.results);
      }
    } catch (error) {}
  };

  const deleteMissionMeasure = async (tjc_mission_measure: number) => {
    try {
      await deleteTJCMissionMeasure(tjc_mission_measure).call;
    } catch (error) {}
  };

  // If network measures are not in the store, fetch them from the API
  useEffect(() => {
    isMounted = true; // Flag to track component's mount status

    if (!storeMeasures.length) {
      fetchNetworkMeasures(orgId);
    } else {
      setOptions(storeMeasures);
    }

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, []);

  const selectOptions = options.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleChange = (value: number[]) => {
    const {added, removed} = findAddedAndRemovedValues(selectedOptions, value);
    setSelectedOptions(value);
    if (added.length > 0) {
      updateMissionMeasures(tjCompetencyId as number, added);
      getNotification('success', {
        header: 'Success',
        body: 'Measure added.',
      });
    }
    if (removed.length > 0) {
      removed.forEach((measure) => {
        const missionMeasureToRemove = measure; // Gets mission measure from list of all measures
        const tjcMissionMeasureToRemove = tjcMeasures.find(
          (measure) => measure.mission_measure.id === missionMeasureToRemove
        ); // Gets TJC mission measure from list of TJC mission measures

        if (tjcMissionMeasureToRemove) {
          deleteMissionMeasure(tjcMissionMeasureToRemove.id);
          setTJCMeasures(
            tjcMeasures.filter(
              (measure) => measure.id !== tjcMissionMeasureToRemove?.id
            )
          );
        }
      });
      getNotification('success', {
        header: 'Success',
        body: `Measure${removed.length > 1 ? 's' : ''} removed.`,
      });
    }
  };

  if (disabled) {
    return (
      <Tooltip title={disabledMessage}>
        <Select
          mode='multiple'
          allowClear
          disabled={true}
          options={selectOptions}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          filterOption={(inputValue, option) =>
            option?.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
        />
      </Tooltip>
    );
  } else {
    return (
      <Select
        mode='multiple'
        allowClear
        options={selectOptions}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(inputValue, option) =>
          option?.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        }
      />
    );
  }
};
