import React from 'react';
import Container from '../Container';
import {Row, Col} from 'antd';
import style from './style.module.scss';

import { withTranslation } from "react-i18next"

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Row>
          <Col sm={24} md={6}></Col>
          <Col sm={24} md={18} className={style.mainFooter}>
            <div className={style.footersInner}>
              <ul className={style.footersInner__list}>
                <li>© {new Date().getFullYear()} {this.props.t('Company_name')} </li>
                <li>
                  <a>{this.props.t('Footer.Reserved')}</a>
                </li>
              </ul>
              <ul className={style.footersInner__right}>
                <li>
                  <a>{this.props.t('Footer.Legal')}</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={24} md={1}></Col>
        </Row>
      </footer>
    );
  }
}

export default withTranslation()(Footer)
