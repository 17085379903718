import React from 'react';
import {Button, Modal} from 'antd';
import { FileRecord } from '../ScoreCardHistoryTable/ScoreCardHistoryTable';
import { deleteScoreCardLink } from 'Services/Sprints.service';
import useManualApiWithLoading from 'Hooks/useManualApiWithLoading';

type DeleteScoreCardModalProps = {
  record: FileRecord | undefined,
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  callback: () => void;
}

const DeleteScoreCardModal: React.FC<DeleteScoreCardModalProps> = ({record, open, setOpen, callback}) => {
  const { callFunction:deleteScoreCard } = useManualApiWithLoading({
    apiFunction: deleteScoreCardLink
  });

  const handleSave = () => {
    deleteScoreCard(record?.id);
    // As backend has a delay 
    setTimeout(() => {callback()}, 1000);
    setOpen(false);
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const displayText = record?.link ?? record?.filename;

  return (
    <Modal 
      data-testid='overwriteChangesModal'
      aria-modal="true"
      aria-labelledby="modalTitle"
      open={open}
      footer={null}
    >
      <div data-testid="overwriteModalContent" className='full-height full-width flex flex-col items-center gap-3 mt-6  pb-0 px-4'>
        <h1 data-testid="overwriteModalTitle" className='text-3xl font-bold'>Delete File?</h1>
        <p className='text-sm text-black'>
          Are you sure you would like to delete this file?
        </p>
        <a className="underline underline-offset-auto text-black ml-5" href={displayText} target="_blank" rel="noopener noreferrer" download>
          {displayText}
        </a>

        <div data-testid="overwriteModalButtons" className='flex flex-row justify-end mt-3'>
          <Button data-testid='acceptChangesButton' type='primary' onClick={handleSave}>Confirm</Button>
          <Button data-testid='cancelChangesButton' type='text' onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteScoreCardModal;
