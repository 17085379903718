import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment';
import './PatPDF.scss';

const styles = StyleSheet.create({
  page: { fontSize: '12px', fontWeight: 'thin', position: 'relative' },
  row: { flexDirection: 'row' },
  check: {
    width: '12px',
    height: '12px',
    border: '1px solid #3C5DD6',
    backgroundColor: '#3C5DD6',
    marginRight: '5px',
  },
  checkBlanck: {
    width: '12px',
    height: '12px',
    border: '1px solid #000',
    marginRight: '5px',
  },
  rowMargin: { flexDirection: 'row', marginTop: '15px' },
  rowMargin5: { flexDirection: 'row', marginTop: '5px' },
  rowHeaderAtte: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#102431',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderNote: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#E29016',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderObservation: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#5B0949',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderBarries: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#27339F',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderAction: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#236E08',
    color: '#fff',
    padding: '5px',
  },
  rowHeaderFeed: {
    fontSize: '15px',
    flexDirection: 'row',
    marginTop: '15px',
    marginBottom: '5px',
    backgroundColor: '#A63434',
    color: '#fff',
    padding: '5px',
  },
  w100: { width: '100%' },
  w70: { width: '70%' },
  w50: { width: '50%' },
  w33: { width: '33%' },
  w25: { width: '25%' },
  mt80: { marginTop: '80px' },
  mt50: { marginTop: '50px' },
  mt20: { marginTop: '20px' },
  mt15: { marginTop: '15px' },
  mt10: { marginTop: '10px' },
  mr15: { marginRight: '15px' },
  font15: { fontSize: '17px', fontWeight: '900' },
  list: {
    flexDirection: 'row',
    marginTop: '10px',
    marginBottom: '5px',
  },
  hrGray: { borderBottom: '.5px', height: '5px', width: '100%', opacity: '.5' },
  hrBlue: {
    borderBottom: '1px',
    borderBottomColor: '#4DA3D9',
    height: '5px',
    width: '100%',
  },
  logo: { width: '58px', height: '39px', marginBottom: '15px' },
  hidden: { opacity: '0', height: '0px', width: '0px' },

  blueContainer: {
    backgroundColor: '#4272EF',
    padding: '25px',
    paddingTop: '0px',
    paddingBottom: '100px',
    color: '#fff',
    position: 'relative',
  },
  fontXS: {
    fontSize: '8px',
  },
  fontSM: {
    fontSize: '10px',
  },
  fontMD: {
    fontSize: '14px',
  },
  subtitle: {
    fontSize: '13px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '25px',
    marginBottom: '5px',
  },
  circle: {
    border: '8px solid #FCC41D',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
  },
  positionCircle: {
    position: 'absolute',
    right: '25px',
    bottom: '-50px',
  },
});

/**
 * Render a PDF document containing practice assessment results.
 *
 * @param {Object} inspectData - The inspection data.
 * @param {Array} allMilestones - The milestones data.
 * @return {JSX.Element} The PDF document component.
 */
const PatPDF = ({ inspectData, allMilestones }) => {
  return (
    <Document>
      <Page Page size='A4' style={styles.page}>
        <View style={styles.blueContainer}>
          <View style={styles.mt50}>
            <Text style={styles.subtitle}>{inspectData?.title}:</Text>
            <Text style={styles.title}>Practice Assessment Results</Text>
          </View>
          <View style={{ marginTop: '15px' }}>
            <View>
              <Text
                style={{
                  color: '#fff',
                  fontSize: '15px',
                }}>
                {inspectData?.current_phase?.name}
              </Text>
            </View>
          </View>
          <View style={styles.positionCircle}>
            <View style={styles.circle}></View>
          </View>
        </View>
        <View style={{ ...styles.row, marginTop: '100px' }}>
          {inspectData?.collaborators.length ? (
            <View style={{ ...styles.w50, marginLeft: '15px' }}>
              <View style={styles.list}>
                <View style={styles.w25}>
                  <Text style={{ ...styles.fontSM, textDecoration: 'underline' }}>
                    Collaborators:
                  </Text>
                </View>
                <Text style={{ ...styles.fontSM, textDecoration: 'underline' }}>
                  Emails:
                </Text>
              </View>
              {inspectData?.collaborators?.map((collaborator, index) => (
                <View style={styles.row} key={index}>
                  <View style={styles.w25}>
                    <Text style={styles.fontSM}>{collaborator?.name}</Text>
                  </View>
                  <Text style={styles.fontSM}>{collaborator?.email}</Text>
                </View>
              ))}
            </View>
          ) : (
            <View style={{ ...styles.w50, marginLeft: '15px' }}></View>
          )}
          <View>
            <Text style={{ ...styles.font15, textDecoration: 'underline' }}>
              Category Scores:
            </Text>
            {inspectData?.category_scores?.map((category, index) => (
              <View style={{ ...styles.row, ...styles.mt10 }} key={index}>
                <View style={styles.w25}>
                  <Text style={styles.fontSM}>{category?.category}</Text>
                </View>
                <View style={{ ...styles.w25, textAlign: 'right' }}>
                  <Text style={styles.fontSM}>{category?.percentage}%</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        <View
          style={{
            ...styles.list,
            position: 'absolute',
            bottom: '0',
            paddingLeft: '25px',
          }}>
          <View style={styles.w70}>
            <Text style={styles.fontXS}>
              Completed {moment(inspectData?.created_at).format('DD/MM/YYYY')}
            </Text>
          </View>
          <Text style={styles.fontXS}>
            Created by: {inspectData?.created_by?.name}
          </Text>
        </View>
      </Page>
      {allMilestones?.filtered_competencies?.map((item, index) => (
        <Page Page size='A4' style={styles.page} key={index}>
          <View
            style={{
              ...styles.list,
              padding: '25px',
              paddingTop: '0px',
              paddingBottom: '15px',
            }}>
            <View style={styles.w70}>
              <Text style={styles.fontXS}>
                Completed {moment(inspectData?.created_at).format('DD/MM/YYYY')}
              </Text>
            </View>
            <Text style={styles.fontXS}>
              Created by: {inspectData?.created_by?.name}
            </Text>
          </View>
          <View style={styles.mt15}>
            <Text style={{ ...styles.title, paddingLeft: '25px' }}>
              Goals to Reach Next Phase
            </Text>
          </View>
          <View style={{ position: 'relative' }}>
            <View
              style={{
                ...styles.list,
                backgroundColor: '#4272EF',
                color: '#fff',
                padding: '15px',
                width: '95%',
                borderBottomRightRadius: '50%',
                borderTopRightRadius: '50%',
              }}>
              <View style={styles.w70}>
                <Text style={styles.fontMD}>
                  Milestone {item?.competency?.ref_id} - {item?.competency?.title}
                </Text>
              </View>

              {/*  <Text style={styles.fontXS}>
              Created by: {inspectData?.created_by?.name}
            </Text> */}
            </View>
            <View
              style={{
                position: 'absolute',
                zIndex: '-1',
                top: '-18px',
                right: '50px',
              }}>
              <View style={styles.circle}></View>
            </View>
          </View>

          <View style={{ padding: '25px' }}>
            <Text
              style={{
                fontWeight: 'bold',
                marginBottom: '25px',
                fontSize: '16px',
              }}>
              Description
            </Text>
            <Text style={{ fontWeight: 'thin' }}>
              {item?.competency?.description}
            </Text>
            <Text
              style={{
                fontWeight: 'bold',
                marginTop: '25px',
                marginBottom: '25px',
                fontSize: '16px',
              }}>
              Your Answer
            </Text>
            {item?.current_option ? (
              <Text style={{ fontWeight: 'thin' }}>
                {item?.current_option?.description}
              </Text>
            ) : (
              <Text
                style={{
                  fontWeight: 'thin',
                  color: '#DEDEDE',
                  fontSize: '18px',
                }}>
                N/A
              </Text>
            )}
            <Text
              style={{
                fontWeight: 'bold',
                marginTop: '25px',
                marginBottom: '25px',
                fontSize: '16px',
              }}>
              Target State
            </Text>
            <Text style={{ fontWeight: 'thin' }}>
              {item?.target_option?.description}
            </Text>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PatPDF;
