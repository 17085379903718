import {
    FETCH_METADATA_SUCCESS,
    FETCH_METADATA_FAILED
} from '../../Redux.constants';
import { setMetadataSuccess, setMetadataFailed } from './Helper';

const initialState = {
    metadata: {
        phaseColors: {}
    }
}

export default function MetadataReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_METADATA_SUCCESS:
            return setMetadataSuccess(state, action.payload);
        case FETCH_METADATA_FAILED:
            return setMetadataFailed(state, action.payload);
        default:
            return {...state};
    }
};

