import React, { useState } from 'react';
import { Modal, Button, Input, Table } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import TagTable from 'Components/TagTable';

interface Tag {
  key: string;
  name: string;
  editable?: boolean;
}

const initialTags: Tag[] = [
  // ... populate initial tags here
];

const TagDirectory: React.FC<any> = ({visible, setVisible}) => {
  const [tags, setTags] = useState<Tag[]>(initialTags);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (value: string) => {
    setSearchText(value);
    // Perform search operation on your tags here
  };

  const handleEdit = (tagKey: string) => {
    // Set the tag to be editable
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Tag) => {
        return record.editable ? (
          <Input defaultValue={text} />
        ) : (
          <span>{text}</span>
        );
      },
    },
    // Add other columns as needed
  ];

  return (
      <Modal
        title="Network Tag Directory"
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            Save
          </Button>,
        ]}
      >
        <div className="flex flex-row gap-2" > 
          <div className="flex flex-row" > 
            <Input
              style={{height: '32px'}}
              placeholder="Search"
              onChange={e => handleSearch(e.target.value)}
            />
            <Button type="primary" icon={<SearchOutlined/>} >
            </Button>
          </div>
          <div className="flex flex-row" > 
            <Input
              style={{height: '32px'}}
              placeholder="Add Tag"
              onChange={e => handleSearch(e.target.value)}
            />
            <Button type="primary" icon={<PlusOutlined />} >
            </Button>
          </div>
        </div>
        {/* <Table columns={columns} dataSource={tags} pagination={{ pageSize: 25 }} /> */}
        <TagTable></TagTable>
      </Modal>
  );
};

export default TagDirectory;

