import React from 'react';
import {PlusOutlined} from '@ant-design/icons';
import { useTranslation } from "react-i18next";

interface AddNetworkCardProps {
  onClick: () => void;
}


export const AddNetworkCard: React.FC<AddNetworkCardProps> = ({onClick}) => {
  const { t } = useTranslation();
  return (
    <button
      type='button'
      className='group w-full bg-white p-4 rounded-lg shadow-lg min-h-[390px]'
      onClick={onClick}>
      <div className='flex flex-col justify-center items-center gap-2'>
        <span className='w-12 h-12 bg-primary rounded flex items-center justify-center transition-colors duration-150 group-hover:bg-primary-hover'>
          <PlusOutlined className='text-white text-xl leading-none' />
        </span>
        <h3 className='text-lg font-semibold'>{t('Create_a_Nework')}</h3>
      </div>
    </button>
  );
};
