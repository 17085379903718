import React from 'react';
import { Card, CardHeader, CardBody, CardFooter } from '../../../Components/Card';
import { ButtonElement } from '../../../Components/ButtonElement';
import ModalWindow from '../../../Components/ModalWindow';
import FormBlock from '../../../Components/FormBlock';
import style from '../style.module.scss';
import global from '../../../styles/global/style.module.scss';
class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      firstName: this.props.userDetails?.firstName,
      error: 'First Name is required',
      last_name_error: 'Last name is required',
      lastName: this.props.userDetails?.lastName,
      designation: this.props.userDetails.designation
        ? this.props.userDetails.designation
        : 'Unavailable',
    };
  }
  showValue = (text) => (text ? text : 'Unavailable');
  renderListElement = (label, value) => {
    return (
      <li>
        <label>{label}</label>
        <span>{this.showValue(value)}</span>
      </li>
    );
  };
  renderEditList = (label, value) => {
    return (
      <li>
        <FormBlock
          label={label}
          value={value}
          error={
            label === 'First Name'
              ? this.state.firstName === ''
                ? this.state.error
                : null
              : label === 'Last Name'
                ? this.state.lastName === ''
                  ? this.state.last_name_error
                  : null
                : null
          }
          onChange={(e) => {
            this.onInputChange(e, label);
          }}
        />
      </li>
    );
  };
  onInputChange = (e, label) => {
    switch (label) {
      case 'First Name':
        this.setState({ firstName: e.target.value });
        break;
      case 'Last Name':
        this.setState({ lastName: e.target.value });
        break;
      case 'Designation':
        this.setState({ designation: e.target.value });
        break;
    }
  };
  /* eslint-disable max-lines-per-function   */
  modalFooter = () => {
    return (
      <>
        <ButtonElement
          type=''
          children='Cancel'
          onClick={() => {
            this.setState({ showEditModal: false });
          }}
        />
        <ButtonElement
          loading={this.props.UserProfileReducer.updateProfile.loading}
          type='primary'
          children='Save'
          onClick={() => {
            let datatoSend = {
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              user_designation:
                this.state.designation === 'Unavailable'
                  ? ''
                  : this.state.designation,
            };
            this.state.firstName === '' || this.state.lastName === ''
              ? null
              : this.props.updateUserProfile(datatoSend, () => {
                this.setState({ showEditModal: false });
                window.location.reload(true);
              });
          }}
        />
      </>
    );
  };
  renderModalWindow = () => {
    return (
      <ModalWindow
        visible={this.state.showEditModal}
        onCancel={() => {
          this.setState({ showEditModal: false });
        }}
        footer={this.modalFooter()}>
        <h3>Edit Profile</h3>
        <ul className={global.verticalList}>
          {this.renderEditList('First Name', this.state.firstName)}
          {this.renderEditList('Last Name', this.state.lastName)}
          {this.renderEditList('Designation', this.state.designation)}
        </ul>
      </ModalWindow>
    );
  };
  render() {
    const { fullName, email, designation, address } =
      this.props.userDetails || {};
    return (
      <>
        {this.renderModalWindow()}

        <h3>{this.props.tabDetails.title}</h3>

        <ul className={style.userDetails}>
          {this.renderListElement('Name', fullName)}
          {this.renderListElement('Email', email)}
          {this.renderListElement('Designation', designation)}
          <div className={style.userDetails__button}>
            <ButtonElement
              children='Edit'
              className={style.userDetails__button__btn}
              onClick={() => {
                this.setState({ showEditModal: true });
              }}
            />
          </div>
        </ul>
      </>
    );
  }
}
export default UserDetails;
