import axios from 'axios';
import {API_BASE_URL} from '../../../../config/constants';

const API_Call = async (method, url, data, token, content_type) => {
  try {
    let res = await axios({
      url: `${API_BASE_URL}${url}`,
      method: method,
      data: data,
      headers: {
        'content-type': content_type,
        'Authorization': `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export default API_Call;
