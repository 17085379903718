import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {ButtonElement} from '../../../../../../Components/ButtonElement';
import style from '../../style.module.scss';
import {Tooltip} from 'antd';
import {Popover, Button} from 'antd';
import SVGIcons from '../../../../../../Components/SVGIcons';

class OrganisationProfile extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constantValue() {
    return (
      <div>
        <p>Content</p>
        <p>Content</p>
      </div>
    );
  }
  renderButton = ({params, organisationData, organisationName}) => (
    (
      <Popover
        placement='right'
        content={
          <div>
            <a className={style.popover_tab}>
              <span className={style.popover_icon}>
                <SVGIcons type={'SVG-phone'} />
              </span>
              <span>{organisationData?.phone}</span>
            </a>

            <a className={style.popover_tab}>
              <span className={style.popover_icon}>
                <SVGIcons type={'SVG-email'} />
              </span>
              <span>{organisationData?.contact_email}</span>
            </a>
          </div>
        }>
        <a>
          <span>{/* <SVGIcons type={'SVG-email'} /> */}</span>
          {/* <span>{organisationData.contact_email}</span> */}
        </a>
        <ButtonElement
          type='primary'
          className='organisation-btn'
          // children='Network Insights'
          children='Contact'
          // onClick={() =>
          //   this.props.history.push({
          //     pathname: `/${params.id}/organisation-insights/?orgName=${organisationName}`,
          //     data: organisationData,
          //   })
          // }
        />
      </Popover>
    )
  );
  render() {
    let {orgGroupData} = this.props;
    let {params} = this.props.match;
    const organisationName = new URL(window.location.href).searchParams.get(
      'orgName'
    );
    return (
      <div className={style.organizationProfile__content}>
        <div className={style.profileBrand}>
          <span className={style.contentImage}>
            <img
              src={
                orgGroupData
                  ? orgGroupData.image === null
                    ? 'https://openclipart.org/download/271918/blankYellowFolder.svg'
                    : orgGroupData.image
                  : ''
              }></img>
          </span>
        </div>
        <div className={style.profileCompany}>
          <h2>{orgGroupData ? orgGroupData.name : 'Unknown Group'}</h2>
          {/* <span className={style.contentBtn}>
            {this.renderButton({params, organisationData, organisationName})}
          </span> */}
        </div>
      </div>
    );
  }
}

export default withRouter(OrganisationProfile);
