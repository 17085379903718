import React from 'react';
import './index.scss';

export function Dropdown({ children, text, customButton, addOnClass }) {
  let btn = (
    <a href >
      <span>{text}</span>
    </a>
  );
  if (customButton) btn = customButton;

  return (
    <div className={addOnClass ? 'dropdown collapse' : 'dropdown'}>
      {btn}
      <div className='dropdown-menu'>{children}</div>
    </div>
  );
}
