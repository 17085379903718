import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Coloredcard, {Greycard} from './Dashboardhomecard';
import '../App.css';
import style from './style.module.scss';
import {getNotification} from '../../../Components/GetNotification';
import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import {dispatch} from 'rxjs/internal/observable/pairs';
import {saveAs} from 'file-saver';
import {AiOutlineDownload} from 'react-icons/ai';
import {BiLock} from 'react-icons/bi';

const Dashboardhomediv = (props) => {
  const [minimize, setminimize] = useState(true);
  return (
    <div className='DashboardHomeDiv mt-4'>
      <div className='row m-0 p-0'>
        <div className='col-12 BlackForeground Bold FontSize20'>
          <span>{props.title}</span>
          <span
            onClick={() => {
              setminimize(!minimize);
            }}>
            {minimize ? (
              <div className='NegativeImg FloatRight Pointer'></div>
            ) : (
              <div className='PlusImg FloatRight Pointer'></div>
            )}
          </span>
        </div>
        {minimize ? (
          <>
            <div className='col-12'>
              <hr className='hr1' />
            </div>

            <div className='col-6 mt-2'>
              <Coloredcard
                icon={'MarginRight10 PrimarycareImg'}
                text={'Primary Care'}
                style={{
                  style:
                    'ColoredDashboardDiv WhiteForeground dashboarddivgradient1 Flex',
                }}
                page={'new assessment'}
              />{' '}
            </div>
            <div
              className='col-6 mt-2 disabledd'
              style={{
                backgroundColor: '#b1b1b1',
                borderRadius: '5px',
                cursor: 'not-allowed',
                padding: '10px 20px 10px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Coloredcard
                icon={'MarginRight10 SpecialitycareImg'}
                text={'Speciality Care'}
                // page={'toolbox'}
                onClick={false}
                style={{
                  style: ' WhiteForeground dashboarddivgradient Flex  ',
                }}
              />
              <span className={style.lock_icon}>
                <BiLock size='1.3rem' />
              </span>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dashboardhomediv;

export const Dashboardhomedivgrey = (props) => {
  const [minimize, setminimize] = useState(true);
  const params = useParams();
  const [files, setFiles] = useState([]);

  const {hiddenUpload} = props;

  useEffect(() => {
    setFiles([...props.file]);
  }, [props.file]);

  const changeHandler = async (e, id = null) => {
    e.preventDefault();
    const allFiles = files.filter((file) => file.id !== id);
    let data = [];
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      if (
        (file.type === 'application/vnd.ms-excel' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg' ||
          file.type === 'image/jpeg' ||
          file.type === 'application/msword' ||
          file.type ==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'application/pdf' ||
          file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
        file.size < 2024000
      ) {
        // eslint-disable-next-line no-console
        if (
          file.type === 'application/msword' ||
          file.type ==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          file.type === 'application/vnd.ms-excel'
        ) {
          data.push({
            filename: file.name,
            date: new Date(),
            type: 'download',
            mission: params.id,
          });
        } else {
          data.push({
            filename: file.name,
            text: file.name,
            type: 'download',
            mission: params.id,
          });
        }
        props.handleSubmit(
          {
            filename: file.name,
            filepath: file,
            date: new Date(),
            text: file.name,
            type: 'download',
            mission: params.id,
          },
          id,
          props.type
        );
        if (id) {
          setFiles([...allFiles, data[data.length - 1]]);
        } else {
          setFiles([...files, data[data.length - 1]]);
        }
      } else {
        if (file.size > 2024000) {
          getNotification('error', {
            header: 'Failed!',
            body: 'file too large',
          });
        } else {
          getNotification('error', {
            header: 'Failed!',
            body: 'wrong file type',
          });
        }
      }
    }
  };
  const handleDownload = (element) => {
    var a = document.createElement('a'); //Create <a>
    var file = new Blob([element.filepath]);
    a.href = URL.createObjectURL(file);
    a.download = element.filename; //File name Here
    a.click(); //Downloaded file
  };

  return (
    <div className='DashboardHomeDiv mt-4'>
      <div className='row m-0 p-0'>
        <div className='col-12 BlackForeground Bold FontSize20'>
          <span>{props.title}</span>
          <span className={hiddenUpload ? 'd-none' : ''}>
            {props.isFileBtn && (
              <>
                <input
                  type='file'
                  id={props.type}
                  onChange={changeHandler}
                  style={{display: 'none', margin: '50px', fontSize: '10pt'}}
                />
                <label htmlFor={props.type} style={{margin: '50px'}}>
                  <a>
                    <span>upload</span>
                  </a>
                </label>
              </>
            )}
          </span>
          <span
            onClick={() => {
              setminimize(!minimize);
            }}>
            {minimize ? (
              <div className='NegativeImg FloatRight Pointer'></div>
            ) : (
              <div className='PlusImg FloatRight Pointer'></div>
            )}
          </span>
        </div>
        {minimize ? (
          <>
            <div className='col-12'>
              <hr className='hr1' />
            </div>
            <div className='col-12'>
              <h5> Sample Report </h5>
            </div>
            <div className='col-12'>
              <hr className='hr3' />
            </div>
            {props.file2.map((x) => (
              <div className='col-6 mt-2'>
                {' '}
                <Greycard file={x} />
              </div>
            ))}
            <div className='col-12'>
              <hr className='hr3' />
            </div>
            <div className={hiddenUpload ? 'd-none' : 'col-12'}>
              <h5> Uploaded Documents </h5>
            </div>
            <div className={hiddenUpload ? 'd-none' : 'col-12'}>
              <hr className='hr3' />
            </div>
            {files.map((x) => (
              <>
                <div className='col-6 mt-2'>
                  <div className='GreyDashboardDiv dashboarddivgradient3'>
                    <span>{x.filename}</span>
                    {props.isFileBtn && (
                      <>
                        <span
                          style={{
                            float: 'right',
                            cursor: 'pointer',
                            marginRight: '52px',
                          }}>
                          <input
                            type='file'
                            id={x.id}
                            onChange={(e) => changeHandler(e, x.id)}
                            style={{
                              display: 'none',
                              marginLeft: '10px',
                              fontSize: '15pt',
                            }}
                          />
                          <label htmlFor={x.id}>
                            <a>
                              <div
                                style={{
                                  transform: 'rotate(180deg)',
                                  marginTop: '-7px',
                                }}>
                                <img className='uploadingImg'></img>
                              </div>
                            </a>
                          </label>
                          <span
                            style={{
                              position: 'relative',
                              left: '40px',
                              bottom: '23px',
                              cursor: 'pointer',
                            }}>
                            <a onClick={() => handleDownload(x)} download>
                              <img className='DownloadImg'></img>
                            </a>
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
