import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../config/constants";
import { AbortableRequestConfig } from 'types/Request';
import { 
  EthnicityDetail, 
  PaginatedEthnicity, 
  PatchEthnicity, 
  PostEthnicity 
} from "types/Demographic";

const headers = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
    }
  }
}

export function getEthnicities(id: number): {
  call: Promise<AxiosResponse<PaginatedEthnicity>>;
  controller: AbortController;
} {
  const controller = new AbortController();

  return {
    call: axios.get(`${API_BASE_URL}patient-ethnicities/?demographics=${id}`, {
      signal: controller.signal,
      ...headers()
    } as AbortableRequestConfig),
    controller
  }
}

export function postEthnicities(data: PostEthnicity): {
  call: Promise<AxiosResponse<EthnicityDetail>>;
} {
  return {
    call: axios.post(`${API_BASE_URL}patient-ethnicities/`, data, headers())
  }
}

export function patchEthnicities(data: PatchEthnicity): {
  call: Promise<AxiosResponse<EthnicityDetail>>;
} {
  return {
    call: axios.patch(`${API_BASE_URL}patient-ethnicities/${data.id}/`, data, headers())
  }
}

export function deleteEthnicities(id: number): {
  call: Promise<AxiosResponse<void>>;
} {
  return {
    call: axios.delete(`${API_BASE_URL}patient-ethnicities/${id}/`, {
      ...headers()
    }),
  }
}